<template>
  <f7-page
    name="register"
    login-screen
  >
    <div class="text-align-center">
      <img
        class="img_logo"
        :src="APP_LOGO"
        :alt="APPLICATION_NAME"
      >
    </div>

    <RegisterFrom />

    <f7-list>
      <f7-button
        name="btnEnter"
        col
        button
        button-large
        button-raised
        fill
        :disabled="disableRegisterButton"
        class="botonPrimary"
        style="color:#FFFFFF"
        @click="registerUser"
      >
        {{ $t("Register_registerText") }}
      </f7-button>

      <f7-button
        name="btnEnter"
        col
        button
        button-large
        button-raised
        fill
        class="botonPrimary"
        style="color:#FFFFFF"
        @click="goBack"
      >
        {{ $t("Register_backText") }}
      </f7-button>
    </f7-list>
  </f7-page>
</template>

<script>
import {
  mapActions, mapGetters,
} from 'vuex';
import RegisterFrom from '../../components/accounts/RegisterForm.vue';
import { APP_LOGO, APPLICATION_NAME } from '../../js/constants';

export default {
  name: 'Register',
  components: {
    RegisterFrom,
  },
  data() {
    return {
      username: '',
      password: '',
      APP_LOGO,
      APPLICATION_NAME,
      btnForgotPassword: {
        text: this.$t('Login_forgotPass_text'),
        urlDestiny: '#',
      },
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapGetters('authentication', ['disableRegisterButton', 'userRegister']),
  },
  methods: {
    async registerUser() {
      this.$f7.preloader.show();
      try {
        await this.fetchRegisterUser();
        this.$f7.dialog.alert(this.$t('Register_Successful'));
        this.$f7.views.main.router.navigate('/accounts/loginPage/', { reloadCurrent: true });
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    goBack() {
      this.$f7.views.main.router.navigate('/accounts/loginPage/', { reloadCurrent: true });
    },
    ...mapActions('authentication', ['fetchRegisterUser']),
  },
};
</script>
