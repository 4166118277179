<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-machinery-form"
  >
    <DxForm
      id="machineryForm"
    >
      <DxGroupItem :col-count="6">
        <DxGroupItem
          css-class="content-combo"
          :col-span="3"
        >
          <!-- <DxSimpleItem
            name="code"
            :data-field="$t('DxMachineryForm_code_caption')"
            :editor-options="{
              value: actualCode,
              onValueChanged: onChangeCode,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_code_required')" />
          </DxSimpleItem> -->
          <DxSimpleItem
            name="description"
            :data-field="$t('DxMachineryForm_description_caption')"
            :editor-options="{
              value: actualDescription,
              onValueChanged: onChangeDescription,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_description_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="brand"
            :data-field="$t('DxMachineryForm_brand_caption')"
            :editor-options="{
              value: actualBrand,
              onValueChanged: onChangeBrand,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_brand_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="model"
            :data-field="$t('DxMachineryForm_model_caption')"
            :editor-options="{
              value: actualModel,
              onValueChanged: onChangeModel,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_model_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="serial_number"
            :data-field="$t('DxMachineryForm_serial_number_caption')"
            :editor-options="{
              value: actualSerialNumber,
              onValueChanged: onChangeSerialNumber,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_serial_number_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="plate"
            :data-field="$t('DxMachineryForm_plate_caption')"
            :editor-options="{
              value: actualPlate,
              onValueChanged: onChangePlate,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_plate_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="internal_code"
            :data-field="$t('DxMachineryForm_internal_code_caption')"
            :editor-options="{
              value: actualInternalCode,
              onValueChanged: onChangeInternalCode,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_internal_code_required')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem
          :col-span="3"
          css-class="content-combo"
        >
          <DxSimpleItem
            name="family"
            :data-field="$t('DxMachineryForm_family_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: familyAvailables,
              value: actualFamily,
              onValueChanged: onChangeFamily,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_family_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="subfamily"
            :data-field="$t('DxMachineryForm_subfamily_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: subfamilyAvailables,
              value: actualSubfamily,
              onValueChanged: onChangeSubfamily,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_subfamily_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="purchase"
            :data-field="$t('DxMachineryForm_purchase_caption')"
            editor-type="dxDateBox"
            :editor-options="{
              value: actualPurchase,
              onValueChanged: onChangePurchase,
              displayFormat: 'yyyy/MM/dd',
              disabledDates:disableSundays,
            }"
          />
          <DxSimpleItem
            name="attachment"
            :data-field="$t('DxMachineryForm_attachment_caption')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: actualAttachment,
              onValueChanged: onChangeAttachment,
            }"
          />
          <DxSimpleItem
            name="status"
            :data-field="$t('DxMachineryForm_status_caption')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: actualStatus,
              onValueChanged: onChangeStatus,
            }"
          />
        </DxGroupItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';
import Api from '../../services/Api';

export default {
  name: 'DxMachineryForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    editForm: { type: Boolean, default: false },
  },
  data() {
    return {
      // UTILITIES
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      loaded: false,
      // FORM VALUES
      actualCode: '',
      actualDescription: '',
      actualBrand: '',
      actualModel: '',
      actualSerialNumber: '',
      actualPlate: '',
      actualInternalCode: '',
      actualFamily: '',
      actualSubfamily: '',
      actualPurchase: moment().format('MM/DD/YYYY'),
      actualAttachment: false,
      actualStatus: true,
      familyAvailables: [],
      subfamilyAvailables: [],
      keyValues: [],
    };
  },
  computed: {
    ...mapState('Machinery', ['actualMachinery']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    overlay.baseZIndex(9999);
    try {
      const itemsType = await Api.getItemsType();
      this.keyValues = JSON.parse(itemsType.response);
      this.formattingFamilyAvailables(JSON.parse(itemsType.response));
      if (this.editForm) {
        this.setValuesActualMachinery();
        this.onInitializedSubfamily();
      } else {
        this.setValuesNewMachinery();
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // SET VALUES NEW MACHINERY
    setValuesNewMachinery() {
      this.setCode('');
      this.setDescription('');
      this.setBrand('');
      this.setModel('');
      this.setSerialNumber('');
      this.setPlate('');
      this.setInternalCode('');
      this.setFamily('');
      this.setSubfamily('');
      this.setPurchase(moment().format('YYYY-MM-DD'));
      this.setAttachment(false);
      this.setStatus(true);
    },
    // SET VALUES ACTUAL MACHINERY
    setValuesActualMachinery() {
      this.actualCode = this.actualMachinery.code;
      this.actualDescription = this.actualMachinery.description;
      this.actualBrand = this.actualMachinery.brand;
      this.actualModel = this.actualMachinery.model;
      this.actualSerialNumber = this.actualMachinery.serialNumber;
      this.actualPlate = this.actualMachinery.plate;
      this.actualInternalCode = this.actualMachinery.internalCode;
      this.actualFamily = this.actualMachinery.family;
      this.actualSubfamily = this.actualMachinery.subfamily;
      this.actualPurchase = moment(this.actualMachinery.purchase, 'YYYY-MM-DD').format('MM/DD/YYYY');
      this.actualAttachment = this.actualMachinery.attachment;
      this.actualStatus = this.actualMachinery.status;
    },
    // EVENTS HANDLER ITEMS FORM
    onChangeCode(e) {
      this.actualCode = e.value;
      this.setCode(e.value);
    },
    onChangeDescription(e) {
      this.actualDescription = e.value;
      this.setDescription(e.value);
    },
    onChangeBrand(e) {
      this.actualBrand = e.value;
      this.setBrand(e.value);
    },
    onChangeModel(e) {
      this.actualModel = e.value;
      this.setModel(e.value);
    },
    onChangeSerialNumber(e) {
      this.actualSerialNumber = e.value;
      this.setSerialNumber(e.value);
    },
    onChangePlate(e) {
      this.actualPlate = e.value;
      this.setPlate(e.value);
    },
    onChangeInternalCode(e) {
      this.actualInternalCode = e.value;
      this.setInternalCode(e.value);
    },
    onChangeFamily(e) {
      this.actualFamily = e.value;
      this.setFamily(e.value);
      this.actualSubfamily = '';
      this.setSubfamily('');
      for (const keyValue of this.keyValues) {
        if (keyValue.family === `ITEM_SUBFAMILY_${this.actualFamily}`) {
          this.subfamilyAvailables = keyValue.values;
        }
      }
    },
    onInitializedSubfamily() {
      for (const keyValue of this.keyValues) {
        if (keyValue.family === `ITEM_SUBFAMILY_${this.actualFamily}`) {
          this.subfamilyAvailables = keyValue.values;
        }
      }
    },
    onChangeSubfamily(e) {
      this.actualSubfamily = e.value;
      this.setSubfamily(e.value);
    },
    onChangePurchase(e) {
      this.actualPurchase = e.value;
      this.setPurchase(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DD'));
    },
    onChangeStatus(e) {
      this.actualStatus = e.value;
      this.setStatus(e.value);
    },
    onChangeAttachment(e) {
      this.actualAttachment = e.value;
      this.setAttachment(e.value);
    },
    formattingFamilyAvailables(keyValues) {
      for (const keyValue of keyValues) {
        if (keyValue.family === 'ITEM_FAMILY') {
          for (const family of keyValue.values) {
            if (family !== 'TOOL') {
              this.familyAvailables.push(family);
            }
          }
        }
      }
    },
    disableSundays(e) {
      if (e) {
        return [0].indexOf(e.date.getDay()) >= 0;
      }
      return undefined;
    },
    ...mapActions('Machinery', ['setActualMachinery', 'setCode', 'setDescription', 'setBrand', 'setModel', 'setSerialNumber', 'setPlate', 'setInternalCode', 'setFamily', 'setSubfamily', 'setPurchase', 'setAttachment', 'setStatus']),
  },
};
</script>

<style lang="scss">

  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
</style>
