<template>
  <div
    v-if="loaded"
    class="main-stockTable"
  >
    <div
      class="content-devx"
    >
      <DxSelectWarehouse
        :available-warehouses="warehouses"
      />
      <DxDataGrid
        id="stockTable"
        ref="stockTable"
        :data-source="productData"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :column-auto-width="true"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          :storage-key="'DxStockTable'"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="code"
          name="code"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxStockTable_dxColumn_Code')"
        />
        <DxColumn
          data-field="description"
          name="description"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxStockTable_dxColumn_Description')"
        />
        <DxColumn
          data-field="stock"
          name="stock"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxStockTable_dxColumn_Stock')"
        />
        <DxColumn
          data-field="unit"
          name="unit"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxStockTable_dxColumn_Unit')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxStateStoring,
  DxHeaderFilter,
  DxSelection,
  DxPager,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions, mapGetters } from 'vuex';
import DxSelectWarehouse from './DxSelectWarehouse.vue';
import Api from '../../services/Api';

export default {
  name: 'DxStockTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxStateStoring,
    DxHeaderFilter,
    DxSelection,
    DxSelectWarehouse,
    DxPager,
  },
  props: {
  },
  data() {
    return {
      warehouses: [],
      loaded: false,
      products: [],
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    productData() {
      return this.warehouseProductStock;
    },
    ...mapGetters('Prescription', ['warehouseProductStock']),
  },
  async mounted() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      let xhr = await Api.getWarehouses();
      this.warehouses = JSON.parse(xhr.response).data;
      if (this.warehouses.length > 0) {
        xhr = await Api.getStockByWarehouseCode(this.warehouses[0].code);
        const warehousesProducts = JSON.parse(xhr.response).data;
        if (warehousesProducts.length > 0) {
          this.setWarehouseProductStock(warehousesProducts[0].products);
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (message.includes('You do not have permission')) {
        this.$f7.dialog.alert(this.$t(message));
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('Prescription', ['setWarehouseProductStock']),
  },
};
</script>
