// - General
import logo from '../static/img/logo_agroCampo.png';

export const ENV = process.env.NODE_ENV || 'development';
export const { TARGET } = process.env;
export const APP_VERSION = '1.1.0';
export const APPLICATION_NAME = 'CREATEC';

// export const DATABASE_NAME = 'agrotareo';

// export const CONFIG_BASE_URL = ENV === 'development' && TARGET === 'web'
//   ? LOCAL_DEV_PROXY : CONFIG_PRODUCTION_BASE;

// - Backend API
export const SECONDS_TO_REFRESH_AUTOMATICALLY = 60;
export const SECONDS_BETWEEN_SCANS = 3;

// - Color Theme
export const DEFAULT_PRIMARY_COLOR = '#01AB0F';
export const DEFAULT_SECONDARY_COLOR = '#015267';

// Routes
export const APP_LOGO = logo;

// Global constans
export const DAYS_LESS_TO_DATE_PICKER = 7;
