const dictionary = {
  sr: {
    translation: {
      Login_txtWelcome: 'Prijava',
      Login_inpUsername_placeholder: 'Korisničko ime',
      Login_inpPassword_label: 'Lozinka',
      Login_inpPassword_placeholder: 'Lozinka',
      Login_chkRememberMe: 'Zapamti',
      Login_txtRestorePass: 'Povrati lozinku',
      Login_txtNeedHelp: 'Treba Vam pomoć?',
      Login_btnSignIn: 'PRIJAVA',
      Login_btnSignUp_question: 'Niste registrovani?',
      Login_btnSignUp_link: 'Prijavite se',
      Login_msgEmail_title: 'Informacije',
      Login_msgEmail_content: 'Uskoro će te primiti mail za promenu lozinke',
      Login_msgEmail_btnClose: 'Zatvori',
      loggOut_Message: 'Da li ste sigurni da želite da izađete?',
      RecoveryPassword_txtDataAccess: 'Unesite Vaš mail',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Promeni lozinku',
      RecoveryPassword_btnGoBack: 'Nazad',
      ChangePassword_txtDataAccess: 'Unesite novu lozinku',
      ChangePassword_inpEmail_placeholder: 'Lozinka',
      ChangePassword_inpRepeatEmail_placeholder: 'Ponovite lozinku',
      ChangePassword_btnChangePassword: 'Promeni lozinku',
      Register_registerText: 'REGISTRUJ',
      Register_btnEnter: 'PRIHVATI',
      Register_inpName_name: 'IME',
      Register_inpName_placeholder: 'IME',
      Register_inpSurnames_name: 'PREZIME',
      Register_inpSurnames_placeholder: 'PREZIME',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Lozinka',
      Register_inpPassword_placeholder: 'Lozinka',
      Register_inpPasswordVerification_name: 'Ponovite lozinku',
      Register_inpPasswordVerification_placeholder: 'Ponovite lozinku',
      Register_inpUsername_name: 'Korisničko ime',
      Register_inpUsername_placeholder: 'Korisničko ime',
      Nfc_into_error_404: 'Zaposleni nije registrovan',
      Nfc_into_error_400: 'NFC format nije odgovarajući',
      Nfc_into_error_406: 'Zadatak je već kreiran na ovoj lokaciji',
      Nfc_into_error_409: 'Zaposleni već radi na drugom zadatku',
      Nfc_info_txtTitle: 'Čitam karticu',
      Nfc_info_txtSubTitle: 'Prinesite karticu radi čitanja',
      Nfc_navbarpresence_incidence: 'Čitanje NFC-a',
      Nfc_navbarTask_checkout: 'Produktivnost',
      Nfc_navbarpresence_manual: 'Unesi NFC ručno',
      Nfc_navbarpresence_btnContinue: 'Nastavi',
      NfcManually_navbarpresence_btnContinue: 'Nastavi',
      NfcManually_listInput_placeholder: 'Ime ili prezime',
      Profile_info_profile: 'Informacije o profilu',
      Profile_name_placeholder: 'Ime',
      Home_info_txtTitle: 'Nema zaposlenih',
      SheetExport_sheet_export_ok: 'Preuzmi fajl',
      SheetExport_sheet_export_error: 'Greška u preuzimanju',
      Start_order: 'Izaberi zaposlene',
      TasksList_navbar_text: 'Radni nalozi',
      TasksList_lstNewTasks_title: 'Pokreni novi zadatak',
      TasksList_lstInProgressTasks_title: 'Zadaci u toku',
      TasksList_lstCompletedTasks_title: 'Završeni zadaci',
      TasksList_Workers_title: 'Zaposleni',
      TasksList_WorkersSelection_title: 'Broj randika na zadatku',
      TasksList_WorkersSelection_subtitle: 'Kliknite na gornje dugme da bi povezali zaposlene',
      AccordionList_workers: 'Zaposleni',
      AccordionList_worker: 'Radnik',
      Home_conductor: 'Vozač traktora',
      ManualList_listItem_title: 'Nije pronađen zaposleni',
      checkOut_Productivity_placeholder: 'Broj polja',
      checkOut_Productivity: 'Produktivnost',
      checkOut_Task: 'Zadatak  ',
      list_content_farm_img_alt: 'Promeni farmu',
      farmSelection_navbar_text: 'Izaberite farmu',
      activitySelection_navbar_text: 'Izaberi aktivnost',
      locationSelect_navbar_text: 'Izaberite lokaciju',
      ActivitySelection_navbarTask_text: 'Izaberite zadatak',
      ActivitySelection_txtSearchCodeTask: 'Pokreni zadatak za kodiranje:',
      ActivitySelection_txtSearchActivity: 'Izaberi aktivnost',
      ActivitySelection_taskError: 'Zadatak nije pronađen',
      TasksList_filterTask_title: 'Izaberite zadatak',
      DxDailyPlanning_text: 'Kreiraj novi radni nalog',
      DxMonthlyPlanning_text: 'Kreiraj novi mesečni plan',
      DxAddEmployee_text: 'Dodaj novog zaposlenog',
      DxTaskOrders_text: 'Kreiraj novu neplaniranu porudžbinu',
      DetailTabs_machinary_title: 'Mašina',
      DetailTabs_attachment_title: 'Prilog',
      DetailTabs_tools_title: 'Alati',
      DetailTabs_products_title: 'Proizvodi',
      DetailTabs_workers_title: 'Zaposleni',
      DetailTabs_locations_title: 'Lokacije',
      DxDailyPlanning_date_caption: 'Datum',
      DxListEmployee_name_caption: 'Ime i prezime',
      DxListEmployee_code_caption: 'Šifra',
      DxListEmployee_status_caption: 'Status',
      DxListEmployee_type_caption: 'Tip',
      DxListEmployee_driver_caption: 'Vozač',
      DxListEmployee_info_caption: 'Info',
      DxListEmployee_farm_caption: 'Farm',
      DxDailyPlanning_task_caption: 'Zadatak  ',
      DxDailyPlanning_activity_caption: 'Aktivnost',
      DxDailyPlanning_ha_caption: 'Ha',
      DxDailyPlanning_workers_caption: 'Zaposleni',
      DxDailyPlanning_location_caption: 'Lokacija',
      DxDailyPlanning_workingArea_caption: 'Radna zona',
      DxDailyPlanning_goal_caption: 'Cilj  ',
      DxSelectSupervisor: 'Potreban je najmanje jedan poslovođa',
      DxDailyPlanning_workingarea_caption: 'Radna površina',
      DxDailyPlanning_dialogDelete_title: 'Obriši planiranje',
      DxDailyPlanning_dialogDelete_text: 'Da li ste sigurni da želite da izbrišete planiranje?',
      DxDailyPlanning_dialogDelete_confirm: 'Potvrdi',
      DxDailyPlanning_dialogDelete_cancel: 'Otkaži',
      DxDailyWorkOrders_dialogDelete_title: 'Obriši radni nalog',
      DxDailyWorkOrders_dialogDelete_text: 'Da li ste sigurni da želite da izbrišete radni nalog?',
      DxDailyWorkOrders_dialogDelete_confirm: 'Potvrdi',
      DxDailyWorkOrders_dialogDelete_cancel: 'Otkaži',
      DxDailyWorkOrders_dialogDelete_noOpen: 'Ne možete obrisati već pokrenut radni nalog',
      DxDailyWorkOrders_dialogUpdate_noOpen: 'Ne možete izmeniti već pokrenut radni nalog',
      PlanningComponent_caption_text: 'Aktivnost & zadatak',
      EmployeeComponent_caption_text: 'Podaci o korisniku',
      PlanningComponent_activityName_caption: 'Naziv aktivnosti',
      PlanningComponent_activitycode_caption: 'Šifra aktivnosti',
      PlanningComponent_workingarea_caption: 'Radna površina',
      EmployeeComponent_name_caption: 'Ime',
      EmployeeComponent_status_caption: 'Status',
      EmployeeComponent_surname_caption: 'Prezime',
      EmployeeComponent_identificationNumber_caption: 'ID broj',
      EmployeeComponent_role_caption: 'Uloga',
      EmployeeComponent_type_caption: 'Tip',
      EmployeeComponent_drive_caption: 'Vozač',
      EmployeeComponent_group_caption: 'Grupa',
      EmployeeComponent_shift_caption: 'Smena',
      EmployeeComponent_email_caption: 'Email',
      EmployeeComponent_password_caption: 'Lozinka',
      EmployeeComponent_identification_title: 'Identifikacija zaposlenog',
      EmployeeComponent_typeIdentification_caption: 'Tip',
      EmployeeComponent_code_caption: 'Šifra',
      DxEmployeeForm_surname_require: 'Prezime je obavezno',
      DxEmployeeForm_ID_require: 'Obavezan je identifikacioni broj',
      DxEmployeeForm_name_require: 'Ime je obavezno',
      DxEmployeeForm_status_require: 'Status je obavezan',
      DxEmployeeForm_role_require: 'Uloga je obavezna',
      DxEmployeeForm_type_require: 'Tip je obavezan',
      DxEmployeeForm_email_require: 'Email je obavezan',
      DxEmployeeForm_password_require: 'Šifra je obavezna',
      PlanningComponent_taskName_caption: 'Ime zadatka',
      PlanningComponent_taskCode_caption: 'Šifra zadatka',
      PlanningComponent_date_caption: 'Datum',
      PlanningComponent_numberWorkers_caption: 'Broj radnika',
      PlanningComponent_family_caption: 'Porodica',
      PlanningComponent_addMachineButton_caption: 'Dodaj mašinu',
      PlanningComponent_shift_caption: 'Smena',
      PlanningComponent_groupName_caption: 'Ime grupe',
      CreatePlanning_ButtonSave_caption: 'Sačuvaj',
      PlanningComponent_dailyPlanning: 'DNEVNI PLAN',
      PlanningComponent_edit_dailyPlanning: 'Izmeni dnevni plan',
      StoreProduct_Buttoncancel_caption: 'Otkaži',
      StoreProduct_Buttonsave_caption: 'Sačuvaj',
      CreatePlanning_Title: 'KREIRAJ DNEVNI PLAN',
      CreateMonthlyPlanning_Title: 'KREIRAJ MESEČNI PLAN',
      EditMonthlyPlanning_Title: 'IZMENI MESEČNI PLAN',
      CreatePlanning_ButtonCancel_caption: 'Otkaži',
      CreatePlanning_ValidationDate: 'Datum je obavezan.',
      CreatePlanning_ValidationTaskName: 'Naziv zadatka je obavezan.',
      CreatePlanning_ValidationActivityName: 'Naziv aktivnosti je obavezan.',
      CreatePlanning_ValidationNumberOfWorker: 'Broj radnika ne može biti nula.',
      CreatePlanning_ValidationNumberOfDriver: 'Broj vozača ne može biti nula.',
      CreatePlanning_ValidationActualLocationsSelected: 'Lokacija je obavezna',
      CreateEmployee_ValidationIdentificationNumber: 'Identifikacioni broj je obavezan',
      CreateOrder_ValidationSupervisorsLocation: 'Postoje lokacije bez dodeljenog poslovođe.',
      CreateOrder_ValidationItemsWorkOrder: 'Postoje nedodeljeni predmeti.',
      DxMonthlyMachinary_text: 'Dodaj novu mašinu',
      CreateOrder_ValidationLocationExists: 'Lokacije zadatka postoje sa ovim datumom.',
      CreateEmployee_ValidationName: 'Ima ej obavezno.',
      CreateEmployee_ValidationSurname: 'Prezime je obavezno.',
      CreateEmployee_ValidationEmail: 'Email je obavezan.',
      CreateEmployee_ValidationPassword: 'Lozinka je obavezna.',
      CreateEmployee_Title: 'KREIRAJ ZAPOSLENOG',
      EditEmployee_Title: 'IZMENI ZAPOSLENOG',
      DxDailyPlanning_edit_text: 'Izmeni',
      DxDailyPlanning_edit_hint: 'Izmeni',
      DxDailyPlanning_new_order_text: 'Novi nalog',
      DxDailyPlanning_new_order_hint: 'Novi nalog',
      DxDailyPlanning_task_status_caption: 'Status zadatka',
      DxDailyPlanning_work_order_status_caption: 'Status radnog naloga',
      DxDailyPlanning_supervisors_caption: 'Poslovođe',
      MonthlyComponent_type_caption: 'Tip',
      MonthlyComponent_performance_caption: 'Performanse',
      MonthlyComponent_performanceunit_caption: 'Jedinica performanse',
      MonthlyComponent_consumption_caption: 'Utrošak',
      MonthlyComponent_consumptionunit_caption: 'Jedinica utroška',
      MonthlyComponent_quantity_caption: 'Količina',
      MonthlyComponent_typeconsumption_caption: 'Tip potrošnje',
      MonthlyComponent_notes_caption: 'Beleške',
      DetailTabs_supervisors_title: 'Poslovođe',
      DetailTabAttachemnt_not_selected: 'Prilog nije izabran',
      DetailTabProduct_no_seleccionado: 'Proizvod nije izabran',
      SelectSupervisor_supervisor_caption: 'Poslovođa',
      SelectSupervisor_location_caption: 'Lokacija',
      WorkOrder_title: 'RADNI NALOZI',
      Employee_title: 'LISTA ZAPOSLENIH',
      WorkOrderDetail_select_supervisor: 'Izaberi poslovođu',
      WorkOrderDetail_select_maquinaria: 'Izaberi mašinu',
      WorkOrderDetail_order: 'PORUDŽBINA',
      DxWorkOrders_edit_text: 'Izmeni',
      DxWorkOrders_edit_hint: 'Izmeni',
      DxWorkOrders_modal_title: 'Izmeni radni nalog',
      DxWorkOrders_machinery_caption: 'Mašina',
      DxWorkOrders_driver_caption: 'Vozač',
      DxWorkOrders_km_start_caption: 'KM Start',
      DxWorkOrders_km_finish_caption: 'KM Kraj ',
      DxWorkOrders_fuel_consumption_caption: 'Potrošnja goriva (L)',
      DxWorkOrders_aditional_info_caption: 'Dodatne informacije',
      DxProductPerformance_machinery_name: 'Proizvod',
      DxProductPerformance_machinery_qtyCollected: 'Prikupljena količina',
      DxProductPerformance_machinery_qtyPlanned: 'Planirana količina',
      DxProductPerformance_machinery_qtyTask: 'Količina vrćena u magacin',
      DxProductPerformance_machinery_qtyUsed: 'Upotrebljena količina',
      EditOrder_ButtonSave_caption: 'Sačuvaj',
      EditOrder_ButtonCancel_caption: 'Otkaži',
      CreateOrder_ButtonSave_caption: 'Sačuvaj',
      CreateOrder_ButtonCancel_caption: 'Otkaži',
      CreateOrder_ValidationOutDate: 'Ne možete kreirati rani nalog rasporedom van dozvoljenih datuma',
      DxSelectedItems_ya_esta_seleccionado_alert: 'je već izabran',
      DxDailyPlanning_delete_text: 'Obriši',
      DxDailyPlanning_delete_hint: 'Obriši',
      DxWorkOrder_Button_View_text: 'Pregled',
      DxWorkOrder_Button_View_hint: 'Pregled',
      DetailTabWorkers_sin_trabajadores: 'Nije izabran zaposleni',
      DetailTabAttachemnt_no_selected: 'Nije izabran prilog',
      DetailTabProduct_not_selected: 'Nije izabran proizvod',
      WorkOrderDetail_select_date: 'Izaberi datum',
      DxSelectDate_date_caption: 'Datum',
      PlanningComponent_typeUnit_caption: 'Tip jedinice',
      DxDailyPlanning_performanceha_caption: 'Zadatak',
      DxDailyPlanning_totalArea: 'Ukupna površina (ha)',
      PlanningComponent_performanceUnit_caption: 'Jedinica produktivnosti',
      PlanningComponent_performanceCount_caption: 'Cilj produktivnosti',
      PlanningComponent_distributed_caption: 'Distribuirano',
      EmployeeComponent_expirationDate_caption: 'Rok Trajanja',
      EmployeeComponent_datepicker_expirationDate_placeholder: 'Odaberite Datum',
      EmployeeComponent_applicator_caption: 'Aplikator',
      Status_Open: 'NEREŠEN',
      Status_InProgress: 'U TOKU',
      Status_Completed: 'ZAVRŠENO',
      Status_Closed: 'Zatvoreno',
      Status_notReally_Closed: 'Nije zatvoreno',
      Status_reallyNot_Closed: 'Nije zatvoreno',
      Status_Pending: 'NEREŠEN',
      Status_Deleted: 'Obrisano',
      DetailTabWorkers_sin_items: 'Ništa nije izabrano',
      DetailTab_sin_seleccion: 'Ništa nije odabrano',
      WorkOrderDetail_additional_info: 'Dodatne informacije',
      WorkOrderDetail_select_driver: 'Izaberite vozača',
      CreateOrder_ValidationItemsDriversWorkOrder: 'Postoje neraspoređeni vozači.',
      Login_inpUsername_validate: 'Popunite ovo polje',
      Login_inpPassword_validate: 'Popunite ovo polje',
      DxEffort_level_caption: 'Nivo',
      DxEffort_activity_caption: 'Aktivnost',
      DxEffort_task_caption: 'Zadatak  ',
      DxEffort_hrHours_caption: 'HR sati',
      DxEffort_totalHours_caption: 'Ukupno sati',
      DxEffort_totalHoursHa_caption: 'Ukupno sati/Ha',
      DxEffort_totalHoursTotalHa_caption: '% od ukupnog broja sati u odnosu na ukupno sve Ha',
      DxEffort_progressCompleted_caption: 'Napredak (% završeno)',
      EffortAgriFarmCockpit_navbar_title: 'IZVEŠTAVANJE O NAPORU AGRI FARM KOKPIT',
      WeatherFarmCockpit_navbar_title: 'KOKPIT ZA VREMENSKU POLJOPRIVREDU',
      DxDailyPlanning_days_caption: 'Dana',
      DxDailyPlanning_goal_ha_caption: 'Cilj Ha',
      DxDailyPlanning_coste: 'Trošak',
      LeftPanel_title_reporting: 'IZVEŠTAVAM',
      LeftPanel_title_plan: 'PLAN ',
      LeftPanel_title_execution: 'REALIZACIJA',
      LeftPanel_title_prescriptions: 'RECEPT',
      LeftPanel_title_weather: 'VREME',
      LeftPanel_title_fertilizers: 'ĐUBRIVA',
      LeftPanel_title_information: 'INFORMACIJE',
      LeftPanel_title_management: 'UPRAVA',
      LeftPanel_subtitle_home: 'Kući',
      LeftPanel_subtitle_dailyPlanning: 'Dnevni plan',
      LeftPanel_subtitle_workOrders: 'Radni nalozi',
      LeftPanel_subtitle_unplannedWorkOrders: 'Neplanirani radni nalozi',
      LeftPanel_subtitle_fertilizersPrescriptions: 'Recepti za đubrivo',
      LeftPanel_subtitle_activeIngredients: 'Aktivna supstanca odobrena od EU',
      LeftPanel_subtitle_logOut: 'Odjava',
      LeftPanel_subtitle_phytosanitariesLocalDatabase: 'Fotosanitarna lokalna baza',
      LeftPanel_subtitle_employee: 'Zaposleni',
      LeftPanel_subtitle_productsLocalDatabase: 'Lokalna baza proizvoda',
      LeftPanel_subtitle_monthlyPlanning: 'Mesečno planiranje',
      PlanningComponent_monthlyPlanning: 'MESEČNO PLANIRANJE',
      Search_placeholder: 'Pretraga',
      WeatherForecast_title: 'Prognoza vremena',
      WeatherStatus_title: 'Status vremena',
      GisMap_title: 'Mapa',
      PlanningComponent_machinary_caption: 'Mašina',
      DxAttachment_dxColumn_TypeAttachment: 'Tip',
      DxAttachment_dxColumn_QuantityAttachment: 'Količina',
      DxMachinery_dxColumn_TypeMachinery: 'Tip',
      DxMachinery_dxColumn_QuantityMachinery: 'Količina',
      DxProducts_dxColumn_activeIngredients: 'Aktivna sustanca',
      DxProducts_dxColumn_productName: 'Naziv proizvoda',
      DxProducts_dxColumn_dosis: 'Doza',
      DxProducts_dxColumn_dosisTotal: 'Ukupna doza',
      DxProducts_dxColumn_solution: 'Rešenje',
      DxProducts_dxColumn_solutionTotal: 'Ukupno rešenje',
      DxProducts_dxColumn_caution: 'Oprez',
      DxProducts_dxColumn_restrictedEntryInterval: 'Interval ulaska',
      DxProducts_dxColumn_preharvestInterval: 'Interval pre žetve',
      ActiveIngredients_navbar_title: 'AKTIVNA SUSTANCA',
      DxCreatePrescription_button_text: 'Kreiraj novi recept',
      DxCreatePrescription_column_active: 'Aktivan',
      DxCreatePrescription_column_name: 'IME',
      DxCreatePrescription_column_type: 'Tip recepta',
      DxCreatePrescription_column_method: 'Način primene',
      DxCreatePrescription_column_disease: 'Bolest',
      DxCreatePrescription_column_activeSince: 'Aktivan od',
      DxCreatePrescription_column_notes: 'Beleške',
      CreatePrescription_Title: 'KREIRAJ NOVI RECEPT',
      EditPrescription_Title: 'IZMENI RECEPT',
      EditProduct_navbar_create: 'Izmeni proizvod',
      EditProduct_navbar_edit: 'KREIRAJ NOVI PROIZVOD',
      DxFormProduct_code_caption: 'ŠIFRA',
      DxFormProduct_code_required: 'Šifra je obavezna',
      DxFormProduct_status_caption: 'STATUS',
      DxFormProduct_comment_caption: 'KOMENTAR',
      DxFormProduct_description_caption: 'OPIS',
      DxFormProduct_component_caption: 'KOMPONENTA',
      DxFormProduct_family_caption: 'PORODICA',
      DxFormProduct_subfamily_caption: 'POD PORODICA',
      DxFormProduct_toxicity_caption: 'OTROVNOST',
      DxFormProduct_description_required: 'Opis je obavezan',
      DxInformationPrescription_name_caption: 'IME',
      DxInformationPrescription_active_caption: 'AKTIVAN',
      DxInformationPrescription_dateofactivation_caption: 'DATUM AKTIVACIJE',
      DxInformationPrescription_typeofprescription_caption: 'TIP RECEPTA',
      DxInformationPrescription_methodofapplication_caption: 'NAČIN PRIMENE',
      DxInformationPrescription_notes_caption: 'BELEŠKE',
      DxInformationPrescription_name_required: 'Naziv je obavezan',
      DxInformationPrescription_date_required: 'Datum je obavezan ',
      DxInformationPrescription_typeofprescription_required: 'Tip recepta je obavezan',
      DxInformationPrescription_methodofapplication_required: 'Način primene je obavezan',
      totalArea_field: 'Ukupna površina (ha)',
      DxAttachment_Title: 'Prilog',
      DxMachineryPerformance_Title: 'Performanse mašine',
      DxProductsPerformance_Title: 'Performance  proizvoda',
      DxMachinery_Title: 'Mašina',
      DxTools_Title: 'ALATI',
      DxProducts_Title: 'Proizvodi',
      DxTools_dxColumn_TypeTool: 'Tip',
      DxTool_dxColumn_QuantityTool: 'Količina',
      CreatePrescription_ValidationName: 'Naziv je obavezan',
      CreatePrescription_ValidationNameDuplicated: 'Naziv je već u upotrebi',
      CreatePrescription_ValidationProducts: 'Morate dodati bar jedan proizvod',
      CreatePrescription_ValidationMethod: 'Metoda recepta je obavezna',
      CreatePrescription_ValidationType: 'Tip recepta je obavezan',
      CreatePrescription_ValidationDate: 'Neophodan je validan datum',
      CreatePrescription_ValidationActive: 'Novi recept mora biti aktivan',
      DxPlanningProducts_prescription: 'Naziv recepta',
      DxPlanningProducts_prescription_required: 'Recept je obavezan',
      DxPlanningProducts_typePrescription: 'Tip recepta',
      DxPlanningProducts_typePrescription_required: 'Tip recepta je obavezan',
      DxPlanningProducts_typeOfPrescription: 'Tip recep.',
      DxPlanningProducts_typeOfPrescription_required: 'Tip recep. je obavezan',
      active_substance_id: 'ID aktivne sustance',
      approval_date: 'Datum odobrenja',
      approval_expiration: 'Datum osteka odobrenja',
      authorisation_in_progress: 'Autorizacija u postupku',
      authorised: 'Autorizovano',
      category: 'Kategorija',
      organic_approved: 'Organski odobreno',
      remark: 'Primedba',
      substitution_candidate: 'Kandidat za zamenu',
      countries: 'Zemlje',
      FertilizersPrescriptions_title: 'RECEPTI ZA ĐUBRIVO',
      ProductsDatabase_title: 'BAZA PROIZVODA',
      DxAttachmentMachinary_dxColumn_Machinary: 'Mašina',
      DxAttachmentMachinary_dxColumn_Attachment: 'Prilog',
      DxWeatherElementLinesGraph_average: 'Prosek',
      DxWeatherElementLinesGraph_averageForecasted: 'Planirani prosek',
      DxWeatherElementLinesGraph_totalForecasted: 'Ukupno planirano',
      DxWeatherElementLinesGraph_total: 'Ukupno',
      DxProduts_rule_range: 'Opseg mora biti veći od 0',
      DxProduts_rule_solution_required: 'Rešenje je obavezno',
      DxProduts_rule_dosis_required: 'Doza je obavezna',
      DxProdutsDataBase_button_text: 'Kreiraj novi proizvod',
      DxProdutsDataBase_edit_text: 'Izmeni',
      DxProdutsDataBase_edit_hint: 'Izmeni',
      DxEmployeeTable_Title: 'Zaposleni',
      DxPostWorkEmployeeTable_rule_range: 'Opseg mora biti veći od 0',
      DxPostWorkEmployeeTable_Name: 'IME',
      DxPostWorkEmployeeTable_Surname: 'Prezime',
      DxPostWorkEmployeeTable_Checkin: 'Prijava',
      DxPostWorkEmployeeTable_Checkout: 'Odjava',
      DxPostWorkEmployeeTable_Productivity: 'Produktivnost',
      DxPostWorkEmployeeTable_Unit: 'Jedinica',
      DxPostWorkEmployeeTable_Notes: 'Beleške',
      DxTaskOrders_navbar_Name: 'NEPLANIRANI RADNI NALOZI',
      PlanningComponent_datepicker_multiple_placeholder: 'Izaberi datume',
      CreatePostWork_Navbar_Title: 'KREIRAJ NOVI NEPLANIRANI RADNI NALOG',
      ViewPostWork_Navbar_Title: 'PREGLED NEPLANIRANIH RADNIH NALOGA',
      DxPostWorkEmployeeTable_dialogCreate_wrongHour: 'Prijava mora biti pre odjave',
      CreatePostWork_Validation_PrescriptionNeeded: 'Morate dodati recept u zadatak',
      DxCreatePrescription_column_family: 'Porodica',
      DxProducts_dxColumn_productGroup: 'Grupa proizvoda',
      DxProducts_dxColumn_productFamily: 'Familija proizvoda',
      DxPlanningForm_activity_name: 'Naziv aktivnosti je obavezan.',
      DxPlanningForm_Task_name: 'Naziv zadatka je obavezan.',
      DxPostWorkEmployeeTable_dialogCreate_wrongCheckIn: 'Prijava mora biti jednaka datumu zadatka',
      LeftPanel_title_machinery: 'Mašina',
      LeftPanel_subtitle_machineryDataBase: 'Baza mašina',
      Machinery_title: 'MAŠINA',
      CreateMachinery_Title: 'KREIRAJ MAŠINU',
      EditMachinery_Title: 'IZMENI MAŠINU',
      DxMachineryDatabase_column_status: 'Status',
      DxMachineryDatabase_column_family: 'Porodica',
      DxMachineryDatabase_column_subfamily: 'Pod porodica',
      DxMachineryDatabase_column_description: 'Opis',
      DxMachineryDatabase_column_brand: 'Brend',
      DxMachineryDatabase_column_code: 'Šifra',
      DxMachineryDatabase_column_attachment: 'Prilog',
      DxMachineryDatabase_button_text: 'Kreiraj novu mašinu',
      DxMachineryForm_code_caption: 'Šifra',
      DxMachineryForm_code_required: 'Šifra je obavezna',
      DxMachineryForm_description_caption: 'Opis',
      DxMachineryForm_description_required: 'Opis je obavezan',
      DxMachineryForm_brand_caption: 'Brend',
      DxMachineryForm_brand_required: 'Brend je obavezan',
      DxMachineryForm_model_caption: 'Model',
      DxMachineryForm_model_required: 'Model je obavezan',
      DxMachineryForm_serial_number_caption: 'Serijski broj',
      DxMachineryForm_serial_number_required: 'Serijski broj je obavezan',
      DxMachineryForm_internal_code_caption: 'Interna šifra',
      DxMachineryForm_internal_code_required: 'Interna šifra je obavezna',
      DxMachineryForm_family_caption: 'Porodica',
      DxMachineryForm_family_required: 'Porodica je obavezna',
      DxMachineryForm_subfamily_caption: 'Pod porodica',
      DxMachineryForm_subfamily_required: 'Pod porodica je obavezna',
      DxMachineryForm_attachment_caption: 'Prilog',
      DxMachineryForm_purchase_caption: 'Nabavka',
      Machinery_ValidationCode: 'Polje šifra je obavezna',
      Machinery_ValidationBrand: 'Polje Brend je obavezno',
      Machinery_ValidationModel: 'Polje Model je obavezno',
      Machinery_ValidationSerialNumber: 'Polje Serijski broj je obavezno',
      Machinery_ValidationPlate: 'Polje Pločica je obavezno',
      Machinery_ValidationDescription: 'Polje Opsi je obavezno',
      Machinery_ValidationFamily: 'Polje Porodica je obavezno',
      Machinery_ValidationSubfamily: 'Polje Pod Porodica je obavezno',
      Machinery_ValidationPurchase: 'Polje Nabavka je obavezno',
      StoreProduct_ValidationComponent: 'Komponenta je obavezna',
      StoreProduct_ValidationCode: 'Šifra je obavezna',
      StoreProduct_ValidationFamily: 'Porodica je obavezna',
      StoreProduct_ValidationSubfamily: 'Pod porodica je obavezna',
      StoreProduct_ValidationSapunit: 'Jedinica je obavezna',
      StoreProduct_ValidationDescription: 'Opis je obavezan',
      StoreProduct_ValidationCodeDuplicated: 'imate duple šifre',
      Button_cancel_text: 'Otkaži',
      Button_save_text: 'Sačuvaj',
      LeftPanel_subtitle_productsDataBase: 'Baza proizvoda',
      DxProductsDataBase_status_caption: 'Status',
      DxProductsDataBase_description_caption: 'Opis',
      DxProductsDataBase_family_caption: 'Porodica',
      DxProductsDataBase_subfamily_caption: 'Pod-porodica',
      DxProductsDataBase_component_caption: 'Komponenta',
      LeftPanel_subtitle_reportingWorker: 'Zaposleni',
      DxWorker_date_caption: 'Datum',
      DxWorker_task_caption: 'Zadatak  ',
      DxWorker_typeTask_caption: 'Tip',
      DxWorker_codeTask_caption: 'Šifra',
      DxWorker_workerName_caption: 'Radnik',
      DxWorker_idNumber_caption: 'Id ',
      DxWorker_typeWorker_caption: 'Tip',
      DxWorker_territorialUnit_caption: 'Teritorijalna jedinica',
      DxWorker_totalHours_caption: 'Časovi',
      DxWorker_workingDay_caption: 'Radni dan',
      DxWorker_performanceUnit_caption: 'Jedinica performanse',
      DxWorker_performanceCount_caption: 'Brojač performanse',
      DxWorker_note_caption: 'Beleška',
      CreateMonthlyPlanning_ValidationDate: 'Broj radnih dana mora odgovarati opsegu izabranih datuma',
      CreateMonthlyPlanning_ValidationTotalWorkingDays: 'Morate planirati barem jedan radni dan',
      DxMonthlyForm_activityTask: 'Aktivnost zadatka',
      DxMonthlyForm_goalUnit: 'Jedinica cilja',
      DxMonthlyForm_DateProgrammed: 'Programirani datum',
      DxMonthlyForm_numberWorkers_caption: 'Broj zaposlenih',
      DxMonthlyForm_performanceWorker_caption: 'Performance/zaposleni',
      DxMonthlyForm_toolsConsumptionTotal: 'Ukupna potrošnja (L)',
      DxMonthlyForm_estimatedCostActivity: 'Procenjen trošak aktivnosti',
      DxMonthlyForm_taskDuration: 'Trajanje zadatka',
      DxToolsMonthlyTable_type_caption: 'Tip',
      DxToolsMonthlyTable_consumption_caption: 'Potrošnja  ',
      DxToolsMonthlyTable_consumption_type_caption: 'Tip potrošnje',
      DxToolsMonthlyTable_consumption_unit_caption: 'Jedinica potrošnje',
      DxMachineryMonthlyTableHADAY: 'HA/DAN',
      DxMachineryMonthlyTableUNDIA: 'UN/DAN',
      Home_navbar_title: 'Kući',
      DxBins_dxColumn_Location: 'Lokacije',
      LeftPanel_title_nursery: 'NURSERY',
      Rules_count_more_than_zero: 'Količina mora biti veća od 0.',
      DxPlannReal_workersOut_caption: 'Radnici napolje',
      scs_banned: 'SCS banned',
      DxTimeWorked_location_caption: 'Lokacija',
    },
  },
};
export default dictionary;
