<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-machinery"
  >
    <DxDataGrid
      id="worker-grid"
      :data-source="territorialUnitFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMachinery"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        type="buttons"
        data-field=""
        :caption="$t('DxTerritorialUnit_Actions')"
        :allow-header-filtering="false"
        cell-template="buttonsTemplate"
      />
      <DxColumn
        data-field="code"
        :caption="$t('DxTerritorialUnit_code')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="name"
        :caption="$t('DxTerritorialUnit_name')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="father_name"
        :caption="$t('DxTerritorialUnit_farther')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="farm"
        :caption="$t('DxTerritorialUnit_farm')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="area"
        :caption="$t('DxTerritorialUnit_area')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
      <template #buttonsTemplate="{ data: territorialUnitFormated }">
        <!-- CREADO -->
        <div
          class="templateButtons"
        >
          <!-- editar -->
          <button
            class="dx-icon-edit boton-editar"
            @click="editTerritorialUnit(territorialUnitFormated.data)"
          />
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxTerritorialUnit',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      territorialUnitFormated: [],
      keyName: 'territorialUnit',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getTerritorialUnit();
      const locationsAvailables = JSON.parse(xhr.response).data;
      for (let i = 0; i < locationsAvailables.length; i += 1) {
        this.territorialUnitFormated.push({
          area: locationsAvailables[i].area,
          code: locationsAvailables[i].code,
          farm: locationsAvailables[i].farm,
          id: locationsAvailables[i].id,
          name: locationsAvailables[i].name,
          type: locationsAvailables[i].type,
          city: locationsAvailables[i].city,
          father_name: locationsAvailables[i].father_name,
          father_id: locationsAvailables[i].father_id,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxTerritorialUnit_Add'),
          onClick: () => {
            // this.setActualDailyPlanning(null);
            // this.initStore();
            // this.updateSelectedTask(null);
            this.$f7.views.main.router.navigate('/createTerritorialUnit/', { reloadCurrent: true });
          },
        },
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('territorialUnit');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'territorialUnit.xlsx');
          });
      });
      e.cancel = true;
    },
    editTerritorialUnit(e) {
      this.setTerritorialUnitToEdit(e);
      this.$f7.views.main.router.navigate('/editTerritorialUnit/', { reloadCurrent: true });
    },
    ...mapActions('PlanningManager', ['setTerritorialUnitToEdit']),
  },
};
</script>
