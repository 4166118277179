<template>
  <DxForm
    v-if="data.length !== 0"
    style="background-color:white"
  >
    <DxGroupItem>
      <DxSimpleItem
        v-for="(item, index) in data"
        :key="index"
        :data-field="item.vehicle"
        :name="item.vehicle"
        :editor-options="{ value: item.driver, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
  <f7-block
    v-else
    name="sin_items"
  >
    {{ $t('DetailTabDrivers_sin_items') }}
  </f7-block>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    data: { type: Array, default: () => [] },
  },
  data() {
    return {
    };
  },
};
</script>
