<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-work-orders main-dx-taskorders"
  >
    <f7-row>
      <f7-col>
        <ExportDataToExcel
          :data-to-export="detailDailyTasksToExport"
          :column-titles="arrayExcelColumnTranslations"
          :name-file="nameFileToExport"
        />
      </f7-col>
      <f7-col>
        <CalendarInitEndDate :key-name="keyName" />
      </f7-col>
    </f7-row>
    <DxDataGrid
      id="gridContainer"
      :data-source="detailpostWortJson"
      :remote-operations="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxTaskOrders"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        :buttons="editButtons"
        type="buttons"
        width="90"
        data-field="Actions"
      />
      <DxColumn
        data-field="shortId"
        name="shortId"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="Activity"
        :caption="$t('DxEffort_activity_caption')"
        :allow-header-filtering="true"
        css-class="bold"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
        css-class="bold"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Workers"
        name="workers"
        :caption="$t('DxDailyPlanning_workers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="Supervisor"
        name="supervisor"
        :caption="$t('DxDailyPlanning_supervisors_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="isOt"
        name="isOt"
        :caption="$t('DxTaskOrder_origen')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="additionalInfoWorkOrder"
        :caption="$t('DxWorkOrders_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
        css-class="bold"
      />
      <DxMasterDetail
        :enabled="true"
        template="master-detail"
      />
      <template #master-detail="{ data: detailpostWortJson }">
        <DxDetailTabs :master-detail-data="detailpostWortJson" />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import DxDetailTabs from './DxDetailTabs.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import ExportDataToExcel from '../ExportDataToExcel.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
    ExportDataToExcel,
  },
  data() {
    return {
      loaded: false,
      detailpostWortJson: [],
      finalLevelLocation: [],
      supervisorsAvailables: [],
      taskStatusTranslated: '',
      workOrderStatusTranslated: '',
      machineryPopup: [],
      productPopup: [],
      postWork: [],
      ocultarBotton: false,
      editButtons: [{
        icon: 'find',
        hint: this.$t('DxWorkOrder_Button_View_hint'),
        visible: this.isViewconVisible,
        onClick: this.viewtask,
      },
      {
        icon: 'trash',
        hint: this.$t('DxDailyPlanning_delete_hint'),
        visible: true,
        onClick: this.deleteTask,
      },
      ],
      keyName: 'postWork',
      pageSizes: [5, 10, 20],
      nameFileToExport: '',
      arrayExcelColumnTranslations: [],
      detailDailyTasksToExport: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getVarietyNameByCode', 'getCategoryNameByCode', 'getClassificationNameByCode', 'getLocationNameLevelSelectedChildId']),
  },
  async beforeMount() {
    await this.fetchLocationTree();
    this.supervisorsAvailables = await this.getSupervisors();
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      this.postWork = await Api.getPostWork(checkedInitEndDate.init, checkedInitEndDate.end);
      const postWortJson = JSON.parse(this.postWork.response);
      this.detailpostWortJson.length = 0;
      for (let i = 0; i < postWortJson.length; i += 1) {
        const items = this.getItems(postWortJson[i].items_drivers);
        // Establedemos los nombres de variedad/categoria/clasificacion a través del code
        postWortJson[i].nursery.forEach((element) => {
          const varietyName = this.getVarietyNameByCode(element.variety);
          const categoryName = this.getCategoryNameByCode(element.category);
          const classificationName = this.getClassificationNameByCode(element.class_type);
          element.variety = varietyName !== '' ? varietyName : element.variety;
          element.category = varietyName !== '' ? categoryName : element.category;
          element.class_type = classificationName !== '' ? classificationName : element.class_type;
        });
        if (postWortJson[i].planner != null) {
          const newDetailPostWork = {
            ID: postWortJson[i].id,
            Date: this.formatoDate(postWortJson[i].date),
            Task: postWortJson[i].task,
            taskCode: postWortJson[i].task_code,
            Activity: postWortJson[i].activity,
            activityCode: postWortJson[i].activity_code,
            TaskStatus: postWortJson[i].status,
            WorkOrderStatus: postWortJson[i].worder_status,
            Supervisor: this.getNameSupervisor(postWortJson[i].responsible),
            Workers: `${this.getTotalWorkers(postWortJson[i].workers)} / ${this.getTotalWorkersPlanner(postWortJson[i].planner.workers)}`,
            WorkersDetailTab: postWortJson[i].workers,
            ItemsDetailTab: items,
            Attachments: postWortJson[i].attachments_vehicles,
            Tools: postWortJson[i].planner.tools,
            ToolsDetailTab: postWortJson[i].tools,
            itemsTypes: postWortJson[i].planner.items,
            items,
            itemsDriversWorkOrder: postWortJson[i].items_drivers,
            locationLevel2: this.getNamesByBdIds(postWortJson[i].location),
            location: postWortJson[i].location,
            Planner: postWortJson[i].planner,
            responsible: postWortJson[i].responsible,
            additionalInfoWorkOrder: postWortJson[i].comment,
            machineryPopup: postWortJson[i].machinery_performance,
            products: postWortJson[i].products,
            productPopup: postWortJson[i].product_performance,
            area_effective: postWortJson[i].area_effective,
            variety: postWortJson[i].planner.variety === null || postWortJson[i].planner.variety === 'ALL' ? '' : postWortJson[i].planner.variety,
            shortId: postWortJson[i].short_id,
            binsDetail: postWortJson[i].nursery,
            performance: postWortJson[i].performance,
            isOt: postWortJson[i].planner.planner_id === null ? 'Plan' : 'Manual',
            cost: postWortJson[i].cost,
            irrigation: postWortJson[i].irrigation,
          };
          this.detailpostWortJson.push(newDetailPostWork);
          this.addElementToExport(newDetailPostWork);
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // MÉTODOS PARA AÑADIR ELEMENTOS FORMATEAçDOS PARA EXPORTAR TODO:
    addElementToExport(newTask) {
      let machinary = '';
      if (newTask.itemsTypes.length > 0) {
        try {
          machinary = this.formatMachinary(newTask.itemsTypes);
        } catch (error) {
          console.error(error);
        }
      }
      let attachments = '';
      if (newTask.Attachments.length > 0) {
        try {
          attachments = this.formatAttachments(newTask.Attachments);
        } catch (error) {
          console.error(error);
        }
      }
      let tools = '';
      if (newTask.Tools.length > 0) {
        try {
          tools = this.formatTools(newTask.Tools);
        } catch (error) {
          console.error(error);
        }
      }
      let products = [];
      if (newTask.products.length > 0) {
        try {
          products = this.formatProducts(newTask);
        } catch (error) {
          console.error(error);
        }
      }
      let locations = [];
      if (newTask.locationLevel2.length > 0) {
        try {
          locations = this.formatLocations(newTask.locationLevel2);
        } catch (error) {
          console.error(error);
        }
      }
      let workers = [];
      if (newTask.WorkersDetailTab.length > 0) {
        try {
          workers = this.formatWorkers(newTask.WorkersDetailTab);
        } catch (error) {
          console.error(error);
        }
      }

      let nursery = [];
      if (newTask.binsDetail.length > 0) {
        try {
          nursery = this.formatNursery(newTask.binsDetail);
        } catch (error) {
          console.error(error);
        }
      }

      const baseFields = {
        short_id: newTask.shortId,
        Date: newTask.Date,
        Activity: newTask.Activity,
        Task: newTask.Task,
        Location: '',
        area_effective: parseFloat(newTask.area_effective).toFixed(2),
        Workers: newTask.Workers,
        Supervisor: newTask.Supervisor,
        machinary,
        attachments,
        tools,
        products: '',
        quantityPlanned: '',
        solutionPlanned: '',
        employes: '',
        employes_type: '',
        employes_performance: '',
        nursery_code: '',
        nursery_variety: '',
        nursery_category: '',
        nursery_class: '',
        nursery_numPlants: '',
      };

      const emptyFields = {
        short_id: newTask.shortId,
        Date: '',
        Activity: '',
        Task: '',
        Location: '',
        area_effective: '',
        Workers: '',
        Supervisor: '',
        machinary: '',
        attachments: '',
        tools: '',
        products: '',
        quantityPlanned: '',
        solutionPlanned: '',
        employes: '',
        employes_type: '',
        employes_performance: '',
        nursery_code: '',
        nursery_variety: '',
        nursery_category: '',
        nursery_class: '',
        nursery_numPlants: '',
      };
      this.arrayExcelColumnTranslations = [this.$t('DxWorkOrder_short_id').toUpperCase(), this.$t('DxDailyPlanning_date_caption').toUpperCase(),
        this.$t('DxDailyPlanning_activity_caption').toUpperCase(), this.$t('DxDailyPlanning_task_caption').toUpperCase(),
        this.$t('DxDailyPlanning_location_caption').toUpperCase(), this.$t('DxDailyPlanning_workingArea_caption').toUpperCase(),
        this.$t('DxDailyPlanning_workers_caption').toUpperCase(),
        this.$t('DxDailyPlanning_supervisors_caption').toUpperCase(),
        this.$t('DetailTabs_machinary_title').toUpperCase(),
        this.$t('DetailTabs_attachment_title').toUpperCase(), this.$t('DetailTabs_tools_title').toUpperCase(),
        this.$t('DetailTabs_products_title').toUpperCase(), this.$t('DxDetailTabs_products_quantityPlanned').toUpperCase(),
        this.$t('DxDetailTabs_products_waterPlanned').toUpperCase(), this.$t('DxDailyPlanning_workers_caption').toUpperCase(),
        this.$t('DxWorkOrders_ExportTranslation_WorkerType').toUpperCase(), this.$t('DxDailyPlanning_workers_performance_caption').toUpperCase(),
        this.$t('DxWorkOrders_ExportTranslation_NurseryCode').toUpperCase(),
        this.$t('DxWorkOrders_ExportTranslation_NurseryVariety').toUpperCase(), this.$t('DxWorkOrders_ExportTranslation_NurseryCategory').toUpperCase(),
        this.$t('DxWorkOrders_ExportTranslation_NurseryClassification').toUpperCase(), this.$t('DxWorkOrders_ExportTranslation_NurseryNumPlants').toUpperCase()];

      if (Api.getAgriFarm() === 'serbia') {
        emptyFields.group = '';
        emptyFields.shift = '';
        baseFields.group = newTask.group;
        baseFields.shift = newTask.shift;
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_Group').toUpperCase());
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_Shift').toUpperCase());
      }

      this.insertNewElementToExport(baseFields, emptyFields, products, workers, nursery, locations, false);
      this.nameFileToExport = `PostWork ${moment().format('YYYY-MM-DD HH:mm:ss')}`;
    },

    async deleteTask(rowSelected) {
      const taskId = rowSelected.row.data.ID;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxDailyWorkOrders_dialogDelete_title'),
        text: this.$t('DxDailyWorkOrders_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_cancel'),
          },
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_confirm'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.deletePostWork(taskId);
                f7.views.main.router.navigate('/postWork/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
      return false;
    },

    formatMachinary(machinaryArray) {
      let machinaryFormated = '';
      machinaryArray.forEach((element) => {
        if (machinaryFormated !== '') {
          machinaryFormated += '\n';
        }
        machinaryFormated += ` ${element.type}: ${element.count} `;
      });
      return machinaryFormated;
    },
    formatAttachments(attachmentsArray) {
      let attachmentsFormated = '';
      attachmentsArray.forEach((element) => {
        if (attachmentsFormated !== '') {
          attachmentsFormated += '\n';
        }
        if (element.attachment_data) {
          attachmentsFormated += ` ${element.attachment_data.description}: ${element.vehicle_data.description} `;
        } else {
          attachmentsFormated += element.vehicle_data.description;
        }
      });
      return attachmentsFormated;
    },
    formatTools(toolsArray) {
      let toolsFormated = '';
      toolsArray.forEach((element) => {
        if (toolsFormated !== '') {
          toolsFormated += '\n';
        }
        toolsFormated += ` ${element.data.description}: ${element.count} `;
      });
      return toolsFormated;
    },
    formatProducts(task) {
      const listProducts = task.products;
      const newProducts = [];
      if (listProducts !== null) {
        for (let i = 0; i < listProducts.length; i += 1) {
          const productSelected = listProducts[i];
          const calcSolutionTotal = (productSelected.solution !== '0.00') ? (parseFloat(productSelected.solution) * parseFloat(task.Planner.area_effective)) : 0;
          const quantityPlanned = this.calulateQuantityPlanned(task, productSelected);
          const unitQuantityPlanned = this.calulateUnitQuantityPlanned(productSelected);
          newProducts.push({
            quantityPlanned: `${quantityPlanned} ${unitQuantityPlanned}`,
            solutionPlanned: `${calcSolutionTotal.toFixed(0).toString()} L`,
            productName: listProducts[i].product.description,
          });
        }
      }
      return newProducts;
    },
    calulateQuantityPlanned(selectedObject, seletedProduct) {
      let quantityPlanned = parseFloat(selectedObject.area_effective * seletedProduct.dosis);
      if (!this.isKilogrameOrliter(seletedProduct)) {
        quantityPlanned = parseFloat((quantityPlanned * seletedProduct.dilution_factor) / 1000).toFixed(2);
      }
      return quantityPlanned;
    },
    calulateUnitQuantityPlanned(seletedProduct) {
      const unitQuantityPlanned = (typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('gr/'))
        ? 'Kg' : 'L';
      return unitQuantityPlanned;
    },
    isKilogrameOrliter(seletedProduct) {
      return typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('l/');
    },
    formatWorkers(workersArray) {
      const newWorkesArray = [];
      workersArray.forEach((element) => {
        let unit;
        let performance;
        if (element.unit === '') {
          performance = element.working_area;
          unit = 'HA';
        } else {
          performance = element.performance;
          unit = element.unit;
        }
        newWorkesArray.push({ fullName: `${element.name} ${element.surname}`, type: element.type, performance: `${performance} ${unit}` });
      });
      return newWorkesArray;
    },
    formatLocations(locationsArray) {
      const newLocationsArray = [];
      locationsArray.forEach((element) => {
        newLocationsArray.push({ locationName: `${element}` });
      });
      return newLocationsArray;
    },
    formatNursery(nurseryArray) {
      const newNurseryArray = [];
      nurseryArray.forEach((element) => {
        newNurseryArray.push({
          nursery_code: element.code,
          nursery_variety: element.variety,
          nursery_category: element.category,
          nursery_class: element.class_type,
          nursery_numPlants: element.count,
        });
      });
      return newNurseryArray;
    },
    insertNewElementToExport(baseFields, emptyFields, products, workers, nursery, locations, childRow) {
      let newObject = {};
      if (childRow) {
        newObject = JSON.parse(JSON.stringify(emptyFields));
      } else {
        newObject = JSON.parse(JSON.stringify(baseFields));
      }
      if (nursery.length > 0) {
        newObject.nursery_code = nursery[0].nursery_code;
        newObject.nursery_variety = nursery[0].nursery_variety;
        newObject.nursery_category = nursery[0].nursery_category;
        newObject.nursery_class = nursery[0].nursery_class;
        newObject.nursery_numPlants = nursery[0].nursery_numPlants;
        nursery.splice(0, 1);
      }
      if (workers.length > 0) {
        newObject.employes = workers[0].fullName;
        newObject.employes_type = workers[0].type;
        newObject.employes_performance = workers[0].performance;
        workers.splice(0, 1);
      }
      if (products.length > 0) {
        newObject.products = products[0].productName;
        newObject.quantityPlanned = products[0].quantityPlanned;
        newObject.solutionPlanned = products[0].solutionPlanned;
        products.splice(0, 1);
      }
      if (locations.length > 0) {
        newObject.Location = locations[0].locationName;
        locations.splice(0, 1);
      }
      this.detailDailyTasksToExport.push(newObject);
      if (workers.length > 0 || products.length > 0 || nursery.length > 0 || locations.length > 0) {
        this.insertNewElementToExport(baseFields, emptyFields, products, workers, nursery, locations, true);
      }
    },
    // Fin métodos para exportar.
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    isViewconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS' || e.row.data.TaskStatus === 'OPEN' || e.row.data.WorkOrderStatus !== 'COMPLETED') {
        return false;
      }
      return true;
    },
    viewtask(rowSelected) {
      if (rowSelected.row.data.TaskStatus === 'COMPLETED') {
        // Comprobación para editar productos / prescripcion
        if (rowSelected.row.data.Planner.prescription) {
          this.setPrescription(rowSelected.row.data.Planner);
        }
        // Comprobación para editar tools
        if (rowSelected.row.data.Planner) {
          rowSelected.row.data.ToolsDetailTab = rowSelected.row.data.Planner.tools;
        }
        // Comprobación para ver irrigation
        if (rowSelected.row.data.irrigation.dose !== null) {
          this.setShowIrrigation(true);
          this.setIrrigationTime(rowSelected.row.data.irrigation.time);
          this.setIrrigationDose(rowSelected.row.data.irrigation.dose);
        }
        this.setActualWorkOrder(rowSelected.row.data);
        this.setProducts(rowSelected.row.data.products);
        this.$f7.views.main.router.navigate('/ViewPostWork/', { reloadCurrent: true });
      }
    },
    // POR CADA FILA QUE SEA TASKSTATUS = COMPLETED Y WORKWORDERSTATUS = COMPLETED DESHABILITO LOS BOTONES EDITAR Y BORRAR
    isEditIconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS') {
        return true;
      }
      return true;
    },
    // Cambio el formato de Date en formato: 10/05/2020
    formatoDate(date) {
      const dateMoment = moment(date);
      return dateMoment.format('YYYY-MM-DD');
    },
    getNamesByBdIds(ids) {
      const names = [];
      const level = 0;
      for (const id of ids) {
        const newName = this.getLocationNameLevelSelectedChildId(id, level);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    // GET WORKERS - SUPERVISORS
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    getNameSupervisor(idSupervisor) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === idSupervisor) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxTaskOrders_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createPostWork/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder', 'setShowIrrigation', 'setIrrigationTime', 'setIrrigationDose']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
    ...mapActions('greenhouse', ['fetchLocationTree']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
