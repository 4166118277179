var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',[_c('div',{staticClass:"CostsPage_content"},[_c('navbar',{attrs:{"text":_vm.$t('Costs_title')}}),_vm._v(" "),_c('f7-row',{staticClass:"content_calendar"},[_c('f7-col',[_c('CalendarInitEndDate',{attrs:{"key-name":_vm.keyName,"dates":_vm.datesDisplayed,"start-date":_vm.startingDate,"end-date":_vm.endingDate}})],1)],1),_vm._v(" "),_c('DxForm',{staticStyle:{"margin":"0 35%"}},[_c('DxGroupItem',{attrs:{"css-class":"content-activity"}},[_c('DxSimpleItem',{attrs:{"css-class":"content-activity__area","data-field":_vm.$t('Costs_Balance'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.balance,
            mode: 'text',
            disabled: true,
          }}})],1)],1),_vm._v(" "),_c('div',{staticClass:"content_costs"},[_c('DxCostsTable',{attrs:{"data-source":_vm.costsDataSource}}),_vm._v(" "),_c('DxSoldTable',{attrs:{"data-source":_vm.soldDataSource}})],1),_vm._v(" "),_c('div',{staticClass:"content_costs"},[_c('div',{staticStyle:{"width":"50%"}},[_c('DxForm',[_c('DxGroupItem',{attrs:{"css-class":"content-activity"}},[_c('DxSimpleItem',{attrs:{"css-class":"content-activity__area","data-field":_vm.$t('Costs_TotalCost'),"editor-type":"dxTextBox","editor-options":{
                value: _vm.totalCost,
                mode: 'text',
                disabled: true,
              }}})],1)],1)],1),_vm._v(" "),_c('div',{staticStyle:{"width":"50%"}},[_c('DxForm',[_c('DxGroupItem',{attrs:{"css-class":"content-activity"}},[_c('DxSimpleItem',{attrs:{"css-class":"content-activity__area","data-field":_vm.$t('Costs_TotalSold'),"editor-type":"dxTextBox","editor-options":{
                value: _vm.totalSold,
                mode: 'text',
                disabled: true,
              }}})],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }