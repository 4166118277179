<template>
  <div>
    <DxPopup
      :visible="isPopupVisible"
      :title="$t('Monitoring_photos')"
      :close-on-outside-click="true"
      @hiding="onHiding"
    >
      <template>
        <DxGallery
          height="100%"
          width="100%"
          :data-source="images"
          :show-nav-buttons="true"
          :no-data-text="$t('no_photos')"
          :show-indicator="true"
          :swipe-enabled="false"
          :stretch-images="true"
        />
      </template>
    </DxPopup>
    <DxButton
      v-if="(images.length > 0)"
      :text="$t('show')"
      @click="onClick"
    />
  </div>
</template>

<script>
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxGallery } from 'devextreme-vue/gallery';

export default {
  components: {
    DxPopup,
    DxButton,
    DxGallery,
  },
  props: {
    images: { type: Array, default: () => [] },
  },
  data() {
    return {
      isPopupVisible: false,
    };
  },
  methods: {
    onClick() {
      this.isPopupVisible = true;
    },
    onHiding() {
      this.isPopupVisible = false;
    },
  },
};
</script>

<style>
.dx-gallery .dx-gallery-nav-button-prev:before,
.dx-gallery .dx-gallery-nav-button-next:before {
  color: #6f4343 !important;
}
.dx-gallery .dx-gallery-nav-button-prev,
.dx-gallery .dx-gallery-nav-button-next {
  font-size: 14px !important;
  font-family: DXIcons !important;
}
.dx-gallery-indicator-item-active,
.dx-gallery-indicator-item-selected {
  background-color: #6f4343 !important;
}

.widget-container {
    margin-right: 240px;
}

#gallery {
    margin: auto;
    max-width: 450px;
}

.options {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 180px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}
</style>
