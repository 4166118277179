<template>
  <f7-page
    name="register"
    login-screen
  >
    <f7-block>
      <div class="text-align-center">
        <img
          class="img_logo"
          :src="APP_LOGO"
          :alt="APPLICATION_NAME"
        >
      </div>
    </f7-block>

    <f7-row>
      <f7-col />
      <f7-col>
        <f7-button
          name="btnFree"
          col
          button
          button-large
          button-raised
          fill
          class="botonPrimary"
          style="color:#FFFFFF"
          @click="goToFree"
        >
          {{ $t("btnFree") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          name="btnPremium"
          col
          button
          button-large
          button-raised
          fill
          class="botonPrimary"
          style="color:#FFFFFF"
          @click="goToPremium"
        >
          {{ $t("btnPremium") }}
        </f7-button>
      </f7-col>
      <f7-col />
    </f7-row>

    <f7-list>
      <f7-button
        name="btnEnter"
        col
        button
        button-large
        button-raised
        fill
        class="botonPrimary"
        style="color:#FFFFFF"
        @click="goBack"
      >
        {{ $t("Register_backText") }}
      </f7-button>
    </f7-list>
  </f7-page>
</template>

<script>
import { APP_LOGO, APPLICATION_NAME } from '../../js/constants';

export default {
  name: 'RegisterTypeSelection',
  data() {
    return {
      APP_LOGO,
      APPLICATION_NAME,
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
  },
  methods: {
    goToFree() {
      this.$f7.views.main.router.navigate('/register/', { reloadCurrent: true });
    },
    goToPremium() {
      this.$f7.views.main.router.navigate('/registerPremium/', { reloadCurrent: true });
    },
    goBack() {
      this.$f7.views.main.router.navigate('/accounts/loginPage/', { reloadCurrent: true });
    },
  },
};
</script>
