<template>
  <div>
    <f7-block-title
      v-if="title !== ''"
      class="no-padding-top"
      style="font-family: 'Roboto', sans-serif;"
    >
      {{ $t(title) }}
    </f7-block-title>
    <div class="content-devx">
      <DxTreeView
        id="locationsTree"
        ref="treeView"
        class="tree-planning__treelist"
        :style="`max-height: ${maxHeight}px`"
        :items="locationsTreeFormated"
        width="auto"
        key-expr="ID"
        parent-id-expr="HEAD_ID"
        :selection-mode="selectionMode"
        :select-nodes-recursive="recursive"
        :show-check-boxes-mode="showCheckBoxesMode"
        :select-by-click="true"
        @selection-changed="saveLocations($event)"
      />
    <!-- selectionMode: 'single' / 'multiple' -->
    <!-- showCheckBoxesMode: 'normal' / 'none' / 'selectAll' (solo si selectionMode = 'multiple') -->
    <!-- items cogera los hijos en cada nodo bajo el atributo 'items'
    items: [{ name: 'A', items: [{ name: 'A1' }, { name: 'A2' }] }]
    text: 'Stores', -> Texto que pinta
    expanded: true, -> Si está expandido o no
    -->
    </div>
  </div>
</template>
<script>
import DxTreeView from 'devextreme-vue/tree-view';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: {
    DxTreeView,
  },
  props: {
    maxHeight: { type: Number, default: 600 },
    recursive: { type: Boolean, default: true },
    selectionMode: { type: String, default: 'multiple' },
    showCheckBoxesMode: { type: String, default: 'normal' },
    locationsTree: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  data() {
    return {
      locationsTreeFormated: [],
    };
  },
  computed: {
    ...mapGetters('PlanningManager', ['actualLocationsSelected']),
  },
  mounted() {
    this.updateTreeList();
  },
  methods: {
    updateTreeList() {
      this.locationsTreeFormated = [];
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
      }
      this.setActualLocationsTreeFormated(this.locationsTreeFormated);
    },
    recursiveAddChildrens(childrens) {
      const newChildrens = [];
      for (let i = 0; i < childrens.length; i += 1) {
        newChildrens.push({
          text: childrens[i].name,
          bd_id: childrens[i].bd_id,
          ID: childrens[i].ID,
          HEAD_ID: childrens[i].HEAD_ID,
          items: this.recursiveAddChildrens(childrens[i].children),
          level: childrens[i].level,
          name: childrens[i].name,
          area: childrens[i].area,
          selected: this.checkSelected(childrens[i].bd_id),
        });
      }
      return newChildrens;
    },
    setLocationTreeFormat(item) {
      this.locationsTreeFormated.push({
        text: item.name,
        bd_id: item.bd_id,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        items: this.recursiveAddChildrens(item.children),
        level: item.level,
        name: item.name,
        area: item.area,
        selected: this.checkSelected(item.bd_id),
      });
    },
    checkSelected(id) {
      return this.actualLocationsSelected.indexOf(id) > -1;
    },
    saveLocations(e) {
      const treeView = e.component;
      const selectedLocation = treeView.getSelectedNodes()
        .map((node) => node.itemData);
      if (selectedLocation.length > 0) {
        this.setActualLocationsSelected([selectedLocation[0]]);
        this.setTotalAreaSelected(selectedLocation[0].area);
      } else {
        this.setActualLocationsSelected([]);
        this.setTotalAreaSelected(0);
      }
    },

    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('PlanningManager', ['setActualLocationsSelected', 'setActualLocationsTreeFormated']),
  },
};
</script>
<style lang="scss" scoped>
</style>
