var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('f7-row',[_c('f7-col',[_c('DxForm',{attrs:{"id":"bareRootContentForm"}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('BareRoots_content_form_level_0'),"editor-options":{
              value: _vm.bareRootsLocationName,
              disabled: true,
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('BareRoots_content_form_variety'),"editor-options":{
              dataSource: _vm.bareRootsVarieties,
              searchEnabled: true,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
              onValueChanged: _vm.updateBareRootsSelectedVariety,
              value: _vm.bareRootsSelectedVariety
            },"editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('BareRoots_content_form_variety_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('BareRoots_content_form_class'),"editor-options":{
              dataSource: _vm.bareRootsClasses,
              searchEnabled: true,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
              searchEnabled: true,
              onValueChanged: _vm.updateBareRootsSelectedClass,
              value: _vm.bareRootsSelectedClass
            },"editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('BareRoots_content_form_class_required')}})],1)],1)],1)],1),_vm._v(" "),_c('f7-col',[_c('DxForm',[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('BareRoots_content_form_level_last'),"editor-options":{
              disabled: true,
              value: _vm.bareRootsSelectedLastLevel,
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('BareRoots_content_form_category'),"editor-options":{
              dataSource: _vm.bareRootsCategories,
              searchEnabled: true,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
              onValueChanged: _vm.updateBareRootsSelectedCategory,
              searchEnabled: true,
              value: _vm.bareRootsSelectedCategory
            },"editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('BareRoots_content_form_category_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('BareRoots_content_form_plants'),"name":"plants","editor-type":"dxNumberBox","editor-options":{
              min: 0,
              mode: 'number',
              onValueChanged: _vm.updateBareRootsCurrentPlants,
              placeholder: '',
              showClearButton: true,
              showSpinButtons: false,
              step: 1,
              value: _vm.bareRootsCurrentPlants,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('BareRoots_content_form_plants_required')}})],1)],1)],1)],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col',[_c('DxForm',[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('BareRoots_content_form_note'),"name":"note","editor-options":{
              maxLength: 140,
              onValueChanged: _vm.updateBareRootsCurrentNote,
              value: _vm.currentNote,
            }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }