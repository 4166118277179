<template>
  <div
    v-if="loaded"
    class="content-devx main-monthly-planning"
  >
    <f7-block>
      <f7-row>
        <f7-col>
          <ExportDataToExcel
            :data-to-export="dataToExport"
            :column-titles="arrayExcelColumnTranslations"
            :name-file="nameFileToExport"
            :disabled="exportDisabled"
          />
          <f7-block-title>
            <span>{{ `${$t('DxAnnualBudgets_version_caption')}: ${annualBudget.annualBudgetVersion}` }}</span>
          </f7-block-title>
        </f7-col>
        <f7-col>
          <CalendarInitEndDate :key-name="keyName" />
        </f7-col>
      </f7-row>
    </f7-block>
    <DxDataGrid
      id="gridContainer"
      :data-source="detailPlanning"
      :remote-operations="true"
      :column-min-width="90"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      :column-auto-width="true"
      key-expr="ID"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMonthlyPlanning"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        type="buttons"
        data-field=""
        :width="60"
        cell-template="buttonsTemplate"
      />
      <template #buttonsTemplate="{ data: detailPlanning }">
        <div>
          <button
            v-if="monthlyPlanningEditable"
            class="dx-icon-edit boton-editar"
            @click="editTaskBadget(detailPlanning, true)"
          />
          <button
            v-else
            class="dx-icon-search boton-search"
            @click="editTaskBadget(detailPlanning, false)"
          />
        </div>
      </template>

      <DxColumn
        data-field="Date"
        name="date"
        alignment="left"
        :width="300"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="days"
        name="days"
        alignment="right"
        :caption="$t('DxDailyPlanning_days_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        :allow-header-filtering="true"
        alignment="left"
        :caption="$t('DxDailyPlanning_activity_caption')"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :allow-header-filtering="true"
        alignment="left"
        :caption="$t('DxDailyPlanning_task_caption')"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        alignment="left"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_location_caption')"
      />
      <DxColumn
        data-field="goalFormated"
        name="goal"
        :allow-header-filtering="false"
        alignment="right"
        :caption="$t('DxMonthlyPlanning_goal_caption')"
      />
      <DxColumn
        data-field="permanent"
        name="permanent"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_p_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="temporary"
        name="temporary"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_t_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="contratist"
        name="contratist"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_c')"
        alignment="right"
      />
      <DxColumn
        data-field="cost"
        name="cost"
        :allow-header-filtering="false"
        :caption="`${$t('DxDailyPlanning_coste')} €`"
        alignment="right"
      />
      <DxColumn
        data-field="comments"
        name="comments"
        :allow-header-filtering="false"
        :caption="`${$t('DxMonthlyPlanning_comments')}`"
        alignment="right"
      />
      <template #totalGroupCount="{ data }">
        <div class="total-group-count">
          <span>{{ $t("DxMonthlyPlanning_totalCost") }}: </span> {{ totalCount }} €
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../services/Api';
import CalendarInitEndDate from './CalendarInitEndDate.vue';
import ExportDataToExcel from './ExportDataToExcel.vue';

export default {
  name: 'DxMonthlyPlanning',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    CalendarInitEndDate,
    ExportDataToExcel,
  },
  data() {
    return {
      loaded: false,
      locationsTreeAux: [],
      detailPlanning: [],
      finalLevelLocation: [],
      locationsTree: [],
      widthSearchPanel: 500,
      currentLevel2Name: '',
      level2Data: [],
      areaLevel2: 0,
      permanent: 0,
      temporary: 0,
      contratist: 0,
      totalCount: 0,
      pageSizes: [5, 10, 20],
      keyName: 'monthlyPlanning',
      dataToExport: [],
      arrayExcelColumnTranslations: [],
      nameFileToExport: '',
      exportDisabled: true,
    };
  },
  computed: {
    ...mapState('Prescription', ['prescription']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('TaskBadget', ['badgedId', 'versionId']),
    ...mapGetters('PlanningManager', ['monthlyPlanningEditable']),
    ...mapState('PlanningManager', ['annualBudget']),
    ...mapGetters('greenhouse', ['getLocationNameLevelSelectedChildId']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getMonthlyPlanning(this.badgedId, this.versionId, checkedInitEndDate.init, checkedInitEndDate.end);
      const planning = JSON.parse(xhr.response);
      this.detailPlanning.length = 0;
      // esto es la estructura que hay que montar para mostrar la tabla MonthlyPlanning
      for (let j = 0; j < planning.task.length; j += 1) {
        this.locationsTreeAux = [...planning.task[j].locations];
        const newPlanning = {
          badgeId: this.badgedId,
          versionId: this.versionId,
          ID: planning.task[j].id,
          Date: this.formattingDates(planning.task[j].dates),
          days: planning.task[j].days,
          Task: planning.task[j].task_name,
          taskName: planning.task[j].task,
          taskCode: planning.task[j].task_code,
          Activity: planning.task[j].activity,
          activityCode: planning.task[j].activity_code,
          goal: planning.task[j].goal,
          goalUnit: planning.task[j].goal_unit,
          locationLevel2: this.getNamesByBdIds(planning.task[j].locations, planning.task[j].activity_code),
          locations: planning.task[j].locations,
          temporary: planning.task[j].temporary,
          permanent: planning.task[j].permanent,
          contratist: planning.task[j].contratist,
          cost: new Intl.NumberFormat('de-DE').format(parseFloat(planning.task[j].cost).toFixed(2)),
          costAux: planning.task[j].cost,
          goalFormated: `${planning.task[j].goal} ${planning.task[j].goal_unit}`,
          variety: planning.task[j].variety === null || planning.task[j].variety === 'ALL' ? '' : planning.task[j].variety,
          comments: planning.task[j].comments,
        };
        this.detailPlanning.push(newPlanning);
      }
      this.calculateSum();
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  async mounted() {
    try {
      const xhr = await Api.getAllDataBudget(this.badgedId, this.versionId);
      this.addPlanningToExport(JSON.parse(xhr.response));
      this.exportDisabled = false;
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    }
  },
  methods: {
    addPlanningToExport(data) {
      data.task.forEach((task) => {
        const dates = this.formattingDates(task.dates);
        const locations = this.getNamesByBdIds(task.locations, task.activity_code);
        const machinary = this.formatMachinary(task.machineries);
        const products = this.formatProducts(task.products);
        const tools = this.toolsFormated(task.tools);
        const baseFields = {
          Activity: task.activity,
        };
        const emptyFields = {
          Activity: '',
        };
        this.arrayExcelColumnTranslations = [this.$t('DxDailyPlanning_activity_caption').toUpperCase()];

        // Activity Code
        baseFields.ActivityCode = task.activity_code;
        emptyFields.ActivityCode = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_activity_code').toUpperCase());
        // AreaEfective
        baseFields.AreaEfective = task.area_effective;
        emptyFields.AreaEfective = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_areaEfective').toUpperCase());
        // Comments
        baseFields.Comments = task.comments;
        emptyFields.Comments = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_Comments').toUpperCase());
        // Cost
        baseFields.Cost = `${task.cost} ${task.currency}`;
        emptyFields.Cost = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_coste').toUpperCase());
        // Goal
        baseFields.Goal = `${task.goal} ${task.goal_unit}`;
        emptyFields.Goal = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxMonthlyPlanning_goal_caption').toUpperCase());
        // TaskCode
        baseFields.TaskCode = task.task_code;
        emptyFields.TaskCode = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_TaskCode').toUpperCase());
        // TaskName
        baseFields.TaskName = task.task_name;
        emptyFields.TaskName = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_task_caption').toUpperCase());
        // variety
        baseFields.variety = task.variety;
        emptyFields.variety = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_variety').toUpperCase());
        // date
        baseFields.date = '';
        emptyFields.date = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_date_caption').toUpperCase());
        // Location
        baseFields.Location = '';
        emptyFields.Location = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_location_caption').toUpperCase());
        // contratist
        baseFields.contratist = task.workers.contratist;
        emptyFields.contratist = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_c').toUpperCase());
        // permanent
        baseFields.permanent = task.workers.permanent;
        emptyFields.permanent = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_p_caption').toUpperCase());
        // temporary
        baseFields.temporary = task.workers.temporary;
        emptyFields.temporary = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_t_caption').toUpperCase());
        // performance
        baseFields.performance = `${task.workers.performance} ${task.workers.performance_unit}`;
        emptyFields.performance = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_performance').toUpperCase());
        // machinary_type
        baseFields.machinary_type = '';
        emptyFields.machinary_type = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_machinary_type').toUpperCase());
        // machinary_consumption
        baseFields.machinary_consumption = '';
        emptyFields.machinary_consumption = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_machinary_consumption').toUpperCase());
        // machinary_consumption_type
        baseFields.machinary_consumption_type = '';
        emptyFields.machinary_consumption_type = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_machinary_consumption_type').toUpperCase());
        // machinary_notes
        baseFields.machinary_notes = '';
        emptyFields.machinary_notes = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_machinary_notes').toUpperCase());
        // machinary_performance
        baseFields.machinary_performance = '';
        emptyFields.machinary_performance = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_machinary_performance').toUpperCase());
        // tools_type
        baseFields.tools_type = '';
        emptyFields.tools_type = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_tools_type').toUpperCase());
        // tools_consumption
        baseFields.tools_consumption = '';
        emptyFields.tools_consumption = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_tools_consumption').toUpperCase());
        // tools_consumption_type
        baseFields.tools_consumption_type = '';
        emptyFields.tools_consumption_type = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_tools_consumption_type').toUpperCase());
        // tools_count
        baseFields.tools_count = '';
        emptyFields.tools_count = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_tools_count').toUpperCase());
        // products_name
        baseFields.products_name = '';
        emptyFields.products_name = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_products_name').toUpperCase());
        // products_code
        baseFields.products_code = '';
        emptyFields.products_code = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_products_code').toUpperCase());
        // products_dilution_factor
        baseFields.products_dilution_factor = '';
        emptyFields.products_dilution_factor = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_products_dilution_factor').toUpperCase());
        // products_dosis
        baseFields.products_dosis = '';
        emptyFields.products_dosis = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_products_dosis').toUpperCase());
        // products_dosis_total
        baseFields.products_dosis_total = '';
        emptyFields.products_dosis_total = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_products_dosis_total').toUpperCase());
        // products_solution
        baseFields.products_solution = '';
        emptyFields.products_solution = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_products_solution').toUpperCase());
        // products_solution_total
        baseFields.products_solution_total = '';
        emptyFields.products_solution_total = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_export_products_solution_total').toUpperCase());

        this.insertNewElementToExport(baseFields, emptyFields, dates, locations, machinary, tools, products, false);
      });
      this.nameFileToExport = `Budget ${moment().format('YYYY-MM-DD HH:mm:ss')}`;
    },
    insertNewElementToExport(baseFields, emptyFields, dates, locations, machinary, tools, products, childRow) {
      let newObject = {};
      if (childRow) {
        newObject = JSON.parse(JSON.stringify(emptyFields));
      } else {
        newObject = JSON.parse(JSON.stringify(baseFields));
      }
      if (dates.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        newObject.date = dates[0];
        dates.splice(0, 1);
      }
      if (locations.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        newObject.Location = locations[0];
        locations.splice(0, 1);
      }
      if (machinary.length > 0) {
        newObject.machinary_consumption = `${machinary[0].consumption} ${machinary[0].consumption_unit}`;
        newObject.machinary_consumption_type = machinary[0].consumption_type;
        newObject.machinary_notes = machinary[0].notes;
        newObject.machinary_performance = `${machinary[0].performance} ${machinary[0].performance_unit}`;
        newObject.machinary_type = machinary[0].type;
        machinary.splice(0, 1);
      }
      if (tools.length > 0) {
        newObject.tools_consumption = `${tools[0].consumption} ${tools[0].consumption_unit}`;
        newObject.tools_consumption_type = tools[0].consumption_type;
        newObject.tools_count = tools[0].count;
        newObject.tools_type = tools[0].type;
        tools.splice(0, 1);
      }
      if (products.length > 0) {
        newObject.products_code = products[0].code;
        newObject.products_dilution_factor = products[0].dilution_factor;
        newObject.products_dosis = products[0].dosis;
        newObject.products_dosis_total = products[0].dosis_total;
        newObject.products_name = products[0].name;
        newObject.products_solution = products[0].solution;
        newObject.products_solution_total = products[0].solution_total;
        products.splice(0, 1);
      }
      this.dataToExport.push(newObject);
      if (dates.length > 0 || locations.length > 0 || machinary.length > 0 || tools.length > 0 || products.length > 0) {
        this.insertNewElementToExport(baseFields, emptyFields, dates, locations, machinary, tools, products, true);
      }
    },
    formatProducts(productArray) {
      const productsFormated = [];
      productArray.forEach((element) => {
        productsFormated.push({
          code: element.code,
          dilution_factor: element.dilution_factor,
          dosis: `${element.dosis} ${element.dosisUt}`,
          dosis_total: element.dosis_total,
          name: element.name,
          solution: `${element.solution} ${element.solutionUt}`,
          solution_total: element.dosis_total,
        });
      });
      return productsFormated;
    },
    toolsFormated(toolsArray) {
      const toolsFormated = [];
      toolsArray.forEach((element) => {
        toolsFormated.push({
          consumption: element.consumption,
          consumption_type: element.consumption_type,
          consumption_unit: element.consumption_unit,
          count: element.count,
          type: element.type,
        });
      });
      return toolsFormated;
    },
    formatMachinary(machinaryArray) {
      const machinaryFormated = [];
      machinaryArray.forEach((element) => {
        machinaryFormated.push({
          consumption: element.consumption,
          consumption_type: element.consumption_type,
          consumption_unit: element.consumption_unit,
          notes: element.notes,
          performance: element.performance,
          performance_unit: element.performance_unit,
          type: element.type,
        });
      });
      return machinaryFormated;
    },

    calculateSum() {
      for (const iterator of this.detailPlanning) {
        this.totalCount += parseFloat(iterator.costAux);
      }
      this.totalCount = new Intl.NumberFormat('de-DE').format(this.totalCount.toFixed(2));
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async editTaskBadget(rowSelected, editable) {
      this.setTaskId(rowSelected.row.data.ID);
      try {
        const xhr = await Api.getMonthlyTaskPlanningId(rowSelected.row.data.badgeId, rowSelected.row.data.versionId, rowSelected.row.data.ID);
        const data = JSON.parse(xhr.response);
        const loadActualTaskBadget = {};
        loadActualTaskBadget.activityName = data.activity;
        loadActualTaskBadget.activityCode = data.activity_code;
        loadActualTaskBadget.taskName = data.task_name;
        loadActualTaskBadget.taskCode = data.task_code;
        loadActualTaskBadget.goal = data.goal;
        loadActualTaskBadget.goalUnit = data.goal_unit;
        loadActualTaskBadget.workers = {
          CONTRATIST: data.workers.contratist,
          PERMANENT: data.workers.permanent,
          TEMPORARY: data.workers.temporary,
        };
        loadActualTaskBadget.performanceWorker = data.workers.performance;
        loadActualTaskBadget.dateProgrammed = data.dates;
        loadActualTaskBadget.machinery = data.machineries;
        loadActualTaskBadget.attachments = data.attachments;
        loadActualTaskBadget.tools = data.tools;
        loadActualTaskBadget.prescription = data.prescription;
        loadActualTaskBadget.products = data.products;
        loadActualTaskBadget.totalWorkingDays = data.dates.length;
        loadActualTaskBadget.locations = data.locations;
        loadActualTaskBadget.area_effective = data.area_effective;
        loadActualTaskBadget.variety = data.variety;
        loadActualTaskBadget.comments = data.comments;
        this.setTaskBadgetEditable(editable);
        this.setActualTaskBadget(loadActualTaskBadget);
        this.setPrescription(data.prescription);
        this.$f7.views.main.router.navigate('/editMonthly/', { reloadCurrent: true });
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    getNamesByBdIds(ids, activityCode) {
      const names = [];
      let level = 1;
      if ((activityCode === '06' || activityCode === '16') && Api.getAgriFarm() === 'serbia') {
        level = 3;
      }
      for (const id of ids) {
        const newName = this.getLocationNameLevelSelectedChildId(id, level);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        const index = formatedDates.indexOf(moment(date).format('MM-YYYY'));
        if (index === -1) {
          formatedDates.push(moment(date).format('MM-YYYY'));
        }
      }
      return formatedDates;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('MonthlyPlaning');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MonthlyPlaning.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      let cssClass = 'add-task';
      if (!this.monthlyPlanningEditable) {
        cssClass = 'hide';
      }
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: cssClass,
          },
          text: this.$t('DxMonthlyPlanning_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createMonthly/', { reloadCurrent: true });
          },
        },
      }, {
        location: 'after',
        template: 'totalGroupCount',
      });
    },
    ...mapActions('TaskBadget', ['setBadgedId', 'setVersionId', 'setTaskId', 'setActualTaskBadget', 'setTaskBadgetEditable']),
    ...mapActions('Prescription', ['setPrescription']),
  },
};
</script>
<style lang="scss">
.main-monthly-planning
  .total-group-count{
      font-size: 16px;
      font-weight: 700;
      border: 1px solid rgba(133,103,103,0.2);
      margin-right: 10px;
      padding: 8px 12px;
      border-radius: 5px;
      background: #FBF7EF;
      color: #470000;
  }
  .total-group-count span {
    font-weight: 400;
    font-size: 13px;
  }
  .boton-editar, .boton-search {
    width: auto;
    padding: 3px 6px;
    font-weight: 500;
    border: 1px solid var(--ha-gray);
    text-transform: uppercase;
    margin-right: 5px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    text-decoration: none;
    outline:none;
  }
  .boton-editar {
    background: var(--fr-inprogress-tint);
    border-color: var(--ha-yellow);
    color: var(--ha-orange);
    &:hover {
      background: var(--ha-orange);
      border-color: var(--ha-orange);
      color: var(--fr-inprogress-tint);
    }
  }
  .boton-search {
    background: var(--fr-pending-tint);
    border-color: var(  --fr-pending);
    color: var(  --fr-open-tint);
    &:hover {
      background: var( --fr-rejected-tint);
      color:var(--fr-open);
    }
  }
</style>
