<template>
  <f7-page>
    <navbar :text="$t('ClimateGrids_navbar_title')" />
    <f7-subnavbar class="subnavbar">
      <!-- <div class="subnavbar__element">
        <span>Data Frequency:</span>
        <DxSelectBox
          ref="measuringSelect"
          :data-source="measuringFrequency"
          :value="selectedMeasuringFrequency"
          :on-value-changed="updateMeasuringFrequency"
        />
      </div> -->
      <div class="subnavbar__element">
        <span>Location:</span>
        <DxSelectBox
          ref="locationSelect"
          :data-source="locationsToSelect"
          :value="locationsToSelect[0].bd_id"
          :on-value-changed="updateSelectedFarmID"
          display-expr="name"
          value-expr="bd_id"
        />
      </div>
    </f7-subnavbar>
    <f7-col
      v-if="loaded"
      width="100"
    >
      <div class="content-calendar-block">
        <CalendarInitEndDate :key-name="keyName" />
      </div>
      <DxWeatherTemperatureGrid
        :data-source="temperaturesDataSource"
        weather-element="Temperature"
      />
      <DxWeatherHumidityGrid
        :data-source="humiditiesDataSource"
        weather-element="Humidity"
      />
      <DxWeatherPrecipitationGrid
        :data-source="precipitationsDataSource"
        weather-element="Precipitation"
      />
      <DxWeatherAcumulatedPrecipitationGrid
        :data-source="acumulatedPrecipitationsDataSource"
        weather-element="Precipitation"
      />
      <DxWeatherEvapoTranspirationGrid
        :data-source="evapoTranspirationDataSource"
      />
    </f7-col>
  </f7-page>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import * as overlay from 'devextreme/ui/overlay';
import { mapState, mapGetters, mapActions } from 'vuex';
import DxWeatherHumidityGrid from '../../components/weather/DxWeatherHumidityGrid.vue';
import DxWeatherPrecipitationGrid from '../../components/weather/DxWeatherPrecipitationGrid.vue';
import DxWeatherTemperatureGrid from '../../components/weather/DxWeatherTemperatureGrid.vue';
import navbar from '../../components/NavBar.vue';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';
import DxWeatherAcumulatedPrecipitationGrid from '../../components/weather/DxWeatherAcumulatedPrecipitationGrid.vue';
import DxWeatherEvapoTranspirationGrid from '../../components/weather/DxWeatherEvapoTranspirationGrid.vue';

export default {
  name: 'ClimateGrids',
  components: {
    DxWeatherHumidityGrid,
    DxWeatherPrecipitationGrid,
    DxWeatherTemperatureGrid,
    CalendarInitEndDate,
    navbar,
    DxSelectBox,
    DxWeatherAcumulatedPrecipitationGrid,
    DxWeatherEvapoTranspirationGrid,
  },
  data() {
    return {
      loaded: false,
      locationsAvailables: [],
      locationsToSelect: [],
      temperaturesDataSource: [],
      humiditiesDataSource: [],
      precipitationsDataSource: [],
      acumulatedPrecipitationsDataSource: [],
      evapoTranspirationDataSource: [],
      indexOfRange: 0,
      keyName: 'climateGrids',
    };
  },
  computed: {
    ...mapState('weather', [
      'measuringFrequency',
      'selectedFarmID',
      'selectedMeasuringFrequency',
    ]),
    ...mapState('Reporting', ['locationsTree', 'selectedFarmList']),
    ...mapGetters('weather', [
      'hourlyMeasuredTemperatures',
      'hourlyForecastedTemperatures',
      'dailyMeasuredTemperatures',
      'dailyForecastedTemperatures',
      'hourlyMeasuredHumidities',
      'hourlyForecastedHumidities',
      'dailyMeasuredHumidities',
      'dailyForecastedHumidities',
      'hourlyMeasuredPrecipitations',
      'hourlyForecastedPrecipitations',
      'dailyMeasuredPrecipitations',
      'dailyForecastedPrecipitations',
      'acumulatedMeasuredPrecipitations',
      'evapoTranspiration',
    ]),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  created() {
    this.$f7.preloader.show();
    this.getLocationsAvailables();
    this.locationsAvailables.forEach((location) => {
      if (this.selectedFarmList.includes(location.bd_id)) {
        this.locationsToSelect.push(location);
      }
    });
    if (this.selectedMeasuringFrequency !== this.measuringFrequency[0]) {
      for (let i = 0; i < this.measuringFrequency.length; i += 1) {
        if (this.selectedMeasuringFrequency !== this.measuringFrequency[i]) {
          this.setSelectedMeasuringFrequency(this.measuringFrequency[i]);
        }
      }
    } else {
      this.setSelectedMeasuringFrequency(this.measuringFrequency[0]);
    }
    this.setSelectedFarmID(this.locationsToSelect[0].bd_id).then(() => {
      this.fetchData().then(() => {
        this.updateTemperaturesDataSource();
        this.updateHumiditiesDataSource();
        this.updatePrecipitationsDataSource();
        this.updateAcumulatedPrecipitationsDataSource();
        this.updateEvapotranspirationDataSource();
      }).finally(() => {
        this.loaded = true;
        this.$f7.preloader.hide();
      });
    });
  },
  beforeMount() {
    overlay.baseZIndex(999999);
  },
  methods: {
    ...mapActions('weather', [
      'setSelectedFarmID',
      'setSelectedMeasuringFrequency',
      'fetchWeatherForecastData',
      'fetchMeasuredWeatherData',
    ]),
    async fetchData() {
      await this.fetchWeatherForecastData(this.selectedFarmList);
      await this.fetchMeasuredWeatherData(this.selectedFarmList);
    },
    async updateMeasuringFrequency(e) {
      const newMeasuringFrequency = e.value;
      // if (newMeasuringFrequency === 'DAILY') {
      //   this.indexOfRange = 1;
      // }
      this.setSelectedMeasuringFrequency(newMeasuringFrequency);
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
      await this.updateAcumulatedPrecipitationsDataSource();
      await this.updateEvapotranspirationDataSource();
    },
    async updateSelectedFarmID(e) {
      const newSelectedFarmID = e.value;
      this.setSelectedFarmID(newSelectedFarmID);
      this.$f7.preloader.show();
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
      await this.updateAcumulatedPrecipitationsDataSource();
      await this.updateEvapotranspirationDataSource();
      this.$f7.preloader.hide();
    },
    async updateTemperaturesDataSource() {
      this.temperaturesDataSource = await this.hourlyMeasuredTemperatures;
    },
    async updateHumiditiesDataSource() {
      this.humiditiesDataSource = await this.hourlyMeasuredHumidities;
    },
    async updatePrecipitationsDataSource() {
      this.precipitationsDataSource = await this.hourlyMeasuredPrecipitations;
    },
    async updateAcumulatedPrecipitationsDataSource() {
      this.acumulatedPrecipitationsDataSource = await this.acumulatedMeasuredPrecipitations;
    },
    async updateEvapotranspirationDataSource() {
      this.evapoTranspirationDataSource = await this.evapoTranspiration;
    },
    getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
  },
};
</script>
<style lang="scss">
.subnavbar {
    display: flex;
    justify-content: flex-start;
    background: var(--f7-navbar-bg-color);
    border-bottom: 1px solid #e4e6ed;
    &__element {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.subnavbar__element span {
  font-size: 12px;
  padding: 10px;
  text-align: right;
}
.subnavbar-inner {
    justify-content: space-evenly;
}
.content__graphs {
  padding: 0 0 50px;
  background: #e4e6ed;
  min-height: 100vh;
}
.content-calendar-block {
padding-top: 15px;
padding-right: 15px;
}
</style>
