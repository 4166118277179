<template>
  <f7-page>
    <navbar :text="$t('LeftPanel_subtitle_wholesale_supplies_prices')" />
    <DxWholesaleSuppliesPrices />
    <DxSicuaniAgroveterinarySuppliesPrices />
    <DxQuillabambaAgroveterinarySuppliesPrices />
  </f7-page>
</template>

<script>
import DxWholesaleSuppliesPrices from '../../components/markets/DxCuscoAgroveterinarySuppliesPrices.vue';
import navbar from '../../components/NavBar.vue';
import DxSicuaniAgroveterinarySuppliesPrices from '../../components/markets/DxSicuaniAgroveterinarySuppliesPrices.vue';
import DxQuillabambaAgroveterinarySuppliesPrices from '../../components/markets/DxQuillabambaAgroveterinarySuppliesPrices.vue';

export default {
  name: 'WholesaleSuppliesPrices',
  components: {
    DxWholesaleSuppliesPrices,
    DxSicuaniAgroveterinarySuppliesPrices,
    DxQuillabambaAgroveterinarySuppliesPrices,
    navbar,
  },
};
</script>

<style>

</style>
