/* eslint-disable max-len */
import moment from 'moment';
import Api from '../../services/Api';

const defaultConfig = {
  selectedRangeOfDays: 1,
  selectedMeasuringFrequency: 'RAW',
  rangeOfDays: ['1', '7', '10', '14'],
  measuringFrequency: ['RAW', 'HOURLY', 'DAILY'],
};

export default {
  namespaced: true,
  state: {
    weatherForecastedDataByLocations: [],
    weatherMeasuredDataByLocations: [],
    rangeOfDays: defaultConfig.rangeOfDays,
    selectedRangeOfDays: defaultConfig.selectedRangeOfDays,
    measuringFrequency: defaultConfig.measuringFrequency,
    selectedMeasuringFrequency: defaultConfig.selectedMeasuringFrequency,
    selectedFarmID: '',
  },
  getters: {
    hourlyMeasuredTemperatures(state) {
      const locationMeasuredTemperatures = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );

      // const { temperaturaAriaMedia } = locationMeasuredTemperatures[0].parameters.TEMPERATURE_AVG;
      // const { temperaturaAriaMin } = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MIN;
      // const { temperaturaAriaMax } = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MAX;
      // const hourlyMeasuredTemperatures = [];

      // for (let i = 0; i < temperaturaAriaMedia.length; i += 1) {
      //   hourlyMeasuredTemperatures.push({
      //     minTemp: temperaturaAriaMin[i].value,
      //     maxTemp: temperaturaAriaMax[i].value,
      //     measuredTemperature: temperaturaAriaMedia[i].value,
      //     date: temperaturaAriaMedia[i].date,
      //   });
      // }
      const temperatura = locationMeasuredTemperatures[0].parameters.TEMPERATURE.data;
      const hourlyMeasuredTemperatures = [];
      for (let i = 0; i < temperatura.length; i += 1) {
        hourlyMeasuredTemperatures.push({
          Temperature: temperatura[i].value,
          date: temperatura[i].date,
        });
      }
      return hourlyMeasuredTemperatures;
    },
    hourlyForecastedTemperatures(state) {
      // const locationForecastedTemperatures = state.weatherForecastedDataByLocations
      //   .filter(
      //     (weatherForecastedDataLocation) => weatherForecastedDataLocation.location === state.selectedFarmID,
      //   );

      // const { temperature } = locationForecastedTemperatures[0].parameters.TEMPERATURE;
      // const hourlyForecastedTemperature = [];
      // for (let i = 0; i < temperature.length; i += 1) {
      //   hourlyForecastedTemperature.push({
      //     forecastedTemperature: temperature[i].value,
      //     date: temperature[i].date,
      //   });
      // }
      return [];
    },
    dailyMeasuredTemperatures(state) {
      const locationMeasuredTemperatures = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );

      const { temperaturaAriaMedia } = locationMeasuredTemperatures[0].parameters.TEMPERATURE_AVG;
      const dailyMeasuredTemperaturesAVG = [];
      const averageDatesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      temperaturaAriaMedia.forEach((measuredTemperatureAVG) => {
        const date = measuredTemperatureAVG.date.split(' ')[0];

        if (averageDatesDone.indexOf(date) === -1) {
          averageDatesDone.push(date);
          const currentDayTemperaturesAVG = temperaturaAriaMedia
            .filter((temperature) => date === temperature.date.split(' ')[0]);

          const currentDayTemperaturesAVGValues = currentDayTemperaturesAVG
            .map((currentDayTemperature) => currentDayTemperature.value);

          const totalTemperaturesAVG = currentDayTemperaturesAVGValues.reduce(sumReducer);

          const average = totalTemperaturesAVG / currentDayTemperaturesAVGValues.length;

          dailyMeasuredTemperaturesAVG.push({ date, unit: 'celsius', value: average });
        }
      });

      const { temperaturaAriaMin } = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MIN;
      const dailyMeasuredTemperaturesMIN = [];
      const minDatesDone = [];

      temperaturaAriaMin.forEach((measuredTemperatureMIN) => {
        const date = measuredTemperatureMIN.date.split(' ')[0];

        if (minDatesDone.indexOf(date) === -1) {
          minDatesDone.push(date);
          const currentDayTemperaturesMIN = temperaturaAriaMin
            .filter((temperature) => date === temperature.date.split(' ')[0]);

          const currentDayTemperaturesMINValues = currentDayTemperaturesMIN
            .map((currentDayTemperature) => currentDayTemperature.value);

          const min = Math.min(...currentDayTemperaturesMINValues);

          dailyMeasuredTemperaturesMIN.push({ date, unit: 'celsius', value: min });
        }
      });

      const { temperaturaAriaMax } = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MAX;
      const dailyMeasuredTemperaturesMAX = [];
      const maxDatesDone = [];

      temperaturaAriaMax.forEach((measuredTemperatureMAX) => {
        const date = measuredTemperatureMAX.date.split(' ')[0];

        if (maxDatesDone.indexOf(date) === -1) {
          maxDatesDone.push(date);
          const currentDayTemperaturesMAX = temperaturaAriaMin
            .filter((temperature) => date === temperature.date.split(' ')[0]);

          const currentDayTemperaturesMAXValues = currentDayTemperaturesMAX
            .map((currentDayTemperature) => currentDayTemperature.value);

          const max = Math.max(...currentDayTemperaturesMAXValues);

          dailyMeasuredTemperaturesMAX.push({ date, unit: 'celsius', value: max });
        }
      });

      const dailyMeasuredTemperatures = [];

      for (let i = 0; i < dailyMeasuredTemperaturesAVG.length; i += 1) {
        dailyMeasuredTemperatures.push({
          minTemp: dailyMeasuredTemperaturesMIN[i].value,
          maxTemp: dailyMeasuredTemperaturesMAX[i].value,
          measuredTemperature: dailyMeasuredTemperaturesAVG[i].value,
          date: dailyMeasuredTemperaturesAVG[i].date,
        });
      }

      return dailyMeasuredTemperatures;
    },
    dailyForecastedTemperatures(state) {
      const locationForecastedTemperatures = state.weatherForecastedDataByLocations
        .filter(
          (weatherForecastedDataLocation) => weatherForecastedDataLocation.location === state.selectedFarmID,
        );
      const temperatures = locationForecastedTemperatures[0].parameters.TEMPERATURE.temperature;

      const dailyForecastedTemperaturesAVG = [];
      const dailyForecastedTemperaturesMIN = [];
      const dailyForecastedTemperaturesMAX = [];
      const datesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      temperatures.forEach((temperatureData) => {
        const date = temperatureData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);

          const currentDayTemperatures = temperatures
            .filter((temperature) => date === temperature.date.split(' ')[0]);

          const currentDayTemperaturesValues = currentDayTemperatures
            .map((currentDayTemperature) => currentDayTemperature.value);

          const totalTemperaturesAVG = currentDayTemperaturesValues.reduce(sumReducer);

          const avg = totalTemperaturesAVG / currentDayTemperaturesValues.length;

          const min = Math.min(...currentDayTemperaturesValues);
          const max = Math.max(...currentDayTemperaturesValues);

          dailyForecastedTemperaturesAVG.push({ date, unit: 'celsius', value: avg });
          dailyForecastedTemperaturesMIN.push({ date, unit: 'celsius', value: min });
          dailyForecastedTemperaturesMAX.push({ date, unit: 'celsius', value: max });
        }
      });
      const dailyForecastedTemperatures = [];

      for (let i = 0; i < dailyForecastedTemperaturesAVG.length; i += 1) {
        dailyForecastedTemperatures.push({
          minTemp: dailyForecastedTemperaturesMIN[i].value,
          maxTemp: dailyForecastedTemperaturesMAX[i].value,
          forecastedTemperature: dailyForecastedTemperaturesAVG[i].value,
          date: dailyForecastedTemperaturesAVG[i].date,
        });
      }

      return dailyForecastedTemperatures;
    },
    hourlyMeasuredHumidities(state) {
      const locationMeasuredHumidities = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );
      // const hourlyMeasuredHumiditiesAVG = locationMeasuredHumidities[0].parameters.HUMIDITY_AVG.umiditaRelativaMedia;
      // const hourlyMeasuredHumiditiesMIN = locationMeasuredHumidities[0].parameters.HUMIDITY_MIN.umiditaRelativaMin;
      // const hourlyMeasuredHumiditiesMAX = locationMeasuredHumidities[0].parameters.HUMIDITY_MAX.umiditaRelativaMax;

      // const hourlyMeasuredHumidities = [];
      // for (let i = 0; i < hourlyMeasuredHumiditiesAVG.length; i += 1) {
      //   hourlyMeasuredHumidities.push({
      //     minTemp: hourlyMeasuredHumiditiesMIN[i].value,
      //     maxTemp: hourlyMeasuredHumiditiesMAX[i].value,
      //     measuredHumidity: hourlyMeasuredHumiditiesAVG[i].value,
      //     date: hourlyMeasuredHumiditiesAVG[i].date,
      //   });
      // }

      const humidity = locationMeasuredHumidities[0].parameters.HUMIDITY.data;
      const hourlyMeasuredHumidities = [];

      for (let i = 0; i < humidity.length; i += 1) {
        hourlyMeasuredHumidities.push({
          Humidity: humidity[i].value,
          date: humidity[i].date,
        });
      }

      return hourlyMeasuredHumidities;
    },
    hourlyForecastedHumidities(state) {
      // const locationForecastedHumidities = state.weatherForecastedDataByLocations
      //   .filter(
      //     (weatherForecastedDataLocation) => weatherForecastedDataLocation.location === state.selectedFarmID,
      //   );

      // const { humidity } = locationForecastedHumidities[0].parameters.HUMIDITY;
      // const hourlyForecastedHumidity = [];
      // for (let i = 0; i < humidity.length; i += 1) {
      //   hourlyForecastedHumidity.push({
      //     forecastedHumidity: humidity[i].value,
      //     date: humidity[i].date,
      //   });
      // }
      return [];
    },
    dailyMeasuredHumidities(state) {
      const locationMeasuredHumidities = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );

      const { umiditaRelativaMedia } = locationMeasuredHumidities[0].parameters.HUMIDITY_AVG;
      const dailyMeasuredHumiditiesAVG = [];
      const averageDatesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      umiditaRelativaMedia.forEach((measuredHumidityAVG) => {
        const date = measuredHumidityAVG.date.split(' ')[0];

        if (averageDatesDone.indexOf(date) === -1) {
          averageDatesDone.push(date);
          const currentDayHumiditiesAVG = umiditaRelativaMedia
            .filter((humidity) => date === humidity.date.split(' ')[0]);

          const currentDayHumidityAVGValues = currentDayHumiditiesAVG
            .map((currentDayHumidity) => currentDayHumidity.value);

          const totalHumiditiesAVG = currentDayHumidityAVGValues.reduce(sumReducer);

          const average = totalHumiditiesAVG / currentDayHumidityAVGValues.length;

          dailyMeasuredHumiditiesAVG.push({ date, unit: 'percentage', value: average });
        }
      });

      const { umiditaRelativaMin } = locationMeasuredHumidities[0].parameters.HUMIDITY_MIN;
      const dailyMeasuredHumiditiesMIN = [];
      const minDatesDone = [];

      umiditaRelativaMin.forEach((measuredHumidityMIN) => {
        const date = measuredHumidityMIN.date.split(' ')[0];

        if (minDatesDone.indexOf(date) === -1) {
          minDatesDone.push(date);
          const currentDayHumiditiesMIN = umiditaRelativaMin
            .filter((humidity) => date === humidity.date.split(' ')[0]);

          const currentDayHumiditiesMINValues = currentDayHumiditiesMIN
            .map((currentDayHumidity) => currentDayHumidity.value);

          const min = Math.min(...currentDayHumiditiesMINValues);

          dailyMeasuredHumiditiesMIN.push({ date, unit: 'percentage', value: min });
        }
      });

      const { umiditaRelativaMax } = locationMeasuredHumidities[0].parameters.HUMIDITY_MAX;
      const dailyMeasuredHumiditiesMAX = [];
      const maxDatesDone = [];

      umiditaRelativaMax.forEach((measuredHumidityMAX) => {
        const date = measuredHumidityMAX.date.split(' ')[0];

        if (maxDatesDone.indexOf(date) === -1) {
          maxDatesDone.push(date);
          const currentDayHumiditiesMAX = umiditaRelativaMax
            .filter((humidity) => date === humidity.date.split(' ')[0]);

          const currentDayHumiditiesMAXValues = currentDayHumiditiesMAX
            .map((currentDayHumidity) => currentDayHumidity.value);

          const max = Math.max(...currentDayHumiditiesMAXValues);

          dailyMeasuredHumiditiesMAX.push({ date, unit: 'celsius', value: max });
        }
      });
      const dailyMeasuredHumidities = [];

      for (let i = 0; i < dailyMeasuredHumiditiesAVG.length; i += 1) {
        dailyMeasuredHumidities.push({
          minTemp: dailyMeasuredHumiditiesMIN[i].value,
          maxTemp: dailyMeasuredHumiditiesMAX[i].value,
          measuredHumidity: dailyMeasuredHumiditiesAVG[i].value,
          date: dailyMeasuredHumiditiesAVG[i].date,
        });
      }
      return dailyMeasuredHumidities;
    },
    dailyForecastedHumidities(state) {
      const locationForecastedHumidities = state.weatherForecastedDataByLocations
        .filter(
          (weatherForecastedDataLocation) => weatherForecastedDataLocation.location === state.selectedFarmID,
        );
      const humidities = locationForecastedHumidities[0].parameters.HUMIDITY.humidity;

      const dailyForecastedHumiditiesAVG = [];
      const dailyForecastedHumiditiesMIN = [];
      const dailyForecastedHumiditiesMAX = [];
      const datesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      humidities.forEach((humidityData) => {
        const date = humidityData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);

          const currentDayHumidities = humidities
            .filter((humidity) => date === humidity.date.split(' ')[0]);

          const currentDayHumiditiesValues = currentDayHumidities
            .map((currentDayHumidity) => currentDayHumidity.value);

          const totalHumiditiesAVG = currentDayHumiditiesValues.reduce(sumReducer);

          const avg = totalHumiditiesAVG / currentDayHumiditiesValues.length;

          const min = Math.min(...currentDayHumiditiesValues);
          const max = Math.max(...currentDayHumiditiesValues);

          dailyForecastedHumiditiesAVG.push({ date, unit: 'percentage', value: avg });
          dailyForecastedHumiditiesMIN.push({ date, unit: 'percentage', value: min });
          dailyForecastedHumiditiesMAX.push({ date, unit: 'percentage', value: max });
        }
      });
      const dailyForecastedHumidities = [];

      for (let i = 0; i < dailyForecastedHumiditiesAVG.length; i += 1) {
        dailyForecastedHumidities.push({
          minTemp: dailyForecastedHumiditiesMIN[i].value,
          maxTemp: dailyForecastedHumiditiesMAX[i].value,
          forecastedHumidity: dailyForecastedHumiditiesAVG[i].value,
          date: dailyForecastedHumiditiesAVG[i].date,
        });
      }
      return dailyForecastedHumidities;
    },
    measuredPrecipitations(state) {
      const locationMeasuredPrecipitations = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );
      const pluviometer = locationMeasuredPrecipitations[0].parameters.PLUVIOMETER.data;
      const dailyPluviometer = locationMeasuredPrecipitations[0].parameters.DAILY_PLUVIOMETER.data;
      let monthPluviometer = [];
      if (locationMeasuredPrecipitations[0].parameters.MONTH_PLUVIOMETER) {
        monthPluviometer = locationMeasuredPrecipitations[0].parameters.MONTH_PLUVIOMETER.data;
      }

      const measuredPrecipitations = {};

      for (let i = 0; i < pluviometer.length; i += 1) {
        measuredPrecipitations[pluviometer[i].date] = {
          hourlyPrecipitation: pluviometer[i].value,
          date: pluviometer[i].date,
        };
      }
      for (let i = 0; i < dailyPluviometer.length; i += 1) {
        measuredPrecipitations[`${dailyPluviometer[i].date}T00:00:00`].dailyPrecipitation = dailyPluviometer[i].value;
      }
      for (let i = 0; i < monthPluviometer.length; i += 1) {
        measuredPrecipitations[`${monthPluviometer[i].date}T00:00:00`].monthPrecipitation = monthPluviometer[i].value;
      }
      return Object.values(measuredPrecipitations);
    },

    acumulatedMeasuredPrecipitations(state) {
      const locationMeasuredPrecipitations = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );
      const dailyPluviometer = locationMeasuredPrecipitations[0].parameters.DAILY_PLUVIOMETER.data;
      let monthPluviometer = [];
      if (locationMeasuredPrecipitations[0].parameters.MONTH_PLUVIOMETER) {
        monthPluviometer = locationMeasuredPrecipitations[0].parameters.MONTH_PLUVIOMETER.data;
      }

      const measuredPrecipitations = {};

      for (let i = 0; i < dailyPluviometer.length; i += 1) {
        measuredPrecipitations[dailyPluviometer[i].date] = {
          dailyPrecipitation: dailyPluviometer[i].value,
          date: dailyPluviometer[i].date,
        };
      }
      for (let i = 0; i < monthPluviometer.length; i += 1) {
        measuredPrecipitations[monthPluviometer[i].date].monthlyPrecipitation = monthPluviometer[i].value;
      }
      return Object.values(measuredPrecipitations);
    },
    evapoTranspiration(state) {
      const locationMeasuredEvapo = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );
      let evapoTranspiration = [];
      if (locationMeasuredEvapo[0].parameters.EVAPO_ETO) {
        evapoTranspiration = locationMeasuredEvapo[0].parameters.EVAPO_ETO.data;
      }

      const measuredEvapo = [];

      for (let i = 0; i < evapoTranspiration.length; i += 1) {
        measuredEvapo.push({
          EvapoTranspiration: evapoTranspiration[i].value,
          date: evapoTranspiration[i].date,
        });
      }
      return measuredEvapo;
    },
    measuredEvaporations(state) {
      const locationMeasuredPrecipitations = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );
      let evapo = [];
      if (locationMeasuredPrecipitations[0].parameters.EVAPO_ETO) {
        evapo = locationMeasuredPrecipitations[0].parameters.EVAPO_ETO.data;
      }
      const measuredEvaporations = [];

      for (let i = 0; i < evapo.length; i += 1) {
        measuredEvaporations.push({
          Evaporation: evapo[i].value,
          date: evapo[i].date,
        });
      }
      return measuredEvaporations;
    },

    hourlyMeasuredPrecipitations(state) {
      const locationMeasuredPrecipitations = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );

      // const { pioggia } = locationMeasuredPrecipitations[0].parameters.PLUVIOMETER;
      // const measuredPrecipitations = [];

      // pioggia.forEach((precipitation) => {
      //   measuredPrecipitations.push({
      //     measuredPrecipitation: precipitation.value,
      //     date: precipitation.date,
      //   });
      // });

      const pluviometer = locationMeasuredPrecipitations[0].parameters.PLUVIOMETER.data;
      const measuredPrecipitations = [];

      for (let i = 0; i < pluviometer.length; i += 1) {
        measuredPrecipitations.push({
          Precipitation: pluviometer[i].value,
          date: pluviometer[i].date,
        });
      }
      return measuredPrecipitations;
    },
    hourlyForecastedPrecipitations(state) {
      // const locationForecastedPrecipitations = state.weatherForecastedDataByLocations
      //   .filter(
      //     (weatherForecastedDataLocation) => weatherForecastedDataLocation.location === state.selectedFarmID,
      //   );

      // const { precipIntensity } = locationForecastedPrecipitations[0].parameters.PLUVIOMETER;
      // const forecastedPrecipitations = [];

      // precipIntensity.forEach((precipitation) => {
      //   forecastedPrecipitations.push({
      //     forecastedPrecipitation: precipitation.value,
      //     date: precipitation.date,
      //   });
      // });
      return [];
    },
    dailyMeasuredPrecipitations(state) {
      const locationMeasuredPrecipitations = state.weatherMeasuredDataByLocations
        .filter(
          (weatherMeasuredDataLocation) => weatherMeasuredDataLocation.location === state.selectedFarmID,
        );
      const measuredPrecipitations = locationMeasuredPrecipitations[0].parameters.DAILY_PLUVIOMETER.data;
      const dailyMeasuredPrecipitations = [];
      const datesDone = [];
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      measuredPrecipitations.forEach((precipitationData) => {
        const date = precipitationData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);
          const currentDayPrecipitations = measuredPrecipitations
            .filter((precipitation) => date === precipitation.date.split(' ')[0]);

          const currentDayPrecipitationsValues = currentDayPrecipitations
            .map((currentDayHumidity) => currentDayHumidity.value);

          const totalPrecipitations = currentDayPrecipitationsValues.reduce(sumReducer);

          dailyMeasuredPrecipitations.push({ date, Precipitation: totalPrecipitations });
        }
      });
      return dailyMeasuredPrecipitations;
    },
    dailyForecastedPrecipitations(state) {
      const locationForecastedPrecipitations = state.weatherForecastedDataByLocations
        .filter(
          (weatherForecastedDataLocation) => weatherForecastedDataLocation.location === state.selectedFarmID,
        );
      const forecastedPrecipitations = locationForecastedPrecipitations[0].parameters.PLUVIOMETER.precipIntensity;

      const dailyForecastedPrecipitations = [];
      const datesDone = [];
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      forecastedPrecipitations.forEach((precipitationData) => {
        const date = precipitationData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);
          const currentDayPrecipitations = forecastedPrecipitations
            .filter((precipitation) => date === precipitation.date.split(' ')[0]);

          const currentDayPrecipitationsValues = currentDayPrecipitations
            .map((currentDayPrecipitation) => currentDayPrecipitation.value);

          const totalPrecipitations = currentDayPrecipitationsValues.reduce(sumReducer);

          dailyForecastedPrecipitations.push({ date, forecastedPrecipitation: totalPrecipitations });
        }
      });
      return dailyForecastedPrecipitations;
    },
  },

  mutations: {
    SET_WEATHER_FORECAST_DATA(state, payload) {
      state.weatherForecastedDataByLocations = payload;
    },
    SET_WEATHER_MEASURED_DATA(state, payload) {
      state.weatherMeasuredDataByLocations = payload;
    },
    SET_SELECTED_FARM_ID(state, payload) {
      state.selectedFarmID = payload;
    },
    SET_SELECTED_MEASURING_FREQUENCY(state, payload) {
      state.selectedMeasuringFrequency = payload;
    },
  },
  actions: {
    async fetchWeatherForecastData({ commit, rootGetters }, locations) {
      const { weatherFarmCockpit } = rootGetters['CalendarInitEndDate/actualFilter'];
      let formattedInit = '';
      let formattedEnd = '';
      if ((typeof weatherFarmCockpit === 'undefined')) {
        formattedInit = moment.utc().subtract(7, 'd').format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment.utc().add(7, 'd').format('YYYY-MM-DDT23:59:59');
      } else {
        const { init, end } = weatherFarmCockpit;
        formattedInit = moment(init).format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment(end).format('YYYY-MM-DDT23:59:59');
      }
      try {
        // const forecasts = await Api.getClimate(locations, 'FORECAST', formattedInit, formattedEnd, ['TEMPERATURE', 'PLUVIOMETER', 'HUMIDITY'], true);
        const forecasts = await Api.getClimateCreatec(locations, formattedInit, formattedEnd, true);
        commit('SET_WEATHER_FORECAST_DATA', JSON.parse(forecasts.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchMeasuredWeatherData({ commit, rootGetters }, locations) {
      const { weatherFarmCockpit } = rootGetters['CalendarInitEndDate/actualFilter'];
      let formattedInit = '';
      let formattedEnd = '';
      if ((typeof weatherFarmCockpit === 'undefined')) {
        formattedInit = moment.utc().subtract(7, 'd').format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment.utc().add(7, 'd').format('YYYY-MM-DDT23:59:59');
      } else {
        const { init, end } = weatherFarmCockpit;
        formattedInit = moment(init).format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment(end).format('YYYY-MM-DDT23:59:59');
      }
      try {
        const measures = await Api.getClimateCreatec(locations, formattedInit, formattedEnd, true);
        // const measures = await Api.getClimate(locations, 'MEASURED', formattedInit, formattedEnd, ['TEMPERATURE_MIN', 'TEMPERATURE_MAX', 'TEMPERATURE_AVG', 'PLUVIOMETER', 'HUMIDITY_AVG'], true);
        commit('SET_WEATHER_MEASURED_DATA', JSON.parse(measures.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    setSelectedFarmID({ commit }, newSelectedFarmID) {
      commit('SET_SELECTED_FARM_ID', newSelectedFarmID);
    },
    setSelectedMeasuringFrequency({ commit }, newMeasuringFrequency) {
      commit('SET_SELECTED_MEASURING_FREQUENCY', newMeasuringFrequency);
    },
    setSelectedRangeOfDays({ commit }, newRangeOfDays) {
      commit('SET_SELECTED_RANGE_OF_DAYS', newRangeOfDays);
    },

  },
};
