<template>
  <f7-page>
    <navbar :text="$t('ProductCollection_navbar_title')" />
    <DxProductWarehouse :key-name="'productCollection'" />
  </f7-page>
</template>

<script>
import DxProductWarehouse from '../../components/warehouse/DxProductWarehouse.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ProductWarehouse',
  components: {
    DxProductWarehouse,
    navbar,
  },
};
</script>
