import HomePage from '../pages/home.vue';
// Accounts
import Login from '../pages/accounts/loginPage.vue';
import RecoveryPasswordPage from '../pages/accounts/recoveryPasswordPage.vue';
import ChangePasswordPage from '../pages/accounts/changePasswordPage.vue';
import Register from '../pages/accounts/register.vue';
import Logout from '../pages/accounts/logout.vue';
import RegisterTypeSelection from '../pages/accounts/registerTypeSelection.vue';
import RegisterPremium from '../pages/accounts/registerPremium.vue';
// Presence
import NfcPage from '../pages/presence/nfcPage.vue';
import NfcManually from '../pages/presence/nfcManually.vue';
import AccessPage from '../pages/presence/accessPage.vue';
import NfcManuallyAccessPage from '../pages/presence/nfcManuallyAccess.vue';
import NfcAccessPage from '../pages/presence/nfcPageAccess.vue';
// Tasks
import ActivitySelection from '../pages/activitySelection.vue';
import FarmSelection from '../pages/farmSelection.vue';
import TaskList from '../pages/tasks/list.vue';
import WorkersSelection from '../pages/tasks/workersSelection.vue';
import LocationSelect from '../pages/locationSelect.vue';
import CheckOut from '../pages/tasks/checkOut.vue';
import PostWork from '../pages/tasks/postWork.vue';
import Market from '../pages/markets/wholesaleMarkets.vue';
import WholesaleSuppliesPrices from '../pages/markets/wholesaleSuppliesPrices.vue';
import WholesaleMarketPricesOf from '../pages/markets/wholesaleMarketPrices.vue';

// DevExtreme
import ItemsTable from '../pages/ItemsTable.vue';
import DailyPlanning from '../pages/dailyPlanning.vue';
import TreePlanning from '../pages/treePlanning.vue';
import TabPanel from '../pages/tabPanel.vue';
import CreateEmployee from '../pages/createEmployee.vue';
import CreatePlanning from '../pages/createPlanning.vue';
import EditPlanning from '../pages/editPlanning.vue';
import CreateOrder from '../pages/createOrder.vue';
import EditOrder from '../pages/editOrder.vue';
import WorkOrders from '../pages/workOrders.vue';
import createPostWork from '../pages/createPostWork.vue';
import employees from '../pages/employees.vue';
import ViewPostWork from '../pages/viewPostWork.vue';
import MonthlyPlanning from '../pages/monthly/monthlyPlanning.vue';
import CreateMonthlyPlanning from '../pages/monthly/createMonthlyPlanning.vue';
import EditMonthlyPlanning from '../pages/monthly/editMonthlyPlanning.vue';
import Harvesting from '../pages/harvesting.vue';
import Warehouse from '../pages/warehouse.vue';
import AnnualBudgets from '../pages/monthly/annualBudgets.vue';
import CreateAnnualBudget from '../pages/monthly/createAnnualBudget.vue';
import CloneAnnualBudget from '../pages/monthly/cloneAnnualBudget.vue';

// Reporting
import Cockpit from '../pages/reporting/cockpit.vue';
import EffortActivity from '../pages/reporting/effortActivity.vue';
import EffortTask from '../pages/reporting/effortTask.vue';
import PrecisionFarming from '../pages/reporting/precisionFarming.vue';
import WeatherFarmCockpitPage from '../pages/reporting/weatherFarmCockpitPage.vue';
import ReportingWorker from '../pages/reporting/reportingWorker.vue';
import ReportingBpas from '../pages/reporting/reportingBpas.vue';
import ReportingMachinery from '../pages/reporting/reportingMachinery.vue';
import TimeWorked from '../pages/reporting/timeWorked.vue';
import ErrorLogs from '../pages/reporting/logs.vue';
import EmployeeEffectiveness from '../pages/reporting/reportingEmployeeEffectiveness.vue';
import ReportingWorkOrders from '../pages/reporting/reportingWorkOrders.vue';
import ListTaskAgruped from '../pages/reporting/listTaskAgruped.vue';
import PlannedVsReal from '../pages/reporting/plannedVsReal.vue';
import Irrigation from '../pages/reporting/irrigation.vue';
import NurseryReport from '../pages/reporting/nursery.vue';

// Prescription
import ActiveIngredients from '../pages/prescriptionManager/activeIngredients.vue';
import PrescriptionManager from '../pages/prescriptionManager/prescriptionManager.vue';
import StoreProduct from '../pages/product/storeProduct.vue';
import CreatePrescription from '../pages/prescriptionManager/createPrescription.vue';
import EditPrescription from '../pages/prescriptionManager/editPrescription.vue';
import ProductsDatabase from '../pages/product/productsDatabase.vue';

// Machinery
import MachineryDatabase from '../pages/machinery/machineryDatabase.vue';
import CreateMachinery from '../pages/machinery/createMachinery.vue';
import EditMachinery from '../pages/machinery/editMachinery.vue';

// Costs
import CostsDatabase from '../pages/costs/costsDatabase.vue';
import CreateCosts from '../pages/costs/createCost.vue';
import EditCosts from '../pages/costs/editCost.vue';
import Costs from '../pages/costs/costs.vue';

// Others
import NotFoundPage from '../pages/404.vue';
import ExportWorker from '../pages/ExportWorkerList.vue';

// Variety
import VarietyDatabase from '../pages/variety/varietyDatabase.vue';

// Tools
import ToolsDatabase from '../pages/dataTools/toolsDatabase.vue';
import CreateTool from '../pages/dataTools/createTool.vue';
import EditTool from '../pages/dataTools/editTool.vue';

// Warehouse
import ProductCollection from '../pages/warehouse/productCollection.vue';
import ReturnedProduct from '../pages/warehouse/returnedProduct.vue';
import Stock from '../pages/warehouse/stock.vue';

// Greenhouse
import BinsFormPage from '../pages/greenhouse/binsFormPage.vue';
import createBatch from '../pages/greenhouse/createBatch.vue';
import editBatch from '../pages/greenhouse/editBatch.vue';
import batches from '../pages/greenhouse/batches.vue';
import bins from '../pages/greenhouse/bins.vue';
import binsReclassification from '../pages/greenhouse/binsReclassification.vue';
import BareRootsFormPage from '../pages/greenhouse/bareRootsFormPage.vue';
import BareRoots from '../pages/greenhouse/bareRoots.vue';

// Scouting
import PestsDiseases from '../pages/scouting/pestsDiseasesDatabase.vue';
import ExtremeWeatherCondicions from '../pages/scouting/extremeWeatherCondicionsDatabase.vue';
import Phenologies from '../pages/scouting/phenologiesDatabase.vue';
import MonitoringPoints from '../pages/scouting/monitoringPoints.vue';
import CreatePoint from '../pages/scouting/createPoint.vue';
import EditPoint from '../pages/scouting/editPoint.vue';
import MonitoringOthers from '../pages/scouting/monitoringOthers.vue';
import MonitoringPests from '../pages/scouting/monitoringPests.vue';
import MonitoringDiseases from '../pages/scouting/monitoringDiseases.vue';
import MonitoringPhenologies from '../pages/scouting/monitoringPhenologies.vue';
import MonitoringWeatherCondicions from '../pages/scouting/monitoringWeatherCondicions.vue';
import casualRegister from '../pages/scouting/casualRegister.vue';
import formAction from '../pages/scouting/formAction.vue';

// WEATHER
import ClimateGrids from '../pages/weather/climateGrids.vue';

// TERRITORIAL UNITS
import TerritorialUnit from '../pages/territorialUnit/territorialUnitPage.vue';
import editCreateTerritorialUnit from '../pages/territorialUnit/editCreateTerritorialUnitPage.vue';

const routes = [
  // Default
  {
    path: '/',
    component: Cockpit,
  },
  // Reporting
  {
    path: '/cockpit/',
    component: Cockpit,
  },
  {
    path: '/effortActivity/',
    component: EffortActivity,
  },
  {
    path: '/effortTask/',
    component: EffortTask,
  },
  {
    path: '/precisionFarming/',
    component: PrecisionFarming,
  },
  {
    path: '/weatherFarmCockpit/',
    component: WeatherFarmCockpitPage,
  },
  {
    path: '/reportingWorker/',
    component: ReportingWorker,
  },
  {
    path: '/reportingBpas/',
    component: ReportingBpas,
  },
  {
    path: '/reportingMachinery/',
    component: ReportingMachinery,
  },
  {
    path: '/timeWorked/',
    component: TimeWorked,
  },
  {
    path: '/employeeEffectiveness/',
    component: EmployeeEffectiveness,
  },
  {
    path: '/reportingWorkOrders/',
    component: ReportingWorkOrders,
  },
  {
    path: '/listTaskAgruped/',
    component: ListTaskAgruped,
  },
  {
    path: '/plannedVsReal/',
    component: PlannedVsReal,
  },
  {
    path: '/irrigation/',
    component: Irrigation,
  },
  {
    path: '/nurseryReport/',
    component: NurseryReport,
  },
  // Accounts
  {
    path: '/accounts/loginPage/',
    component: Login,
  },
  {
    path: '/recoveryPasswordPage/',
    component: RecoveryPasswordPage,
  },
  {
    path: '/changePasswordPage/',
    component: ChangePasswordPage,
  },
  {
    path: '/register/',
    component: Register,
  },
  {
    path: '/accounts/logout/',
    component: Logout,
  },
  {
    path: '/registerTypeSelection/',
    component: RegisterTypeSelection,
  },
  {
    path: '/registerPremium/',
    component: RegisterPremium,
  },
  // Presence
  {
    path: '/nfc/',
    component: NfcPage,
  },
  {
    path: '/nfcManually/',
    component: NfcManually,
  },
  {
    path: '/accessPage/',
    component: AccessPage,
  },
  {
    path: '/nfcAccessPage/',
    component: NfcAccessPage,
  },
  {
    path: '/nfcManuallyAccessPage/',
    component: NfcManuallyAccessPage,
  },
  // Tasks
  {
    path: '/tasks/list/',
    component: TaskList,
  },
  {
    path: '/treePlanning/',
    component: TreePlanning,
  },
  {
    path: '/dailyPlanning/',
    component: DailyPlanning,
  },
  {
    path: '/createOrder/',
    component: CreateOrder,
  },
  {
    path: '/editOrder/:pathBack/',
    component: EditOrder,
  },
  {
    path: '/workOrders/',
    component: WorkOrders,
  },
  {
    path: '/viewPostWork/',
    component: ViewPostWork,
  },
  {
    path: '/createPostWork/',
    component: createPostWork,
  },
  {
    path: '/employees/',
    component: employees,
  },
  {
    path: '/postWork/',
    component: PostWork,
  },
  {
    path: '/market/',
    component: Market,
  },
  {
    path: '/wholesaleSuppliesPrices/',
    component: WholesaleSuppliesPrices,
  },
  {
    path: '/wholesaleMarketPricesOf/',
    component: WholesaleMarketPricesOf,
  },
  // Prescription
  {
    path: '/activeIngredients/',
    component: ActiveIngredients,
  },
  {
    path: '/presciptionManager/',
    component: PrescriptionManager,
  },
  {
    path: '/storeProduct/',
    component: StoreProduct,
  },
  {
    path: '/createPrescription/',
    component: CreatePrescription,
  },
  {
    path: '/editPrescription/',
    component: EditPrescription,
  },
  {
    path: '/productsDatabase/',
    component: ProductsDatabase,
  },
  // Machinery
  {
    path: '/machineryDatabase/',
    component: MachineryDatabase,
  },
  {
    path: '/createMachinery/',
    component: CreateMachinery,
  },
  {
    path: '/editMachinery/',
    component: EditMachinery,
  },
  // Costs
  {
    path: '/costsDatabase/',
    component: CostsDatabase,
  },
  {
    path: '/createcosts/',
    component: CreateCosts,
  },
  {
    path: '/editcosts/',
    component: EditCosts,
  },
  {
    path: '/costs/',
    component: Costs,
  },
  {
    path: '/territorialUnit/',
    component: TerritorialUnit,
  },
  {
    path: '/createTerritorialUnit/',
    component: editCreateTerritorialUnit,
  },
  {
    path: '/editTerritorialUnit/',
    component: editCreateTerritorialUnit,
  },
  // Check Out
  {
    path: '/tasks/checkOut/:pathBack/',
    component: CheckOut,
  },
  // Others
  {
    path: '/dashboard/',
    component: TaskList,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/ExportWorkerList/',
    component: ExportWorker,
  },
  {
    path: '/locationSelect/',
    component: LocationSelect,
  },
  {
    path: '/tasks/workersSelection/',
    component: WorkersSelection,
  },
  // Tasks
  {
    path: '/activitySelection/',
    component: ActivitySelection,
  },
  // Tasks
  {
    path: '/farmSelection/',
    component: FarmSelection,
  },
  {
    path: '/itemsTable/',
    component: ItemsTable,
  },
  {
    path: '/editPlanning/',
    component: EditPlanning,
  },
  {
    path: '/createPlanning/',
    component: CreatePlanning,
  },
  {
    path: '/createEmployee/',
    component: CreateEmployee,
  },
  {
    path: '/tabPanel/',
    component: TabPanel,
  },
  // MONTHLY PLANNING
  {
    path: '/monthlyPlanning/',
    component: MonthlyPlanning,
  },
  {
    path: '/createMonthly/',
    component: CreateMonthlyPlanning,
  },
  {
    path: '/editMonthly/',
    component: EditMonthlyPlanning,
  },
  {
    path: '/annualBudgets/',
    component: AnnualBudgets,
  },
  {
    path: '/createAnnualBudget/',
    component: CreateAnnualBudget,
  },
  {
    path: '/cloneAnnualBudget/',
    component: CloneAnnualBudget,
  },
  // HARVESTING
  {
    path: '/harvesting/',
    component: Harvesting,
  },
  // WAREHOUSE
  {
    path: '/warehouse/',
    component: Warehouse,
  },
  {
    path: '/logs/',
    component: ErrorLogs,
  },
  // VARIETY
  {
    path: '/varietyDatabase/',
    component: VarietyDatabase,
  },
  // TOOLS
  {
    path: '/toolsDatabase/',
    component: ToolsDatabase,
  },
  {
    path: '/createTool/',
    component: CreateTool,
  },
  {
    path: '/editTool/',
    component: EditTool,
  },
  // WAREHOUSE
  {
    path: '/productCollection/',
    component: ProductCollection,
  },
  {
    path: '/returnedProduct/',
    component: ReturnedProduct,
  },
  {
    path: '/stock/',
    component: Stock,
  },
  // GREENHOUSE
  {
    path: '/batches/create/',
    component: createBatch,
  },
  {
    path: '/batches/edit/',
    component: editBatch,
  },
  {
    path: '/batches/',
    component: batches,
  },
  {
    path: '/bins/',
    component: bins,
  },
  {
    path: '/stock-management/reclassification/',
    component: binsReclassification,
  },
  {
    path: '/bins/create/',
    component: BinsFormPage,
  },
  {
    path: '/bins/edit/',
    component: BinsFormPage,
  },
  {
    path: '/bins/reclassify/',
    component: BinsFormPage,
  },
  {
    path: '/bins/move/',
    component: BinsFormPage,
  },
  {
    path: '/bare-roots/',
    component: BareRoots,
  },
  {
    path: '/bare-roots/create/',
    component: BareRootsFormPage,
  },
  {
    path: '/bare-roots/edit/',
    component: BareRootsFormPage,
  },
  // SCOUTING
  {
    path: '/scouting/pestsDiseases/',
    component: PestsDiseases,
  },
  {
    path: '/scouting/extremeWeatherCondicions/',
    component: ExtremeWeatherCondicions,
  },
  {
    path: '/scouting/phenologies/',
    component: Phenologies,
  },
  {
    path: '/monitoringPoints/',
    component: MonitoringPoints,
  },
  {
    path: '/createPoint/',
    component: CreatePoint,
  },
  {
    path: '/editPoint/',
    component: EditPoint,
  },
  {
    path: '/monitoringOthers/',
    component: MonitoringOthers,
  },
  {
    path: '/monitoringPests/',
    component: MonitoringPests,
  },
  {
    path: '/monitoringDiseases/',
    component: MonitoringDiseases,
  },
  {
    path: '/monitoringPhenologies/',
    component: MonitoringPhenologies,
  },
  {
    path: '/monitoringWeatherCondicions/',
    component: MonitoringWeatherCondicions,
  },
  {
    path: '/casualRegister/',
    component: casualRegister,
  },
  {
    path: '/formAction/',
    component: formAction,
  },
  // WEATHER
  {
    path: '/climateGrids/',
    component: ClimateGrids,
  },
  // No found
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
