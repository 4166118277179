<template>
  <DxDataGrid
    id="gridMachinery"
    :visible="machineryPopup.length !== 0"
    :data-source="machineryPopup"
    :show-borders="true"
    :show-column-lines="true"
    :show-row-lines="false"
    :column-auto-width="false"
    :on-row-updated="showButtonSave"
    @exporting="onExportingMachineryPopup"
  >
    <DxStateStoring
      :enabled="true"
      type="localStorage"
      storage-key="DxMachineryPerformance"
    />
    <DxEditing
      :allow-updating="true"
      :select-text-on-edit-start="selectTextOnEditStart"
      :start-edit-action="startEditAction"
      mode="batch"
    />
    <DxExport
      :enabled="true"
    />
    <DxPaging :page-size="18" />
    <DxSearchPanel
      :visible="true"
      :width="200"
      :highlight-case-sensitive="true"
      :placeholder="$t('Search_placeholder')"
    />
    <DxColumn
      data-field="item_id"
      :caption="$t('DxWorkOrders_machinery_caption')"
      :allow-editing="false"
      width="20%"
    >
      <DxLookup
        :data-source="itemsAvailables"
        value-expr="id"
        :display-expr="machineryNameFormatted"
      />
    </DxColumn>
    <DxColumn
      data-field="driver_id"
      :caption="$t('DxWorkOrders_driver_caption')"
      :allow-editing="false"
    >
      <DxLookup
        :data-source="driversAvailables"
        value-expr="id"
        display-expr="name"
      />
    </DxColumn>
    <DxColumn
      data-field="km_start"
      data-type="number"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_km_start_caption')"
    >
      <DxRequiredRule />
    </DxColumn>
    <DxColumn
      data-field="km_finish"
      data-type="number"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_km_finish_caption')"
    >
      <DxRequiredRule />
    </DxColumn>
    <DxColumn
      data-field="km_total"
      data-type="number"
      :editor-options="numberColumnOptions"
      :calculate-cell-value="getTotalKm"
      :caption="$t('DxWorkOrders_kmTotal_caption')"
    />
    <DxColumn
      data-field="horometer_start"
      data-type="number"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_horometer_start_caption')"
    >
      <DxRequiredRule />
    </DxColumn>
    <DxColumn
      data-field="horometer_finish"
      data-type="number"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_horometer_finish_caption')"
    >
      <DxRequiredRule />
    </DxColumn>
    <DxColumn
      data-field="horometer_total"
      data-type="number"
      :calculate-cell-value="getTotalHorometer"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_HorometerTotal_caption')"
    />
    <DxColumn
      data-field="working_area"
      data-type="number"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_working_area_caption')"
    >
      <DxRequiredRule />
    </DxColumn>
    <DxColumn
      data-field="fuel_consumption"
      data-type="number"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_fuel_consumption_caption')"
    >
      <DxRequiredRule />
    </DxColumn>
    <DxColumn
      data-field="fuel_type"
      name="fuel_type"
      alignment="left"
      :caption="$t('MonthlyComponent_typeconsumption_caption')"
    >
      <DxLookup
        :data-source="typeCosumptionAvailable"
        :display-expr="dysplayConsumptionType"
      />
      <DxRequiredRule />
    </DxColumn>
    <DxColumn
      data-field="comment"
      :caption="$t('DxWorkOrders_aditional_info_caption')"
    />
  </DxDataGrid>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxEditing,
  DxLookup,
  DxRequiredRule,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';

export default {
  name: 'DxMachineryPerformance',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxEditing,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
  },
  props: {
    machineryPopup: { type: Array, default: () => [] },
  },
  data() {
    return {
      typeCosumptionAvailable: ['DIESEL', 'GASOLINE'],
      itemsAvailables: [],
      driversAvailables: [],
      selectTextOnEditStart: true,
      startEditAction: 'click',
      numberColumnOptions: {
        showSpinButtons: true, min: 0, showClearButton: false,
      },
    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.itemsAvailables = await this.getItems('MACHINERY', 'VEHICLE');
      this.driversAvailables = await this.getEmployeesDriver();
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    machineryNameFormatted(e) {
      return e.item.description + e.item.code;
    },
    // GET ITEMS AND DRIVERS
    async getItems(type, family) {
      const xhr = await Api.getItems(type, family);
      return JSON.parse(xhr.response);
    },
    async getEmployeesDriver() {
      const xhr = await Api.getEmployees();
      return JSON.parse(xhr.response).data.filter((employee) => employee.driver === true);
    },
    isDriver(empleado) {
      for (let i = 0; i < empleado.properties.length; i += 1) {
        if (empleado.properties[i].key === 'drive') {
          return empleado.properties[i].value;
        }
      }
      return 'False';
    },
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      return this.$t('GASOLINE');
    },
    // UTILITIES
    showButtonSave() {
      this.setSaveButtonPopupWorkOrder(false);
    },
    // EXPORT EXCEL
    onExportingMachineryPopup(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('MachineryPerformance');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MachineryPerformance.xlsx');
          });
      });
      e.cancel = true;
    },
    getTotalKm(rowData) {
      return rowData.km_finish - rowData.km_start;
    },
    getTotalHorometer(rowData) {
      return rowData.horometer_finish - rowData.horometer_start;
    },
    ...mapActions('PlanningManager', ['setSaveButtonPopupWorkOrder']),
  },

};
</script>
