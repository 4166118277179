<template>
  <div v-if="loaded">
    <div
      class="content-devx content-dx-IrrigationTable"
    >
      <CalendarInitEndDate :key-name="keyName" />
      <DxDataGrid
        id="irrigationTable"
        ref="irrigationTable"
        :data-source="displayedIrrigations"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
      >
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="false"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="date_init"
          name="date_init"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_from')"
        />
        <DxColumn
          data-field="date_end"
          name="date_end"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_to')"
        />
        <DxColumn
          data-field="field"
          name="field"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_location')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="sector"
          name="sector"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_sector')"
        />
        <DxColumn
          v-if="farm === 'serbia'"
          data-field="well"
          name="well"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_well')"
        />
        <DxColumn
          v-if="farm === 'serbia'"
          data-field="location"
          name="parcel"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_parcel')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="location"
          name="location"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_team')"
        />
        <DxColumn
          data-field="time"
          name="time"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_time')"
        />
        <DxColumn
          data-field="water_volume"
          name="water_volume"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_waterVolume')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f1"
          name="f1"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f1')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f2"
          name="f2"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f2')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f3"
          name="f3"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f3')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f4"
          name="f4"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f4')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f5"
          name="f5"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f5')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f6"
          name="f6"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f6')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f7"
          name="f7"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f7')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="f8"
          name="f8"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_f8')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="avg_ph"
          name="avg_ph"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_avgPh')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="avg_ce"
          name="avg_ce"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_avgCe')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="real_flow"
          name="real_flow"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_realFlow')"
        />
        <DxColumn
          v-if="farm !== 'serbia'"
          data-field="prev_flow"
          name="prev_flow"
          alignment="left"
          :caption="$t('DxIrrigationTable_dxColumn_prevFlow')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    CalendarInitEndDate,
  },
  data() {
    return {
      displayedIrrigations: [],
      loaded: false,
      keyName: 'irrigation',
      farm: Api.getAgriFarm(),
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getLocationNameByLocationId', 'getLocationNameLevelZeroByChildId']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      let initDate = '';
      let endDate = '';
      if (typeof this.actualFilter[this.keyName] !== 'undefined') {
        initDate = this.actualFilter[this.keyName].init;
        endDate = this.actualFilter[this.keyName].end;
      }
      const xhr = await Api.getIrrigation(initDate, endDate);
      await this.fetchLocationTree();
      const irrigations = JSON.parse(xhr.response);
      for (let i = 0; i < irrigations.data.length; i += 1) {
        const teamName = this.getLocationNameByLocationId(irrigations.data[i].location);
        const levelZero = this.getLocationNameLevelZeroByChildId(irrigations.data[i].location);
        this.displayedIrrigations.push({
          id: irrigations.data[i].id,
          company: irrigations.data[i].company_id,
          date_init: irrigations.data[i].date_init,
          date_end: irrigations.data[i].date_end,
          field: levelZero,
          location: teamName,
          sector: irrigations.data[i].sector,
          time: irrigations.data[i].time,
          water_volume: irrigations.data[i].water_volume,
          f1: irrigations.data[i].f1,
          f2: irrigations.data[i].f2,
          f3: irrigations.data[i].f3,
          f4: irrigations.data[i].f4,
          f5: irrigations.data[i].f5,
          f6: irrigations.data[i].f6,
          f7: irrigations.data[i].f7,
          f8: irrigations.data[i].f8,
          avg_ph: irrigations.data[i].avg_ph,
          avg_ce: irrigations.data[i].avg_ce,
          real_flow: irrigations.data[i].real_flow,
          prev_flow: irrigations.data[i].prev_flow,
          well: irrigations.data[i].well ?? '',
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions('greenhouse',
      [
        'fetchLocationTree',
      ]),
  },
};
</script>
