var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"50px 10%"}},[_c('DxForm',{attrs:{"id":"form"}},[_c('DxGroupItem',{attrs:{"col-count":2,"caption":_vm.$t('Register_registerText')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpName_name'),"editor-options":{
          onValueChanged: _vm.changeName,
          value: _vm.actualName,
        },"editor-type":"dxTextBox","name":"Name"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpName_name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpSurnames_name'),"editor-options":{
          onValueChanged: _vm.changeSurname,
          value: _vm.actualSurname,
        },"editor-type":"dxTextBox","name":"Surname"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpSurnames_name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpCity'),"editor-options":{
          items: _vm.citiesToShow,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          onValueChanged: _vm.changeCity,
          value: _vm.actualCity,
        },"editor-type":"dxSelectBox","name":"cityName"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpCity')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpInscriptionType'),"editor-options":{
          items: _vm.inscriptionTypes,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          onValueChanged: _vm.changeInscriptionType,
          value: _vm.actualInscriptionType,
        },"editor-type":"dxSelectBox","name":"InscriptionType"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpInscriptionType')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpEmail_name'),"editor-options":{
          onValueChanged: _vm.changeEmail,
          value: _vm.actualEmail,
          disabled: _vm.isEmailAndEmailConfirmEqual
        },"editor-type":"dxTextBox","name":"e-mail"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpEmail_name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpPassword'),"editor-options":{
          onValueChanged: _vm.changePassword,
          value: _vm.actualPassword,
          mode: 'password',
          disabled: _vm.isPswdAndPswdConfirmEqual
        },"editor-type":"dxTextBox","name":"pass"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpPassword')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpConfirmEmail_name'),"editor-options":{
          onValueChanged: _vm.changeEmailConfirm,
          value: _vm.actualEmailConfirm,
          disabled: _vm.isEmailAndEmailConfirmEqual
        },"editor-type":"dxTextBox","name":"Confirm e-mail"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpConfirmEmail_name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpConfirmPassword'),"editor-options":{
          onValueChanged: _vm.changePasswordConfirm,
          value: _vm.actualPasswordConfirm,
          mode: 'password',
          disabled: _vm.isPswdAndPswdConfirmEqual
        },"editor-type":"dxTextBox","name":"Confirm Password"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpConfirmPassword')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpPhone'),"editor-options":{
          onValueChanged: _vm.changePhone,
          value: _vm.actualPhone,
        },"editor-type":"dxNumberBox","name":"Phone"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpPhone')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_sms'),"editor-options":{
          onValueChanged: _vm.changeSMS,
          value: _vm.actualSMS,
        },"editor-type":"dxCheckBox","name":"sms"}}),_vm._v(" "),_c('DxEmptyItem',{attrs:{"visible":_vm.actualSMS}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_cultive'),"editor-options":{
          dataSource: ['Patata', 'Maiz', 'Cacao', 'Café'],
          onValueChanged: _vm.changeCultive,
          value: _vm.actualCultive,
        },"editor-type":"dxRadioGroup","name":"cultive","visible":_vm.actualSMS}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_cultive')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }