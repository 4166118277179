<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-Tools-form"
  >
    <DxForm
      id="ToolsForm"
    >
      <DxGroupItem :col-count="6">
        <DxGroupItem
          css-class="content-combo"
          :col-span="3"
        >
          <DxSimpleItem
            name="code"
            :data-field="$t('DxToolsDatabase_column_code')"
            :editor-options="{
              value: actualCode,
              onValueChanged: onChangeCode,
            }"
          >
            <DxRequiredRule :message="$t('Tools_ValidationCode')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="description"
            :data-field="$t('DxToolsDatabase_column_description')"
            :editor-options="{
              value: actualDescription,
              onValueChanged: onChangeDescription,
            }"
          >
            <DxRequiredRule :message="$t('Tools_ValidationDescription')" />
          </DxSimpleItem>
          <DxSimpleItem
            v-if="(editForm)"
            name="id"
            :data-field="$t('DxToolsDatabase_column_id')"
            :editor-options="{
              value: actualId,
              onValueChanged: onChangeId,
              readOnly: true,
            }"
          />
        </DxGroupItem>
        <DxGroupItem
          :col-span="3"
          css-class="content-combo"
        >
          <DxSimpleItem
            name="family"
            :data-field="$t('DxToolsDatabase_column_family')"
            :editor-options="{
              // items: familyAvailables,
              value: actualFamily,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
              readOnly: true,
            }"
          >
            <DxRequiredRule :message="$t('Tools_ValidationFamily')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="subfamily"
            :data-field="$t('DxToolsDatabase_column_subfamily')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: subfamilyAvailables,
              value: actualSubfamily,
              onValueChanged: onChangeSubfamily,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          >
            <DxRequiredRule :message="$t('Tools_ValidationSubfamily')" />
          </DxSimpleItem>
          <DxSimpleItem
            v-if="(editForm)"
            name="itemId"
            :data-field="$t('DxToolsDatabase_column_itemId')"
            :editor-options="{
              value: actualItemId,
              onValueChanged: onChangeItemId,
              readOnly: true,
            }"
          />
          <DxSimpleItem
            name="status"
            :data-field="$t('DxToolsDatabase_column_status')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: actualStatus,
              onValueChanged: onChangeStatus,
            }"
          />
          <DxSimpleItem
            name="fuel"
            :data-field="$t('DxToolsDatabase_column_fuel')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: actualFuel,
              onValueChanged: onChangeFuel,
            }"
          />
        </DxGroupItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';
import Api from '../../services/Api';

export default {
  name: 'DxToolsForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    editForm: { type: Boolean, default: false },
  },
  data() {
    return {
      // UTILITIES
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      loaded: false,
      // FORM VALUES
      active: false,
      actualCode: '',
      actualDescription: '',
      actualId: '',
      actualItemId: '',
      actualFuel: true,
      actualFamily: 'TOOL',
      actualSubfamily: '',
      actualAttachment: false,
      actualStatus: true,
      subfamilyAvailables: [],
      keyValues: [],
    };
  },
  computed: {
    ...mapState('Tool', ['actualTools']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    overlay.baseZIndex(9999);
    try {
      const itemsType = await Api.getItemsType();
      this.keyValues = JSON.parse(itemsType.response);
      if (this.editForm) {
        this.active = true;
        this.setValuesActualTools();
        this.onInitializedSubfamily();
      } else {
        this.onInitializedSubfamily();
        this.setValuesNewTools();
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // SET VALUES NEW Tools
    setValuesNewTools() {
      this.setCode('');
      this.setDescription('');
      this.setId('');
      this.setItemId('');
      this.setFamily(this.actualFamily);
      this.setSubfamily(this.keyValues);
      this.setFuel(true);
      this.setStatus(true);
    },
    // SET VALUES ACTUAL Tools
    setValuesActualTools() {
      this.actualCode = this.actualTools.code;
      this.actualDescription = this.actualTools.description;
      this.actualId = this.actualTools.id;
      this.actualItemId = this.actualTools.itemId;
      this.actualFamily = this.actualTools.family;
      this.actualSubfamily = this.actualTools.subfamily;
      this.actualFuel = (this.actualTools.fuel === this.$t('Yes'));
      this.actualStatus = (this.actualTools.statusColumn === this.$t('DxMachineryDatabase_column_active'));
    },
    // EVENTS HANDLER ITEMS FORM
    onChangeCode(e) {
      this.actualCode = e.value;
      this.setCode(e.value);
    },
    onChangeDescription(e) {
      this.actualDescription = e.value;
      this.setDescription(e.value);
    },
    onChangeId(e) {
      this.actualId = e.value;
      this.setId(e.value);
    },
    onChangeItemId(e) {
      this.actualItemId = e.value;
      this.setItemId(e.value);
    },
    onInitializedSubfamily() {
      for (const keyValue of this.keyValues) {
        if (keyValue.family === `ITEM_SUBFAMILY_${this.actualFamily}`) {
          this.subfamilyAvailables = keyValue.values;
        }
      }
    },
    onChangeSubfamily(e) {
      this.actualSubfamily = e.value;
      this.setSubfamily(e.value);
    },
    onChangeFuel(e) {
      this.actualFuel = e.value;
      this.setFuel(e.value);
    },
    onChangeStatus(e) {
      this.actualStatus = e.value;
      this.setStatus(e.value);
    },
    disableSundays(e) {
      if (e) {
        return [0].indexOf(e.date.getDay()) >= 0;
      }
      return undefined;
    },
    ...mapActions('Tool', ['setActualTools', 'setCode', 'setDescription', 'setId', 'setItemId', 'setFuel', 'setFamily', 'setSubfamily', 'setStatus']),
  },
};
</script>

<style lang="scss">

  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
</style>
