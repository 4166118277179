<template>
  <div
    class="content-devx content-dx-daily-planning"
  >
    <DxForm
      id="formProduct"
    >
      <DxGroupItem :col-count="6">
        <DxGroupItem
          css-class="content-combo"
          :col-span="3"
        >
          <DxSimpleItem
            name="status"
            :data-field="$t('DxFormProduct_status_caption')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: currentStatus,
              onValueChanged: changeStatus,
            }"
          />
          <!-- <DxSimpleItem
            name="SCSauthorized"
            :data-field="$t('DxFormProduct_SCS_autorizado')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: currentSCSauthorized,
              onValueChanged: onChangeStatusSCS,
            }"
          />
          <DxSimpleItem
            name="EUauthorized"
            :data-field="$t('DxFormProduct_EU_autorizado')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: currentEUauthorized,
              onValueChanged: onChangeStatusEU,
            }"
          /> -->
          <DxSimpleItem
            name="code"
            :data-field="$t('DxFormProduct_code_caption')"
            :editor-options="{
              value: currentCode,
              onValueChanged: changeCode,
            }"
          >
            <DxRequiredRule :message="$t('DxFormProduct_code_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="family"
            :data-field="$t('DxFormProduct_family_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: families,
              value: currentFamily,
              onValueChanged: changeFamily,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          >
            <DxRequiredRule :message="$t('StoreProduct_ValidationFamily')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="subfamily"
            :data-field="$t('DxFormProduct_subfamily_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: filterListSubFamilies,
              value: currentSubfamily,
              displayExpr: 'id',
              valueExpr:'id',
              onValueChanged: changeSubfamily,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          >
            <DxRequiredRule :message="$t('StoreProduct_ValidationSubfamily')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="component"
            :data-field="$t('DxFormProduct_component_caption')"
            :editor-options="{
              value: currentComponent,
              onValueChanged: changeComponent,
              disabled: isDisabled
            }"
          />
          <DxSimpleItem
            name="MaxDosisPermitida"
            :data-field="$t('DxFormProduct_maxdosis')"
            editor-type="dxNumberBox"
            :editor-options="{
              showSpinButtons: true,
              mode: 'number',
              value: currentMaxDoses,
              showClearButton: true,
              min: '0',
              onValueChanged: onChangeMaxDose,
            }"
          />
        </DxGroupItem>
        <DxGroupItem
          :col-span="3"
          css-class="content-combo"
        >
          <DxSimpleItem
            name="toxicity"
            :data-field="$t('DxFormProduct_toxicity_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: listToxicitys,
              value: currentToxicity,
              onValueChanged: changeToxicity,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          />
          <DxSimpleItem
            name="SAPunit"
            :data-field="$t('DxFormProduct_sapunit_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: listUnit,
              value: currentSapunit,
              onValueChanged: changeSapunit,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          >
            <DxRequiredRule :message="$t('StoreProduct_ValidationSapunit')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="description"
            :data-field="$t('DxFormProduct_description_caption')"
            editor-type="dxTextArea"
            :editor-options="{
              value: currentDescription,
              onValueChanged: changeDescription,
              maxLength: 256,
            }"
          >
            <DxRequiredRule :message="$t('StoreProduct_ValidationDescription')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="comment"
            :data-field="$t('DxFormProduct_comment_caption')"
            editor-type="dxTextArea"
            :editor-options="{
              value: currentComment,
              onValueChanged: changeComment,
              maxLength: 256,
            }"
          />
          <DxSimpleItem
            name="preHarvest"
            :data-field="$t('DxFormProduct_preHarvest')"
            editor-type="dxNumberBox"
            :editor-options="{
              showSpinButtons: true,
              mode: 'number',
              showClearButton: true,
              min: '0',
              value: currentPreharvest,
              onValueChanged: onChangePreharvest,
            }"
          />
          <DxSimpleItem
            name="IntervalIn"
            :data-field="$t('DxFormProduct_currentIn')"
            editor-type="dxNumberBox"
            :editor-options="{
              showSpinButtons: true,
              mode: 'number',
              value: currentIntervalIn,
              showClearButton: true,
              min: '0',
              onValueChanged: onChangeIntervalIn,
            }"
          />
        </DxGroupItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxFormProduct',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      currentCode: '',
      currentComponent: '',
      currentStatus: 'true',
      currentDescription: '',
      currentComment: '',
      currentFamily: '',
      currentSubfamily: '',
      currentToxicity: '',
      currentSapunit: '',
      currentInternalcode: '',
      currentId: '',
      currentMaxDoses: '',
      currentIntervalIn: '',
      currentSCSauthorized: false,
      currentEUauthorized: false,
      currentPreharvest: '',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      families: [],
      listFamilies: [],
      listSubFamilies: [],
      isDisabled: false,
      listToxicitys: [],
    };
  },
  computed: {
    filterListSubFamilies() {
      const filterList = [];
      for (const element of this.listFamilies) {
        if (element.id === this.currentFamily) {
          for (const subfamily of element.items) {
            filterList.push(subfamily);
          }
        }
      }
      return filterList;
    },
    ...mapState('Product', ['product', 'listUnit']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    if (this.product.id !== '') {
      this.setValuesProduct();
    } else {
      this.setNewProduct();
    }
    this.loadLists();
    EventBus.$on('updateProduct', this.updateProduct);
  },
  beforeDestroy() {
    EventBus.$off('updateProduct', this.updateProduct);
  },
  methods: {
    async updateProduct() {
      const currentProduct = {};
      currentProduct.id = this.currentId;
      currentProduct.code = this.currentCode;
      currentProduct.component = this.currentComponent;
      currentProduct.status = this.currentStatus;
      currentProduct.description = this.currentDescription;
      currentProduct.comment = this.currentComment;
      currentProduct.family = this.currentFamily;
      currentProduct.subfamily = this.currentSubfamily;
      currentProduct.maxDoses = this.currentMaxDoses;
      currentProduct.properties = [];
      currentProduct.properties.push({ key: 'SAPunit', value: this.currentSapunit });
      currentProduct.properties.push({ key: 'toxicity', value: (this.currentToxicity).toString() });
      currentProduct.properties.push({ key: 'maxDoses', value: this.currentMaxDoses });
      currentProduct.properties.push({ key: 'SCS_authorized', value: `${this.currentSCSauthorized}` });
      currentProduct.properties.push({ key: 'EU_authorized', value: `${this.currentEUauthorized}` });
      currentProduct.properties.push({ key: 'intervalIn', value: this.currentIntervalIn });
      currentProduct.properties.push({ key: 'preharvest', value: this.currentPreharvest });
      this.setProduct(currentProduct);
    },
    async loadLists() {
      const xhr = await Api.getDataSubfamilyByType('product');
      this.listFamilies = JSON.parse(xhr.response);
      this.families = JSON.parse(xhr.response).map((e) => e.id);
      console.log(this.families);
      const xhrToxi = await Api.getKeysToxicity();
      if (JSON.parse(xhrToxi.response).length > 0) {
        this.listToxicitys = JSON.parse(xhrToxi.response)[0].values;
      }
      this.listSubFamilies = [];
    },
    setValuesProduct() {
      this.currentId = this.product.id;
      this.currentCode = this.product.code;
      this.currentComponent = this.product.component;
      this.currentStatus = this.product.status === 'Active';
      this.currentDescription = this.product.description;
      this.currentComment = this.product.comment;
      this.currentFamily = this.product.family;
      this.currentSubfamily = this.product.subfamily;
      if (typeof this.product.properties.find((x) => x.key === 'toxicity') !== 'undefined') {
        this.currentToxicity = this.product.properties.find((x) => x.key === 'toxicity').value;
      }
      this.currentSapunit = (typeof (this.product.properties.find((x) => x.key === 'SAPunit')) !== 'undefined')
        ? this.product.properties.find((x) => x.key === 'SAPunit').value : '';
      this.currentMaxDoses = this.$helpers.getProperty(this.product.properties, 'maxDoses');
      this.currentIntervalIn = this.$helpers.getProperty(this.product.properties, 'intervalIn');
      this.currentEUauthorized = this.$helpers.getProperty(this.product.properties, 'EU_authorized') === 'true';
      this.currentSCSauthorized = this.$helpers.getProperty(this.product.properties, 'SCS_authorized') === 'true';
      this.currentPreharvest = this.$helpers.getProperty(this.product.properties, 'preharvest');
    },
    changeCode(e) {
      this.currentCode = e.value;
    },
    changeComponent(e) {
      this.currentComponent = e.value;
    },
    changeStatus(e) {
      this.currentStatus = e.value;
    },
    changeDescription(e) {
      this.currentDescription = e.value;
    },
    changeComment(e) {
      this.currentComment = e.value;
    },
    changeFamily(e) {
      this.currentFamily = e.value;
      this.currentSubfamily = '';
      this.isDisabled = (e.value !== 'PHITOSANITARY');
      this.currentComponent = (e.value === 'PHITOSANITARY') ? this.currentComponent : '';
    },
    changeSubfamily(e) {
      this.currentSubfamily = e.value;
    },
    changeToxicity(e) {
      this.currentToxicity = e.value;
    },
    changeSapunit(e) {
      this.currentSapunit = e.value;
    },
    onChangeActive(e) {
      this.actualActive = e.value;
    },
    onChangeMaxDose(e) {
      this.currentMaxDoses = e.value;
    },
    onChangeIntervalIn(e) {
      this.currentIntervalIn = e.value;
    },
    onChangeStatusSCS(e) {
      this.currentSCSauthorized = e.value;
    },
    onChangeStatusEU(e) {
      this.currentEUauthorized = e.value;
    },
    onChangePreharvest(e) {
      this.currentPreharvest = e.value;
    },
    ...mapActions('Product', ['setProduct', 'setListFamily', 'setNewProduct']),
  },
};
</script>

<style lang="scss">

  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
</style>
