<template>
  <div
    class="content-devx content-dx-worker main-dx-machinery"
  >
    <f7-block-title>
      {{ $t('HuancaroMarketPrices') }}
    </f7-block-title>
    <DxDataGrid
      id="worker-grid"
      :data-source="data"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxWeatherHumidityGrid"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="Página {0} de {1} ({2} elementos)"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="Nro"
        caption="Nro"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="PRODUCTO"
        caption="PRODUCTO"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="VARIEDAD"
        caption="VARIEDAD"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="CANTIDAD"
        caption="CANTIDAD"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="UNIDAD"
        caption="UNIDAD"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="pUnitario"
        caption="P. UNITARIO"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'HuancaroMarketPrices',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      pageSizes: [5, 10, 20, 30],
      data: [
        {
          Nro: 1,
          PRODUCTO: 'PAPA',
          VARIEDAD: 'CANCHAN',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.5,
        },
        {
          Nro: 1,
          PRODUCTO: 'PAPA',
          VARIEDAD: 'CANCHAN',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 2,
        },
        {
          Nro: 1,
          PRODUCTO: 'PAPA',
          VARIEDAD: 'AMARILLA',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.5,
        },
        {
          Nro: 1,
          PRODUCTO: 'PAPA',
          VARIEDAD: 'MACTILLO',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.3,
        },
        {
          Nro: 1,
          PRODUCTO: 'PAPA',
          VARIEDAD: 'CICA',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.5,
        },
        {
          Nro: 1,
          PRODUCTO: 'PAPA',
          VARIEDAD: 'COMPIS',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.5,
        },
        {
          Nro: 1,
          PRODUCTO: 'PAPA',
          VARIEDAD: 'YUNGAY',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.5,
        },
        {
          Nro: 2,
          PRODUCTO: 'MAIZ',
          VARIEDAD: 'CHULLPI',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 7,
        },
        {
          Nro: 2,
          PRODUCTO: 'MAIZ',
          VARIEDAD: 'CHUSPI',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 5,
        },
        {
          Nro: 2,
          PRODUCTO: 'MAIZ',
          VARIEDAD: 'ESTAQUILLADO',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 6,
        },
        {
          Nro: 2,
          PRODUCTO: 'MAIZ',
          VARIEDAD: 'BLANCO',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 4.5,
        },
        {
          Nro: 3,
          PRODUCTO: 'HABA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1,
        },
        {
          Nro: 4,
          PRODUCTO: 'ARBEJA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 3,
        },
        {
          Nro: 5,
          PRODUCTO: 'VAIN ITA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 4,
        },
        {
          Nro: 6,
          PRODUCTO: 'CEBOLLA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1,
        },
        {
          Nro: 7,
          PRODUCTO: 'ZANAHORIA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.5,
        },
        {
          Nro: 8,
          PRODUCTO: 'MORAYA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 8,
        },
        {
          Nro: 9,
          PRODUCTO: 'CEBADA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 2.5,
        },
        {
          Nro: 10,
          PRODUCTO: 'HABA SECA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 3,
        },
        {
          Nro: 11,
          PRODUCTO: 'CHOCLO',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 1.5,
        },
        {
          Nro: 12,
          PRODUCTO: 'TOMATE',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 3,
        },
        {
          Nro: 13,
          PRODUCTO: 'PALTA',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 6,
        },
        {
          Nro: 14,
          PRODUCTO: 'ZAPALLO',
          VARIEDAD: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 2,
        },
      ],
    };
  },
  computed: {

  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('HuancaroMarketPrices');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WeatherHumidityGrid.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
