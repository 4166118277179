<template>
  <f7-page>
    <navbar :text="$t('WeatherFarmCockpit_navbar_title')" />
    <f7-subnavbar class="subnavbar">
      <!-- <div class="subnavbar__element">
        <span>Data Frequency:</span>
        <DxSelectBox
          ref="measuringSelect"
          :data-source="measuringFrequency"
          :value="measuringFrequency[0]"
          :on-value-changed="updateMeasuringFrequency"
        />
      </div> -->
      <div class="subnavbar__element">
        <span>Location:</span>
        <DxSelectBox
          ref="locationSelect"
          :data-source="locationsToSelect"
          :value="locationsToSelect[0].bd_id"
          :on-value-changed="updateSelectedFarmID"
          display-expr="name"
          value-expr="bd_id"
        />
      </div>
    </f7-subnavbar>
    <div class="content__graphs___calendar  padding-top">
      <CalendarInitEndDate :key-name="keyName" />
    </div>
    <div class="content__graphs padding-top">
      <f7-block
        v-if="loaded"
      >
        <DxWeatherElementLinesGraph
          :data-source="temperaturesDataSource"
          weather-element="Temperature"
        />
        <DxWeatherElementLinesGraph
          :data-source="humiditiesDataSource"
          weather-element="Humidity"
        />
        <DxWeatherElementBarsGraph
          :data-source="precipitationsDataSource"
          weather-element="Precipitation"
        />
        <DxEvapoElementBarsGraph
          :data-source="evaporationsDataSource"
          weather-element="Evaporation"
        />
      </f7-block>
    </div>
  </f7-page>
</template>

<script>

import DxSelectBox from 'devextreme-vue/select-box';
import * as overlay from 'devextreme/ui/overlay';
import { mapState, mapGetters, mapActions } from 'vuex';
import DxWeatherElementLinesGraph from '../../components/reporting/DxWeatherElementLinesGraph.vue';
import DxWeatherElementBarsGraph from '../../components/reporting/DxWeatherElementBarsGraph.vue';
import navbar from '../../components/NavBar.vue';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';
import DxEvapoElementBarsGraph from '../../components/reporting/DxEvapoElementBarsGraph.vue';

export default {
  components: {
    navbar,
    DxWeatherElementLinesGraph,
    DxWeatherElementBarsGraph,
    DxSelectBox,
    CalendarInitEndDate,
    DxEvapoElementBarsGraph,
  },
  data() {
    return {
      loaded: false,
      locationsAvailables: [],
      locationsToSelect: [],
      temperaturesDataSource: [],
      humiditiesDataSource: [],
      precipitationsDataSource: [],
      evaporationsDataSource: [],
      keyName: 'weatherFarmCockpit',
    };
  },
  computed: {
    ...mapState('weatherFarm', [
      'measuringFrequency',
      'selectedFarmID',
      'selectedMeasuringFrequency',
    ]),
    ...mapState('Reporting', ['locationsTree', 'selectedFarmList']),
    ...mapGetters('weatherFarm', [
      'hourlyMeasuredTemperatures',
      'hourlyForecastedTemperatures',
      'dailyMeasuredTemperatures',
      'dailyForecastedTemperatures',
      'hourlyMeasuredHumidities',
      'hourlyForecastedHumidities',
      'dailyMeasuredHumidities',
      'dailyForecastedHumidities',
      'hourlyMeasuredPrecipitations',
      'hourlyForecastedPrecipitations',
      'dailyMeasuredPrecipitations',
      'dailyForecastedPrecipitations',
      'measuredPrecipitations',
      'measuredEvaporations',
    ]),
  },
  created() {
    this.$f7.preloader.show();
    this.getLocationsAvailables();
    this.locationsAvailables.forEach((location) => {
      if (this.selectedFarmList.includes(location.bd_id)) {
        this.locationsToSelect.push(location);
      }
    });
    this.setSelectedMeasuringFrequency(this.measuringFrequency[0]);
    this.setSelectedFarmID(this.locationsToSelect[0].bd_id).then(() => {
      this.fetchData().then(() => {
        this.updateTemperaturesDataSource();
        this.updateHumiditiesDataSource();
        this.updatePrecipitationsDataSource();
        this.updateEvaporationDataSource();
      }).finally(() => {
        this.loaded = true;
        this.$f7.preloader.hide();
      });
    });
  },
  beforeMount() {
    overlay.baseZIndex(999999);
  },
  methods: {
    ...mapActions('weatherFarm', [
      'setSelectedFarmID',
      'setSelectedMeasuringFrequency',
      'fetchWeatherForecastData',
      'fetchMeasuredWeatherData',
    ]),
    async fetchData() {
      await this.fetchWeatherForecastData(this.selectedFarmList);
      await this.fetchMeasuredWeatherData(this.selectedFarmList);
    },
    async updateMeasuringFrequency(e) {
      const newMeasuringFrequency = e.value;
      if (newMeasuringFrequency === 'DAILY') {
        this.indexOfRange = 1;
      }
      this.setSelectedMeasuringFrequency(newMeasuringFrequency);
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
      await this.updateEvaporationDataSource();
    },
    async updateSelectedFarmID(e) {
      const newSelectedFarmID = e.value;
      this.setSelectedFarmID(newSelectedFarmID);
      this.$f7.preloader.show();
      console.log('asdasda');
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
      await this.updateEvaporationDataSource();
      this.$f7.preloader.hide();
    },
    async updateTemperaturesDataSource() {
      this.temperaturesDataSource = await this.hourlyMeasuredTemperatures;
    },
    async updateHumiditiesDataSource() {
      this.humiditiesDataSource = await this.hourlyMeasuredHumidities;
    },
    async updatePrecipitationsDataSource() {
      this.precipitationsDataSource = await this.measuredPrecipitations;
    },
    async updateEvaporationDataSource() {
      this.evaporationsDataSource = await this.measuredEvaporations;
    },
    getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
  },

};
</script>

<style lang="scss">
.subnavbar {
    display: flex;
    justify-content: flex-start;
    background: var(--f7-navbar-bg-color);
    border-bottom: 1px solid #e4e6ed;
    &__element {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.subnavbar__element span {
  font-size: 12px;
  padding: 10px;
  text-align: right;
}
.subnavbar-inner {
    justify-content: space-evenly;
}
.content__graphs {
  padding: 50px 0 0px;
  background: #e4e6ed;
  min-height: 100vh;
  padding-top: 40px !important;
}
.content__graphs___calendar {
  padding: 50px 0 0px;
  background: #e4e6ed;
  min-height: 5vh;
  position: relative;
  padding-top: 20px !important;
  z-index: 999 !important;
}
.content-calendar-block {
  padding-top: 15px;
  padding-right: 15px;
  position: absolute;
  z-index: 999 !important;
  right: 0;
}
.dx-btn-cancel {
  background-color: #fff;
}
</style>
