<template>
  <f7-page>
    <navbar :text="$t('PlanningComponent_harvesting')" />
    <!-- <UploadHarvestingPlan /> -->
    <DxWorkOrders
      :include-activities="includeActivities"
      :back="back"
      :key-name="'harvesting'"
    />
  </f7-page>
</template>

<script>

// import UploadHarvestingPlan from '../components/harvesting/UploadHarvestingPlan.vue';
import DxWorkOrders from '../components/workOrder/DxWorkOrders.vue';
import navbar from '../components/NavBar.vue';

export default {
  components: {
    // UploadHarvestingPlan,
    DxWorkOrders,
    navbar,
  },
  data() {
    return {
      includeActivities: ['08'],
      back: 'harvesting',
    };
  },
};
</script>

<style>

</style>
