<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-daily-planning"
  >
    <DxDataGrid
      id="gridContainer"
      :data-source="detailPlanning"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      key-expr="ID"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxPlannedVsReal"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="18" />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        alignment="left"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="activityCode"
        name="activityCode"
        :allow-header-filtering="false"
        :caption="$t('PlanningComponent_activitycode_caption')"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        :allow-header-filtering="false"
        :caption="$t('PlanningComponent_taskCode_caption')"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :allow-header-filtering="false"
        alignment="left"
        :caption="$t('DxDailyPlanning_task_caption')"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        alignment="left"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_location_caption')"
      />
      <DxColumn
        data-field="goalFormated"
        name="goal"
        :allow-header-filtering="false"
        alignment="right"
        :caption="$t('DxMonthlyPlanning_goal_caption')"
      />
      <DxColumn
        data-field="realGoal"
        name="realGoal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_realGoal_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="goalVsReal"
        name="goalVsReal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_goalVsReal_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="cost"
        name="cost"
        :allow-header-filtering="false"
        :caption="`${$t('DxDailyPlanning_coste')} €`"
        alignment="right"
      />
      <DxColumn
        data-field="realCost"
        name="realCost"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_realCost')"
        alignment="right"
      />
      <DxColumn
        data-field="CostVsReal"
        name="CostVsReal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_CostVsReal')"
        alignment="right"
      />
      <DxColumn
        data-field="versionName"
        name="versionName"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_CostVsReal_versionName')"
        alignment="right"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import * as overlay from 'devextreme/ui/overlay';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxPlannedVsReal',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
  },
  data() {
    return {
      loaded: false,
      locationsTreeAux: [],
      detailPlanning: [],
      detailDailyTasks: [],
      finalLevelLocation: [],
      locationsTree: [],
      widthSearchPanel: 500,
      currentLevel2Name: '',
      level2Data: [],
      areaLevel2: 0,
      badgeId: '',
      versionId: '',
      permanent: 0,
      temporary: 0,
      contratist: 0,
      totalCount: 0,
      prueba: [],
      versionName: '',
      allCosts: [],
      currentYear: moment().format('yyyy'),
    };
  },
  computed: {
    ...mapState('Prescription', ['prescription']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      let xhr = await Api.getMonthlyTasks(); // esta es la llamada para traer la sabana
      const { data } = JSON.parse(xhr.response);
      const filteredBudget = data.filter((element) => element.year === parseInt(this.currentYear, 10));
      this.badgeId = filteredBudget[0].id;
      if (filteredBudget.length > 0) {
        const lastVersion = filteredBudget[0].version[filteredBudget[0].version.length - 1];
        this.versionId = lastVersion.id;
        this.versionName = lastVersion.name;
      }
      const xhr2 = await Api.getMonthlyPlanning(this.badgeId, this.versionId);
      const planning = JSON.parse(xhr2.response);
      this.detailPlanning.length = 0;
      // esto es la estructura que hay que montar para mostrar la tabla MonthlyPlanning
      for (let j = 0; j < planning.task.length; j += 1) {
        this.locationsTreeAux = [...planning.task[j].locations];
        const newPlanning = {
          badgeId: this.badgeId,
          versionId: this.versionId,
          versionName: this.versionName,
          ID: planning.task[j].id,
          Date: this.formattingDates(planning.task[j].dates),
          days: planning.task[j].days,
          Task: planning.task[j].task_name,
          taskName: planning.task[j].task,
          taskCode: planning.task[j].task_code,
          Activity: planning.task[j].activity,
          activityCode: planning.task[j].activity_code,
          goal: planning.task[j].goal,
          goalUnit: planning.task[j].goal_unit,
          locationLevel2: this.getLocationLevel2(),
          locations: planning.task[j].locations,
          temporary: planning.task[j].temporary,
          permanent: planning.task[j].permanent,
          contratist: planning.task[j].contratist,
          cost: parseFloat(planning.task[j].cost).toFixed(2),
          goalFormated: `${planning.task[j].goal} ${planning.task[j].goal_unit}`,
          realGoal: 0.00,
          goalVsReal: 0,
          realCost: 0.00,
          CostVsReal: 0,
        };
        // Cargamos solo una tarea de cada tipo en el reporte
        if (this.detailPlanning.filter(
          (monthly) => monthly.activityCode === newPlanning.activityCode
          && monthly.taskCode === newPlanning.taskCode,
        ).length === 0) {
          this.detailPlanning.push(newPlanning);
        }
      }
      xhr = await Api.getAllCosts();
      this.allCosts = JSON.parse(xhr.response).data;
      const firstDay = `${this.currentYear}-01-01`;
      const lastDay = `${this.currentYear}-12-31`;
      xhr = await Api.getDailyTasks(true, [], [], firstDay, lastDay, true);
      const dailyTasks = JSON.parse(xhr.response);
      this.detailDailyTasks.length = 0;
      for (let i = 0; i < dailyTasks.detail.length; i += 1) {
        for (let j = 0; j < dailyTasks.detail[i].tasks.length; j += 1) {
          if (dailyTasks.detail[i].status === 'COMPLETED') {
            this.locationsTreeAux = [...dailyTasks.detail[i].tasks[j].location];
            const newTask = {
              ID: dailyTasks.detail[i].tasks[j].id,
              Date: moment(dailyTasks.detail[i].tasks[j].date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              Task: dailyTasks.detail[i].tasks[j].task,
              taskCode: dailyTasks.detail[i].tasks[j].task_code,
              Activity: dailyTasks.detail[i].tasks[j].activity,
              activityCode: dailyTasks.detail[i].tasks[j].activity_code,
              TaskStatus: dailyTasks.detail[i].tasks[j].status,
              Workers: dailyTasks.detail[i].tasks[j].workers,
              Attachments: dailyTasks.detail[i].tasks[j].attachments_vehicles,
              Tools: dailyTasks.detail[i].tasks[j].planner.tools,
              itemsTypes: dailyTasks.detail[i].tasks[j].planner.items,
              locationLevel2: this.getLocationLevel2(),
              Planner: dailyTasks.detail[i].tasks[j].planner,
              machinery: dailyTasks.detail[i].tasks[j].machinery_performance,
              products: dailyTasks.detail[i].tasks[j].products,
              productPerfor: dailyTasks.detail[i].tasks[j].product_performance,
              variety: dailyTasks.detail[i].tasks[j].variety,
            };

            const filteredPlannedTask = this.detailPlanning.filter(
              (element) => element.taskCode === newTask.taskCode
              && element.activityCode === newTask.activityCode
              && element.locations.filter((loc) => newTask.Planner.locations.includes(loc)),
            );

            if (filteredPlannedTask.length > 0) {
              // La tarea actual coincide con una de las planificadas, calculamos:
              // Coste Real
              let finalCost = parseFloat(filteredPlannedTask[0].realCost);
              // Objetivo real.
              newTask.Workers.forEach((worker) => {
                filteredPlannedTask[0].realGoal += parseFloat(worker.performance);
                // Coste trabajadores
                finalCost += parseFloat(parseFloat(this.getCostByCode(worker.type)) * parseFloat(worker.performance));
              });
              // Objetivo planificadovsReal
              filteredPlannedTask[0].goalVsReal = ((filteredPlannedTask[0].realGoal / filteredPlannedTask[0].goal) * 100).toFixed(2);
              // Coste maquinaria
              newTask.machinery.forEach((machinery) => {
                finalCost += parseFloat(parseFloat(this.getCostByCode(machinery.fuel_type)) * parseFloat(machinery.fuel_consumption));
              });
              // Coste productos
              newTask.productPerfor.forEach((productPerf) => {
                const filteredProducts = newTask.products.filter((pro) => pro.productName === productPerf.item_id);
                if (filteredProducts.length > 0) {
                  const family = this.$helpers.getProperty(filteredProducts[0].product.properties, 'family');
                  const subfamily = this.$helpers.getProperty(filteredProducts[0].product.properties, 'subfamily');
                  const dosis = filteredProducts[0].dosisUt;
                  finalCost += parseFloat(this.getProductCost(family, subfamily, dosis, productPerf.qty_used));
                }
              });

              // Coste Tools
              newTask.Tools.forEach((tool) => {
                finalCost += parseFloat(parseFloat(this.getCostByCode(tool.fuel_type)) * parseFloat(tool.fuel_consumption));
              });
              filteredPlannedTask[0].realCost = (parseFloat(filteredPlannedTask[0].realCost) + parseFloat(finalCost)).toFixed(2);
              // Coste Completado %
              if (parseFloat(filteredPlannedTask[0].realCost) > 0 && parseFloat(filteredPlannedTask[0].cost) > 0) {
                filteredPlannedTask[0].CostVsReal = ((parseFloat(filteredPlannedTask[0].realCost) / parseFloat(filteredPlannedTask[0].cost)) * 100).toFixed(2);
              }
            }
            this.detailDailyTasks.push(newTask);
          }
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getProductCost(family, subfamily, dosis, qtyUsed) {
      let subFamilyPrice = 0;
      if (family === 'FERTILIZERS') {
        switch (dosis) {
          case 'Cc/Hl':
            subFamilyPrice = this.getCostByCode('FERTILIZER_L');
            break;
          case 'Gr/Hl':
            subFamilyPrice = this.getCostByCode('FERTILIZER_KG');
            break;
          case 'Kg/Ha':
            subFamilyPrice = this.getCostByCode('FERTILIZER_KG');
            break;
          case 'L/Ha':
            subFamilyPrice = this.getCostByCode('FERTILIZER_L');
            break;
          default:
            break;
        }
      } else if (family === 'SUBSTRATE') {
        // TODO: Cuando realmente ferrero nos pase los costes, habrá que cambiarlo.
        subFamilyPrice = this.getCostByCode('PESTICIDE');
      } else {
        subFamilyPrice = this.getCostByCode(subfamily);
      }
      return (parseFloat(subFamilyPrice) * parseFloat(qtyUsed));
    },
    getCostByCode(type) {
      for (let i = 0; i < this.allCosts.length; i += 1) {
        if (this.allCosts[i].code === type) return this.allCosts[i].price;
      }
      return 0;
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsTreeAux.length === 0) {
          // Si no quedan ids en el array de parcelas de esta tarea
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      if (items.level === 2) {
        // Solo comprobamos los elementos con nivel === 2, sus hijos los comprobamos dentro
        this.currentLevel2Name = items.name;
      }
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsTreeAux.indexOf(items.bd_id);
        if (check !== -1) {
          const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
          if (alreadyInserted === -1) {
            // Está en el array de localizaciones y aun no lo hemos guardado
            this.level2Data.push(this.currentLevel2Name);
          }
          this.areaLevel2 += items.area;
          this.locationsTreeAux.splice(check, 1); // Elimino el id del array
        }
      } else if (this.locationsTreeAux.length !== 0) {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (locations.find((element) => element === item.bd_id)) {
          this.finalLevelLocation.push(item.name);
        }
      }
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date).format('YYYY-MM-DD'));
      }
      return formatedDates;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('PlannedVsReal');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PlannedVsReal.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('TaskBadget', ['setBadgedId', 'setVersionId', 'setTaskId', 'setActualTaskBadget']),
    ...mapActions('Prescription', ['setPrescription']),
  },
};
</script>
<style scoped>
.total-group-count{
    font-size: 16px;
    font-weight: 700;
    border: 1px solid rgba(133,103,103,0.2);
    margin-right: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    background: #FBF7EF;
    color: #470000;
}
.total-group-count span {
  font-weight: 400;
  font-size: 13px;
}
</style>
