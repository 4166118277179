<template>
  <f7-page
    name="register"
    login-screen
  >
    <div class="text-align-center">
      <img
        class="img_logo"
        :src="APP_LOGO"
        :alt="APPLICATION_NAME"
      >
    </div>

    <f7-block class="content-devx information">
      <p>
        Para contratar el servicio premium/seguimiento nutricional es necesario rellenar el formulario de ficha de cliente que se adjunta junto con el recibo
        del pago del servicio de 173 soles y enviarlo por correo a atencionalclienteperu@agqlabs.com para obtener su usuario y contraseña de agrocampo.
      </p>

      <p>
        Para más información puede consultar la web de AGQ Labs en Perú
        <f7-link
          href="http://www.agq.com.es/laboratorios-peru"
          external
          target="_blank"
        >
          :: AGQ :: Laboratorios en Perú
        </f7-link>
      </p>

      <p>Gracias por confiar en AGQ Labs.</p>
    </f7-block>

    <f7-list>
      <f7-button
        name="downloadForm"
        col
        button
        button-large
        button-raised
        fill
        class="botonPrimary"
        style="color:#FFFFFF"
        target="_blank"
        external
        href="../../static\ficha_nuevo_cliente_peru.pdf"
      >
        {{ $t("download_form") }}
      </f7-button>

      <f7-button
        name="goBack"
        col
        button
        button-large
        button-raised
        fill
        class="botonPrimary"
        style="color:#FFFFFF"
        @click="goBack"
      >
        {{ $t("Register_backText") }}
      </f7-button>
    </f7-list>
  </f7-page>
</template>

<script>
import { APP_LOGO, APPLICATION_NAME } from '../../js/constants';

export default {
  name: 'RegisterPremium',

  data() {
    return {
      APP_LOGO,
      APPLICATION_NAME,
      btnForgotPassword: {
        text: this.$t('Login_forgotPass_text'),
        urlDestiny: '#',
      },
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
  },
  methods: {
    goBack() {
      this.$f7.views.main.router.navigate('/registerTypeSelection/', { reloadCurrent: true });
    },
  },
};
</script>
<style scoped>
.information {
  width: 700px;
  max-width: 100%;
}
</style>
