<template>
  <f7-page>
    <navbar :text="$t('Variety_title')" />
    <DxVarietyDatabase />
  </f7-page>
</template>

<script>

import DxVarietyDatabase from '../../components/variety/DxVarietyDatabase.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxVarietyDatabase,
    navbar,
  },
};
</script>

<style>

</style>
