<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-products-collection"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      :id="`${keyName}Grid`"
      :data-source="productWarehouseFormated"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :on-editor-preparing="onEditorPreparing"
      :on-editing-start="onEditingStart"
      @exporting="onExporting"
      @row-updated="updatedProduct"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <!-- <DxEditing
        :allow-updating="true"
        mode="row"
      /> -->
      <DxColumn
        type="buttons"
        data-field=""
        :allow-header-filtering="false"
        cell-template="buttonsTemplate"
      />
      <template #buttonsTemplate="{ data: productWarehouseFormated }">
        <div
          v-if="productWarehouseFormated.data.statusCollection === $t('DxProductWarehouse_column_statusNotConfirmed')"
          class="templateButtons"
        >
          <button
            class="boton-enviar"
            @click="sendProduct(productWarehouseFormated.data)"
          >
            {{ $t('DxBinsTable_ButtonText_Send') }}
          </button>
        </div>
        <div
          v-else
          class="templateButtons"
        >
          <button
            class="boton-reenviar"
            @click="reSendProduct(productWarehouseFormated.data)"
          >
            {{ $t('DxProductWarehouse_ButtonText_ReSend') }}
          </button>
          <button
            class="boton-ajustar"
            @click="adjustProduct(productWarehouseFormated.data)"
          >
            {{ $t('DxProductWarehouse_ButtonText_Adjust') }}
          </button>
        </div>
      </template>
      <DxColumn
        data-field="date"
        name="date"
        :caption="$t('DxProductWarehouse_column_date')"
        :allow-header-filtering="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="shortId"
        name="shortId"
        :caption="$t('DxProductWarehouse_shortId')"
        :allow-header-filtering="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="activityCode"
        name="activityCode"
        :caption="$t('DxProductWarehouse_column_activityCode')"
        :allow-header-filtering="true"
        alignment="right"
        :allow-editing="false"
      />
      <DxColumn
        data-field="activity"
        name="activity"
        :caption="$t('DxProductWarehouse_column_activity')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        :caption="$t('DxProductWarehouse_column_taskCode')"
        :allow-header-filtering="true"
        :allow-editing="false"
        alignment="right"
      />
      <DxColumn
        data-field="task"
        name="task"
        :caption="$t('DxProductWarehouse_column_task')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="productName"
        name="productName"
        :caption="$t('DxProductWarehouse_column_productName')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="supervisor"
        name="brasupervisornd"
        :caption="$t('DxProductWarehouse_column_supervisor')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />

      <DxColumn
        data-field="planedQuantity"
        name="planedQuantity"
        :caption="$t('DxProductWarehouse_column_planedQuantity')"
        :allow-header-filtering="false"
        data-type="number"
        alignment="right"
      />
      <DxColumn
        data-field="quantity"
        name="productWarehouse"
        :caption="$t('DxProductWarehouse_column_productWarehouse')"
        :allow-header-filtering="false"
        data-type="number"
        alignment="right"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="warehouse.cost_center"
        name="cost_center"
        :caption="$t('DxProductWarehouse_column_cost_center')"
      >
        <DxLookup
          :data-source="costCentersAvailables"
          display-expr="name"
          value-expr="code"
        />
        <DxRequiredRule :message="$t('DxProductWarehouse_column_cost_center_required')" />
      </DxColumn>
      <DxColumn
        data-field="warehouse.warehouse"
        name="warehouse"
        :caption="$t('DxProductWarehouse_column_warehouse')"
      >
        <DxLookup
          :data-source="warehousesAvailables"
          display-expr="name"
          value-expr="code"
        />
        <DxRequiredRule :message="$t('DxProductWarehouse_column_warehouse_required')" />
      </DxColumn>
      <DxColumn
        v-if="keyName === 'productCollection'"
        data-field="statusCollection"
        name="statusCollection"
        :caption="$t('DxProductWarehouse_column_statusCollection')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        v-else
        data-field="statusReturned"
        name="statusReturned"
        :caption="$t('DxProductWarehouse_column_statusCollection')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="taskStatus"
        name="taskStatus"
        :caption="$t('DxProductWarehouse_column_status')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
    </DxDataGrid>

    <DxPopup
      :visible.sync="popupOpened"
      :title="$t('DxProductWarehouse_Popup_Send_Title')"
      :show-close-button="false"
      width="auto"
      height="auto"
    >
      <template>
        <f7-block-footer
          class="content-action-botton"
        >
          <f7-row>
            <f7-col width="100">
              <DxForm
                id="form"
                caption="caption!"
              >
                <DxGroupItem>
                  <DxSimpleItem
                    :data-field="`${$t('DxProductWarehouse_shortId')}`"
                    name="workingArea"
                    :caption="$t('DxProductWarehouse_shortId')"
                    :editor-options="{
                      disabled:true,
                      value: shortIdSelected,
                      showSpinButtons: false,
                      showClearButton: false,
                    }"
                  />
                  <DxSimpleItem
                    :data-field="`${$t('DxProductWarehouse_column_planedQuantity')}`"
                    name="workingArea"
                    :caption="$t('DxProductWarehouse_column_planedQuantity')"
                    :editor-options="{
                      disabled:true,
                      value: plannedQuantity,
                      showSpinButtons: false,
                      mode: 'number',
                      placeholder: '0',
                      showClearButton: false,
                      min: '0',
                    }"
                  />
                  <DxSimpleItem
                    :data-field="`${$t('DxProductWarehouse_column_productWarehouse')}`"
                    name="PerformanceCount"
                    editor-type="dxNumberBox"
                    :editor-options="{
                      value: actualQuantity,
                      showSpinButtons: false,
                      mode: 'number',
                      placeholder: '0',
                      showClearButton: true,
                      min: '0',
                      step: '1',
                      onValueChanged: onCurrentQuantityChange,
                    }"
                  >
                    <DxRequiredRule :message="$t('DxProductWarehouse_column_productWarehouse_required')" />
                  </DxSimpleItem>
                  <DxSimpleItem
                    :data-field="$t('DxProductWarehouse_column_cost_center')"
                    :editor-options="{
                      disabled: disableCostCenter,
                      dataSource: costCentersNames,
                      searchEnabled: true,
                      showDataBeforeSearch: false,
                      value: actualCostCenter,
                      onValueChanged: onChangeCostCenter,
                    }"
                    editor-type="dxSelectBox"
                    name="taskName"
                    :caption="$t('DxProductWarehouse_column_cost_center')"
                  >
                    <DxRequiredRule :message="$t('DxProductWarehouse_column_cost_center_required')" />
                  </DxSimpleItem>

                  <DxSimpleItem
                    :data-field="$t('DxProductWarehouse_column_warehouse')"
                    :editor-options="{
                      disabled: disableWarehouse,
                      dataSource: warehousesNames,
                      searchEnabled: true,
                      showDataBeforeSearch: false,
                      value: actualWarehouse,
                      onValueChanged: onChangeWarehouse,
                    }"
                    editor-type="dxSelectBox"
                    name="taskName"
                    :caption="$t('DxProductWarehouse_column_warehouse')"
                  >
                    <DxRequiredRule :message="$t('DxProductWarehouse_column_warehouse_required')" />
                  </DxSimpleItem>
                </DxGroupItem>
              </DxForm>
            </f7-col>
            <f7-col width="100">
              <f7-row>
                <f7-button
                  class="dx-btn-cancel margin-right"
                  raised
                  type="default"
                  text-color="grey"
                  styling-mode="outlined"
                  name="ButtonCancel"
                  @click="closePopup"
                >
                  {{ $t('DxBinsTable_button_cancel') }}
                </f7-button>
                <f7-button
                  class="dx-btn-success"
                  raised
                  type="success"
                  styling-mode="contained"
                  name="ButtonSave"
                  @click="confirmSend()"
                >
                  {{ $t("DxBinsTable_button_send") }}
                </f7-button>
              </f7-row>
            </f7-col>
          </f7-row>
        </f7-block-footer>
      </template>
    </DxPopup>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
  DxLookup,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import { DxRangeRule } from 'devextreme-vue/validator';
import { DxPopup } from 'devextreme-vue/popup';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxProductWarehouse',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    CalendarInitEndDate,
    DxEditing,
    DxRangeRule,
    DxLookup,
    DxRequiredRule,
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
  },
  props: {
    keyName: { type: String, default: '' },
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      productWarehouseFormated: [],
      supervisorsAvailables: [],
      costCentersAvailables: [],
      warehousesAvailables: [],
      warehousesNames: [],
      costCentersNames: [],
      popupOpened: false,
      actualWarehouse: '',
      actualCostCenter: '',
      actualQuantity: 0,
      plannedQuantity: 0,
      idProductSelected: '',
      bd_idProductSelected: '',
      actualWarehouseCode: '',
      actualCostCenterCode: '',
      shortIdSelected: '',
      disableCostCenter: false,
      disableWarehouse: false,
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.supervisorsAvailables = await this.getSupervisors();
      this.costCentersAvailables = await this.getCostCenters();
      this.warehousesAvailables = await this.getWarehouses();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      // const xhr = await Api.getDailyTasks(true, [], [], checkedInitEndDate.init, checkedInitEndDate.end, true, true);
      const xhr = await Api.getWarehouseTasks(checkedInitEndDate.init, checkedInitEndDate.end);
      const dailyTasks = JSON.parse(xhr.response);
      for (let i = 0; i < dailyTasks.data.length; i += 1) {
        if (dailyTasks.data[i].status === 'COMPLETED' || dailyTasks.data[i].status === 'IN_PROGRESS') {
          for (let k = 0; k < dailyTasks.data[i].product_performance.length; k += 1) {
            const warehouses = dailyTasks.data[i].product_performance[k].warehouse;
            if (warehouses.length > 0 && (
              (this.keyName === 'productCollection' && warehouses.filter((element) => element.type === 'good_issue').length > 0)
              || (this.keyName !== 'productCollection' && warehouses.filter((element) => element.type === 'good_receipt').length > 0)
            )) {
              for (let l = 0; l < warehouses.length; l += 1) {
                // Si estamos en productCollection & el tipo de fila es good_issue, la pintamos
                if ((this.keyName === 'productCollection' && warehouses[l].type === 'good_issue')
                || (this.keyName !== 'productCollection' && warehouses[l].type === 'good_receipt')) {
                  this.productWarehouseFormated.push({
                    date: moment(dailyTasks.data[i].date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                    shortId: dailyTasks.data[i].short_id,
                    ID: dailyTasks.data[i].id,
                    activityCode: dailyTasks.data[i].activity_code,
                    activity: dailyTasks.data[i].activity,
                    taskCode: dailyTasks.data[i].task_code,
                    taskStatus: this.$t(`DxProductWarehouse_column_${dailyTasks.data[i].status}`),
                    task: dailyTasks.data[i].task,
                    productName: this.getProductNameById(dailyTasks.data[i].product_performance[k].item_id, dailyTasks.data[i].products),
                    productId: dailyTasks.data[i].product_performance[k].item_id,
                    supervisor: this.getNameSupervisorById(dailyTasks.data[i].responsible),
                    planedQuantity: dailyTasks.data[i].product_performance[k].qty_planned,
                    quantity: warehouses[l].amount,
                    idSelectedWarehouse: warehouses[l].id,
                    warehouse: {
                      warehouse: warehouses[l].warehouse,
                      cost_center: warehouses[l].cost_center,
                    },
                    statusCollection: warehouses[l].sent ? this.$t('DxProductWarehouse_column_statusConfirmed') : this.$t('DxProductWarehouse_column_statusNotConfirmed'),
                    statusReturned: warehouses[l].sent ? this.$t('DxProductWarehouse_column_statusConfirmed') : this.$t('DxProductWarehouse_column_statusNotConfirmed'),
                  });
                }
              }
            } else {
              // El elemento no ha sido enviado aun
              this.productWarehouseFormated.push({
                date: moment(dailyTasks.data[i].date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                shortId: dailyTasks.data[i].short_id,
                ID: dailyTasks.data[i].id,
                activityCode: dailyTasks.data[i].activity_code,
                activity: dailyTasks.data[i].activity,
                taskCode: dailyTasks.data[i].task_code,
                taskStatus: this.$t(`DxProductWarehouse_column_${dailyTasks.data[i].status}`),
                task: dailyTasks.data[i].task,
                productName: this.getProductNameById(dailyTasks.data[i].product_performance[k].item_id, dailyTasks.data[i].products),
                productId: dailyTasks.data[i].product_performance[k].item_id,
                supervisor: this.getNameSupervisorById(dailyTasks.data[i].responsible),
                planedQuantity: dailyTasks.data[i].product_performance[k].qty_planned,
                quantity: dailyTasks.data[i].product_performance[k].qty_collected,
                warehouse: {
                  warehouse: '',
                  cost_center: '',
                },
                statusCollection: this.$t('DxProductWarehouse_column_statusNotConfirmed'),
                statusReturned: this.$t('DxProductWarehouse_column_statusNotConfirmed'),
              });
            }
          }
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (message.includes('You do not have permission')) {
        this.$f7.dialog.alert(this.$t(message));
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onChangeCostCenter(e) {
      this.actualCostCenter = e.value;
      const selectedCenter = this.costCentersAvailables.filter((element) => element.name === e.value);
      if (selectedCenter.length > 0) {
        this.actualCostCenterCode = selectedCenter[0].code;
      }
    },
    onChangeWarehouse(e) {
      this.actualWarehouse = e.value;
      const selectedWarehouse = this.warehousesAvailables.filter((element) => element.name === e.value);
      if (selectedWarehouse.length > 0) {
        this.actualWarehouseCode = selectedWarehouse[0].code;
      }
    },
    onCurrentQuantityChange(e) {
      const newValue = (e.value) ?? 0;
      this.actualQuantity = newValue;
    },
    closePopup() {
      this.popupOpened = false;
    },
    sendProduct(e) {
      this.shortIdSelected = e.shortId;
      this.bd_idProductSelected = e.productId;
      this.idProductSelected = e.ID;
      this.plannedQuantity = e.planedQuantity;
      this.actualQuantity = e.quantity;
      this.actualWarehouse = '';
      this.actualWarehouseCode = '';
      this.actualCostCenter = '';
      this.actualCostCenterCode = '';
      this.disableCostCenter = false;
      this.disableWarehouse = false;
      this.popupOpened = true;
    },
    async reSendProduct(e) {
      this.$f7.preloader.show();
      try {
        await Api.reSendProduct(e.ID, e.idSelectedWarehouse);
        this.$f7.dialog.alert(this.$t('DxProductWarehouse_Alert_CorrectResend'));
      } catch (reason) {
        const message = this.$helpers.getFilteredErrorMessage(reason);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    adjustProduct(e) {
      this.shortIdSelected = e.shortId;
      this.bd_idProductSelected = e.productId;
      this.idProductSelected = e.ID;
      this.plannedQuantity = e.planedQuantity;
      this.actualQuantity = e.quantity;
      this.actualCostCenterCode = e.warehouse.cost_center;
      this.actualWarehouseCode = e.warehouse.warehouse;
      this.actualWarehouse = '';
      const selectedWarehouse = this.warehousesAvailables.filter((element) => element.code === e.warehouse.warehouse);
      if (selectedWarehouse.length > 0) {
        this.actualWarehouse = selectedWarehouse[0].name;
      }
      this.actualCostCenter = '';
      const selectedCostCenter = this.costCentersAvailables.filter((element) => element.code === e.warehouse.cost_center);
      if (selectedCostCenter.length > 0) {
        this.actualCostCenter = selectedCostCenter[0].name;
      }
      this.disableCostCenter = true;
      this.disableWarehouse = true;
      this.popupOpened = true;
    },
    async confirmSend() {
      if (this.actualQuantity >= 0 && this.actualCostCenterCode !== '' && this.actualWarehouseCode !== '') {
        this.$f7.preloader.show();
        try {
          const formatedRow = [];
          formatedRow.push({
            product_id: this.bd_idProductSelected,
            amount: this.actualQuantity,
            cost_center: this.actualCostCenterCode,
            warehouse: this.actualWarehouseCode,
          });
          await Api.updateProductWarehouse(this.idProductSelected, this.keyName === 'productCollection' ? { product_out: formatedRow } : { product_in: formatedRow });
          this.popupOpened = false;
          this.$f7.views.main.router.navigate(`/${this.keyName}/`, { reloadCurrent: true });
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      } else {
        this.$f7.dialog.alert(this.$t('DxProductWarehouse_Alert_FieldsRequired'));
      }
    },
    onEditingStart(e) {
      if (e && e.data && e.data.taskStatus !== this.$t('DxProductWarehouse_column_COMPLETED')) {
        this.$f7.dialog.alert(this.$t('DxProductWarehouse_Product_Not_Completed'));
      }
    },
    onEditorPreparing(e) {
      if (e && e.row && e.row.data.taskStatus !== this.$t('DxProductWarehouse_column_COMPLETED')) {
        e.component.cancelEditData();
        e.component.refresh();
      }
    },
    async updatedProduct(e) {
      const currentRow = e.data;
      const formatedRow = [];
      if (this.keyName === 'productCollection' && currentRow.statusCollection === this.$t('DxProductWarehouse_column_statusConfirmed')) {
        this.$f7.dialog.alert(this.$t('DxProductWarehouse_edit_validation'));
      } else if (this.keyName === 'returnedProduct' && currentRow.statusReturned === this.$t('DxProductWarehouse_column_statusConfirmed')) {
        this.$f7.dialog.alert(this.$t('DxProductWarehouse_edit_validation'));
      } else {
        this.$f7.preloader.show();
        try {
          formatedRow.push({
            ID: currentRow.id,
            product_id: currentRow.productId,
            amount: currentRow.quantity,
            cost_center: currentRow.warehouse.cost_center,
            warehouse: currentRow.warehouse.warehouse,
          });
          await Api.updateProductWarehouse(currentRow.ID, this.keyName === 'productCollection' ? { product_out: formatedRow } : { product_in: formatedRow });
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
          this.$f7.views.main.router.navigate(`/${this.keyName}/`, { reloadCurrent: true });
        }
      }
    },
    // UTILITIES
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    async getCostCenters() {
      const xhr = await Api.getCostCenters();
      const costCenters = JSON.parse(xhr.response).data;
      costCenters.forEach((element) => {
        this.costCentersNames.push(element.name);
      });
      return costCenters;
    },
    async getWarehouses() {
      const xhr = await Api.getWarehouses();
      const warehouses = JSON.parse(xhr.response).data;
      warehouses.forEach((element) => {
        this.warehousesNames.push(element.name);
      });

      return warehouses;
    },
    getProductNameById(productId, products) {
      for (const product of products) {
        if (product.product.id === productId) {
          return product.product.description;
        }
      }
      return '';
    },
    getNameSupervisorById(supervisorId) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === supervisorId) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ProductWarehouse');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProductWarehouse.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
<style lang="scss">
.main-dx-products-collection, .templateButtons{
  .boton-enviar .boton-reenviar .boton-ajustar{
    font-size: 13px;
    line-height: 1.2;
    padding: 4px 8px 3px!important;
    width: auto;
    min-width: 26px;
    border: 0;
    border-radius: 2px;
    outline:none;
  }
  .boton-reenviar {
    background-color: #eee6d2;
    border: 1px solid rgba(89,90,92,0.35);
    color: #595a5c;
    margin: 0 2px!important;
    &:hover{
      background:#e7dcbf;
    }
  }
  .boton-enviar {
    background-color: #daeed2;
    border: 1px solid rgba(54,121,14,0.35);
    color: var(--ha-dark-green);
    margin: 0 2px!important;
    &:hover{
      background: #acd99a;
    }
  }
  .boton-ajustar {
    background-color: #e6e6e6;
    border: 1px solid rgba(135,119,135,0.35);
    color: #877787;
    margin: 0 2px!important;
    &:hover{
      background:#dddddd;
    }
  }
}

</style>
