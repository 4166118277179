<template>
  <div v-if="loaded">
    <f7-row>
      <f7-col>
        <DxForm>
          <DxGroupItem
            css-class="content-activity"
          >
            <DxSimpleItem
              css-class="content-activity__area"
              :data-field="$t('totalArea_field')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: totalAreaSelected,
                mode: 'number',
                disabled: true,
              }"
            />
          </DxGroupItem>
        </DxForm>
        <DxForm
          id="form"
          caption="Activity Task"
        >
          <DxGroupItem
            :caption="$t('PlanningComponent_caption_text')"
          >
            <DxSimpleItem
              :data-field="$t('PlanningComponent_activityName_caption')"
              :editor-options="{
                items: activityAvailables,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                onValueChanged: activitySelectedEvent,
                value: actualActivityName,
              }"
              editor-type="dxSelectBox"
              name="activityName"
            >
              <DxRequiredRule :message="$t('DxPlanningForm_activity_name')" />
            </DxSimpleItem>

            <DxSimpleItem
              :data-field="$t('PlanningComponent_activitycode_caption')"
              name="activityCode"
              :editor-options="{ disabled: true, value: actualActivityCode }"
            />
            <DxSimpleItem
              :data-field="$t('PlanningComponent_taskName_caption')"
              :editor-options="{
                dataSource: tasksAvailableName,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                onValueChanged: taskSelectedEvent,
                value: actualTaskName
              }"
              editor-type="dxSelectBox"
              name="taskName"
            >
              <DxRequiredRule :message="$t('DxPlanningForm_Task_name')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('PlanningComponent_taskCode_caption')"
              name="taskCode"
              :editor-options="{ disabled: true, value: actualTaskCode }"
            />
            <DxSimpleItem
              :data-field="$t('PlanningComponent_variety_caption')"
              :editor-options="{
                items: varietyAvailables,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                onValueChanged: varietySelectedEvent,
                displayExpr: 'name',
                valueExpr: 'name',
                value: actualVariety,
              }"
              editor-type="dxSelectBox"
              name="variety"
              :visible="actualActivityCode === '08'"
            >
              <DxRequiredRule :message="$t('DxPlanningForm_variety_required')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="`${$t('PlanningComponent_workingarea_caption')} (HA)`"
              name="workingArea"
              :caption="$t('PlanningComponent_workingarea_caption')"
              :editor-options="{
                value: workingArea,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0',
                max: totalAreaSelected,
                step: '1',
                onValueChanged: workingAreaChanged,
              }"
            />
            <DxGroupItem
              :col-count="3"
            >
              <DxSimpleItem
                :data-field="`${$t('PlanningComponent_performanceCount_caption')} (${actualGoalUnit})`"
                :col-span="2"
                name="PerformanceCount"
                editor-type="dxNumberBox"
                :editor-options="{
                  value: parseFloat(actualGoal),
                  showSpinButtons: false,
                  mode: 'number',
                  placeholder: '0',
                  showClearButton: true,
                  min: '0',
                  step: '1',
                  onValueChanged: goalSelectEvent,
                }"
                :visible="visiblePerformance"
              />
              <DxSimpleItem
                data-field=""
                name="PerformanceCount"
                :editor-options="{
                  value: `${(parseFloat(actualGoal)/8)} ${this.$t('DxMonthlyForm_jornada')}`,
                  showSpinButtons: false,
                  onValueChanged: goalSelectEvent,
                  readOnly: true
                }"
                :visible="visiblePerformance"
              />
            </DxGroupItem>
            <DxSimpleItem
              name="comments"
              :data-field="$t('PlanningComponent_comments_caption')"
              editor-type="dxTextArea"
              :editor-options="{
                value: actualComments,
                onValueChanged: onChangeComments,
                maxLength: 256,
              }"
            />
          </DxGroupItem>
          <DxGroupItem
            :caption="$t('PlanningComponent_numberWorkers_caption')"
          >
            <DxSimpleItem
              :data-field="$t('TEMPORARY')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: getWorkerTypeValue('TEMPORARY'),
                onValueChanged: temporarySelectEvent,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0',
                disabled: disabledTemporary,
              }"
            />
            <DxSimpleItem
              :data-field="$t('PERMANENT')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: getWorkerTypeValue('PERMANENT'),
                onValueChanged: permanentSelectEvent,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0',
                disabled: disabledPermanent,
              }"
            />
            <DxSimpleItem
              :data-field="$t('CONTRATIST')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: getWorkerTypeValue('CONTRATIST'),
                onValueChanged: contratistSelectEvent,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0',
                disabled: disabledContratist,
              }"
            />
            <DxSimpleItem
              :data-field="$t('DxMonthlyForm_performanceWorker_caption')"
              name="PerformanceWorker"
              editor-type="dxNumberBox"
              :editor-options="{
                value: actualPerformanceWorker,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0',
                step: '1',
                onValueChanged: performanceWorkerSelectEvent,
              }"
            />
            <DxSimpleItem
              :data-field="$t('PlanningComponent_performanceUnit_caption')"
              :editor-options="{
                dataSource: dataForm.performanceTypes,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                onValueChanged: goalUnitSelectedEvent,
                value: actualGoalUnit,
                showDropDownButton:false,
                placeholder:'',
                disabled: true,
              }"
              editor-type="dxSelectBox"
              name="PerformanceUnit"
            />
            <DxEmptyItem />
            <DxSimpleItem
              :data-field="$t('PlanningComponent_date_caption')"
              name="date"
            >
              <template #default>
                <f7-row>
                  <f7-col width="10">
                    <f7-button
                      class="dx-icon-clear boton-clear-calendar"
                      @click="clearCalendar"
                    />
                  </f7-col>
                  <f7-col width="90">
                    <v-calendar
                      is-expanded
                      :from-page="{ month: selectedMonth, year: selectedYear }"
                      :columns="2"
                      :attributes="attributes"
                      :disabled-dates="{ weekdays: [1] }"
                      @dayclick="onDayClick"
                    />
                  </f7-col>
                </f7-row>
              </template>
              <DxRequiredRule :message="$t('CreatePlanning_ValidationDate')" />
            </DxSimpleItem>
          </DxGroupItem>
          <f7-block class="content-activity__area">
            <span>{{ $t("DxMonthlyForm_workersDaysRequired") }}:</span> {{ numberWorkingDays }}
          </f7-block>
        </DxForm>
      </f7-col>
    </f7-row>
    <f7-row class="block">
      <f7-col
        v-if="showTables"
        class="content-machinery"
      >
        <f7-block-title>
          {{ $t('DxMachinery_Title') }}:
        </f7-block-title>
        <DxMachineryMonthlyTable
          :machinary="machinaryList"
        />
        <f7-block class="content-activity__area">
          <span>{{ $t("DxMonthlyForm_machineryDaysRequired") }}:</span> {{ numberMachineryDays }}
        </f7-block>
        <f7-block-title>
          {{ $t('DxAttachment_Title') }}:
        </f7-block-title>
        <DxAttachmentMonthlyTable
          :attachment="attachmentList"
        />
        <f7-block-title>
          {{ $t('DxTools_Title') }}:
        </f7-block-title>
        <DxToolsMonthlyTable
          :select-tool="toolList"
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
        />
        <f7-block class="content-activity__area">
          <span>{{ $t("DxMonthlyForm_toolsConsumptionTotal") }}: </span> {{ estimatedToolsCosts.totalConsumption }}
        </f7-block>
        <div v-if="showProducts && showTables">
          <f7-block-title>
            {{ $t('DxProducts_Title') }}:
          </f7-block-title>
          <DxProductsMonthlyTable :edit-form="editForm" />
        </div>
        <f7-block class="content-activity__area">
          <span>{{ $t("DxMonthlyForm_estimatedCostActivity") }}: </span> {{ estimatedCostsActivity }} €
        </f7-block>
        <f7-block class="content-activity__area">
          <span>{{ $t("DxMonthlyForm_taskDuration") }}: {{ initDate }} - {{ endDate }} ({{ numberWorkingDays }})</span>
        </f7-block>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
  DxEmptyItem,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';
import DxMachineryMonthlyTable from './DxMachineryMonthlyTable.vue';
import DxAttachmentMonthlyTable from './DxAttachmentMonthlyTable.vue';
import DxToolsMonthlyTable from './DxToolsMonthlyTable.vue';
import DxProductsMonthlyTable from './DxProductsMonthlyTable.vue';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxMonthlForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxMachineryMonthlyTable,
    DxAttachmentMonthlyTable,
    DxToolsMonthlyTable,
    DxProductsMonthlyTable,
    DxEmptyItem,
  },
  props: {
    dataForm: { type: Object, default: () => {} },
    editForm: { type: Boolean, default: false },
    showTables: { type: Boolean, default: true },
  },
  data() {
    return {
      // Utilities
      loaded: false,
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      // Activity Task
      activityAvailables: [],
      taskAvailables: [],
      actualActivityName: '',
      actualActivityCode: '',
      actualTaskName: '',
      actualTaskCode: '',
      actualGoal: 0,
      actualGoalUnit: '',
      // actualWorkers
      actualWorkers: {
        TEMPORARY: 0,
        PERMANENT: 0,
        CONTRATIST: 0,
      },
      actualPerformanceWorker: 0,
      // Tables
      machinaryList: [],
      attachmentList: [],
      toolList: [],
      actualDate: [],
      tasksAvailableName: [],
      costs: [],
      products: [],
      numberMachineryDays: 0,
      estimatedMachineryCosts: {
        total: 0,
        gasoline: 0,
        diesel: 0,
        totalConsumption: 0,
      },
      estimatedToolsCosts: {
        total: 0,
        gasoline: 0,
        diesel: 0,
        totalConsumption: 0,
      },
      estimatedWorkersCosts: {
        total: 0,
        TEMPORARY: 0,
        PERMANENT: 0,
        CONTRATIST: 0,
      },
      estimatedProductsCosts: {
        total: 0,
      },
      estimatedCostsActivity: 0,
      numberWorkingDays: 0,
      visiblePerformance: false,
      disabledTemporary: false,
      disabledPermanent: false,
      disabledContratist: false,
      workingArea: 0,
      varietyAvailables: [],
      actualVariety: '',
      actualComments: '',
      selectedMonth: moment().month() + 1,
      selectedYear: moment().year(),
    };
  },
  computed: {
    totalWorkers() {
      let totalWorkers = 0;
      totalWorkers += this.actualWorkers.TEMPORARY;
      totalWorkers += this.actualWorkers.PERMANENT;
      totalWorkers += this.actualWorkers.CONTRATIST;
      return totalWorkers;
    },
    dates() {
      return this.actualDate.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
    initDate() {
      let init = '';
      if (this.actualDate.length === 0) {
        return moment().format('YYYY-MM-DD');
      }
      init = moment(this.actualDate[0].date).format('YYYY-MM-DD');
      for (const date of this.actualDate) {
        if (moment(date.date).isBefore(init)) {
          init = moment(date.date).format('YYYY-MM-DD');
        }
      }
      return moment(init).format('YYYY-MM-DD');
    },
    endDate() {
      let init = '';
      if (this.actualDate.length === 0) {
        return moment().format('YYYY-MM-DD');
      }
      init = moment(this.actualDate[0].date).format('YYYY-MM-DD');
      for (const date of this.actualDate) {
        if (moment(date.date).isAfter(init)) {
          init = moment(date.date).format('YYYY-MM-DD');
        }
      }
      return moment(init).format('YYYY-MM-DD');
    },
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('TaskBadget', ['actualTaskBadget']),
    ...mapState('PlanningManager', ['showProducts']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    overlay.baseZIndex(9999);
    try {
      let xhr = await Api.getDataSubfamilyByType('machinery');
      const machineryAttachment = JSON.parse(xhr.response);
      this.setDataMachinaryAttachmentTool(machineryAttachment);
      xhr = await Api.getDataSubfamilyByType('tool');
      const dataTools = JSON.parse(xhr.response);
      this.setDataTool(dataTools);
      xhr = await Api.getAllCosts();
      this.costs = JSON.parse(xhr.response).data;
      xhr = await Api.getAllVariety();
      this.varietyAvailables = JSON.parse(xhr.response);
      if (!this.editForm) {
        this.setValuesNewTaskBadget();
      } else {
        this.setValuesActualTaskBadget();
        this.setShowProducts(this.actualTaskBadget.products.length > 0);
        for (const workerType in this.actualTaskBadget.workers) {
          if (this.actualTaskBadget.workers.hasOwnProperty(workerType)) {
            this.setDisabledTypeWorkers(workerType, this.actualTaskBadget.workers[workerType]);
          }
        }
      }
      this.setActivityAvailables();
      this.setTaskAvailables();
      EventBus.$on('updateCosts', this.updateCosts);
      EventBus.$on('changeTotalAreaSelected', this.updateWorkingArea);
      this.updateCosts();

      if (this.actualDate.length > 0) {
        this.selectedMonth = moment(this.actualDate[0].date).month() + 1;
        this.selectedYear = moment(this.actualDate[0].date).year();
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('changeTotalAreaSelected');
    EventBus.$off('updateCosts', this.updateCosts);
  },
  methods: {
    clearCalendar() {
      this.actualDate = [];
      this.actualDatesIso8601 = [];
      this.setDateProgrammed([]);
    },
    onChangeComments(e) {
      this.actualComments = e.value;
      this.setComments(e.value);
    },
    // SET VALUES NEW TASK BADGET
    setValuesNewTaskBadget() {
      this.setActivityName(this.actualActivityName);
      this.setActivityCode(this.actualActivityCode);
      this.setTaskName(this.actualTaskName);
      this.setTaskCode(this.actualTaskCode);
      this.setGoal(this.actualGoal);
      this.setGoalUnit(this.actualGoalUnit);
      this.setWorkers(this.actualWorkers);
      this.setPerformanceWorker(this.actualPerformanceWorker);
      this.setDateProgrammed([]);
      this.setMachinery([]);
      this.setAttachments([]);
      this.setTools([]);
      this.setProducts([]);
      this.setPrescription(null);
      this.setShowProducts(false);
      this.setTotalAreaSelected(0);
      this.workingArea = 0;
      this.setWorkingArea(this.workingArea);
      this.setVariety(this.actualVariety);
      this.setComments(this.actualComments);
    },
    // SET VALUES ACTUAL TASK BADGET
    setValuesActualTaskBadget() {
      this.actualActivityName = this.actualTaskBadget.activityName;
      this.actualActivityCode = this.actualTaskBadget.activityCode;
      this.actualTaskName = this.actualTaskBadget.taskName;
      this.actualTaskCode = this.actualTaskBadget.taskCode;
      this.actualGoal = this.actualTaskBadget.goal;
      this.actualGoalUnit = this.actualTaskBadget.goalUnit;
      this.actualWorkers = this.actualTaskBadget.workers;
      this.actualPerformanceWorker = this.actualTaskBadget.performanceWorker;
      this.actualDateProgrammed = this.actualTaskBadget.dateProgrammed;
      this.actualMachinery = this.actualTaskBadget.machinery;
      this.actualAttachments = this.actualTaskBadget.attachments;
      this.actualTools = this.actualTaskBadget.tools;
      this.actualProducts = this.actualTaskBadget.products;
      this.loadDates(this.actualTaskBadget.dateProgrammed, 'YYYY-MM-DD');
      this.visiblePerformance = this.setVisiblePerformance(this.actualTaskBadget.taskCode);
      this.workingArea = this.actualTaskBadget.area_effective;
      this.setWorkingArea(this.workingArea);
      this.actualVariety = this.actualTaskBadget.variety;
      this.actualComments = this.actualTaskBadget.comments;
    },
    // EVENTS HANDLER ITEMS FORM
    activitySelectedEvent(e) {
      this.actualActivityName = e.value;
      this.actualActivityCode = this.getTask(this.dataForm.activityName, e.value).code;
      this.setActivityName(this.actualActivityName);
      this.setActivityCode(this.actualActivityCode);
      this.actualTaskName = '';
      this.actualTaskCode = '';
      this.setTaskName('');
      this.setTaskCode('');
      this.setTaskAvailables();
      this.visiblePerformance = false;
      this.updateCosts();
      if (this.actualActivityCode !== '08') {
        this.actualVariety = '';
        this.setVariety('');
      }
    },
    taskSelectedEvent(e) {
      const selectTask = this.getTask(this.taskAvailables, e.value);
      this.actualTaskName = e.value;
      this.actualTaskCode = selectTask.code;
      this.setTaskName(this.actualTaskName);
      this.setTaskCode(this.actualTaskCode);
      this.showProductTable(selectTask);
      this.visiblePerformance = this.setVisiblePerformance(selectTask.code);
      this.actualGoal = 0;
      this.setGoal(this.actualGoal);
      this.updateCosts();
    },
    goalSelectEvent(e) {
      const newValue = (e.value) ?? 0;
      this.actualGoal = newValue;
      this.setGoal(this.actualGoal);
      this.updateCosts();
    },
    goalUnitSelectedEvent(e) {
      this.actualGoalUnit = e.value;
      this.setGoalUnit(e.value);
      this.updateCosts();
    },
    performanceWorkerSelectEvent(e) {
      const newValue = (e.value) ?? 0;
      this.setPerformanceWorker(newValue);
      this.actualPerformanceWorker = newValue;
      this.updateCosts();
    },
    // SET ITEMS SELECT - ACTIVITIES & TASKS AVAILABLES
    setActivityAvailables() {
      for (const activity of this.dataForm.activityName) {
        this.activityAvailables.push(activity.name);
      }
    },
    setTaskAvailables() {
      this.tasksAvailableName = [];
      this.setTaskName([]);
      this.taskAvailables = this.dataForm.taskName.filter(
        (task) => task.activity_code === this.actualActivityCode,
      );
      for (const task of this.taskAvailables) {
        this.tasksAvailableName.push(task.name);
      }
    },
    temporarySelectEvent(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.updateValueWorkers('TEMPORARY', e.value);
    },
    permanentSelectEvent(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.updateValueWorkers('PERMANENT', e.value);
    },
    contratistSelectEvent(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.updateValueWorkers('CONTRATIST', e.value);
    },
    updateValueWorkers(workerType, value) {
      const newWorkers = this.actualTaskBadget.workers;
      switch (workerType) {
        case 'TEMPORARY':
          newWorkers.TEMPORARY = value;
          break;
        case 'PERMANENT':
          newWorkers.PERMANENT = value;
          break;
        case 'CONTRATIST':
          newWorkers.CONTRATIST = value;
          break;
        default:
          break;
      }
      this.setWorkers(newWorkers);
      this.actualWorkers = newWorkers;
      this.updateCosts();
      this.setDisabledTypeWorkers(workerType, value);
    },
    getWorkerTypeValue(workerType) {
      let typeSelected = 0;
      switch (workerType) {
        case 'TEMPORARY':
          typeSelected = this.actualWorkers.TEMPORARY;
          break;
        case 'PERMANENT':
          typeSelected = this.actualWorkers.PERMANENT;
          break;
        case 'CONTRATIST':
          typeSelected = this.actualWorkers.CONTRATIST;
          break;

        default:
          break;
      }
      return typeSelected;
    },
    setDataMachinaryAttachmentTool(machineryAttachment) {
      this.machinaryList.length = 0;
      this.attachmentList.length = 0;
      for (let i = 0; i < machineryAttachment.length; i += 1) {
        if (machineryAttachment[i].id === 'VEHICLE') {
          for (let j = 0; j < machineryAttachment[i].items.length; j += 1) {
            this.machinaryList.push({
              name: machineryAttachment[i].items[j].id,
              id: machineryAttachment[i].items[j].id,
            });
          }
        } else if (machineryAttachment[i].id === 'ATTACHMENT') {
          for (let j = 0; j < machineryAttachment[i].items.length; j += 1) {
            this.attachmentList.push({
              name: machineryAttachment[i].items[j].id,
              id: machineryAttachment[i].items[j].id,
            });
          }
        }
      }
    },
    setDataTool(tools) {
      this.toolList.length = 0;
      for (let i = 0; i < tools.length; i += 1) {
        if (tools[i].id === 'TOOL') {
          for (let j = 0; j < tools[i].items.length; j += 1) {
            this.toolList.push({
              name: tools[i].items[j].id,
              id: tools[i].items[j].items[0].id,
            });
          }
          break;
        }
      }
    },
    // UTILITIES
    getTask(items, name) {
      for (const item of items) {
        if (item.name === name) {
          return item;
        }
      }
      return '';
    },
    showProductTable(selectTask) {
      let valueToShowTable = false;
      if (typeof selectTask.properties !== 'undefined') {
        selectTask.properties.forEach((element) => {
          if (element.key === 'prescription') {
            if (element.value !== 'False') {
              valueToShowTable = true;
            }
          }
        });
      }
      if (!valueToShowTable) {
        this.setPrescription(null);
        this.setProducts([]);
        this.updateCosts();
      }
      this.setShowProducts(valueToShowTable);
    },
    loadDates(dates, formatOrigin) {
      const actualDatesIso8601 = [];
      this.actualDate = [];
      for (const date of dates) {
        const formatedDate = moment(date, formatOrigin).format('YYYY-MM-DD');
        actualDatesIso8601.push(formatedDate);
        this.actualDate.push({ id: formatedDate, date: moment(formatedDate).format() });
      }
      this.setDateProgrammed(actualDatesIso8601);
    },
    onDayClick(day) {
      if (day.weekdayPosition === 7) return; // Ignoramos los domingos a petición de Ferrero
      const idx = this.actualDate.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.actualDate.splice(idx, 1);
      } else {
        this.actualDate.push({
          id: day.id,
          date: day.date,
        });
      }
      this.updateActualDate();
    },
    updateActualDate() {
      if (this.numberWorkingDays === 0) {
        this.actualDate.length = 0;
        return;
      }
      let index = 1;
      while (this.actualDate.length < this.numberWorkingDays) {
        const newDate = this.$helpers.addWorkdays(index, moment(this.initDate)).format('YYYY-MM-DD');
        if (!this.existDateInActualDate(newDate)) {
          this.actualDate.push({
            id: newDate,
            date: this.$helpers.addWorkdays(index, moment(this.initDate)).format(),
          });
        }
        index += 1;
      }
      const actualDatesIso8601 = [];
      for (const actualDate of this.actualDate) {
        actualDatesIso8601.push(moment(actualDate.date).format('YYYY-MM-DD'));
      }
      this.setDateProgrammed(actualDatesIso8601);
    },
    existDateInActualDate(newDate) {
      for (const date of this.actualDate) {
        if (moment(newDate).format('YYYY-MM-DD') === date.id) {
          return true;
        }
      }
      return false;
    },
    updateNumberWorkingDays() {
      if (this.actualGoalUnit === 'HA') {
        this.actualGoal = this.workingArea;
        this.setGoal(this.actualGoal);
      }
      if ((this.totalWorkers === 0) || (this.actualGoal === 0)
       || (this.actualPerformanceWorker === 0)) {
        this.numberWorkingDays = 0;
      } else {
        this.numberWorkingDays = Math.ceil((this.actualGoal / this.actualPerformanceWorker) / this.totalWorkers);
      }
      this.setTotalWorkingDays(this.numberWorkingDays);
    },
    updateNumberMachineryDays() {
      if (this.actualGoalUnit === 'HA') {
        this.actualGoal = this.workingArea;
        this.setGoal(this.actualGoal);
      }
      this.numberMachineryDays = 0;
      this.setNumberMachineryDays(this.numberMachineryDays);
      if ((this.actualTaskBadget.machinery.length === 0) || (this.actualGoal === 0)) {
        return;
      }
      let totalPerformance = 0;
      for (const machinary of this.actualTaskBadget.machinery) {
        totalPerformance += parseFloat(machinary.performance);
      }
      if (totalPerformance === 0) {
        return;
      }
      this.numberMachineryDays = Math.ceil(parseFloat(this.actualGoal) / totalPerformance);
      this.setNumberMachineryDays(this.numberMachineryDays);
    },
    // GET COSTS
    updateEstimatedWorkersCosts() {
      this.estimatedWorkersCosts = {
        total: 0,
        TEMPORARY: 0,
        PERMANENT: 0,
        CONTRATIST: 0,
      };
      this.estimatedWorkersCosts.TEMPORARY = this.actualTaskBadget.workers.TEMPORARY * this.getPriceByName('TEMPORARY');
      this.estimatedWorkersCosts.PERMANENT = this.actualTaskBadget.workers.PERMANENT * this.getPriceByName('PERMANENT');
      this.estimatedWorkersCosts.CONTRATIST = this.actualTaskBadget.workers.CONTRATIST * this.getPriceByName('CONTRATIST');
      this.estimatedWorkersCosts.total = this.estimatedWorkersCosts.TEMPORARY + this.estimatedWorkersCosts.PERMANENT + this.estimatedWorkersCosts.CONTRATIST;
      this.estimatedWorkersCosts.total *= this.numberWorkingDays;
    },
    updateEstimatedItemsCosts(items, numberDays) {
      const estimatedCosts = {
        total: 0,
        gasoline: 0,
        diesel: 0,
        totalConsumption: 0,
      };
      for (const item of items) {
        let count = 1;
        if (typeof (item.count) !== 'undefined') {
          count = item.count;
        }
        if (item.consumption_type === 'GASOLINE') {
          estimatedCosts.gasoline += parseFloat(item.consumption) * count;
        } else if (item.consumption_type === 'DIESEL') {
          estimatedCosts.diesel += parseFloat(item.consumption) * count;
        }
      }
      estimatedCosts.totalConsumption = (estimatedCosts.gasoline + estimatedCosts.diesel) * numberDays;
      estimatedCosts.gasoline *= this.getPriceByName('GASOLINE') * numberDays;
      estimatedCosts.diesel *= this.getPriceByName('DIESEL') * numberDays;
      estimatedCosts.total = estimatedCosts.gasoline + estimatedCosts.diesel;
      return estimatedCosts;
    },
    updateEstimatedProductsCosts() {
      this.estimatedProductsCosts = {
        total: 0,
      };
      for (const product of this.actualTaskBadget.products) {
        if (this.getFamilyProduct(product.product.properties) === 'FERTILIZERS') {
          switch (product.dosisUt) {
            case 'Cc/Hl':
              this.estimatedProductsCosts.total += product.dosis_total * this.getPriceByName('FERTILIZER_L');
              break;
            case 'Gr/Hl':
              this.estimatedProductsCosts.total += product.dosis_total * this.getPriceByName('FERTILIZER_KG');
              break;
            case 'Kg/Ha':
              this.estimatedProductsCosts.total += product.dosis_total * this.getPriceByName('FERTILIZER_KG');
              break;
            case 'L/Ha':
              this.estimatedProductsCosts.total += product.dosis_total * this.getPriceByName('FERTILIZER_L');
              break;
            default:
              break;
          }
        } else if (this.getFamilyProduct(product.product.properties) === 'SUBSTRATE') {
          // TODO: Cuando realmente ferrero nos pase los costes, habrá que cambiarlo.
          const nameSubfamily = 'PESTICIDE';
          this.estimatedProductsCosts.total += product.dosis_total * this.getPriceByName(nameSubfamily);
        } else {
          this.estimatedProductsCosts.total += product.dosis_total * this.getPriceByName(this.getSubfamilyProduct(product.product.properties));
        }
      }
    },
    updateCosts() {
      this.updateNumberWorkingDays();
      this.updateEstimatedWorkersCosts();
      this.updateNumberMachineryDays();
      this.estimatedMachineryCosts = this.updateEstimatedItemsCosts(this.actualTaskBadget.machinery, this.numberMachineryDays);
      this.estimatedToolsCosts = this.updateEstimatedItemsCosts(this.actualTaskBadget.tools, this.numberWorkingDays);
      this.updateEstimatedProductsCosts();
      this.estimatedCostsActivity = new Intl.NumberFormat('de-DE').format((this.estimatedWorkersCosts.total + this.estimatedMachineryCosts.total + this.estimatedToolsCosts.total + this.estimatedProductsCosts.total).toFixed(2));
    },
    // UTILITIES GET COSTS
    getPriceByName(name) {
      let nameFinal = name;
      if (name === 'INSECTICIDE') {
        nameFinal = 'PESTICIDE';
      }
      for (const iterate of this.costs) {
        if (iterate.code === nameFinal) {
          return iterate.price;
        }
      }
      return 0;
    },
    getFamilyProduct(properties) {
      for (const property of properties) {
        if (property.key === 'family') {
          return property.value;
        }
      }
      return '';
    },
    getSubfamilyProduct(properties) {
      for (const property of properties) {
        if (property.key === 'subfamily') {
          return property.value;
        }
      }
      return '';
    },
    setVisiblePerformance(taskCode) {
      for (const task of this.dataForm.taskName) {
        if (task.code === taskCode) {
          if (task.performance_unit !== '') {
            this.setGoalUnit(task.performance_unit);
            this.actualGoalUnit = task.performance_unit;
            return true;
          }
        }
      }
      this.setGoalUnit('HA');
      this.actualGoalUnit = 'HA';
      return false;
    },
    setDisabledTypeWorkers(workerType, value) {
      switch (workerType) {
        case 'TEMPORARY':
          if (value !== 0) {
            this.disabledPermanent = true;
            this.disabledContratist = true;
          } else {
            this.disabledPermanent = false;
            this.disabledContratist = false;
          }
          break;
        case 'PERMANENT':
          if (value !== 0) {
            this.disabledTemporary = true;
            this.disabledContratist = true;
          } else {
            this.disabledTemporary = false;
            this.disabledContratist = false;
          }
          break;
        case 'CONTRATIST':
          if (value !== 0) {
            this.disabledPermanent = true;
            this.disabledTemporary = true;
          } else {
            this.disabledPermanent = false;
            this.disabledTemporary = false;
          }
          break;
        default:
          break;
      }
    },
    workingAreaChanged(e) {
      if (e.value === null || e.value < 0) {
        e.value = 0;
      }
      if (e.value > this.totalAreaSelected) {
        e.value = this.totalAreaSelected;
      }
      this.workingArea = e.value;
      this.setWorkingArea(e.value);
      this.updateCosts();
    },
    updateWorkingArea() {
      this.workingArea = this.totalAreaSelected;
      this.initialWorkingArea = this.workingArea;
      this.setWorkingArea(this.workingArea);
      this.updateCosts();
    },
    varietySelectedEvent(e) {
      this.actualVariety = e.value.name;
      this.setVariety(e.value.name);
    },
    ...mapActions('TaskBadget', ['setActivityName', 'setActivityCode', 'setTaskName', 'setTaskCode', 'setGoal', 'setGoalUnit', 'setWorkers', 'setWorkersByPos', 'setDateProgrammed', 'setMachinery', 'setPerformanceWorker', 'setAttachments', 'setTools', 'setProducts', 'setTotalWorkingDays', 'setPrescription', 'setNumberMachineryDays', 'setVariety', 'setComments']),
    ...mapActions('PlanningManager', ['setShowProducts', 'setWorkingArea']),
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
  },
};
</script>

<style lang="scss">
#form-container {
  margin: 10px 10px 30px;
}

.long-title h3 {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.content-activity{
  .dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection{
    padding-top: 0;
    margin-top: -5px;
  }
  &__area{
    background-color: #EAE3D8;
    border-radius: 3px;
    padding: 8px 10px;
    margin: 15px 0;
    color: #6F4343;
    opacity: 1;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    min-height: 50px;
    span, input{
      color: #6F4343;
      opacity: 1;
      text-transform: none;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      padding-right: 5px;
    }
    input{
      font-weight: normal;
    }
  }
}
.content-machinery{
  .content-devx{
    margin: 0 0 15px 0!important;
  }
  .dx-form-group-caption{
    padding: 0 0 10px 0!important;
    display: block;
  }
}
.boton-clear-calendar {
    width: auto;
    padding: 7px 7px;
    border: 1px solid var(--ha-gray);
    text-transform: uppercase;
    margin-right: 5px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    text-decoration: none;
    outline:none;
    background: var(--fr-inprogress-tint);
    border-color: var(--ha-yellow);
    color: var(--ha-orange)!important;
    height: 30px;
  }
</style>
