<template>
  <f7-page>
    <navbar :text="$t('Bins_Title')" />
    <DxBinsTable />
  </f7-page>
</template>

<script>
import DxBinsTable from '../../components/greenhouse/DxBinsTable.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ReceivedBins',
  components: {
    navbar,
    DxBinsTable,
  },
  computed: {
  },
  methods: {
  },
};
</script>
