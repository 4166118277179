<template>
  <f7-page>
    <navbar :text="$t('Monitoring_weather_condicions_navbar_title')" />
    <DxMonitoringWeatherCondicions :key-name="'monitoringWeatherCondicions'" />
  </f7-page>
</template>

<script>
import DxMonitoringWeatherCondicions from '../../components/scouting/DxMonitoringWeatherCondicions.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'MonitoringWeatherCondicions',
  components: {
    DxMonitoringWeatherCondicions,
    navbar,
  },
};
</script>
