<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-work-orders main-dx-work-orders"
  >
    <f7-row>
      <f7-col>
        <CalendarInitEndDate
          :key-name="keyName"
          :dates="datesDisplayed"
          :start-date="startingDate"
          :end-date="endingDate"
        />
      </f7-col>
    </f7-row>
    <DxDataGrid
      id="gridContainer"
      :data-source="marketData"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="product"
        name="product"
        :caption="$t('DxMarketTab_product')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="origin"
        name="origin"
        :caption="$t('DxMarketTab_origin')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="price"
        name="price"
        :caption="$t('DxMarketTab_price')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="amount"
        name="amount"
        :caption="$t('DxMarketTab_amount')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="bulletin"
        name="bulletin"
        :caption="$t('DxMarketTab_bulletin')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="date"
        name="date"
        :caption="$t('DxMarketTab_date')"
        :allow-header-filtering="true"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  props: {
    keyName: { type: String, default: 'Market' },
  },
  data() {
    return {
      marketData: [],
      loaded: false,
      pageSizes: [5, 10, 20],
      datesDisplayed: null,
      startingDate: new Date().setDate(new Date().getDate() - 31),
      endingDate: new Date(),
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      let xhr;
      if (this.actualFilter.Market !== undefined && this.actualFilter.Market) {
        xhr = await Api.getMarket({ init: this.actualFilter.Market.init, end: this.actualFilter.Market.end });
      } else {
        xhr = await Api.getMarket({ init: moment(this.startingDate).format('YYYY-MM-DD'), end: moment(this.endingDate).format('YYYY-MM-DD') });
      }
      const marketList = JSON.parse(xhr.response);
      this.datesDisplayed = { init: marketList.init, end: marketList.end };
      for (let i = 0; i < marketList.data.length; i += 1) {
        let origin = '';
        if (marketList.data[i].origin) {
          origin = marketList.data[i].origin.code;
        }
        const register = {
          product: marketList.data[i].product.code,
          origin,
          price: '',
          amount: '',
          bulletin: marketList.data[i].flow,
          date: '',
        };
        // 1 registro de cada producto por día, no todos tienen precio y no todos tienen cantidad
        if (marketList.data[i].parameters.PRICE) {
          for (let j = 0; j < marketList.data[i].parameters.PRICE.length; j += 1) {
            const newRegister = JSON.parse(JSON.stringify(register));
            newRegister.price = `${parseFloat(marketList.data[i].parameters.PRICE[j].value).toFixed(2)} ${this.$t(marketList.data[i].parameters.PRICE[j].unit)}`;
            newRegister.date = marketList.data[i].parameters.PRICE[j].date;
            if (marketList.data[i].parameters.AMOUNT && marketList.data[i].parameters.AMOUNT[j]) {
              newRegister.amount = `${marketList.data[i].parameters.AMOUNT[j].value} ${this.$t(marketList.data[i].parameters.AMOUNT[j].unit)}`;
            }
            this.marketData.push(newRegister);
          }
        } else if (marketList.data[i].parameters.AMOUNT) {
          for (let j = 0; j < marketList.data[i].parameters.AMOUNT.length; j += 1) {
            const newRegister = JSON.parse(JSON.stringify(register));
            newRegister.amount = `${marketList.data[i].parameters.AMOUNT[j].value} ${this.$t(marketList.data[i].parameters.AMOUNT[j].unit)}`;
            newRegister.date = marketList.data[i].parameters.AMOUNT[j].date;
            this.marketData.push(newRegister);
          }
        } else {
          this.marketData.push(register);
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
  },
};
</script>
<style scoped>
</style>
