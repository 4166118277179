<template>
  <div class="content-devx">
    <f7-block class="content__barsgraph">
      <DxChart
        id="chart"
        :data-source="dataSourceFiltered"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings
          :ignore-empty-points="true"
          argument-field="date"
        />
        <DxSeries
          pane="top"
          :color="measureColor"
          :value-field="`${weatherElement}`"
          type="bar"
          :bar-width="50"
          :name="`${$t('DxEvapoElementBarsGraph_DailyEvapo')}, ${weatherElementMeasure}`"
        />

        <DxPane name="top" />
        <DxArgumentAxis
          discrete-axis-division-mode="crossLabels"
          argument-type="datetime"
        >
          <DxLabel
            display-mode="stagger"
            format="d MMM"
          />
        </DxArgumentAxis>
        <DxValueAxis pane="top">
          <DxGrid :visible="true" />
          <DxTitle :text="`${$t(weatherElement)}, ${weatherElementMeasure}`" />
        </DxValueAxis>
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
        />
        <DxExport :enabled="true" />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxTitle :text="`${$t(weatherElement)}`" />
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
      </DxChart>
    </f7-block>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxPane,
  DxScrollBar,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxZoomAndPan,
  DxExport,
  DxGrid,
} from 'devextreme-vue/chart';

export default {
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxPane,
    DxGrid,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxLabel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxExport,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    weatherElement: {
      type: String,
      default: 'Precipitation',
    },
  },
  data() {
    return {
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      measureColor: '#68C158',
    };
  },
  computed: {
    ...mapState('weather', [
      'selectedFarmID',
      'startDate',
      'endDate',
      'selectedMeasuringFrequency',
    ]),
    ...mapState('Reporting', ['selectedFarmList']),
    lineWidth() {
      return this.selectedMeasuringFrequency === 'DAILY' ? 1 : 0;
    },
    weatherElementMeasure() {
      return (this.weatherElement === 'Precipitation' || this.weatherElement === 'Evaporation') ? 'mm' : '%';
    },
    ...mapState('CalendarInitEndDate', ['actualFilter']),
    dataSourceFiltered() {
      return typeof this.actualFilter.weatherFarmCockpit === 'undefined' || this.actualFilter.weatherFarmCockpit === '' ? this.dataSource : this.dataSource.filter(
        (dataRow) => moment(dataRow.date).format('YYYY-MM-DD') <= this.actualFilter.weatherFarmCockpit.end
        && moment(dataRow.date).format('YYYY-MM-DD') >= this.actualFilter.weatherFarmCockpit.init,
      );
    },
  },

  methods: {
    customizeTooltip(pointInfo) {
      const seriesName = pointInfo.seriesName.split(',')[0];
      const seriesMeasureUnit = pointInfo.seriesName.split(',')[1].trim();
      const date = moment(pointInfo.argument).format('ddd D MMM');

      return {
        text: `${seriesName} on ${date}: 
          ${pointInfo.value.toFixed(2)} ${seriesMeasureUnit} `,
      };
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
#chart-demo {
    height: 700px;
}
#chart {
    width: 100%;
    min-width: 600px;
    height: 500px;
    margin: 0 0 15px;
}
</style>
