<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-employees"
  >
    <DxDataGrid
      id="gridContainer"
      :data-source="listEmployees"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxEmployees"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        :width="70"
        :buttons="editButtons"
        data-field="Actions"
        type="buttons"
      />
      <DxColumn
        data-field="status"
        name="task"
        :caption="$t('DxListEmployee_status_caption')"
        :allow-header-filtering="true"
        :width="80"
      />
      <DxColumn
        data-field="surname"
        name="date"
        :caption="$t('DxListEmployee_surname_caption')"
        :allow-header-filtering="false"
        css-class="bold"
        :width="200"
      />
      <DxColumn
        data-field="name"
        name="date"
        :caption="$t('DxListEmployee_name_caption')"
        :allow-header-filtering="false"
        css-class="bold"
        :width="200"
      />
      <!-- <DxColumn
        v-if="checkAgriFarm"
        data-field="group"
        name="group"
        :caption="$t('DxListEmployee_group_caption')"
        :allow-header-filtering="false"
        css-class="bold"
        :width="200"
      />
      <DxColumn
        v-if="checkAgriFarm"
        data-field="shift"
        name="shift"
        :caption="$t('DxListEmployee_shift_caption')"
        :allow-header-filtering="false"
        css-class="bold"
        :width="200"
      /> -->
      <DxColumn
        data-field="code"
        name="activity"
        :caption="$t('DxListEmployee_code_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="type"
        name="location"
        :caption="$t('DxListEmployee_type_caption')"
        :allow-header-filtering="true"
      />
      <!-- <DxColumn
        data-field="nfc"
        name="location"
        :width="125"
        :caption="$t('DxListEmployee_nfc_caption')"
        :allow-header-filtering="true"
      /> -->
      <DxColumn
        data-field="role"
        name="area_effective"
        :width="100"
        alignment="center"
        :caption="$t('DxListEmployee_role_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="drive"
        name="area_effective"
        :caption="$t('DxListEmployee_driver_caption')"
        :allow-header-filtering="false"
        :width="80"
        alignment="center"
      />
      <DxColumn
        data-field="farm"
        name="farm"
        :caption="$t('DxListEmployee_farm_caption')"
        :allow-header-filtering="true"
        :calculate-cell-value="currentFarms"
        :lookup="{
          dataSource: farmFilter
        }"
        :calculate-filter-expression="filteredExpression"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  // DxMasterDetail,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
// import DxDetailTabs from './DxDetailTabs.vue';
// import DxPopupWorkOrder from './DxPopupWorkOrder.vue';
import Api from '../../services/Api';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    // DxMasterDetail,
    // DxDetailTabs,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    // DxPopupWorkOrder,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      listEmployees: [],
      employees: [],
      locationsFake: [],
      finalLevelLocation: [],
      supervisorsAvailables: [],
      taskStatusTranslated: '',
      workOrderStatusTranslated: '',
      machineryPopup: [],
      productPopup: [],
      ocultarBotton: false,
      editButtons: [{
        icon: 'edit',
        hint: this.$t('DxWorkOrders_edit_hint'),
        onClick: this.editEmployee,
      },
      ],
      pageSizes: [5, 10, 20],
      farmFilter: [],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    this.setEmployee(null);
    try {
      this.employees = await this.methodEmployees();
      this.listEmployees.length = 0;
      for (let i = 0; i < this.employees.data.length; i += 1) {
        let nfc = '';
        for (let j = 0; j < this.employees.data[i].auth.length; j += 1) {
          nfc = this.employees.data[i].auth[j].type === 'NFC' ? this.employees.data[i].auth[j].code : '';
        }
        const items = this.getProperties(this.employees.data[i].properties);

        if (this.employees.data[i].farm.length > 0) {
          this.employees.data[i].farm.forEach((element) => {
            if (this.farmFilter.indexOf(element) === -1) {
              this.farmFilter.push(element);
            }
          });
        }
        if (this.employees.data[i].id != null) {
          this.listEmployees.push({
            ID: this.employees.data[i].id,
            name: this.employees.data[i].name,
            surname: this.employees.data[i].surname,
            code: this.employees.data[i].code,
            status: this.employees.data[i].status ? this.$t('DxMachineryDatabase_column_active') : this.$t('DxMachineryDatabase_column_inactive'),
            type: this.$t(items[0].type),
            drive: this.employees.data[i].driver ? this.$t('Yes') : this.$t('No'),
            email: this.employees.data[i].email,
            auth: this.employees.data[i].auth,
            farm: this.employees.data[i].farm,
            farm_id: this.employees.data[i].farm_id,
            company_id: this.employees.data[i].company_id,
            info: this.employees.data[i].info !== undefined ? this.employees.data[i].info : '',
            supervisor: this.employees.data[i].supervisor,
            properties: this.employees.data[i].properties,
            nfc,
            role: this.employees.data[i].supervisor === true ? 'SUPERVISOR' : 'OPERADOR',
            applicator: this.employees.data[i].applicator,
            expirationDate: this.employees.data[i].expiration_sag,
            group: this.$helpers.getProperty(this.employees.data[i].properties, 'group'),
            shift: this.$helpers.getProperty(this.employees.data[i].properties, 'shift'),
            sms: this.$helpers.getProperty(this.employees.data[i].properties, 'sms').toLowerCase() === 'true',
            phone: this.$helpers.getProperty(this.employees.data[i].properties, 'phone'),
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    checkAgriFarm() {
      return Api.getAgriFarm() === 'serbia';
    },
    currentFarms(rowData) {
      return rowData.farm;
    },
    filteredExpression(filter) {
      return [this.currentFarms, 'contains', filter];
    },
    getProperties(properties) {
      const items = [];
      for (let i = 0; i < properties.length; i += 1) {
        if (properties[i].key === 'type') {
          items.push({ type: properties[i].value });
        } else if (properties[i].key === 'drive') {
          items.push({ drive: properties[i].value });
        } else if (properties[i].key === 'group') {
          items.push({ group: properties[i].value });
        }
      }
      return items;
    },
    // GET EMPLOYEES
    async methodEmployees() {
      const xhr = await Api.getEmployeesAll();
      return JSON.parse(xhr.response);
    },
    // EDIT AND DELETE TASK
    editEmployee(rowSelected) {
      if (rowSelected.row.data.drive === this.$t('No')) {
        rowSelected.row.data.drive = false;
      } else {
        rowSelected.row.data.drive = true;
      }
      this.setEmployee(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/createEmployee/', { reloadCurrent: true });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Employees');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Employees.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      // if (!this.$helpers.havePermission(this.getPermissions.company_update_master_data)) return;
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxAddEmployee_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createEmployee/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('EmployeeManager', ['setEmployee']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
