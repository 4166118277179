const dictionary = {
  en: {
    translation: {
      // Palabras reservadas INIT: NO TRADUCIR A OTROS LENGUAJES
      // hasta que se cambie la forma de generar los formularios
      // por enumerados y no se le envien los valores al back
      DIESEL: 'DIESEL',
      GASOLINE: 'GASOLINE',
      TEMPORARY: 'TEMPORARY',
      PERMANENT: 'PERMANENT',
      CONTRATIST: 'CONTRATIST',
      HARVESTER: 'HARVESTER',
      TRACTOR: 'TRACTOR',
      'CAR & VEHICLE': 'CAR & VEHICLE',
      'SOIL GRADER': 'SOIL GRADER',
      EXCAVATOR: 'EXCAVATOR',
      FORKLIFT: 'FORKLIFT',
      TRUCK: 'TRUCK',
      BULLDOZER: 'BULLDOZER',
      SPRAYER: 'SPRAYER',
      Yes: 'Yes',
      No: 'No',
      DxMachineryDatabase_column_active: 'Active',
      DxMachineryDatabase_column_inactive: 'No Active',
      activeSustance: 'Active',
      active: 'Active',
      noActive: 'No Active',
      DxEmployeeForm_operator: 'Operator',
      DxEmployeeForm_active: 'Active',
      DxEmployeeForm_noActive: 'No Active',
      // Palabras reservadas END: NO TRADUCIR A OTROS LENGUAJES
      Login_txtWelcome: 'Login',
      Login_inpUsername_placeholder: 'Email',
      Login_inpPassword_label: 'Password',
      Login_inpPassword_placeholder: 'Password',
      Login_chkRememberMe: 'Remember me',
      Login_txtRestorePass: 'Restore Password',
      Login_txtNeedHelp: 'Do you need some help?',
      Login_btnSignIn: 'SIGN IN',
      Login_btnSignUp_question: 'Not registered yet?',
      Login_btnSignUp_link: 'Sign up here',
      Login_msgEmail_title: 'Information',
      Login_msgEmail_content: 'You will shortly receive an email to change your password',
      Login_msgEmail_btnClose: 'Close',
      loggOut_Message: 'Are you sure you want to exit?',
      RecoveryPassword_txtDataAccess: 'Enter your email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reset password',
      RecoveryPassword_btnGoBack: 'Go back',
      ChangePassword_txtDataAccess: 'Enter your new password',
      ChangePassword_inpEmail_placeholder: 'Password',
      ChangePassword_inpRepeatEmail_placeholder: 'Repeat password',
      ChangePassword_btnChangePassword: 'Change password',
      Register_registerText: 'REGISTER',
      Register_btnEnter: 'ENTER',
      Register_inpName_name: 'Name',
      Register_inpName_placeholder: 'Name',
      Register_inpSurnames_name: 'Surnames',
      Register_inpSurnames_placeholder: 'Surnames',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Password',
      Register_inpPassword_placeholder: 'Password',
      Register_inpPasswordVerification_name: 'RepeatPassword',
      Register_inpPasswordVerification_placeholder: 'Repeat password',
      Register_inpUsername_name: 'Username',
      Register_inpUsername_placeholder: 'Username',
      Nfc_into_error_404: 'The worker is not registered',
      Nfc_into_error_400: 'The nfc format is invalid',
      Nfc_into_error_406: 'The current task is already created in this location',
      Nfc_into_error_409: 'The worker is already working in another task',
      Nfc_info_txtTitle: 'Card reading',
      Nfc_info_txtSubTitle: 'Approach the card for reading',
      Nfc_navbarpresence_incidence: 'NFC scanning',
      Nfc_navbarTask_checkout: 'Productivity',
      Nfc_navbarpresence_manual: 'Add NFC manually',
      Nfc_navbarpresence_btnContinue: 'Continue',
      NfcManually_navbarpresence_btnContinue: 'Continue',
      NfcManually_listInput_placeholder: 'Name or surname',
      Profile_info_profile: 'Profile info',
      Profile_name_placeholder: 'Name',
      Profile_surname_label: '',
      Profile_surname_placeholder: '',
      Profile_email_label: '',
      Profile_email_placeholder: '',
      Profile_mobile_label: '',
      Profile_mobile_placeholder: '',
      Profile_password_profile: '',
      Profile_oldPass_label: '',
      Profile_oldPass_placeholder: '',
      Profile_newPass_label: '',
      Profile_newPass_placeholder: '',
      Profile_checkNewPass_label: '',
      Profile_checkNewPass_placeholder: '',
      Profile_change_password: '',
      Profile_photo_profile: '',
      Profile_update_photo: '',
      Profile_setup_profile: '',
      Profile_language_label: '',
      Profile_theme_label: '',
      Profile_save_changes: '',
      Home_info_txtTitle: 'No workers',
      ExportWorkerList_btnExport: '',
      ExportExcel_thNombre: '',
      ExportExcel_thTipo: '',
      ExportExcel_thFecha: '',
      ExportExcel_captionListaEmpleados: '',
      SheetExport_sheet_export_ok: 'Downloaded file: ',
      SheetExport_sheet_export_error: 'Error into download',
      Start_order: 'Select Workers',
      TasksList_navbar_text: 'Work Orders',
      TasksList_lstNewTasks_title: 'Init new task',
      TasksList_lstInProgressTasks_title: 'In progress tasks',
      TasksList_lstCompletedTasks_title: 'Completed tasks',
      TasksList_Workers_title: 'Workers',
      TasksList_WorkersSelection_title: 'No workers in task',
      TasksList_WorkersSelection_subtitle: 'Click the top button to link workers',
      AccordionList_workers: 'workers',
      AccordionList_worker: 'worker',
      Home_conductor: 'Tractor driver',
      ManualList_listItem_title: 'No worker found',
      checkOut_Productivity_placeholder: 'Field Number',
      checkOut_Productivity: 'Productivity',
      checkOut_Task: 'Task',
      list_content_farm_img_alt: 'Change farm',
      farmSelection_navbar_text: 'Select Farm',
      activitySelection_navbar_text: 'Select Activity',
      locationSelect_navbar_text: 'Select Location',
      ActivitySelection_navbarTask_text: 'Select task',
      ActivitySelection_txtSearchCodeTask: 'Start task to code: ',
      ActivitySelection_txtSearchActivity: 'Choose an activity: ',
      ActivitySelection_taskError: 'Task not found',
      TasksList_filterTask_title: 'Select task',
      DxDailyPlanning_text: 'Create new OT',
      DxMonthlyPlanning_text: 'Create new monthly planning',
      DxAddEmployee_text: 'Add new Worker',
      DxTaskOrders_text: 'Register new work order',
      DetailTabs_machinary_title: 'Machinery',
      DetailTabs_attachment_title: 'Attachments',
      DetailTabs_tools_title: 'Tools',
      DetailTabs_products_title: 'Products',
      DetailTabs_workers_title: 'Workers',
      DetailTabs_locations_title: 'Locations',
      DxDailyPlanning_date_caption: 'Date',
      DxListEmployee_name_caption: 'Name',
      DxListEmployee_surname_caption: 'Surname',
      DxListEmployee_code_caption: 'Id Number',
      DxListEmployee_status_caption: 'Status',
      DxListEmployee_type_caption: 'Type',
      DxListEmployee_nfc_caption: 'Badge id',
      DxListEmployee_driver_caption: 'Driver',
      DxListEmployee_info_caption: 'Info',
      DxListEmployee_farm_caption: 'Farm',
      DxDailyPlanning_task_caption: 'Task',
      DxDailyPlanning_activity_caption: 'Activity',
      DxDailyPlanning_ha_caption: 'Ha',
      DxDailyPlanning_workers_caption: 'Workers',
      DxDailyPlanning_location_caption: 'Location',
      DxDailyPlanning_workingArea_caption: 'Working area (HA)',
      DxMonthlyPlanning_goal_caption: 'Goal',
      DxSelectSupervisor: 'At least one supervisor is needed',
      DxDailyPlanning_workingarea_caption: 'Working Area (HA)',
      DxDailyPlanning_dialogDelete_title: 'Delete planning',
      DxDailyPlanning_dialogDelete_text: 'Are you sure to delete the planning?',
      DxDailyPlanning_dialogDelete_confirm: 'Confirm',
      DxDailyPlanning_dialogDelete_cancel: 'Cancel',
      DxDailyWorkOrders_dialogDelete_title: 'Delete work order',
      DxDailyWorkOrders_dialogDelete_text: 'Are you sure to delete the work order?',
      DxDailyWorkOrders_dialogDelete_confirm: 'Confirm',
      DxDailyWorkOrders_dialogDelete_cancel: 'Cancel',
      DxDailyWorkOrders_dialogDelete_noOpen: 'Cannot delete a work order already started.',
      DxDailyWorkOrders_dialogUpdate_noOpen: 'Cannot edit a work order already started.',
      PlanningComponent_caption_text: 'Activity & Task',
      EmployeeComponent_caption_text: 'Data of Worker',
      PlanningComponent_activityName_caption: 'Activity Name',
      PlanningComponent_activitycode_caption: 'Activity Code',
      PlanningComponent_workingarea_caption: 'Working Area',
      EmployeeComponent_name_caption: 'Name',
      EmployeeComponent_status_caption: 'Status',
      EmployeeComponent_surname_caption: 'Surname',
      EmployeeComponent_identificationNumber_caption: 'Id number',
      EmployeeComponent_role_caption: 'Role',
      EmployeeComponent_type_caption: 'Type',
      EmployeeComponent_drive_caption: 'Driver',
      EmployeeComponent_group_caption: 'Group',
      EmployeeComponent_shift_caption: 'Shift',
      EmployeeComponent_email_caption: 'Email',
      EmployeeComponent_password_caption: 'New Password',
      EmployeeComponent_identification_title: 'Identification of Worker',
      EmployeeComponent_typeIdentification_caption: 'Type',
      EmployeeComponent_code_caption: 'Code',
      DxEmployeeForm_surname_require: 'Surname is required',
      DxEmployeeForm_ID_require: 'identification number is required',
      DxEmployeeForm_name_require: 'Name is required',
      DxEmployeeForm_status_require: 'Status is required',
      DxEmployeeForm_role_require: 'Role is required',
      DxEmployeeForm_type_require: 'Type is required',
      DxEmployeeForm_email_require: 'Email is required',
      DxEmployeeForm_password_require: 'Password is required',
      PlanningComponent_taskName_caption: 'Task Name',
      PlanningComponent_taskCode_caption: 'Task Code',
      DxPlannReal_plannWorkers_caption: 'Planned workers',
      DxPlannReal_workers_caption: 'Workers in',
      DxWorkOrders_PlannMachinary_caption: 'Planned machinery',
      DxWorkOrders_WorkedMachinary_caption: 'Working machinery',
      PlanningComponent_date_caption: 'Date',
      PlanningComponent_numberWorkers_caption: 'Number of Workers',
      PlanningComponent_family_caption: 'Family',
      PlanningComponent_addMachineButton_caption: 'Add Machinery',
      PlanningComponent_shift_caption: 'Shift',
      PlanningComponent_groupName_caption: 'Group Name',
      CreatePlanning_ButtonSave_caption: 'Save',
      PlanningComponent_dailyPlanning: 'DAILY PLAN',
      PlanningComponent_harvesting: 'HARVESTING',
      PlanningComponent_edit_dailyPlanning: 'Edit daily plan',
      StoreProduct_Buttoncancel_caption: 'Cancel',
      StoreProduct_Buttonsave_caption: 'Save',
      CreatePlanning_Title: 'CREATE DAILY PLAN',
      CreateMonthlyPlanning_Title: 'CREATE MONTHLY PLAN',
      EditMonthlyPlanning_Title: 'EDIT MONTHLY PLAN',
      CreatePlanning_ButtonCancel_caption: 'Cancel',
      CreatePlanning_ValidationDate: 'Date is required.',
      CreatePlanning_ValidationTaskName: 'taskName is required.',
      CreatePlanning_ValidationActivityName: 'ActivityName is required.',
      CreatePlanning_ValidationNumberOfWorker: 'The number of workers cannot be zero.',
      CreatePlanning_ValidationNumberOfDriver: 'The number of drivers cannot be zero.',
      CreatePlanning_ValidationActualLocationsSelected: 'Location is necessary',
      CreateEmployee_ValidationIdentificationNumber: 'Identificacion number is required',
      CreateOrder_ValidationSupervisorsLocation: 'There are locations without an assigned supervisor.',
      CreateOrder_ValidationItemsWorkOrder: 'There are unassigned items.',
      DxMonthlyMachinary_text: 'Add new machinery',
      CreateOrder_ValidationLocationExists: 'Task locations exists with this date.',
      CreateEmployee_ValidationName: 'Name is required.',
      CreateEmployee_ValidationSurname: 'Surname is required.',
      CreateEmployee_ValidationEmail: 'Email is required.',
      CreateEmployee_ValidationPassword: 'Password is required.',
      CreateEmployee_Title: 'CREATE WORKER',
      EditEmployee_Title: 'EDIT WORKER',
      DxDailyPlanning_edit_text: 'Edit',
      DxDailyPlanning_edit_hint: 'Edit',
      DxDailyPlanning_new_order_text: 'New order',
      DxDailyPlanning_new_order_hint: 'New order',
      DxDailyPlanning_task_status_caption: 'Task Status',
      DxDailyPlanning_work_order_status_caption: 'Work Order Status',
      DxDailyPlanning_supervisors_caption: 'Supervisors',
      MonthlyComponent_type_caption: 'Type',
      MonthlyComponent_performance_caption: 'Performance',
      MonthlyComponent_performanceunit_caption: 'Performance Unit',
      MonthlyComponent_consumption_caption: 'Consumption',
      MonthlyComponent_consumptionunit_caption: 'Consumption Unit',
      MonthlyComponent_quantity_caption: 'Quantity',
      MonthlyComponent_typeconsumption_caption: 'Consumption Type',
      MonthlyComponent_notes_caption: 'Notes',
      DetailTabs_supervisors_title: 'Supervisors',
      DetailTabAttachemnt_not_selected: 'No attachment selected',
      DetailTabProduct_no_seleccionado: 'No product selected',
      SelectSupervisor_supervisor_caption: 'Supervisor',
      SelectSupervisor_location_caption: 'Location',
      WorkOrder_title: 'WORK ORDER STATUS',
      FuelRegistration_title: 'FUEL REGISTRATION',
      Employee_title: 'LIST WORKERS',
      WorkOrderDetail_select_supervisor: 'Select supervisor',
      WorkOrderDetail_select_maquinaria: 'Select machinery',
      WorkOrderDetail_order: 'ORDER',
      DxWorkOrders_edit_text: 'Edit',
      DxWorkOrders_edit_hint: 'Edit',
      DxWorkOrders_modal_title: 'Edit Work Order',
      DxWorkOrders_machinery_caption: 'Machinery',
      DxWorkOrders_driver_caption: 'Driver',
      DxWorkOrders_km_start_caption: 'Km start',
      DxWorkOrders_km_finish_caption: 'Km end',
      DxWorkOrders_horometer_start_caption: 'Hourmeter start',
      DxWorkOrders_horometer_finish_caption: 'Hourmeter end',
      DxWorkOrders_fuel_consumption_caption: 'Fuel consumption (L)',
      DxWorkOrders_fuel_type_caption: 'Fuel type',
      DxWorkOrders_working_area_caption: 'Working area',
      DxWorkOrders_aditional_info_caption: 'Additional info',
      DxProductPerformance_unit: 'Unit',
      DxProductPerformance_products_name: 'Product',
      DxProductPerformance_products_qtyCollected: 'Quantity collected',
      DxProductPerformance_products_qtyPlanned: 'Quantity planned',
      DxProductPerformance_products_qtyTask: 'Quantity returned to warehouse',
      DxProductPerformance_products_qtyUsed: 'Quantity used',
      DxProductPerformance_products_qtyReturnWarehouse: 'Quantity returned to warehouse',
      DxProductPerformance_products_totalRealPrescription: 'Solution Used (L)',
      DxProductPerformance_products_realSolution: 'Real solution',
      DxProductPerformance_products_realDilutionFactor: 'Real dilution factor',
      DxProductPerformance_products_appliedDose: 'Applied dose',
      DxProductPerformance_products_theoricalDose: 'Theorical Dose',
      EditOrder_ButtonSave_caption: 'Save',
      EditOrder_ButtonCancel_caption: 'Cancel',
      CreateOrder_ButtonSave_caption: 'Save',
      CreateOrder_ButtonCancel_caption: 'Cancel',
      CreateOrder_ValidationOutDate: 'You cannot create a work order with an out of date schedule',
      DxSelectedItems_ya_esta_seleccionado_alert: 'Is already selected',
      DxDailyPlanning_delete_text: 'Delete',
      DxDailyPlanning_delete_hint: 'Delete',
      DxWorkOrder_Button_View_text: 'View',
      DxWorkOrder_Button_View_hint: 'View',
      DetailTabWorkers_sin_trabajadores: 'No workers selected',
      DetailTabAttachemnt_no_selected: 'No Attachment selected',
      DetailTabProduct_not_selected: 'No product selected',
      WorkOrderDetail_select_date: 'Select date',
      DxSelectDate_date_caption: 'Date',
      PlanningComponent_typeUnit_caption: 'Type unit',
      DxDailyPlanning_performanceha_caption: 'Goal',
      DxDailyPlanning_totalArea: 'Total area (ha)',
      PlanningComponent_performanceUnit_caption: 'Productivity Unit',
      PlanningComponent_performanceCount_caption: 'Productivity Goal',
      PlanningComponent_distributed_caption: 'Distributed',
      EmployeeComponent_expirationDate_caption: 'Expiration Date',
      EmployeeComponent_datepicker_expirationDate_placeholder: 'Select Date',
      EmployeeComponent_applicator_caption: 'Applicator',
      Status_Open: 'PENDING',
      Status_InProgress: 'IN PROGRESS',
      Status_Completed: 'COMPLETED',
      Status_Closed: 'Closed',
      Status_notReally_Closed: 'Not Closed',
      Status_reallyNot_Closed: 'Not Closed',
      Status_Pending: 'Pending',
      Status_Deleted: 'Deleted',
      DetailTabWorkers_sin_items: 'No items selected',
      DetailTab_sin_seleccion: 'Nothing selected',
      WorkOrderDetail_additional_info: 'Additional information',
      WorkOrderDetail_select_driver: 'Select driver',
      CreateOrder_ValidationItemsDriversWorkOrder: 'There are unassigned drivers.',
      Login_inpUsername_validate: 'Complete this field',
      Login_inpPassword_validate: 'Complete this field',
      DxEffort_level_caption: 'Level',
      DxEffort_activity_caption: 'Activity',
      DxEffort_task_caption: 'Task',
      DxEffort_hrHours_caption: 'HR Hours',
      DxEffort_totalHours_caption: 'Total Hours',
      DxEffort_totalHoursHa_caption: 'Total hours/Ha',
      DxEffort_totalHoursTotalHa_caption: '% of total hours vs total all Ha',
      DxEffort_progressCompleted_caption: 'Progress (% Complete)',
      EffortAgriFarmCockpit_navbar_title: 'REPORTING EFFORT AGRI FARM COCKPIT',
      WeatherFarmCockpit_navbar_title: 'CLIMATE GRAPHS',
      AgriFarmCockpit_navbar_title: 'REPORTING AGRI FARM COCKPIT',
      EffortTask_navbar_title: 'REPORTING EFFORT TASK',
      PrecisionFarming_navbar_title: 'PRECISION FARMING',
      DxDailyPlanning_days_caption: 'Days',
      DxDailyPlanning_p_caption: 'Permanent',
      DxDailyPlanning_t_caption: 'Temporary',
      DxDailyPlanning_c: 'Contratist',
      DxDailyPlanning_coste: 'Cost',
      LeftPanel_subtitle_harvesting: 'Harvesting',
      LeftPanel_title_reporting: 'REPORTING',
      LeftPanel_title_plan: 'PLAN',
      LeftPanel_title_execution: 'EXECUTION',
      LeftPanel_title_prescriptions: 'PRESCRIPTIONS',
      LeftPanel_title_weather: 'WEATHER',
      LeftPanel_title_warehouse: 'WAREHOUSE',
      LeftPanel_subtitle_fuelRegistration: 'Fuel Registration',
      LeftPanel_title_precisionFarming: 'PRECISION FARMING',
      LeftPanel_title_fertilizers: 'FERTILIZERS',
      LeftPanel_title_information: 'INFORMATION',
      LeftPanel_title_dataConfiguration: 'DATA CONFIGURATION',
      LeftPanel_subtitle_home: 'Home',
      LeftPanel_subtitle_AgriFarmCockpit: 'Agri Farm Cockpit',
      LeftPanel_subtitle_effortAgriFarm: 'Effort Agri Farm Cockpit',
      LeftPanel_subtitle_dailyPlanning: 'Daily Plan',
      LeftPanel_subtitle_workOrders: 'Work Order Status',
      LeftPanel_subtitle_unplannedWorkOrders: 'Manual register  WO',
      LeftPanel_subtitle_postWork: 'Post Work',
      LeftPanel_subtitle_prescriptionManager: 'Prescription',
      LeftPanel_subtitle_fertilizersPrescriptions: 'Fertilizers Prescriptions',
      LeftPanel_subtitle_activeIngredients: 'Active Ingredients authorized EU',
      LeftPanel_subtitle_weatherFarmCockpit: 'Climate Graphs',
      LeftPanel_subtitle_precisionFarming: 'Precision Farming',
      LeftPanel_subtitle_logOut: 'Sign out',
      LeftPanel_subtitle_phytosanitariesLocalDatabase: 'Phytosanitaries Local Database',
      LeftPanel_subtitle_employee: 'Worker',
      LeftPanel_subtitle_productsLocalDatabase: 'Products Local Database',
      Search_placeholder: 'Search',
      WeatherForecast_title: 'Weather Forecast',
      WeatherStatus_title: 'Weather Status',
      GisMap_title: 'Map',
      PlanningComponent_machinary_caption: 'Machinery',
      DxProducts_dxColumn_Unit: 'Unit',
      DxAttachment_dxColumn_TypeAttachment: 'Type',
      DxAttachment_dxColumn_QuantityAttachment: 'Quantity',
      DxMachinery_dxColumn_TypeMachinery: 'Type',
      DxMachinery_dxColumn_QuantityMachinery: 'Quantity',
      DxProducts_dxColumn_activeIngredients: 'Active ingredients',
      DxProducts_dxColumn_productName: 'Product name',
      DxProducts_dxColumn_dosis: 'Dose',
      DxProducts_dxColumn_dosisUt: 'Dose Ut',
      DxProducts_dxColumn_dosisTotal: 'Quantity Planned',
      DxProducts_dxColumn_solution: 'Solution',
      DxProducts_dxColumn_solutionUt: 'Solution UoM',
      DxProducts_dxColumn_dilutionFactor: 'Dilution Factor',
      DxProducts_dxColumn_unit: 'Unit',
      DxProducts_dxColumn_dilutionFactorUt: 'Unit',
      DxProducts_dxColumn_solutionTotal: 'Water planned (L)',
      DxProducts_dxColumn_caution: 'Caution',
      DxProducts_dxColumn_restrictedEntryInterval: 'Entry Interval',
      DxProducts_dxColumn_preharvestInterval: 'PreHarvest Interval',
      ActiveIngredients_navbar_title: 'ACTIVE INGREDIENTS AUTHORIZED EU',
      PrescriptionManager_navbar_title: 'PRESCRIPTION MANAGER',
      DxCreatePrescription_button_text: 'Create new Prescription',
      DxCreatePrescription_column_active: 'Active',
      DxCreatePrescription_column_name: 'Name of prescription',
      DxCreatePrescription_column_type: 'Type ',
      DxCreatePrescription_column_method: 'Orchard/Nursery',
      ORCHARD: 'Orchard',
      NURSERY: 'Nursery',
      DxCreatePrescription_column_disease: 'Disease',
      DxCreatePrescription_column_activeSince: 'Active since',
      DxCreatePrescription_column_notes: 'Notes',
      CreatePrescription_Title: 'CREATE NEW PRESCRIPTION',
      EditPrescription_Title: 'EDIT PRESCRIPTION',
      EditProduct_navbar_create: 'EDIT PRODUCT',
      EditProduct_navbar_edit: 'CREATE NEW PRODUCT',
      DxFormProduct_code_caption: 'CODE',
      DxFormProduct_code_required: 'Code is required',
      DxFormProduct_status_caption: 'STATUS',
      DxFormProduct_comment_caption: 'COMMENT',
      DxFormProduct_description_caption: 'DESCRIPTION',
      DxFormProduct_component_caption: 'ACTIVE INGREDIENT',
      DxFormProduct_family_caption: 'GROUP',
      DxFormProduct_subfamily_caption: 'SUBGROUP',
      DxFormProduct_sapunit_caption: 'UNIT',
      DxFormProduct_toxicity_caption: 'TOXICITY',
      DxFormProduct_description_required: 'Description is required.',
      DxInformationPrescription_name_caption: 'NAME',
      DxInformationPrescription_active_caption: 'ACTIVE',
      DxInformationPrescription_dateofactivation_caption: 'DATE OF ACTIVATION',
      DxInformationPrescription_typeofprescription_caption: 'TYPE OF PRESCRIPTION',
      DxInformationPrescription_methodofapplication_caption: 'ORCHARD / NURSERY',
      DxInformationPrescription_notes_caption: 'NOTES',
      DxInformationPrescription_name_required: 'Name is required',
      DxInformationPrescription_date_required: 'Date is required',
      DxInformationPrescription_typeofprescription_required: 'Type of prescription is required',
      DxInformationPrescription_methodofapplication_required: 'The field Orchard/Nursery is required',
      totalArea_field: 'Total area (ha)',
      totalArea_title: 'Summary of selected Plots',
      DxAttachment_Title: 'Attachments',
      DxMachineryPerformance_Title: 'Machinery Performance',
      DxProductsPerformance_Title: 'Products Performance',
      DxMachinery_Title: 'Machinery',
      DxTools_Title: 'Tools',
      DxProducts_Title: 'Products',
      DxTools_dxColumn_TypeTool: 'Type',
      DxTool_dxColumn_QuantityTool: 'Quantity',
      CreatePrescription_ValidationName: 'Name is required',
      CreatePrescription_ValidationNameDuplicated: 'Name already in use',
      CreatePrescription_ValidationProducts: 'You need to add at least one product',
      CreatePrescription_ValidationMethod: 'Method of prescription is required',
      CreatePrescription_ValidationType: 'Type of prescription is required',
      CreatePrescription_ValidationDate: 'A valid date is required',
      CreatePrescription_ValidationActive: 'A new prescription must be active',
      DxPlanningProducts_prescription: 'Name Prescription',
      DxPlanningProducts_prescription_required: 'Prescription is required',
      DxPlanningProducts_typePrescription: 'Type Prescription',
      DxPlanningProducts_typePrescription_required: 'Type Prescription is required',
      DxPlanningProducts_typeOfPrescription: 'Type of presc.',
      DxPlanningProducts_typeOfPrescription_required: 'Type of presc. is required',
      // DxActiveIngredientsVademecum i18next desde vuex.
      active_substance_id: 'Active substance id',
      approval_date: 'Approval date',
      approval_expiration: 'Approval expiration',
      authorisation_in_progress: 'Authorization in progress',
      authorised: 'Authorized',
      category: 'Category',
      organic_approved: 'Organic approved',
      remark: 'Remark',
      status: 'Status',
      substitution_candidate: 'Substitution candidate',
      countries: 'Countries',
      //
      MarketTabComponent_market_tittle: 'Market',
      FertilizersPrescriptions_title: 'FERTILIZERS PRESCRIPTIONS',
      ProductsDatabase_title: 'PRODUCTS DATABASE',
      DxAttachmentMachinary_dxColumn_Machinary: 'Machinery',
      DxAttachmentMachinary_dxColumn_Attachment: 'Attachment',
      DxWeatherElementLinesGraph_average: 'Average',
      DxWeatherElementLinesGraph_averageForecasted: 'Average Forecasted',
      DxWeatherElementLinesGraph_totalForecasted: 'Total Forecasted',
      DxWeatherElementLinesGraph_total: 'Total',
      DxProduts_rule_range: 'Range should be up 0',
      DxProduts_rule_solution_required: 'Solution is required',
      DxProduts_rule_dosis_required: 'Dose is required',
      DxProdutsDataBase_button_text: 'Create new product',
      DxProdutsDataBase_edit_text: 'Edit',
      DxProdutsDataBase_edit_hint: 'Edit',
      DxEmployeeTable_Title: 'Workers',
      DxPostWorkEmployeeTable_rule_range: 'Range should be up 0',
      DxPostWorkEmployeeTable_Name: 'Name',
      DxPostWorkEmployeeTable_Surname: 'Surname',
      DxPostWorkEmployeeTable_Checkin: 'Checkin',
      DxPostWorkEmployeeTable_Checkout: 'Checkout',
      DxPostWorkEmployeeTable_Productivity: 'Productivity',
      DxPostWorkEmployeeTable_Unit: 'Unit',
      DxPostWorkEmployeeTable_Notes: 'Notes',
      DxTaskOrders_navbar_Name: 'REGISTER WORK ORDER',
      PlanningComponent_datepicker_multiple_placeholder: 'Select dates',
      CreatePostWork_Navbar_Title: 'REGISTER NEW WORK ORDER',
      ViewPostWork_Navbar_Title: 'VIEW WORK ORDER',
      DxPostWorkEmployeeTable_dialogCreate_wrongHour: 'Checkin must be before checkout.',
      CreatePostWork_Validation_PrescriptionNeeded: 'You must add a prescription in this task',
      DxCreatePrescription_column_family: 'Family',
      DxProducts_dxColumn_productGroup: 'Type',
      DxProducts_dxColumn_productFamily: 'Group',
      DxPlanningForm_activity_name: 'Activity name is required',
      DxPlanningForm_Task_name: 'Task name is required',
      DxPostWorkEmployeeTable_dialogCreate_wrongCheckIn: 'Check in must be same as task date',
      LeftPanel_title_machinery: 'MACHINERY',
      LeftPanel_subtitle_machineryDataBase: 'Machinery Database',
      Machinery_title: 'MACHINERY',
      CreateMachinery_Title: 'CREATE MACHINERY',
      EditMachinery_Title: 'EDIT MACHINERY',
      DxMachineryDatabase_column_status: 'Status',
      DxMachineryDatabase_column_family: 'Family',
      DxMachineryDatabase_column_subfamily: 'Subfamily',
      DxMachineryDatabase_column_description: 'Item Description',
      DxMachineryDatabase_column_brand: 'Brand',
      DxMachineryDatabase_column_code: 'Internal Code',
      DxMachineryDatabase_column_attachment: 'Attachment',
      DxMachineryDatabase_button_text: 'Create new machinery',
      DxMachineryForm_code_caption: 'Code',
      DxMachineryForm_code_required: 'Code is required',
      DxMachineryForm_description_caption: 'Description',
      DxMachineryForm_description_required: 'Description is required',
      DxMachineryForm_brand_caption: 'Brand',
      DxMachineryForm_brand_required: 'Brand is required',
      DxMachineryForm_model_caption: 'Model',
      DxMachineryForm_model_required: 'Model is required',
      DxMachineryForm_serial_number_caption: 'Serial Number',
      DxMachineryForm_serial_number_required: 'Serial Number required',
      DxMachineryForm_plate_caption: 'Plate',
      DxMachineryForm_plate_required: 'Plate is required',
      DxMachineryForm_internal_code_caption: 'Internal Code',
      DxMachineryForm_internal_code_required: 'Internal Code is required',
      DxMachineryForm_family_caption: 'Family',
      DxMachineryForm_family_required: 'Family is required',
      DxMachineryForm_subfamily_caption: 'Subfamily',
      DxMachineryForm_subfamily_required: 'Subfamily is required',
      DxMachineryForm_attachment_caption: 'Attachment',
      DxMachineryForm_purchase_caption: 'Purchase',
      DxMachineryForm_purchase_required: 'Purchase The field',
      DxMachineryForm_status_caption: 'Status',
      Machinery_ValidationSAPCode: 'The field Code is required',
      Machinery_ValidationInternalCode: 'The field Internal Code is required',
      Machinery_ValidationBrand: 'The field Brand is required',
      Machinery_ValidationModel: 'The field Model is required',
      Machinery_ValidationSerialNumber: 'The field Serial Number is required',
      Machinery_ValidationPlate: 'The field Plate is required',
      Machinery_ValidationDescription: 'The field Description is required',
      Machinery_ValidationFamily: 'The field Family is required',
      Machinery_ValidationSubfamily: 'The field Subfamily is required',
      Machinery_ValidationPurchase: 'The field Purchase is required',
      StoreProduct_ValidationComponent: 'Component is required',
      StoreProduct_ValidationCode: 'ID is required',
      StoreProduct_ValidationFamily: 'Group Name is required',
      StoreProduct_ValidationSubfamily: 'Subgroup is required',
      StoreProduct_ValidationSapunit: 'Unit is required',
      StoreProduct_ValidationDescription: 'Description is required',
      StoreProduct_ValidationCodeDuplicated: 'Code is duplicated',
      // Generics
      Button_cancel_text: 'Cancel',
      Button_save_text: 'Save',
      LeftPanel_subtitle_productsDataBase: 'Products Database',
      DxProductsDataBase_status_caption: 'Status',
      DxProductsDataBase_description_caption: 'Description',
      DxProductsDataBase_code_caption: 'Code',
      DxProductsDataBase_family_caption: 'Group',
      DxProductsDataBase_subfamily_caption: 'Subgroup',
      DxProductsDataBase_component_caption: 'Active Ingredient',
      LeftPanel_subtitle_reportingWorker: 'Workers',
      ReportingWorker_navbar_title: 'REPORTING WORKERS',
      ReportingTimeWorked_navbar_title: 'CLOCK IN / OUT',
      DxWorker_date_caption: 'Date',
      DxWorker_task_caption: 'Task',
      DxWorker_typeTask_caption: 'Type',
      DxWorker_codeTask_caption: 'Task Code',
      DxWorker_workerName_caption: 'Worker',
      DxWorker_name_caption: 'Name',
      DxWorker_workerSurname_caption: 'Surname',
      DxWorker_idNumber_caption: 'Id Number',
      DxWorker_typeWorker_caption: 'Type',
      DxWorker_territorialUnit_caption: 'Territorial Unit',
      DxWorker_totalHours_caption: 'Nº Hours',
      DxWorker_workingDay_caption: 'Working Day',
      DxWorker_performanceUnit_caption: 'Prformance Unit',
      DxWorker_performanceCount_caption: 'Performance',
      DxWorker_note_caption: 'Note',
      CreateMonthlyPlanning_ValidationDate: 'The number of working days must correspond to the number of dates selected',
      CreateMonthlyPlanning_ValidationTotalWorkingDays: 'You must plan at least one work day',
      DxMonthlyForm_activityTask: 'Activity Task',
      DxMonthlyForm_goalUnit: 'Goal Unit',
      DxMonthlyForm_DateProgrammed: 'Date Programmed',
      DxMonthlyForm_numberWorkers_caption: 'Number of Workers',
      DxMonthlyForm_performanceWorker_caption: 'PERFORMANCE/WORKER',
      DxMonthlyForm_workersDaysRequired: 'WORKERS DAYS REQUIRED',
      DxMonthlyForm_machineryDaysRequired: 'MACHINERY DAYS REQUIRED',
      DxMonthlyForm_toolsConsumptionTotal: 'TOOLS CONSUMPTION TOTAL (L)',
      DxMonthlyForm_estimatedCostActivity: 'ESTIMATED COST ACTIVITY',
      DxMonthlyForm_taskDuration: 'TASK DURATION',
      DxToolsMonthlyTable_type_caption: 'Type',
      DxToolsMonthlyTable_consumption_caption: 'Consumption',
      DxToolsMonthlyTable_consumption_type_caption: 'Consumption Type',
      DxToolsMonthlyTable_consumption_unit_caption: 'Consumption Unit',
      DxMachineryMonthlyTableHADAY: 'HA/DAY',
      DxMachineryMonthlyTableUNDIA: 'UN/DAY',
      Home_navbar_title: 'HOME',
      // CRUD Costes.
      Costs_title: 'COSTS',
      DxCostsDatabase_column_name: 'Name',
      DxCostsDatabase_edit_text: 'Edit',
      DxCostsDatabase_edit_hint: 'Edit',
      LeftPanel_subtitle_costsDataBase: 'Costs Database',
      DxCostsDatabase_column_code: 'Code',
      DxCostsDatabase_column_id: 'id',
      DxCostsDatabase_column_price: 'Price',
      DxCostsDatabase_column_currency: 'Currency',
      DxCostsDatabase_column_currency_code: 'Currency Code',
      DxCostsDatabase_column_unit: 'Unit',
      DxCost_button_text: 'Create new Costs',
      DxCost_title_creation: 'CREATE COSTS',
      DxCostForm_code_required: 'Code is required',
      DxCostForm_name_required: 'Name is required',
      DxCostForm_price_required: 'Price is required',
      DxCostForm_Unit_required: 'Unit is required',
      DxCostForm_Currency_required: 'Currency is required',
      DxCostForm_Id_required: 'Id is required',
      CreacteCost_ValidationCode: 'The code field is required',
      CreacteCost_ValidationName: 'The name field is required',
      CreacteCost_ValidationPrecio: 'The Price field is required',
      CreacteCost_ValidationUnit: 'The unit field is required',
      CreacteCost_ValidationCurrency: 'The currency field is required',
      CreacteCost_ValidationCurrencyCode: 'The currency code field is required',
      CreacteCost_ValidationId: 'The ID field is required',
      CreateCost_dialogDelete_title: 'Delete Cost',
      DeleteCost_dialogDelete_text: 'Are you sure to delete the Selected Cost?',
      LeftPanel_subtitle_reportingBpas: 'Phytosanitary Use Report',
      LeftPanel_subtitle_reportingMachinery: 'Machinery',
      LeftPanel_subtitle_timeWorked: 'Clock in / out',
      // Summary BPAS
      DxDxBpas_Date_caption: 'Date',
      DxDxBpas_Location_caption: 'Territorial Units',
      DxDxBpas_ActiveIngredients_caption: 'Active Ingredients',
      DxDxBpas_dosisUt_caption: 'dose',
      DxDxBpas_attachment_caption: 'Attachment',
      DxDxBpas_machineryfamily_caption: 'Machinery family',
      DxDxBpas_attachmentFamily_caption: 'Attachment family',
      DxDxBpas_Machinary_caption: 'Machinery',
      DxDxBpas_Name_caption: 'Product name',
      DxDxBpas_QtyUsed_caption: 'Quantity used Product',
      DxDxBpas_SolutionTotal_caption: 'Planned Solution',
      DxDxBpas_Variety_caption: 'Variety',
      DxDxBpas_Worker_caption: 'Applicator',
      DxDxBpas_Task_caption: 'Task',
      DxDxBpas_CodeTask_caption: ' Task Code',
      DxDxBpas_TypeTask_caption: 'Type',
      DxDxBpas_Formulas_caption: 'Prescription name',
      DxListEmployee_role_caption: 'Role',
      DxPrescriptionManager_dosis: 'Dose',
      DxPrescriptionManager_solution: ' Solution',
      DxPrescriptionManager_description: 'Item Description',
      DxMachineryDatabase_company: 'Farm',
      // Summary Machinery
      DxMachinery_Date_caption: 'Date',
      DxMachinery_Location_caption: 'Territorial Units',
      DxMachinery_Attachment_caption: 'Attachment',
      DxMachinery_unit_caption: 'unit',
      DxMachinery_init_caption: 'km start',
      DxMachinery_end_caption: 'km end',
      DxMachinery_total_caption: 'km made',
      DxMachinery_Name_caption: 'Machine',
      DxMachinery_workingArea_caption: 'Working area',
      DxMachinery_SolutionTotal_caption: 'Solution',
      DxMachinery_Variety_caption: 'Variety',
      DxMachinery_Worker_caption: 'Driver',
      DxMachinery_Task_caption: 'Task',
      DxMachinery_typeFuel_caption: 'Fuel Type',
      DxMachinery_quantityFuel_caption: 'fuel quantity',
      DxMachinery_quantity_caption: 'Quantity',
      DxMachinery_CodeTask_caption: 'Task Code',
      DxMachinery_InternalCode_caption: 'Internal Code',
      DxMachinery_TypeTask_caption: 'Type',
      DxMachinery_performance_caption: 'Performance',
      DxMachinery_Formulas_caption: 'Formulation',
      DxMachinery_comment_caption: 'Notes',
      DxWorker_ActivityCode_caption: 'Activity Code',
      DxWorker_group_caption: 'Group',
      DxWorker_shift_caption: 'Shift',
      DxWorker_Activity_caption: 'Activity',
      DxWorker_location_caption: 'Location',
      DxWorker_initDate_caption: 'Clock in',
      DxWorker_endDate_caption: 'Clock out',
      DxWorker_checkIn_caption: 'Check in',
      DxWorker_checkOut_caption: 'Check out',
      DxWorker_jornada_caption: 'workday',
      ReportingMachinery_navbar_title: 'REPORTING MACHINERY',
      ReportingBPAS_navbar_title: 'PHYTOSANITARY USE REPORT',
      DxDxBpas_activity_caption: 'Activity',
      DxDxBpas_activityCode_caption: 'Activity Code',
      DxMachinery_Activity_caption: 'Activity',
      DxMachinery_ActivityCode_caption: 'Activity Code',
      DxMonthlyPlanning_totalCost: 'Total Cost',
      DxPostWorkEmployeeTable_WorkingArea: 'Working Area',
      CreatePlanning_ValidationTotalWorkingDays: 'The number of working days for machinery exceeds the number of total working days. Please adjust the performance of the machinery.',
      CreateOrder_ValidationAttachments: 'There are unassigned machinery in attachments',
      UploadHarvestingPlan_preloader: 'Uploading the file. This may take a little time depending on the size of the file',
      UploadHarvestingPlan_success: 'File uploaded successfully. We are processing the works orders. It may take a while to see them',
      UploadHarvestingPlan_error: 'The following error has occurred:',
      UploadHarvestingPlan_button: 'Upload file',
      UploadHarvestingPlan_empty: 'Please, select a file',
      LeftPanel_subtitle_logs: 'Harvest Logs',
      ReportingLogs_navbar_title: 'LOGS',
      DxErrorLogs_Date_caption: 'Date',
      DxErrorLogs_Time_caption: 'Time',
      DxErrorLogs_HDSID_caption: 'HDS Id',
      DxErrorLogs_Description_caption: 'Description',
      DxSelectSupervisorPerformance_SelectSupervisor_required: 'Supervisor required',
      CalendarInitEndDate_btnClear: 'Clear',
      CalendarInitEndDate_btnSearch: 'Search',
      PlanningComponent_seasional_caption: 'Number',
      DxProductPerformance_machinery_total_real_consumption: 'Total area prescription',
      DxDxBpas_totalRealPrescription_caption: 'Solution used',
      DxWorkOrders_notes_caption: 'Notes',
      DxWorkOrders_variety_caption: 'Variety',
      ReportingEmployeeEffectiveness_navbar_title: 'WORKER EFFECTIVENESS',
      ReportingEmployeeEffectiveness_date: 'Date',
      ReportingEmployeeEffectiveness_task: 'Task',
      ReportingEmployeeEffectiveness_taskCode: 'Task Code',
      ReportingEmployeeEffectiveness_Activity: 'Activity',
      ReportingEmployeeEffectiveness_activityCode: 'Activity Code',
      ReportingEmployeeEffectiveness_performance: 'Performance',
      ReportingEmployeeEffectiveness_timeSpent: 'Time Spent',
      ReportingEmployeeEffectiveness_fullname: 'Full Name',
      ReportingEmployeeEffectiveness_type: 'Type',
      ReportingEmployeeEffectiveness_IdTask: 'Id Task',
      ReportingEmployeeEffectiveness_SumaParcial: 'Parcial Sum',
      HOURS: 'Hours',
      ReportingEmployeeEffectiveness_ratioTimeSpent: '% Time Spent',
      ReportingEmployeeEffectiveness_ratioTotalPerformance: '% Total Performance',
      ReportingEmployeeEffectiveness_ratioPerformanceGoal: '% Goal Performance',
      ReportingWorkOrders_navbar_title: 'REPORTING WORK ORDERS',
      LeftPanel_subtitle_reportingWorkOrders: 'Work Orders',
      DxListTaskGroupedActivivty_taskCode: 'Task Code',
      DxListTaskGroupedActivivty_performanceUnit: 'Performance Unit',
      DxListTaskGroupedActivivty_task: 'Tasks',
      DxListTaskGroupedActivivty_Activity: 'Activity',
      DxListTaskGroupedActivivty_NavBarName: 'ACTIVITY AND TASKS',
      LeftPanel_subtitle_ListTaskGroupedActivivty: 'Activity and Tasks',
      PlanningComponent_variety_caption: 'Variety',
      DxPlanningForm_variety_required: 'Variety required',
      DxDailyPlanning_realGoal_caption: 'Real goal',
      DxDailyPlanning_goalVsReal_caption: '% Completed Goal',
      DxDailyPlanning_realCost: 'Real cost',
      DxDailyPlanning_CostVsReal: '% Completed Cost',
      planned_title: 'PLANNED VS REAL',
      ReportingplannedVsReal_navbar_title: 'PLANNED VS REAL',
      Variety_title: 'VARIETY',
      LeftPanel_subtitle_varietyDatabase: 'Variety Database',
      DxVarietyDatabase_column_code: 'Code',
      DxVarietyDatabase_column_name: 'Name',
      DxMachineryDatabase_sapid: 'ID',
      DxProductsDataBase_UMSAP_caption: 'UNIT',
      DxProductTable_dialogCreate_solutionNotEmpty: 'The solution field cannot be empty or less than 0',
      DxDetailTabs_products_quantityPlanned: 'Quantity Planned',
      DxDetailTabs_products_waterPlanned: 'Water Planned',
      CreatePlanning_ValidationPrescription: 'You must select a prescription',
      DxBpas_Machinary_InternalCodecaption: 'Internal Code',
      DxDxBpas_QtyUsedUnit_caption: 'Quantity unit',
      DxWorkOrders_kmTotal_caption: 'km performed',
      DxWorkOrders_HorometerTotal_caption: 'Horometer performed',
      DxProductPerformance_products_doseUsed: 'Doses used',
      PlanningComponent_grouped_caption: 'Grouped',
      Workers_StackedBarPlanned: 'Workers',
      machinary_StackedBarPlanned: 'Machineries',
      ChartPlanned_StackedBarPlanned: 'Planned vs Working',
      plannWorkers_StackedBarPlanned: 'Planned Workers',
      plannMachinery_StackedBarPlanned: 'Planned machineries',
      DxPostWorkMachinaryDriverTable_machinery_validating: 'Machinery is already selected',
      dxSideBySideBar_totalWorkerByActivity: 'Total Workers By Activity',
      dxSideBySideBar_Worker: 'Workers',
      dxSideBySideBarDayWorked_totalWorkerByActivity: 'Days Worked per Activity',
      dxSideBySideBarDayWorked_totalWorkerByActivity_Worked: 'Days Worked',
      DxToolsDatabase_column_status: 'Status',
      DxToolsDatabase_column_code: 'Code',
      DxToolsDatabase_column_family: 'Family',
      DxToolsDatabase_column_subfamily: 'Subfamily',
      DxToolsDatabase_column_description: 'Description',
      DxToolsDatabase_column_brand: 'Brand',
      DxToolsDatabase_column_id: 'id',
      DxToolsDatabase_column_fuel: 'fuel',
      DxToolsDatabase_column_itemId: 'item Id',
      LeftPanel_subtitle_tools: 'Tools',
      editTool_Title: 'EDIT TOOL',
      createTool_Title: 'CREATE TOOL',
      Tools_ValidationCode: 'The field Code is required',
      Tools_ValidationBrand: 'The field Brand is required',
      Tools_ValidationID: 'The field id is required',
      Tools_ValidationItemID: 'The field item ID is required',
      Tools_ValidationDescription: 'The field Description is required',
      Tools_ValidationFamily: 'The field Family is required',
      Tools_ValidationSubfamily: 'The field Subfamily is required',
      Tools_ValidationFuel: 'The field Fuel is required',
      DxWorkOrder_short_id: 'ID',
      DxManualPerformance_total_performance: 'Total Performance',
      DxPopupWorkOrder_Title_supervisor: 'Supervisor',
      DxPopupWorkOrder_Title_totalPerformance: 'Performance',
      DxPopupWorkOrder_Title_machinery: 'Machinery',
      DxPopupWorkOrder_Title_product: 'Products',
      DxWorkOrders_manualPerformance_caption: 'Total Performance',
      PlanningComponent_manualPerformance_caption: 'Total Performance',
      ProductCollection_navbar_title: 'PRODUCT COLLECTION',
      LeftPanel_subtitle_productCollection: 'Product Collection',
      ReturnedProduct_navbar_title: 'RETURNED PRODUCT',
      LeftPanel_subtitle_returnedProduct: 'Returned Product',
      DxProductWarehouse_column_date: 'Date',
      DxProductWarehouse_shortId: 'ID',
      DxProductWarehouse_column_activity: 'Activity Name',
      DxProductWarehouse_column_activityCode: 'Activity Code',
      DxProductWarehouse_column_task: 'Task',
      DxProductWarehouse_column_taskCode: 'Task Code',
      DxProductWarehouse_column_productName: 'Product Name',
      DxProductWarehouse_column_supervisor: 'Supervisor',
      DxProductWarehouse_column_productWarehouse: 'Quantity',
      DxProductWarehouse_column_statusCollection: 'Status',
      DxProductWarehouse_column_statusConfirmed: 'Sent',
      DxProductWarehouse_column_statusNotConfirmed: 'Not Sent',
      DxProductWarehouse_column_cost_center: 'Cost Center',
      DxProductWarehouse_column_cost_center_required: 'Cost Center required',
      DxProductWarehouse_column_warehouse: 'Warehouse',
      DxProductWarehouse_column_warehouse_required: 'Warehouse required',
      DxProductWarehouse_column_status: 'Order Status',
      DxProductWarehouse_column_COMPLETED: 'Completed',
      DxProductWarehouse_column_IN_PROGRESS: 'In Progress',
      DxProductWarehouse_column_planedQuantity: 'Planned Quantity',
      // Greenhouse
      DxBins_dxColumn_Code: 'Code',
      DxBins_dxColumn_NameBins: 'Name',
      DxBins_dxColumn_CreationDate: 'Creation Date',
      BatchesForm_edit_batches_info: 'Batch Info',
      BatchesForm_create_batches_info: 'Batch Info',
      CreateBatch_Title: 'CREATE BATCH',
      EditBatch_Title: 'EDIT BATCH',
      CreateBatch_ButtonSave_caption: 'CREATE',
      DxBatches_dxColumn_BinsList: 'Content Bins',
      DxBatches_dxColumn_NameBatch: 'Batch Name',
      DxBatches_dxColumn_CreationDate: 'Creation Date',
      DxBatches_dxColumn_Code: 'Code',
      LeftPanel_subtitle_create_batches: 'Create Batch',
      LeftPanel_subtitle_batches: 'Consult Batches',
      LeftPanel_subtitle_create_bins: 'Create Bins',
      LeftPanel_subtitle_bins: 'Consult Bins',
      LeftPanel_subtitle_received_bins: 'Received bins list',
      LeftPanel_subtitle_reclassification: 'Reclassification',
      LeftPanel_subtitle_create_bare_roots: 'Create Bare Root',
      LeftPanel_subtitle_bare_roots: 'Consult Bare Roots',
      BareRootsForm_bare_root_info: 'Bare Root Data',
      BareRoots_create_navbar_title: 'CREATE BARE ROOT',
      BareRoots_edit_navbar_title: 'EDIT BARE ROOT',
      BareRoots_title: 'BARE ROOTS',
      BareRoots_content_form_level_0: 'Level 0',
      BareRoots_content_form_variety: 'Variety',
      BareRoots_content_form_variety_required: 'Variety required',
      BareRoots_content_form_class: 'Classification',
      BareRoots_content_form_class_required: 'Classification required',
      BareRoots_content_form_level_last: 'Level',
      BareRoots_content_form_category: 'Category',
      BareRoots_content_form_category_required: 'Category required',
      BareRoots_content_form_plants: 'Nº of Plants',
      BareRoots_content_form_plants_required: 'Nº of plantas must be greater then 0',
      BareRoots_content_form_note: 'Note',
      CreateBareRoot_ButtonSave_caption: 'Create',
      EditBareRoot_ButtonSave_caption: 'Save changes',
      Batches_Title: 'BATCHES',
      Bins_create_navbar_title: 'CREATE BIN',
      Bins_reclassify_navbar_title: 'RECLASSIFY BIN',
      Bins_edit_navbar_title: 'EDIT BIN',
      BinsForm_bins_info: 'Bins Data',
      DxBinsInfoForm_date_caption: 'Date',
      DxBinsInfoForm_name_caption: 'Name',
      DxBinsInfoForm_bins_location_caption: 'Farm',
      Bins_content_title: 'Bins Content',
      DxBinsContentGrid_code: 'Code',
      DxBinsContentGrid_parent_code: 'Parent Code',
      DxBinsContentGrid_location_level_0_caption: 'Level 0',
      DxBinsContentGrid_location_level_last_caption: 'Level',
      DxBinsContentGrid_variety_caption: 'Variety',
      DxBinsContentGrid_category_caption: 'Category',
      DxBinsContentGrid_classification_caption: 'Classification',
      DxBinsContentGrid_plants_count_caption: 'Nº of Plants',
      Bins_Title: 'BINS',
      Reclassification_Title: 'RECLASSIFICATION',
      DxBinsTable_State_Created: 'Created',
      DxBinsTable_State_Registered: 'Registered',
      DxBinsTable_State_Closed: 'Closed',
      DxBinsTable_State_Sent: 'Sent',
      DxBinsTable_State_Received: 'Received',
      DxBinsTable_State_ToReceive: 'To receive',
      DxBinsTable_dxColumn_Code: 'Code',
      DxBinsTable_dxColumn_Name: 'Nº Bins',
      DxBinsTable_dxColumn_Origin: 'Farm Origin',
      DxBinsTable_dxColumn_LotName: 'Nº Batch',
      DxBinsTable_dxColumn_Destination: 'Farm Destination',
      DxBinsTable_dxColumn_State: 'State',
      DxDetailTabBins_dxColumn_Variety: 'Variety',
      DxDetailTabBins_dxColumn_NumPlants: 'Nº Plants',
      DxDetailTabBins_dxColumn_Category: 'Category',
      DxDetailTabBins_dxColumn_Classification: 'Classification',
      DxDetailTabsBins_BinsData: 'Bin´s data',
      DxDetailTabsBins_Origin: 'Origin',
      DxDetailTabsBins_Destination: 'Destination',
      DxDetailTabBins_dxColumn_Code: 'Code',
      DxDetailTabBins_dxColumn_LastLevel: 'Level',
      DxBinsTable_ButtonText_Register: 'Register',
      DxBinsTable_ButtonText_Send: 'Send',
      DxBinsTable_ButtonText_Move: 'Move',
      DxBinsTable_ButtonText_ConfirmReception: 'Confirm Reception',
      DxBinsTable_ButtonText_Close: 'Close',
      DxSelectLevel0Location_modal_title: 'Select destination:',
      DxSelectLevel0Location_requiredRule_farm: 'This field is required',
      DxSelectLevel0Location_farm: 'Destination',
      DxBinsTable_button_send: 'Send',
      DxBinsTable_button_cancel: 'Cancel',
      CreateBin_ButtonSave_caption: 'CREATE',
      ReclassifyBin_ButtonSave_caption: 'RECLASSIFY',
      MoveBin_ButtonSave_caption: 'MOVE',
      EditBin_ButtonSave_caption: 'SAVE',
      EditBatch_ButtonSave_caption: 'SAVE',
      ButtonCancel_caption: 'CANCEL',
      DxBinsContentGridPlanning_bin: 'Bin',
      DxBinsContentGridPlanning_variety: 'Variety',
      DxBinsContentGridPlanning_category: 'Category',
      DxBinsContentGridPlanning_classification: 'Classification',
      DxBinsContentGridPlanning_content: 'Content',
      DxBinsContentGridPlanning_plants_count: 'Nº Plants',
      DxNursery_Title: 'Nursery',
      CreatePlanning_Duplicated_Nursery_Rows: 'You have duplicated one or more Nursery rows',
      Nursery_OnlyOneLastLevelMessage: '* CAUTION: Select only one last level',
      DetailTabs_nursery_title: 'Nursery',
      BatchesInfoForm_name: 'Name',
      DxProductWarehouse_edit_validation: 'Product is already sent',
      DxProductWarehouse_Product_Not_Completed: 'You can not edit this product: Product order is not completed',
      Bins_move_navbar_title: 'MOVE BINS CONTENT',
      LeftPanel_subtitle_monthlyPlanning: 'Annual Budget',
      AnnualBudgets_Title: 'ANNUAL BUDGET',
      DxAnnualBudgets_year_caption: 'Year',
      DxAnnualBudgets_version_caption: 'Version',
      DxAnnualBudgets_status_caption: 'Status',
      DxAnnualBudgets_createAnnualBudget_text: 'Create Annual Budget',
      CreateAnnualBudget_Title: 'CREATE ANNUAL BUDGET',
      AnnualBudget_version_validation: 'The field Version is required',
      AnnualBudget_year_validation: 'The field Year is required',
      DxAnnualBudgetForm_version_caption: 'Version',
      DxAnnualBudgetForm_year_caption: 'Year',
      PlanningComponent_monthlyPlanning: 'Annual Budget',
      CloneAnnualBudget_Title: 'CLONE ANNUAL BUDGET VERSION',
      AnnualBudget_catch_NAME_EXIST_case: 'Two versions cannot have the same name',
      AnnualBudget_catch_NOT_CLOSED_case: 'All versions must be closed',
      AnnualBudget_catch_VERSION_NOT_FOUND_case: 'Version not found',
      AnnualBudget_catch_BADGET_NOT_FOUND_case: 'Badget not found',
      AnnualBudget_catch_ERROR_BADGET_MANY_VERSIONS_case: 'Badget cannot have more than 3 versions',
      DxBins_dxColumn_Location: 'Location',
      LeftPanel_title_nursery: 'NURSERY',
      Rules_count_more_than_zero: 'The quantity has to be greater than 0.',
      LeftPanel_subtitle_pestsDiseases: 'Pests and diseases',
      DxPestsDiseasesDatabase_edit_hint: 'Edit',
      DxPestsDiseasesDatabase_column_type: 'Type',
      DxPestsDiseasesDatabase_column_name: 'Name',
      DxPestsDiseasesDatabase_column_scientific_name: 'Scientific name',
      DxPestsDiseasesDatabase_column_symthoms: 'Symthoms',
      DxPestsDiseasesDatabase_column_photos: 'Photos',
      DxPestsDiseasesDatabase_button_create_text: 'Create a new pest/disease',
      PestsDiseases_title: 'PESTS AND DISEASES',
      LeftPanel_subtitle_extremeWeatherCondicions: 'Extreme weather conditions',
      ExtremeWeatherCondicions_title: 'EXTREME WEATHER CONDITIONS',
      DxExtremeWeatherCondicionsDatabase_edit_hint: 'Edit',
      DxExtremeWeatherCondicionsDatabase_column_type: 'Type',
      DxExtremeWeatherCondicionsDatabase_column_name: 'Name',
      DxExtremeWeatherCondicionsDatabase_button_create_text: 'Create a new extreme weather conditions',
      LeftPanel_subtitle_phenologies: 'Phenologies',
      PhenologiesDatabase_title: 'PHENOLOGIES',
      DxPhenologiesDatabase_edit_hint: 'Edit',
      DxPhenologiesDatabase_column_photos: 'Photos',
      DxPhenologiesDatabase_column_code: 'Code',
      DxPhenologiesDatabase_column_phase: 'Phase',
      DxPhenologiesDatabase_column_description: 'Description',
      DxPhenologiesDatabase_button_create_text: 'Create new phenology',
      DxPlannReal_workersOut_caption: 'Workers out',
      LeftPanel_title_scouting: 'SCOUTING',
      LeftPanel_subtitle_monitoringPoints: 'Monitoring Points',
      MonitoringPoints_title: 'MONITORING POINTS',
      CreatePoint_Title: 'CREATE POINT',
      EditPoint_Title: 'EDIT POINT',
      DxMonitoringPoints_name: 'Name',
      DxMonitoringPoints_location: 'Location',
      DxMonitoringPoints_coordinates: 'Coordinates',
      DxMonitoringPoints_numberPlants: 'Nº Plants Monitoring',
      DxMonitoringPoints_variety: 'Variety',
      DxMonitoringPoints_type: 'Type',
      DxMonitoringPoints_yearPlanting: 'Year of Planting',
      DxMonitoringPoints_statusFormatted: 'Status',
      DxMonitoringPoints_note: 'Notes',
      DxMonitoringPoints_createPoint_button: 'Create new Point',
      Point_name_validation: 'Name is required',
      DxPointForm_name_caption: 'Name',
      DxPointForm_latitude_caption: 'Latitude',
      DxPointForm_longitude_caption: 'Longitude',
      DxPointForm_numberPlants_caption: 'Nº Plants Monitoring',
      DxPointForm_variety_caption: 'Variety',
      DxPointForm_yearPlanting_caption: 'Year of Planting',
      DxPointForm_note_caption: 'Note',
      DxPointForm_name_required: 'Name is required',
      DxPointForm_type_caption: 'Type',
      DxTreePlanning_onlyLevel1_location_already_selected: 'Location already selected',
      DxPointForm_location_caption: 'Farm',
      Point_type_validation: 'Type is required',
      Button_confirm_text: 'Confirm',
      DxMonitoringPoints_dialogDelete_title: 'Delete Monitoring Point',
      DxMonitoringPoints_dialogDelete_text: 'Are you sure to delete the Monitoring Point?',
      Point_date_validation: 'Year required',
      DxWarehouse_totalKm_caption: 'Total Km',
      DxWarehouse_totalHorometer_caption: 'Total Horometer',
      DxWarehouse_totalKm_validation: 'Km start cannot be higher than Km finish',
      DxWarehouse_totalHorometer_validation: 'Horometer start cannot be higher than Horometer finish',
      scs_banned: 'SCS banned',
      DxTimeWorked_location_caption: 'Location',
      DxPostWorkMachinaryDriverTable_km_validating: 'Km start cannot be higher than Km finish',
      DxPostWorkMachinaryDriverTable_horometer_validating: 'Horometer start cannot be higher than Horometer finish',
      DxWorkOrders_fuel_horometerDiff_caption: 'Operating hours',
      Monitoring_column_date: 'Date',
      Monitoring_point_type: 'Point type',
      Monitoring_point_description: 'Point description',
      Monitoring_point_location: 'Location',
      Monitoring_point_longitude: 'Longitude',
      Monitoring_point_latitude: 'Latitude',
      Monitoring_point_number_plants: 'Nº Plants',
      Monitoring_point_variety: 'Variety',
      Monitoring_point_planting_year: 'Planting year',
      Monitoring_point_note: 'Point notes',
      Monitoring_number: 'Number',
      Monitoring_plagues_and_diseases: 'Plagues/Diseases',
      Monitoring_state: 'State',
      Monitoring_phenological_phase: 'Phenological phase',
      Monitoring_comments: 'Comments',
      Monitoring_photos: 'Photos',
      LeftPanel_subtitle_monitoringPests: 'Pests',
      LeftPanel_subtitle_monitoringDiseases: 'Diseases',
      LeftPanel_subtitle_monitoringPhenologies: 'Phenologies',
      LeftPanel_subtitle_monitoringWeatherCondicions: 'Weather conditions',
      LeftPanel_subtitle_monitoringOthers: 'Others',
      Monitoring_pests_navbar_title: 'MONITORING - PESTS',
      Monitoring_diseases_navbar_title: 'MONITORING - DISEASES',
      Monitoring_phenologies_navbar_title: 'MONITORING - PHENOLOGIES',
      Monitoring_weather_condicions_navbar_title: 'MONITORING - WEATHER CONDITIONS',
      Monitoring_others_navbar_title: 'MONITORING - OTHERS',
      LeftPanel_subtitle_climateGrids: 'Climate Grids',
      ClimateGrids_navbar_title: 'CLIMATE GRIDS',
      ClimateGrids_DxWeatherHumidityGrid_blockTitle: 'HUMIDITY',
      ClimateGrids_DxWeatherPrecipitationGrid_blockTitle: 'PRECIPITATION',
      ClimateGrids_DxWeatherTemperatureGrid_blockTitle: 'TEMPERATURE',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Temperature: 'Avg Temperature',
      ClimateGrids_DxWeatherTemperatureGrid_max_Temperature: 'Max Temperature',
      ClimateGrids_DxWeatherTemperatureGrid_min_Temperature: 'Min Temperature',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Humidity: 'Avg Humidity',
      ClimateGrids_DxWeatherTemperatureGrid_max_Humidity: 'Max Humidity',
      ClimateGrids_DxWeatherTemperatureGrid_min_Humidity: 'Min Humidity',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Precipitation: 'Avg Precipitation',
      Monitoring_point_name: 'MONITORING POINT',
      Monitoring_point_variety_code: 'Variety Code',
      no_photos: 'There are no images to show',
      show: 'Show',
      LeftPanel_subtitle_Irrigation: 'Irrigation',
      IrrigationPage_navbar_title: 'IRRIGATION',
      UploadFile_error: 'The following error has occurred:',
      UploadFile_preloader: 'Uploading the file. This may take a little time depending on the size of the file',
      UploadFile_success: 'File uploaded successfully. We are processing it. It may take a while to see the changes',
      UploadFile_button: 'Upload file',
      UploadFile_empty: 'Please, select a file',
      DxIrrigationTable_dxColumn_from: 'From',
      DxIrrigationTable_dxColumn_to: 'To',
      DxIrrigationTable_dxColumn_location: 'Field',
      DxIrrigationTable_dxColumn_sector: 'Sector',
      DxIrrigationTable_dxColumn_time: 'Time (min)',
      DxIrrigationTable_dxColumn_waterVolume: 'Volume (m3)',
      DxIrrigationTable_dxColumn_f1: 'F1',
      DxIrrigationTable_dxColumn_f2: 'F2',
      DxIrrigationTable_dxColumn_f3: 'F3',
      DxIrrigationTable_dxColumn_f4: 'F4',
      DxIrrigationTable_dxColumn_f5: 'F5',
      DxIrrigationTable_dxColumn_f6: 'F6',
      DxIrrigationTable_dxColumn_f7: 'F7',
      DxIrrigationTable_dxColumn_f8: 'F8',
      DxIrrigationTable_dxColumn_avgPh: 'AVG PH',
      DxIrrigationTable_dxColumn_avgCe: 'AVG CE',
      DxIrrigationTable_dxColumn_realFlow: 'Real Flow (m3/h)',
      DxIrrigationTable_dxColumn_prevFlow: 'Prev. Flow (m3/h)',
      DxIrrigationTable_dxColumn_team: 'Team',
      DxIrrigationTable_dxColumn_well: 'Well',
      DxIrrigationTable_dxColumn_parcel: 'Parcel',
      Point_location_validation: 'Select only one location',
      Point_no_selected_location_validation: 'Select a valid location',
      CreateEmployee_EmployeeCodeDuplicated: 'Worker ID already used',
      CreateEmployee_EmployeeGenericError: 'An error has ocurred: ',
      DxLocationSelector_ParentLevel: 'Upper Level',
      company_not_found: 'Company not found',
      task_type_not_found: 'Task type not found',
      planner_not_found: 'Planner not found',
      task_not_found: 'Task not found',
      item_not_found: 'Item not found',
      farm_not_found: 'Farm not found',
      product_not_found: 'Product not found',
      product_not_in_prescription: 'Product is not in prescription',
      product_not_in_task: 'Product is not in task',
      product_stock_is_less: 'Product stock is less than specified',
      prescription_not_found: 'Prescription not found',
      badget_not_found: 'Badget not found',
      badget_version_not_found: 'Badget version not found',
      badget_version_task_not_found: 'Badget version task not found',
      variety_not_found: 'Variety not found',
      irrigation_task_not_found: 'Irrigation task not found',
      scouting_not_found: 'Scouting not found',
      scouting_point_not_found: 'Scouting Point not found',
      warehouse_log_not_found: 'Warehouse´s log not found',
      warehouse_not_found: 'Warehouse not found',
      cost_center_not_found: 'Cost center not found',
      badget_version_is_closed: 'Badget Version is closed',
      planner_not_in_planning_status: 'Planner not in planning status',
      task_location_exist_with_same_date: 'Task location exists with same date',
      task_not_referenced: 'Task not referencez',
      planner_not_referenced: 'Planner not referenced',
      task_deleted_correctly: 'Task deleted correctly',
      no_task_for_selected_date_404: 'No task for selected date',
      location_not_found: 'Location not found',
      worker_not_found: 'Worker not found',
      worker_already_exist_in_task: 'Worker already in this task',
      worker_already_exist_in_other_task: 'Worker alreayd in another task',
      worker_not_exist_in_task: 'Worker is not in this task',
      code_not_exist_in_request: 'Code does not exists in request',
      code_not_exist: 'Code doesn´t exists',
      outdated: 'Outdated',
      invalid_responsible: 'Invalid responsible',
      invalid_item_family: 'Invalid item family',
      Not_Found: 'Not found',
      ID_Must_be_especified: 'ID must be especified',
      Phenological_phase_not_found: 'Phenological phase not found',
      Plagues_and_diseases_not_found: 'Plagues and diseases not found',
      Scouting_point_required: 'Scouting Point required',
      CreatePlanning_DuplicatedEmployees: 'Duplicated workers: ',
      PlanningComponent_comments_caption: 'Comments',
      DxPostWorkEmployeeTable_dialogCreate_wrongWorker: 'There is a worker not selectable',
      DxDailyPlanning_creation_caption: 'Creation',
      DxDailyPlanning_CreationPlanned_caption: 'Planned',
      DxDailyPlanning_CreationManually_caption: 'Manually',
      DxWorkOrders_ExportTranslation_WorkerType: 'WORKER TYPE',
      DxWorkOrders_ExportTranslation_NurseryCode: 'NURSERY CODE',
      DxWorkOrders_ExportTranslation_NurseryVariety: 'NURSERY VARIETY',
      DxWorkOrders_ExportTranslation_NurseryCategory: 'NURSERY CATEGORY',
      DxWorkOrders_ExportTranslation_NurseryClassification: 'NURSERY CLASSIFICATION',
      DxWorkOrders_ExportTranslation_NurseryNumPlants: 'NURSERY Nº PLANTS',
      ExportDataToExcel_Button: 'Export All',
      DxDailyPlanning_CostVsReal_versionName: 'Version',
      DxProductWarehouse_Popup_Send_Title: 'Send:',
      DxProductWarehouse_ButtonText_ReSend: 'Resend',
      DxProductWarehouse_ButtonText_Adjust: 'Adjust',
      DxProductWarehouse_Alert_FieldsRequired: 'All the fields are required',
      DxProductWarehouse_Alert_CorrectResend: 'Product resent correctly',
      DxBinsContentGrid_PlantingYear: 'Planting Year',
      DxProducts_dxColumn_solution_total: 'Total Solution',
      DxListEmployee_group_caption: 'Group',
      DxListEmployee_shift_caption: 'Shift',
      DxWeatherGrid_date_time: 'Hour',
      Monitoring_point_code: 'Code',
      DxFormProduct_maxdosis: 'Maximum Allowed Dose',
      DxFormProduct_currentIn: 'Restricted entry interval',
      DxFormProduct_SCS_autorizado: 'SCS Authorized',
      DxFormProduct_EU_autorizado: 'EU Authorized',
      DxFormProduct_preHarvest: 'Preharvest Interval',
      DxProductsDataBase_currentIN: 'Hours',
      DxProductsDataBase_preHarverst: 'Days',
      DxMonthlyForm_jornada: 'workdays',
      DxMachinery_AttachmentInternalCode_caption: 'Attachment code',
      DxWorkOrders_ExportTranslation_Group: 'Group',
      DxWorkOrders_ExportTranslation_Shift: 'Shift',
      DxMonthlyPlanning_comments: 'Comments',
      DxMonthlyPlanning_goal_harvesting_caption: 'Total Kg',
      DxDailyPlanning_workers_performance_caption: 'Performance',
      DxMachinery_horometer_start_caption: 'Hourmeter start',
      DxMachinery_horometer_end_caption: 'Hourmeter finish',
      DxMachinery_horometer_total_caption: 'Hourmeter total',
      DxScouting_fullName: 'Fullname',
      LeftPanel_subtitle_territorialUnit: 'Territorial Units',
      LeftPanel_subtitle_territorialUnitcaps: 'TERRITORIAL UNITS',
      DxTerritorialUnit_area: 'Area',
      DxTerritorialUnit_code: 'Code',
      DxTerritorialUnit_farm: 'Farm',
      DxTerritorialUnit_id: 'ID',
      DxTerritorialUnit_name: 'Name',
      DxTerritorialUnit_pattern: 'Pattern',
      DxTerritorialUnit_planting_year: 'Planting year',
      DxTerritorialUnit_plants: 'Plants',
      DxTerritorialUnit_variety_code: 'Variety code',
      DxTerritorialUnit_variety_name: 'Variety name',
      DetailTabDrivers_sin_items: 'No data',
      DetailTabs_driver_title: 'Drivers',
      Stock_Title: 'Stock',
      LeftPanel_subtitle_stock: 'Stock',
      DxStockTable_dxColumn_Code: 'Code',
      DxStockTable_dxColumn_Description: 'Description',
      DxStockTable_dxColumn_Stock: 'Stock',
      DxPostWorkMachinaryDriverTable_km_or_horometer_validating: 'You must select a starting Km or Horometer',
      DxPostWorkEmployeeManualHarvesting_Kg: 'Kg Collected',
      DxProductWarehouse_column_productWarehouse_required: 'Quantity is required',
      DxWorkOrders_ExportTranslation_Variety: 'Variety',
      DxWorkOrders_ExportTranslation_Driver: 'Driver',
      DxDailyPlanning_export_activity_code: 'Activity Code',
      DxDailyPlanning_export_areaEfective: 'Area efective',
      DxDailyPlanning_export_Comments: 'Comments',
      DxDailyPlanning_export_TaskCode: 'Task code',
      DxDailyPlanning_export_variety: 'Variety',
      DxDailyPlanning_export_performance: 'Productivity',
      DxDailyPlanning_export_machinary_consumption: 'Machinery Consumption',
      DxDailyPlanning_export_machinary_consumption_type: 'Consumption Type',
      DxDailyPlanning_export_machinary_notes: 'Machinery Notes',
      DxDailyPlanning_export_machinary_performance: 'Machinery Performance',
      DxDailyPlanning_export_machinary_type: 'Machinery type',
      DxDailyPlanning_export_tools_consumption: 'Tool consumption',
      DxDailyPlanning_export_tools_consumption_type: 'Consumption type',
      DxDailyPlanning_export_tools_count: 'Quantity',
      DxDailyPlanning_export_tools_type: 'Tool type',
      DxDailyPlanning_export_products_code: 'Product Code',
      DxDailyPlanning_export_products_dilution_factor: 'Dilution Factor',
      DxDailyPlanning_export_products_dosis: 'Product Dose',
      DxDailyPlanning_export_products_dosis_total: 'Product total dose',
      DxDailyPlanning_export_products_name: 'Product Name',
      DxDailyPlanning_export_products_solution: 'Product Solution',
      DxDailyPlanning_export_products_solution_total: 'Product Total Solution',
      DxStockTable_dxColumn_Unit: 'Unit',
      DxTaskOrder_origen: 'Source',
      DxBareRootTable_dxColumn_CreationDate: 'Fecha de creación',
      DxBareRootTable_dxColumn_Name: 'Name',
      DxBareRootTable_dxColumn_Origin: 'Origin',
      DxBareRootTable_dxColumn_PlantYear: 'Planting Year',
      DxBareRootTable_dxColumn_Variety: 'Variety',
      DxBareRootTable_dxColumn_Category: 'Category',
      DxBareRootTable_dxColumn_ClassType: 'Class',
      DxBareRootTable_dxColumn_NumPlants: 'Nº Plants',
      DxBareRootTable_dxColumn_BinCode: 'Bin',
      DxBatchesInfoForm_Farm: 'Location',
      DxBatchesInfoForm_Farm_Rule: 'You must choose a location',
      DxBatchesInfoForm_Name_Rule: 'Name is required',
      DxBinsInfoForm_guide_number_caption: 'Nº Guía Despacho',
      DxBinsContentGrid_egress_caption: 'Egress',
      DxBpas_short_id: 'ID OT',
      DxDxBpas_Farm_caption: 'Farm',
      DxDxBpas_Working_Area_caption: 'Working area',
      DxToolsTable_Add_Change_typeTools: 'The tool is already selected',
      DxToolsTable_tools: 'Tool',
      error_401: 'The user or password is incorrect',
      TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED: 'Total kg collected must be greater than zero.',
      TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE: 'Machinery data is incomplete',
      TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO: 'When the units are "Cc/Hl", "Gr/Hl" you must select a total prescription other than 0.',
      error_403: 'You do not have permissions to perform this action. Please contact your administrator',
      DxToolsTable_Add_Change_error_count: 'Not enough tools',
      LeftPanel_subtitle_nurseryReport: 'Nursery',
      Nursery_navbar_title: 'NURSERY',
      DxTable_Date_caption: 'Date',
      DxTable_ut_caption: 'UT',
      DxTable_type_caption: 'Type',
      DxTable_variety_caption: 'Variety',
      DxTable_plantYear_caption: 'Plant year',
      DxTable_category_caption: 'Category',
      DxTable_amount_caption: 'Amount',
      DxTable_notes_caption: 'Notes',
      DxNurseryStockReport_typeReclassified_caption: 'Reclassified type',
      DxNurseryStockReport_pottedYear_caption: 'Potted year',
      DxNurseryStockReport_categoryReclassified_caption: 'Reclassified category',
      DxNurseryStockReport_finalDestination_caption: 'Final destination',
      DxNurseryReport_classification: 'Clasificación',
      DxNurseryReport_stock: 'Stock',
      DxNurseryReport_egresos: 'Expenses',
      DxNurseryEgresosReport_egresosType_caption: 'Expenses types',
      DxNurseryEgresosReport_bins_caption: 'Bins',
      DxNurseryEgresosReport_orm_caption: 'N Guides / ORM',
      DxNurseryEgresosReport_destiny_caption: 'Destiny',
      DxNurseryEgresosReport_state_caption: 'Shipping status',
      // CREATEC
      Register_inpConfirmEmail_name: 'Email Confirmation',
      Register_inpPhone: 'Phone',
      Register_inpCountry: 'Country',
      Register_inpComunity: 'Comunity',
      Register_inpCity: 'City',
      Register_inpProvince: 'Province',
      Register_inpPassword: 'Password',
      Register_inpConfirmPassword: 'Confirm Password',
      Register_inpInscriptionType: 'Inscription Type',
      UserRegister_Alert_Email: 'Email and Email confirmation must be the same',
      UserRegister_Alert_Password: 'Password and Password Confirmation must be the same',
      Register_Successful: 'Register Successful',
      EMAIL_ALREADY_REGISTERED: 'This email is already registered',
      DxTerritorialUnit_Add: 'Create New T.U.',
      TerritorialUnit_content_title: 'Territorial Unit´s Caracteristics',
      Territorial_Unit_Location_Select: 'Parent Level (If Necessary)',
      CreateTerritorialUnit_navbar_caption: 'Create Territorial Unit',
      EditTerritorialUnit_navbar_caption: 'Edit Territorial Unit',
      CreateTerritorialUnit_ButtonSave_caption: 'Save',
      EditTerritorialUnit_ButtonSave_caption: 'Update',
      createTerritorialUnit_Alert: 'All the Data fields must be filled',
      DxTerritorialUnitContentForm_Title: 'Territorial Unit Data:',
      DxTerritorialUnitContentForm_Name: 'Name',
      DxTerritorialUnitContentForm_Area: 'Area (Ha)',
      DxTerritorialUnitContentForm_Required_Name: 'Name is required',
      DxTerritorialUnitContentForm_Required_Area: 'Area is required',
      DxTerritorialUnitContentForm_Code: 'Code',
      DxTerritorialUnitContentForm_Required_Code: 'Code is required',
      ORGANIZATIVE: 'Organizative',
      AGRARIAN: 'Agrarian',
      DxTerritorialUnitContentForm_Type: 'Type',
      DxTerritorialUnitContentForm_Required_Type: 'Type is required',
      DxTerritorialUnit_Actions: 'Actions',
      CODE_IN_USE: 'Code Already in Use',
      DxTerritorialUnit_farther: 'Father',
      LeftPanel_subtitle_market: 'MARKET',
      DxMarketTab_product: 'Product',
      DxMarketTab_price: 'Price',
      DxMarketTab_amount: 'Amount',
      DxMarketTab_bulletin: 'Bulletin',
      DxMarketTab_date: 'Date',
      ton: 'Tons',
      sol_kg: 'Sol/Kg',
      Register_backText: 'Back',
      DxMarketTab_origin: 'Origin',
      PlanningComponent_sold_caption: 'Ammount',
      PlanningComponent_cost_caption: 'Cost',
      DxPostWorkIrrigation_Title: 'Register Applied Irrigation Dose',
      DxPostWorkIrrigation_Dose: 'Irrigation Dose',
      DxPostWorkIrrigation_Hour: 'Hour',
      ClimateGrids_DxWeatherTemperatureGrid_Precipitation: 'Precipitation',
      ClimateGrids_DxWeatherTemperatureGrid_Humidity: 'Humidity',
      ClimateGrids_DxWeatherTemperatureGrid_Temperature: 'Temperature',
      DxWeatherElementLinesGraph_hourly: 'Hourly Precipitation',
      DxWeatherElementLinesGraph_daily: 'Daily Precipitation',
      DxWeatherElementLinesGraph_monthly: 'Monthly Precipitation',
      monthly: 'Monthly',
      daily: 'Daily',
      hourly: 'Hourly',
      Precipitation: 'Precipitation',
      DxEvapoElementBarsGraph_DailyEvapo: 'Daily Evapotranspiration',
      Evaporation: 'Evapotranspiration',
      Humidity: 'Humidity',
      Temperature: 'Temperature',
      ClimateGrids_DxWeatherAcumulatedPrecipitationGrid_blockTitle: 'ACCUMULATED PRECIPITATION',
      DxWeatherAcumulatedPrecipitationGrid_daily: 'Daily',
      DxWeatherAcumulatedPrecipitationGrid_monthly: 'Monthly',
      ClimateGrids_DxWeatherETHOGrid_blockTitle: 'EVAPOTRANSPIRATION',
      DxWeatherEvapoTranspirationGrid_Evapo: 'Evapotranspiration',
      LeftPanel_title_costs: 'COSTS',
      DxCostTable_date: 'Date',
      DxCostTable_task: 'Activity',
      DxCostTable_cost: 'Cost',
      DxSoldTable_cost: 'Entry',
      Costs_TotalSold: 'Total Sold',
      Costs_TotalCost: 'Total Cost',
      Costs_Balance: 'Balance',
      DxSoldTable_Title: 'ENTRIES',
      DxCostTable_Title: 'COSTS',
      INVALID_USER: 'No active account found with the given credentials',
      Register_sms: 'Receive SMS',
      EmployeeComponent_sms_caption: 'Receive SMS',
      EmployeeComponent_phone_caption: 'Phone (+51)',
      DxEmployeeForm_phone_require: 'Phone is required',
      CreateEmployee_ValidationPhone: 'Phone is required',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
      DxWeatherStatus_WeekDay_caption: 'WeekDay',
      DxWeatherStatus_Temperature_caption: 'Temperature',
      DxWeatherStatus_Precipitation_caption: 'Precipitation',
      DxWeatherStatus_Humidity_caption: 'Humidity',
      CreatePlanning_ValidationPrescriptionQtyProducts: 'All prescription products must have a used quantity and a collected quantity.',
      createTerritorialUnit_Alert_Organizative: 'A location without parent must be Organizative',
      btnFree: 'Free',
      btnPremium: 'Premium',
      Register_cultive: 'Cultive',
      download_form: 'Download Form',
    },
  },
};
export default dictionary;
