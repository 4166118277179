<template>
  <div>
    <DxForm
      id="form"
    >
      <DxGroupItem
        :col-count="2"
        :caption="$t('DxTerritorialUnitContentForm_Title')"
      >
        <!-- NAME / Area -->
        <DxSimpleItem
          :data-field="$t('DxTerritorialUnitContentForm_Name')"
          :editor-options="{
            onValueChanged: changeName,
            value: actualName,
          }"
          editor-type="dxTextBox"
          name="Name"
        >
          <DxRequiredRule :message="$t('DxTerritorialUnitContentForm_Required_Name')" />
        </DxSimpleItem>

        <DxSimpleItem
          :data-field="$t('DxTerritorialUnitContentForm_Area')"
          :editor-options="{
            onValueChanged: changeArea,
            value: actualArea,
            disabled: disableArea,
          }"
          editor-type="dxNumberBox"
          name="Area"
        >
          <DxRequiredRule :message="$t('DxTerritorialUnitContentForm_Required_Area')" />
        </DxSimpleItem>

        <!-- Code / City -->
        <DxSimpleItem
          :data-field="$t('DxTerritorialUnitContentForm_Code')"
          :editor-options="{
            onValueChanged: changeCode,
            value: actualCode,
          }"
          editor-type="dxTextBox"
          name="Code"
        >
          <DxRequiredRule :message="$t('DxTerritorialUnitContentForm_Required_Code')" />
        </DxSimpleItem>

        <DxSimpleItem
          :data-field="$t('Register_inpCity')"
          :editor-options="{
            items: citiesToShow,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            onValueChanged: changeCity,
            value: actualCity,
          }"
          editor-type="dxSelectBox"
          name="cityName"
        >
          <DxRequiredRule :message="$t('Register_inpCity')" />
        </DxSimpleItem>

        <DxSimpleItem
          :data-field="$t('DxTerritorialUnitContentForm_Type')"
          :editor-options="{
            items: typesToDisplay,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            onValueChanged: changeType,
            value: actualType,
          }"
          editor-type="dxSelectBox"
          name="Type"
        >
          <DxRequiredRule :message="$t('DxTerritorialUnitContentForm_Required_Type')" />
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TerritorialUnitContentForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      actualName: '',
      actualArea: 0,
      actualCode: '',
      actualCity: '',
      actualType: '',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      cities: [],
      citiesToShow: [],
      types: ['ORGANIZATIVE', 'AGRARIAN'],
      typesToDisplay: [],
      disableArea: false,
    };
  },
  computed: {
    ...mapGetters('PlanningManager', ['newUTArea', 'newUTName', 'newUTCode', 'newUTCodeCity', 'newUTType']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    try {
      const xhr = await this.fetchGetCities();
      this.cities = JSON.parse(xhr.response);
      this.citiesToShow = JSON.parse(xhr.response).map(
        (city) => city.name,
      );
      this.types.forEach((element) => {
        this.typesToDisplay.push(this.$t(element));
      });
      if (this.isEditing) {
        if (this.newUTArea !== '') this.actualArea = this.newUTArea;
        this.actualName = this.newUTName;
        this.actualCode = this.newUTCode;
        if (this.cities.filter((city) => city.code === this.newUTCodeCity).length > 0) {
          this.actualCity = this.cities.filter((city) => city.code === this.newUTCodeCity)[0].name;
        }
        this.actualType = this.$t(this.newUTType);
        if (this.actualType === this.$t('ORGANIZATIVE')) {
          this.disableArea = true;
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    }
  },
  methods: {
    changeArea(e) {
      this.actualArea = e.value;
      this.setNewUTArea(e.value);
    },
    changeName(e) {
      this.actualName = e.value;
      this.setNewUTName(e.value);
    },
    changeCode(e) {
      this.actualCode = e.value;
      this.setNewUTCode(e.value);
    },
    changeCity(e) {
      this.actualCity = e.value;
      const codeCity = this.cities.filter((city) => city.name === e.value);
      this.setNewUTCodeCity(codeCity[0].code);
    },
    changeType(e) {
      this.actualType = e.value;
      const index = this.typesToDisplay.indexOf(e.value);
      this.setNewUTType(this.types[index]);
      if (e.value === this.$t('ORGANIZATIVE')) {
        this.actualArea = 0;
        this.setNewUTArea(0);
        this.disableArea = true;
      } else {
        this.disableArea = false;
      }
    },
    ...mapActions('PlanningManager', ['setNewUTArea', 'setNewUTName', 'setNewUTCode', 'setNewUTCodeCity', 'setNewUTType']),
    ...mapActions('authentication', ['fetchGetCities']),
  },
};
</script>

<style>

</style>
