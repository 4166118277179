<template>
  <f7-page>
    <navbar :text="$t('PestsDiseases_title')" />
    <DxPestsDiseasesDatabase />
  </f7-page>
</template>

<script>

import DxPestsDiseasesDatabase from '../../components/scouting/DxPestsDiseasesDatabase.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxPestsDiseasesDatabase,
    navbar,
  },
};
</script>

<style>

</style>
