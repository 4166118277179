<template>
  <f7-page>
    <navbar :text="$t('DxTools_Title')" />
    <DxToolsDatabase />
  </f7-page>
</template>

<script>

import DxToolsDatabase from '../../components/dataTools/DxToolsDatabase.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxToolsDatabase,
    navbar,
  },
};
</script>

<style>

</style>
