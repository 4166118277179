<template>
  <f7-block class="no-padding">
    <DxForm
      id="form"
      :col-count="1"
    >
      <DxSimpleItem
        :data-field="$t('DxPostWorkEmployeeManualHarvesting_Kg')"
        editor-type="dxNumberBox"
        :editor-options="{
          value: valueManualHarvesting,
          mode: 'number',
          width: 200,
          onValueChanged: setKgSelected,
          disabled: disableField,
        }"
      />
    </DxForm>
  </f7-block>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';

export default {
  name: 'DxLocationSelector',
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    startValue: { type: Number, default: 0 },
    disableField: { type: Boolean, default: false },
  },
  data() {
    return {
      valueManualHarvesting: 0,
    };
  },
  computed: {
    ...mapGetters('PlanningManager', ['actualWorkOrder']),
  },
  async beforeMount() {
    if (this.actualWorkOrder && this.actualWorkOrder.performance) {
      this.valueManualHarvesting = parseFloat(this.actualWorkOrder.performance.total).toFixed(2);
      this.setKgCollected(this.valueManualHarvesting);
    } else {
      this.valueManualHarvesting = this.startValue;
      this.setKgCollected(this.valueManualHarvesting);
    }
  },
  methods: {
    setKgSelected(e) {
      this.valueManualHarvesting = e.value;
      this.setKgCollected(e.value);
    },
    ...mapActions('PlanningManager', ['setKgCollected']),
  },
};
</script>

<style lang="scss">
</style>
