<template>
  <f7-page
    class="main-formpage"
    @page:beforein="fetchFormData"
    @page:beforeout="resetTerritorialUnitStates"
  >
    <Navbar
      :text="currentTitle"
    />
    <div class="content-devx main-dx-binsinfoform no-shadow">
      <f7-row v-if="loaded">
        <f7-col
          width="25"
          medium="30"
        >
          <DxTreeView
            :selection-mode="'single'"
            :locations-tree="locationTreeByName"
            :title="'Territorial_Unit_Location_Select'"
          />
        </f7-col>
        <f7-col
          width="75"
          medium="70"
        >
          <DxTerritorialUnitContentForm :is-editing="isEditing" />
        </f7-col>
      </f7-row>
    </div>
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right button button-raised text-color-grey"
        type="cancel"
        styling-mode="contained"
        name="ButtonCancel_caption"
        @click="navigateTo"
      >
        {{ $t('ButtonCancel_caption') }}
      </f7-button>

      <f7-button
        class="dx-btn-success button button-raised"
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        :disabled="disableButton"
        @click="saveAction"
      >
        {{ textButtonSave }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import Navbar from '../../components/NavBar.vue';
import DxTreeView from '../../components/DxTreeView.vue';
import DxTerritorialUnitContentForm from '../../components/territorialUnit/DxTerritorialUnitContentForm.vue';
import Api from '../../services/Api';

export default {
  name: 'BareRootsFormPage',
  components: {
    DxTreeView,
    Navbar,
    DxTerritorialUnitContentForm,
  },

  data() {
    return {
      loaded: false,
      bareRootContent: {},
      locationIdSelected: [],
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['locationTreeByName']),
    ...mapGetters('PlanningManager', ['newUTArea', 'newUTName', 'actualLocationsSelected', 'newUTCode', 'newUTCodeCity', 'newUTType', 'editUTId']),
    currentURL() {
      return this.$f7route.url;
    },
    currentTitle() {
      let currentTitle = '';
      switch (this.currentURL) {
        case '/createTerritorialUnit/':
          currentTitle = this.$t('CreateTerritorialUnit_navbar_caption');
          break;
        case '/editTerritorialUnit/':
          currentTitle = this.$t('EditTerritorialUnit_navbar_caption');
          break;
        default:
          currentTitle = this.$t('CreateTerritorialUnit_navbar_caption');
          break;
      }
      return currentTitle;
    },
    isEditing() {
      return this.currentURL === '/editTerritorialUnit/';
    },
    saveAction() {
      return this.createTerritorialUnit;
    },
    disableButton() {
      if (this.newUTName !== ''
          && this.newUTCode !== ''
          && this.newUTCodeCity !== ''
          && this.newUTType !== '') {
        return false;
      }
      return true;
    },
    textButtonSave() {
      let currentTitle = '';
      switch (this.currentURL) {
        case '/createTerritorialUnit/':
          currentTitle = this.$t('CreateTerritorialUnit_ButtonSave_caption');
          break;
        case '/editTerritorialUnit/':
          currentTitle = this.$t('EditTerritorialUnit_ButtonSave_caption');
          break;
        default:
          currentTitle = this.$t('CreateTerritorialUnit_ButtonSave_caption');
          break;
      }
      return currentTitle;
    },
  },
  beforeDestroy() {
    this.resetUTDataStates();
  },
  methods: {
    ...mapActions('PlanningManager', ['setActualLocationsSelected']),
    ...mapActions('authentication', ['setActualMenuSelected']),
    showDialogAlertByError(error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    },
    async fetchFormData() {
      this.$f7.preloader.show();
      try {
        await this.fetchLocationTree();
      } catch (error) {
        this.showDialogAlertByError(error);
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    async createTerritorialUnit() {
      this.$f7.preloader.show();
      try {
        if (this.newUTName === ''
          || this.newUTCode === ''
          || this.newUTCodeCity === ''
          || this.newUTType === ''
        ) {
          this.$f7.dialog.alert(this.$t('createTerritorialUnit_Alert'));
        } else if (this.actualLocationsSelected.length === 0 && this.newUTType.toUpperCase() !== 'ORGANIZATIVE') {
          this.$f7.dialog.alert(this.$t('createTerritorialUnit_Alert_Organizative'));
        } else {
          let idParent = null;
          if (this.actualLocationsSelected.length > 0 && this.actualLocationsSelected[0]) {
            idParent = this.actualLocationsSelected[0].bd_id;
          }
          const newLocation = {
            name: this.newUTName,
            area: this.newUTArea,
            code: this.newUTCode,
            parent: idParent,
            city: this.newUTCodeCity,
            type: this.newUTType.toUpperCase(),
            id: this.editUTId,
          };
          let xhr;
          if (this.isEditing) {
            xhr = await Api.editTerritorialUnit(newLocation);
          } else {
            xhr = await Api.createTerritorialUnit(newLocation);
          }
          if (xhr.status === 409) {
            this.showDialogAlertByError(xhr.responseText);
          } else {
            this.navigateTo();
          }
        }
      } catch (error) {
        console.log('error', error);
        this.showDialogAlertByError(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    resetTerritorialUnitStates() {
    //   this.resetStates();
    },
    navigateTo() {
      this.$f7router.navigate('/territorialUnit/', { reloadCurrent: true });
    },
    ...mapActions('greenhouse', ['fetchLocationTree']),
    ...mapActions('PlanningManager', ['resetUTDataStates']),
  },
};
</script>

<style lang="scss" scoped>
.ha-card {
    padding: 20px;
    margin-top: 30px;
}
</style>
