<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-nursery-egresos"
  >
    <DxDataGrid
      id="egresos-grid"
      :data-source="egresosFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxNurseryEgresosReport"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxTable_Date_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="ut"
        :caption="$t('DxTable_ut_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="type"
        :caption="$t('DxTable_type_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('DxTable_variety_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="plantYear"
        :caption="$t('DxTable_plantYear_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="category"
        :caption="$t('DxTable_category_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="amount"
        :caption="$t('DxTable_amount_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="egresosType"
        :caption="$t('DxNurseryEgresosReport_egresosType_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="bins"
        :caption="$t('DxNurseryEgresosReport_bins_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="orm"
        :caption="$t('DxNurseryEgresosReport_orm_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="destiny"
        :caption="$t('DxNurseryEgresosReport_destiny_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="state"
        :caption="$t('DxNurseryEgresosReport_state_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="notes"
        :caption="$t('DxTable_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import { mapGetters } from 'vuex';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';

export default {
  name: 'DxNurseryEgresosReport',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
  },
  props: {
    egresos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loaded: false,
      egresosFormated: [],
      keyName: 'DxNurseryEgresosReport',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['getLocationNameLevelTwoByChildId']),
  },
  async beforeMount() {
    try {
      for (const egreso of this.egresos) {
        const egresoFormated = {
          date: egreso.date,
          ut: this.getLocationNameLevelTwoByChildId(egreso.origin),
          type: egreso.root_category,
          variety: egreso.variety,
          plantYear: egreso.plant_year,
          category: egreso.class_type,
          amount: egreso.amount,
          egresosType: egreso.egreso,
          bins: egreso.bins,
          orm: egreso.guide_number,
          destiny: this.getLocationNameLevelTwoByChildId(egreso.destination),
          state: egreso.status,
          notes: egreso.notes,
        };
        this.egresosFormated.push(egresoFormated);
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    getTotal(init, end) {
      return end - init;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('egresos');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `reportingNurseryEgresos${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
          });
      });
      e.cancel = true;
    },
  },
};
</script>
