<template>
  <f7-block class="no-padding">
    <DxSelectBox
      id="custom-templates"
      :data-source="farmers"
      :value="farmerSelected"
      display-expr="name"
      value-expr="_id"
      placeholder="Selecciona"
      :show-clear-button="true"
      @value-changed="changeFarmer"
    />
  </f7-block>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  name: 'SelectLanguage',
  components: {
    DxSelectBox,
  },
  props: {
    selectedUser: { type: String, default: null },
  },
  data() {
    return {
      farmerSelected: '',
      farmers: [
        {
          _id: '6179a569096824b95d80adb5',
          name: 'AQUILINA CARDENAS HUALLPA',
        },
        {
          _id: '61854589940e3f05e2edc485',
          name: 'Roberto Angles Ochoa',
        },
        {
          _id: '6145dec077c95c735b080059',
          name: 'FRUCTUOSO OCAMPO PEDRAZA',
        },
        {
          _id: '61874948dd71914200ccc8e4',
          name: 'VICTOR YARAHUAMAN HOLGADO',
        },
        {
          _id: '6315fd8443691a8875f398a9',
          name: 'JOSÉ PÉREZ',
        },
        {
          _id: '618c38208e847b4a6477e3b6',
          name: 'NELSON VALENCIA HUARI',
        },
        {
          _id: '61899c23f52f3cd141316af9',
          name: 'VICTOR M CANAL GIRALDO',
        },
        {
          _id: '617b15d7974ef83ff9be7af9',
          name: 'BAUTISTA APAZA CARBAJAL',
        },
        {
          _id: '61464e9d77c95c735b0806d7',
          name: 'CLEOFE ORCCON HUAYUPA',
        },
        {
          _id: '613fe2a03a096b43fc30dfd8',
          name: 'pedro huillca huallpartupa',
        },
        {
          _id: '6146682d77c95c735b0808d6',
          name: 'LEONIDAS TINCO HUARACCA',
        },
        {
          _id: '617836cbaf486479280af656',
          name: 'aurelia chavez vda de warthon',
        },
        {
          _id: '6143ded31399a57ce6fc2af9',
          name: 'ISAAC CONDORI QUISPE',
        },
        {
          _id: '61411ae0787246d18ed20764',
          name: 'MODESTO FERRO HUALLPAYUNCA',
        },
        {
          _id: '614530ae17e8bac1c6186320',
          name: 'NESTOR CONDOR TARAPAQUI',
        },
        {
          _id: '6145130d17e8bac1c6186208',
          name: 'CLORINDA MONTESINOS CUSIYUPANQUI',
        },
      ],
    };
  },
  beforeMount() {
    if (this.selectedUser) {
      this.farmerSelected = this.selectedUser;
    }
  },
  methods: {
    changeFarmer(e) {
      if (e.value !== this.selectedUser) {
        this.$emit('changeFarmer', e.value);
        this.farmerSelected = e.value;
      }
    },
  },
};
</script>
