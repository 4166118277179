<template>
  <f7-page>
    <navbar :text="$t('IrrigationPage_navbar_title')" />
    <DxUploadFile
      url-to-send="irrigation/file/up"
    />
    <DxIrrigationTable />
  </f7-page>
</template>

<script>

import DxUploadFile from '../../components/reporting/DxUploadFile.vue';
import DxIrrigationTable from '../../components/reporting/DxIrrigationTable.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxUploadFile,
    DxIrrigationTable,
    navbar,
  },
  data() {
    return {
    };
  },
};
</script>

<style>

</style>
