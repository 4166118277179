<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-point-form"
  >
    <div id="form-container">
      <f7-row>
        <f7-col
          width="25"
          medium="30"
        >
          <DxTreePlanning
            :locations-tree="locationFormatted"
            :planning="{locations: [location]}"
            :show-grouped="false"
            :recursive="false"
            :monitoring-points="true"
          />
        </f7-col>
        <f7-col
          width="75"
          medium="70"
        >
          <DxForm
            id="pointForm"
          >
            <DxGroupItem :col-count="6">
              <DxGroupItem
                css-class="content-combo"
                :col-span="3"
              >
                <DxSimpleItem
                  name="name"
                  :data-field="$t('DxPointForm_name_caption')"
                  :editor-options="{
                    value: name,
                    onValueChanged: onChangeName,
                  }"
                >
                  <DxRequiredRule :message="$t('DxPointForm_name_required')" />
                </DxSimpleItem>

                <DxSimpleItem
                  name="latitude"
                  :data-field="$t('DxPointForm_latitude_caption')"
                  :editor-options="{
                    value: latitude,
                    onValueChanged: onChangeLatitude,
                  }"
                />
                <DxSimpleItem
                  name="longitude"
                  :data-field="$t('DxPointForm_longitude_caption')"
                  :editor-options="{
                    value: longitude,
                    onValueChanged: onChangeLongitude,
                  }"
                />
                <DxSimpleItem
                  name="numberPlants"
                  :data-field="$t('DxPointForm_numberPlants_caption')"
                  editor-type="dxNumberBox"
                  :editor-options="{
                    mode: 'number',
                    min: '0',
                    value: numberPlants,
                    onValueChanged: onChangeNumberPlants,
                  }"
                />
              </DxGroupItem>
              <DxGroupItem
                :col-span="3"
                css-class="content-combo"
              >
                <DxSimpleItem
                  name="variety"
                  :data-field="$t('DxPointForm_variety_caption')"
                  editor-type="dxSelectBox"
                  :editor-options="{
                    items: varietyAvailables,
                    value: variety,
                    onValueChanged: onChangeVariety,
                    searchEnabled: true,
                    searchTimeout: searchTimeoutOption,
                    minSearchLength: minSearchLengthOption,
                    showDataBeforeSearch: showDataBeforeSearchOption,
                    displayExpr:'name',
                    valueExpr:'name',
                  }"
                />
                <DxSimpleItem
                  name="type"
                  :data-field="$t('DxPointForm_type_caption')"
                  editor-type="dxSelectBox"
                  :editor-options="{
                    items: typeAvailables,
                    value: type,
                    onValueChanged: onChangeType,
                    searchEnabled: true,
                    searchTimeout: searchTimeoutOption,
                    minSearchLength: minSearchLengthOption,
                    showDataBeforeSearch: showDataBeforeSearchOption,
                  }"
                >
                  <DxRequiredRule :message="$t('Point_type_validation')" />
                </DxSimpleItem>
                <DxSimpleItem
                  name="yearPlanting"
                  :data-field="$t('DxPointForm_yearPlanting_caption')"
                  editor-type="dxDateBox"
                  :editor-options="{
                    value: yearPlanting,
                    onValueChanged: onChangeYearPlanting,
                    maxZoomLevel:'decade',
                    minZoomLevel:'decade',
                    zoomLevel: 'decade',
                    displayFormat: 'yyyy',
                  }"
                />
                <DxSimpleItem
                  name="note"
                  :data-field="$t('DxPointForm_note_caption')"
                  editor-type="dxTextArea"
                  :editor-options="{
                    value: note,
                    onValueChanged: onChangeNote,
                    maxLength: 110,
                  }"
                />
              </DxGroupItem>
            </DxGroupItem>
          </DxForm>
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import DxTreePlanning from '../DxTreePlanning.vue';

export default {
  name: 'DxPointForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxTreePlanning,
  },
  props: {
    editForm: { type: Boolean, default: false },
  },
  data() {
    return {
      // UTILITIES
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      loaded: false,
      locationFormatted: [],
      varietyAvailables: [],
      typeAvailables: [],
      // FORM VALUES
      name: '',
      latitude: 0,
      longitude: 0,
      numberPlants: 0,
      location: '',
      variety: '',
      type: '',
      yearPlanting: moment().format('yyyy'),
      note: '',
    };
  },
  computed: {
    ...mapState('Scouting', ['point']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    overlay.baseZIndex(9999);
    try {
      this.locationFormatted = await this.loadLocationsTree();
      await this.getTypes();
      this.varietyAvailables = await this.getVariety();
      if (this.editForm) {
        this.setValuesEditingPoint();
      } else {
        this.setValuesNewPoint();
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // CREACIÓN DE POINT - SETEO VALORES VACIOS POR DEFECTO
    setValuesNewPoint() {
      this.setName(this.name);
      this.setLatitude(this.latitude);
      this.setLongitude(this.longitude);
      this.setNumberPlants(this.numberPlants);
      this.setLocation(this.location);
      this.setVariety(this.variety);
      this.setType(this.type);
      this.setYearPlanting(this.yearPlanting);
      this.setNote(this.note);
    },
    // EDICIÓN DE POINT - SETEO VALORES QUE YA TENIA PREVIAMENTE
    setValuesEditingPoint() {
      this.name = this.point.name;
      this.latitude = this.point.latitude;
      this.longitude = this.point.longitude;
      this.numberPlants = this.point.numberPlants;
      this.location = this.point.locationId;
      this.variety = this.point.variety;
      this.type = this.point.type;
      if (this.point.yearPlanting !== null) {
        this.yearPlanting = moment(this.point.yearPlanting.toString()).format('yyyy');
      } else {
        this.yearPlanting = '';
      }
      this.note = this.point.note;
    },
    // EVENTOS ONCHANGE DEL FORMULARIO
    onChangeName(e) {
      this.name = e.value;
      this.setName(e.value);
    },
    onChangeLatitude(e) {
      this.latitude = e.value;
      this.setLatitude(e.value);
    },
    onChangeLongitude(e) {
      this.longitude = e.value;
      this.setLongitude(e.value);
    },
    onChangeNumberPlants(e) {
      this.numberPlants = e.value;
      this.setNumberPlants(e.value);
    },
    onChangeLocation(e) {
      this.location = e.value;
      this.setLocation(e.value);
    },
    onChangeVariety(e) {
      this.variety = e.value;
      this.setVariety(e.value);
    },
    onChangeType(e) {
      this.type = e.value;
      this.setType(e.value);
    },
    onChangeYearPlanting(e) {
      this.yearPlanting = e.value;
      this.setYearPlanting(moment(e.value).format('yyyy'));
    },
    onChangeNote(e) {
      this.note = e.value;
      this.setNote(e.value);
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async getVariety() {
      const varietyFormatted = [];
      const xhr = await Api.getAllVariety();
      for (const variety of JSON.parse(xhr.response)) {
        variety.nameAux = variety.name;
        varietyFormatted.push(variety);
      }
      return varietyFormatted;
    },
    async getTypes() {
      const xhr = await Api.getItemsType();
      for (const itemType of JSON.parse(xhr.response)) {
        if (itemType.family === 'SCOUTING_TYPE') {
          this.typeAvailables = itemType.values;
        }
      }
    },
    ...mapActions('Scouting', ['setName', 'setLatitude', 'setLongitude', 'setNumberPlants', 'setVariety', 'setType', 'setYearPlanting', 'setNote', 'setLocation']),
  },
};
</script>

<style lang="scss">

  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
</style>
