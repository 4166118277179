<template>
  <f7-app :params="f7params">
    <leftPanel
      v-if="canAccessApp"
      :elements="opcionesMenu"
      :version="version"
    />
    <f7-view
      v-if="!canAccessApp"
      main
      resizable
      class="safe-areas"
      url="/"
    >
      <login v-if="loginNeeded && !requestNewPasssword" />
    </f7-view>
    <!-- <login v-if="loginNeeded && !requestNewPasssword" /> -->
    <recoveryPassword
      v-if="loginNeeded && requestNewPasssword"
      :opened="requestNewPasssword"
    />
    <f7-view
      v-if="canAccessApp"
      main
      resizable
      class="safe-areas"
      url="/"
    >
      <f7-popup
        class="demo-popup"
        :opened="showMessage"
        @popup:closed="closePopup"
      >
        <f7-page>
          <f7-navbar
            name="msgEmail"
            :title="$t('Login_msgEmail_title')"
          >
            <f7-nav-right>
              <f7-link
                popup-close
              >
                {{ $t("Login_msgEmail_btnClose") }}
              </f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <p>{{ $t("Login_msgEmail_content") }}</p>
          </f7-block>
        </f7-page>
      </f7-popup>
    </f7-view>
  </f7-app>
</template>
<script>

import { Device } from 'framework7/framework7-lite.esm.bundle';
import { mapActions, mapState, mapMutations } from 'vuex';
import { APP_VERSION } from '../js/constants';
import cordovaApp from '../js/cordova-app';
import store from '../store/index';
import routes from '../js/routes';
import leftPanel from './LeftPanel.vue';
import opcionesMenu from '../json/menuList.json';
import Api from '../services/Api';
import Nfc from '../services/Nfc';
import login from '../pages/accounts/loginPage.vue';
import recoveryPassword from './accounts/RecoveryPassword.vue';
import EventBus from '../js/event-bus';

export default {
  components: {
    leftPanel, login, recoveryPassword,
  },
  store,
  data() {
    return {
      version: APP_VERSION,
      opcionesMenu,
      showToDefault: true,
      // Framework7 Parameters
      f7params: {
        id: 'es.hispatec.createc', // App bundle ID
        name: 'Createc', // App name
        theme: 'auto', // Automatic theme detection

        // App routes
        routes,
        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },

        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          androidTextColor: 'white',
          androidBackgroundColor: '#000000',
          iosTextColor: 'white',
          iosBackgroundColor: '#000000',
        },
      },
      // Login screen data
      username: '',
      password: '',
    };
  },
  computed: {
    loginNeeded() {
      return this.loggedUser == null;
    },
    canAccessApp() {
      return this.loggedUser != null;
    },
    requestNewPasssword() {
      return (this.loggedUser == null && this.wantToRecoveryPass === true);
    },
    showMessage() {
      return this.messageChangePassword;
    },
    ...mapState('authentication', [
      'activationCode',
      'loggedUser',
      'appConfig',
      'wantToRemember',
      'wantToRecoveryPass',
      'changePassword',
      'messageChangePassword',
    ]),
  },

  mounted() {
    const self = this;
    this.$f7ready((f7) => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }

      if (self.loggedUser) {
        if (self.wantToRemember) {
          Api.username = self.loggedUser.username;
          Api.token = self.loggedUser.token;
        } else {
          self.logout();
        }
      }

      this.setupScanner();
    });
  },
  methods: {
    setupScanner() {
      function onTagDetected(nfcEvent) {
        const { tag } = nfcEvent;
        let tagDec = cordovaApp.nfc.bytesToHexString(tag.id);
        tagDec = tagDec.match(/.{1,2}/g).reverse();
        tagDec = tagDec.join('').toUpperCase();
        EventBus.$emit('nfc-scan', tagDec);
      }

      function onAddListenerSuccess() {
      }

      function onAddListenerError(e) {
        console.debug(`error: ${e}`);
      }

      Nfc.initScanner(onTagDetected, onAddListenerSuccess, onAddListenerError);
    },

    closePopup() {
      this.setMessageChangePassword();
    },

    ...mapActions('authentication', ['activateApp', 'deactivateApp', 'logout']),
    ...mapActions('AccessControl', ['scan']),
    ...mapMutations('authentication', ['setMessageChangePassword']),
  },
};
</script>
