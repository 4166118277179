<template>
  <f7-page>
    <navbar :text="$t('Monitoring_diseases_navbar_title')" />
    <DxMonitoringDiseases :key-name="'monitoringDiseases'" />
  </f7-page>
</template>

<script>
import DxMonitoringDiseases from '../../components/scouting/DxMonitoringDiseases.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'MonitoringDiseases',
  components: {
    DxMonitoringDiseases,
    navbar,
  },
};
</script>
