<template>
  <f7-page>
    <navbar :text="$t('Batches_Title')" />
    <DxBatchesTable />
  </f7-page>
</template>

<script>
// import { mapState, mapActions } from 'vuex';
import DxBatchesTable from '../../components/greenhouse/DxBatchesTable.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'CreateBatch',
  components: {
    navbar,
    DxBatchesTable,
  },
  computed: {
  },
  methods: {
  },
};
</script>
