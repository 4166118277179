<template>
  <f7-page>
    <div class="CostsPage_content">
      <navbar :text="$t('Costs_title')" />
      <f7-row class="content_calendar">
        <f7-col>
          <CalendarInitEndDate
            :key-name="keyName"
            :dates="datesDisplayed"
            :start-date="startingDate"
            :end-date="endingDate"
          />
        </f7-col>
      </f7-row>
      <DxForm style="margin: 0 35%;">
        <DxGroupItem
          css-class="content-activity"
        >
          <DxSimpleItem
            css-class="content-activity__area"
            :data-field="$t('Costs_Balance')"
            editor-type="dxTextBox"
            :editor-options="{
              value: balance,
              mode: 'text',
              disabled: true,
            }"
          />
        </DxGroupItem>
      </DxForm>
      <div class="content_costs">
        <DxCostsTable
          :data-source="costsDataSource"
        />
        <DxSoldTable
          :data-source="soldDataSource"
        />
      </div>
      <div class="content_costs">
        <div style="width: 50%;">
          <DxForm>
            <DxGroupItem
              css-class="content-activity"
            >
              <DxSimpleItem
                css-class="content-activity__area"
                :data-field="$t('Costs_TotalCost')"
                editor-type="dxTextBox"
                :editor-options="{
                  value: totalCost,
                  mode: 'text',
                  disabled: true,
                }"
              />
            </DxGroupItem>
          </DxForm>
        </div>

        <div style="width: 50%;">
          <DxForm>
            <DxGroupItem
              css-class="content-activity"
            >
              <DxSimpleItem
                css-class="content-activity__area"
                :data-field="$t('Costs_TotalSold')"
                editor-type="dxTextBox"
                :editor-options="{
                  value: totalSold,
                  mode: 'text',
                  disabled: true,
                }"
              />
            </DxGroupItem>
          </DxForm>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import DxCostsTable from '../../components/costs/DxCostsTable.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';
import DxSoldTable from '../../components/costs/DxSoldTable.vue';

export default {
  components: {
    DxCostsTable,
    navbar,
    CalendarInitEndDate,
    DxSoldTable,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
  },
  data() {
    return {
      costsDataSource: [],
      soldDataSource: [],
      startingDate: new Date().setDate(new Date().getDate() - 7),
      endingDate: new Date(),
      datesDisplayed: null,
      keyName: 'Costs',
      totalCost: '',
      totalSold: '',
      balance: '',
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      let xhr;
      if (this.actualFilter.Costs !== undefined && this.actualFilter.Costs) {
        xhr = await Api.getDataCost({ init: this.actualFilter.Costs.init, end: this.actualFilter.Costs.end });
      } else {
        xhr = await Api.getDataCost({ init: moment(this.startingDate).format('YYYY-MM-DD'), end: moment(this.endingDate).format('YYYY-MM-DD') });
      }
      const costsList = JSON.parse(xhr.response);
      this.datesDisplayed = { init: costsList.init, end: costsList.end };
      for (let i = 0; i < costsList.cost.length; i += 1) {
        this.costsDataSource.push({
          date: costsList.cost[i].date,
          task: costsList.cost[i].task,
          cost: costsList.cost[i].cost,
        });
      }
      for (let i = 0; i < costsList.sold.length; i += 1) {
        this.soldDataSource.push({
          date: costsList.sold[i].date,
          task: costsList.sold[i].task,
          cost: costsList.sold[i].cost,
        });
      }
      this.balance = `${costsList.balance} Sol`;
      this.totalCost = `${costsList.total_cost} Sol`;
      this.totalSold = `${costsList.total_sold} Sol`;
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
  },
};
</script>

<style lang="scss">
.CostsPage_content {
  .content_calendar {
    padding: 10px;
  }
  .content_costs {
    display: flex;
    flex-direction: row;
  }
}

</style>
