<template>
  <div class="content__giseffort">
    <f7-block v-if="loaded">
      <div class="loadingFalse">
        <div class="preloader" />
      </div>
    </f7-block>
    <f7-block v-else>
      <div class="label">
        <span v-text="$t('GisMap_title')" />
      </div>
      <div>
        <iframe
          id="mapGIS"
          title="GIS MAP"
          :src="`./static/ferrero-gis/gis/level_map.html?baseurl=${baseUrl}&agrifarm=${agriFarm}&init=${init}&end=${end}&token=${token}&locations=${locations}`"
          style="min-height: 594px;"
        />
      </div>
    </f7-block>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import EventBus from '../../js/event-bus';
import Api from '../../services/Api';

export default {
  name: 'GisEffort',
  data() {
    return {
      loaded: true,
      efforts: {},
      token: encodeURIComponent(`${Api.getToken()}`),
      baseUrl: encodeURIComponent(`${Api.getBaseUrl()}`),
      agriFarm: encodeURIComponent(`${Api.getAgriFarm()}`),
      init: encodeURIComponent(`${moment.utc().startOf('year').format('YYYY-MM-DD')}`),
      end: encodeURIComponent(`${moment.utc().add(1, 'd').format('YYYY-MM-DD')}`),
      locations: encodeURIComponent(JSON.stringify(this.selectedFarmList)),
      event: 'updateGIS',
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'currentLocation', 'currentLocationName']),
  },
  updated() {
    this.loaded = false;
  },
  mounted() {
    EventBus.$on(this.event, this.updateLocations);
  },
  beforeDestroy() {
    EventBus.$off(this.event, this.updateLocations);
  },
  methods: {
    async updateLocations() {
      this.$f7.preloader.show();
      this.loaded = true;
      try {
        this.locations = encodeURIComponent(JSON.stringify(this.selectedFarmList));
        this.loaded = true;
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
        this.loaded = false;
      }
    },
  },
};
</script>

<style lang="scss">
#mapGIS{
  width:100%;
  height:100%;
  border: none;
  margin-bottom: 15px;
}
.content__giseffort .label {
  font-size: 11px;
  line-height: 1.2;
  padding: 4px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--fr-chocolat);
  margin: 15px 0 3px;
}
</style>
