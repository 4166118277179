<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-employee-efectiveness"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="employeeEffectivenessFormated"
      :remote-operations="true"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxEmployeeEffectiveness"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxDxBpas_Date_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="activityCode"
        :caption="$t('ReportingEmployeeEffectiveness_activityCode')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="activity"
        :caption="$t('ReportingEmployeeEffectiveness_Activity')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="taskCode"
        :caption="$t('ReportingEmployeeEffectiveness_taskCode')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="task"
        :caption="$t('ReportingEmployeeEffectiveness_task')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="idTask"
        :caption="$t('ReportingEmployeeEffectiveness_IdTask')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="type"
        :caption="$t('ReportingEmployeeEffectiveness_type')"
        :allow-header-filtering="false"
        alignment="center"
        :width="100"
      />
      <DxColumn
        data-field="fullname"
        :caption="$t('ReportingEmployeeEffectiveness_fullname')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="timeSpent"
        :caption="$t('ReportingEmployeeEffectiveness_timeSpent')"
        :allow-header-filtering="false"
        alignment="center"
        width="100"
      />

      <DxColumn
        data-field="performance"
        :caption="$t('ReportingEmployeeEffectiveness_performance')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="ratioTimeSpent"
        :caption="$t('ReportingEmployeeEffectiveness_ratioTimeSpent')"
        :allow-header-filtering="false"
        alignment="center"
        width="100"
      />
      <DxColumn
        data-field="ratioTotalPerformance"
        :caption="$t('ReportingEmployeeEffectiveness_ratioTotalPerformance')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxColumn
        data-field="ratioPerformanceGoal"
        :caption="$t('ReportingEmployeeEffectiveness_ratioPerformanceGoal')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxEmployeeEfectiveness',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      employeeEffectivenessFormated: [],
      keyName: 'employeeEffectiveness',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getDailyTasks(true, this.includeActivities, this.excludeActivities, checkedInitEndDate.init, checkedInitEndDate.end);
      const dailyTasks = JSON.parse(xhr.response);
      const tasksCompleted = this.getTasksCompleted(dailyTasks.detail);
      for (const taskCompleted of tasksCompleted) {
        const { date } = taskCompleted;
        const { activity } = taskCompleted;
        const activityCode = taskCompleted.activity_code;
        const { task } = taskCompleted;
        const taskCode = taskCompleted.task_code;
        const idTask = taskCompleted.id; // TODO: sustituir por short Id cuando venga del Back.
        let totalTime = 0;
        let totalPerformance = 0;
        const performanceGoal = (taskCompleted.planner.goal_performance.unit === '') ? taskCompleted.planner.working_area : taskCompleted.planner.goal_performance.count;
        for (const worker of taskCompleted.workers) {
          totalTime += this.getTotalWorkingHours(worker.init_date, worker.end_date);
          totalPerformance += parseFloat(worker.performance, 10).toFixed(2);
        }
        for (const worker of taskCompleted.workers) {
          const { type } = worker;
          const fullname = `${worker.name} ${worker.surname}`;
          const timeSpent = this.getTotalWorkingHours(worker.init_date, worker.end_date);
          const performanceCustom = (worker.unit === '') ? worker.working_area : worker.performance;
          const performanceUnitCustom = (worker.unit === '') ? 'HA' : worker.unit;
          const performance = parseFloat(performanceCustom, 10).toFixed(2);
          const ratioTimeSpent = (100 * timeSpent) / totalTime;
          const ratioTotalPerformance = (100 * performance) / totalPerformance;
          const ratioPerformanceGoal = (100 * performance) / performanceGoal;
          this.employeeEffectivenessFormated.push({
            date,
            activity,
            activityCode,
            task,
            taskCode,
            idTask,
            type,
            fullname,
            timeSpent: `${timeSpent} ${this.$t('HOURS')}`,
            performance: `${performanceCustom} ${performanceUnitCustom}`,
            ratioTimeSpent: this.returnDataNotNaN(ratioTimeSpent).toFixed(2),
            ratioTotalPerformance: this.returnDataNotNaN(ratioTotalPerformance).toFixed(2),
            ratioPerformanceGoal: this.returnDataNotNaN(ratioPerformanceGoal).toFixed(2),
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // RatioTime, RatioTotal y RatioPerformance daban Infinito y NaN.
    returnDataNotNaN(element) {
      if (isNaN(element) || !isFinite(element)) {
        return 0;
      }
      return element;
    },
    getTasksCompleted(details) {
      for (const detail of details) {
        if (detail.status === 'COMPLETED') {
          return detail.tasks;
        }
      }
      return [];
    },
    getTotalWorkingHours(init, end) {
      if (end === null) {
        const ms = moment().diff(moment(init));
        const d = moment.duration(ms);
        return `${d.hours()}`;
      }
      const ms = moment(end).diff(moment(init));
      const d = moment.duration(ms);
      return `${d.hours()}`;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('reportingBPAS');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportingBPAS.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
