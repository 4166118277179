export default {
  namespaced: true,
  state: {
    actualPestsDiseases: {},
    actualExtremeWeatherCondicions: {},
    point: {},
    actualSelection: '',
    incidentName: '',
    phenologicalStates: [],
    plaguesAndDiseases: [],
    locationSelected: '',
    formMonitoringPoint: {
      date: '', disease: [], plagues: [], wheater_conditions: [], phenology: [], others: [],
    },
    latitude: 0,
    longitude: 0,
    pictures: [],
    selectedPhenologicalState: {},
    selectedPlague: {},
    selectedPlagueState: '',
    selectedWeatherCondition: {},
    selectedScoutingPoint: '',
  },

  getters: {
    getActualPestsDiseases: (state) => state.actualPestsDiseases,
    getActualExtremeWeatherCondicions: (state) => state.actualExtremeWeatherCondicions,
    point: (state) => state.point,
    actualSelection: (state) => state.actualSelection,
    incidentName: (state) => state.incidentName,
    phenologicalStates: (state) => state.phenologicalStates,
    plaguesAndDiseases: (state) => state.plaguesAndDiseases,
    locationSelected: (state) => state.locationSelected,
    formMonitoringPoint: (state) => state.formMonitoringPoint,
    latitude: (state) => state.latitude,
    longitude: (state) => state.longitude,
    pictures: (state) => state.pictures,
    selectedPhenologicalState: (state) => state.selectedPhenologicalState,
    selectedPlague: (state) => state.selectedPlague,
    selectedPlagueState: (state) => state.selectedPlagueState,
    selectedWeatherCondition: (state) => state.selectedWeatherCondition,
    selectedScoutingPoint: (state) => state.selectedScoutingPoint,
  },
  actions: {
    setActualPestsDiseases(context, value) {
      context.commit('setActualPestsDiseases', value);
    },
    setActualExtremeWeatherCondicions(context, value) {
      context.commit('setActualExtremeWeatherCondicions', value);
    },
    setPoint(context, value) {
      context.commit('setPoint', value);
    },
    setName(context, value) {
      context.commit('setName', value);
    },
    setLatitude(context, value) {
      context.commit('setLatitude', value);
    },
    setLongitude(context, value) {
      context.commit('setLongitude', value);
    },
    setNumberPlants(context, value) {
      context.commit('setNumberPlants', value);
    },
    setLocation(context, value) {
      context.commit('setLocation', value);
    },
    setVariety(context, value) {
      context.commit('setVariety', value);
    },
    setType(context, value) {
      context.commit('setType', value);
    },
    setYearPlanting(context, value) {
      context.commit('setYearPlanting', value);
    },
    setNote(context, value) {
      context.commit('setNote', value);
    },
    resetScoutingStates({ commit }) {
      commit('SET_SELECTED_PLAGUE', {});
      commit('SET_SELECTED_PHENOLOGICAL_STATE', {});
      commit('SET_PHENOLOGICAL_STATES', {});
      commit('SET_PLAGUES_AND_DISEASES', {});
      commit('SET_ACTUAL_SELECTION', '');
      commit('SET_SELECTED_PLAGUE_STATE', '');
      commit('SET_SELECTED_WEATHER_CONDITION', '');
      commit('SET_SELECTED_SCOUTING_POINT', '');
      commit('SET_INCIDENT_NAME', '');
      commit('SET_FORM_MONITORING_POINT', {
        date: '', disease: [], plagues: [], wheater_conditions: [], phenology: [], others: [],
      });
      commit('SET_LOCATION_SELECTED', '');
      commit('SET_PICTURES', []);
      commit('SET_LATITUDE', 0);
      commit('SET_LONGITUDE', 0);
    },
    setActualSelection({ commit }, value) {
      commit('SET_ACTUAL_SELECTION', value);
    },
    setIncidentName({ commit }, value) {
      commit('SET_INCIDENT_NAME', value);
    },
    setPhenologicalStates({ commit }, value) {
      commit('SET_PHENOLOGICAL_STATES', value);
    },
    setPlaguesAndDiseases({ commit }, value) {
      commit('SET_PLAGUES_AND_DISEASES', value);
    },
    setLocationSelected({ commit }, value) {
      commit('SET_LOCATION_SELECTED', value);
    },
    setNewFormMonitoringPoint({ commit }) {
      commit('SET_FORM_MONITORING_POINT', {
        date: '', disease: [], plagues: [], wheater_conditions: [], phenology: [], others: [],
      });
    },
    setLatitudeCR({ commit }, value) {
      commit('SET_LATITUDE', value);
    },
    setLongitudeCR({ commit }, value) {
      commit('SET_LONGITUDE', value);
    },
    setPictures({ commit }, value) {
      commit('SET_PICTURES', value);
    },
    setSelectedPhenologicalState({ commit }, value) {
      commit('SET_SELECTED_PHENOLOGICAL_STATE', value);
    },
    setSelectedPlague({ commit }, value) {
      commit('SET_SELECTED_PLAGUE', value);
    },
    setSelectedPlagueState({ commit }, value) {
      commit('SET_SELECTED_PLAGUE_STATE', value);
    },
    setSelectedWeatherCondition({ commit }, value) {
      commit('SET_SELECTED_WEATHER_CONDITION', value);
    },
    setFormMonitoringPoint({ commit }, value) {
      commit('SET_FORM_MONITORING_POINT', value);
    },
    setSelectedScoutingPoint({ commit }, value) {
      commit('SET_SELECTED_SCOUTING_POINT', value);
    },
  },
  mutations: {
    setActualPestsDiseases(state, value) {
      state.actualPestsDiseases = value;
    },
    setActualExtremeWeatherCondicions(state, value) {
      state.actualExtremeWeatherCondicions = value;
    },
    setPoint(state, value) {
      state.point = value;
    },
    setName(state, value) {
      state.point.name = value;
    },
    setLatitude(state, value) {
      state.point.latitude = value;
    },
    setLongitude(state, value) {
      state.point.longitude = value;
    },
    setNumberPlants(state, value) {
      state.point.numberPlants = value;
    },
    setLocation(state, value) {
      state.point.locationId = value;
    },
    setVariety(state, value) {
      state.point.variety = value;
    },
    setType(state, value) {
      state.point.type = value;
    },
    setYearPlanting(state, value) {
      state.point.yearPlanting = value;
    },
    setNote(state, value) {
      state.point.note = value;
    },
    SET_ACTUAL_SELECTION(state, payload) {
      state.actualSelection = payload;
    },
    SET_INCIDENT_NAME(state, payload) {
      state.incidentName = payload;
    },
    SET_PHENOLOGICAL_STATES(state, payload) {
      state.phenologicalStates = payload;
    },
    SET_PLAGUES_AND_DISEASES(state, payload) {
      state.plaguesAndDiseases = payload;
    },
    SET_LOCATION_SELECTED(state, payload) {
      state.locationSelected = payload;
    },
    SET_FORM_MONITORING_POINT(state, payload) {
      state.formMonitoringPoint = payload;
    },
    SET_LATITUDE(state, payload) {
      state.latitude = payload;
    },
    SET_LONGITUDE(state, payload) {
      state.longitude = payload;
    },
    SET_PICTURES(state, payload) {
      state.pictures = payload;
    },
    SET_SELECTED_PHENOLOGICAL_STATE(state, payload) {
      state.selectedPhenologicalState = payload;
    },
    SET_SELECTED_PLAGUE(state, payload) {
      state.selectedPlague = payload;
    },
    SET_SELECTED_PLAGUE_STATE(state, payload) {
      state.selectedPlagueState = payload;
    },
    SET_SELECTED_WEATHER_CONDITION(state, payload) {
      state.selectedWeatherCondition = payload;
    },
    SET_SELECTED_SCOUTING_POINT(state, payload) {
      state.selectedScoutingPoint = payload;
    },
  },
};
