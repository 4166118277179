<template>
  <f7-page
    class="content-list-order main-formAction  bg-cream"
  >
    <navbar
      :new-icon-enable="true"
      :text="setTitle()"
      :path-back="pathBack"
      :back-with-event="true"
    />
    <!-- DATE -->
    <div class="bg-color-kinder padding-half text-align-center">
      <span class="text-color-white">{{ getActualDate() }}</span>
    </div>
    <div class="content-devx">
      <DxForm
        id="form"
      >
        <!-- PHENOLOGICAL STATE OF THE CROP (OPTIONAL) -->
        <DxSimpleItem
          :data-field="$t('Pests_Phenological_State_of_the_Crop')"
          :caption="$t('Pests_Phenological_State_of_the_Crop')"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: phenologicalStates,
            searchEnabled: false,
            onValueChanged: changePhenologicalState,
            value: selectedPhenologicalState,
            placeholder: 'Selecciona',
            valueExpr: 'id',
            displayExpr: function(item) {
              return item && `${item.code} - ${item.phase}`;
            },
          }"
        />
        <!-- SPECIES -->
        <DxSimpleItem
          v-if="actualSelection === 'diseases' || actualSelection === 'pests'"
          :data-field="$t('Pests_Species')"
          :caption="$t('Pests_Species')"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: species,
            searchEnabled: false,
            onValueChanged: changeSpecie,
            value: selectedSpecie,
            placeholder: 'Selecciona',
            valueExpr: 'id',
            displayExpr: function(item) {
              return item && `${item.scientific_name} ${item.name}`;
            },
          }"
        />
        <!-- STATE OF DEVELOPMENT -->
        <DxSimpleItem
          v-if="actualSelection === 'diseases' || actualSelection === 'pests'"
          :data-field="$t('Pests_State_of_Development')"
          :caption="$t('Pests_State_of_Development')"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: plaguesStates,
            searchEnabled: false,
            onValueChanged: changePlagueState,
            value: selectedPlagueState,
            placeholder: 'Selecciona',
          }"
        />
        <!-- TYPE -->
        <DxSimpleItem
          v-if="actualSelection === 'extreme_weather_conditions'"
          :data-field="$t('ExtremeWeatherConditions_Type')"
          :caption="$t('ExtremeWeatherConditions_Type')"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: weatherConditions,
            searchEnabled: false,
            onValueChanged: changeWeatherCondition,
            value: selectedWeatherCondition,
            placeholder: 'Selecciona',
            valueExpr: 'id',
            displayExpr: 'name',
          }"
        />
        <!-- NOTES -->
        <DxSimpleItem
          :data-field="$t('Pests_Comments')"
          editor-type="dxTextArea"
          :editor-options="{
            value: comments,
            onValueChanged: changeComments,
            showSpinButtons: false,
            placeholder: '',
            showClearButton: true,
            maxLength: 256,
          }"
        />
      </DxForm>
      <f7-block>
        <!-- Nº Detected -->
        <f7-block v-if="actualSelection === 'pests' ">
          <div class="content-list__title text-align-center">
            {{ $t("Pests_NDetected") }}
          </div>
          <f7-stepper
            :min="0"
            :max="9999"
            :step="1"
            large
            fill
            :value="0"
            @input="changeInputStepper($event.target.value)"
          />
        </f7-block>
      </f7-block>

      <f7-row class="padding-left padding-right padding-bottom margin">
        <f7-col>
          <!-- Finish -->
          <f7-button
            name="btnContinue"
            class="dx-btn-success"
            raised
            type="success"
            button
            @click="finish()"
          >
            {{ $t("Pests_Finish") }}
          </f7-button>
        </f7-col>
        <f7-col>
          <!-- Next -->
          <f7-button
            name="btnContinue"
            class="dx-btn-cancel"
            raised
            type="success"
            button
            style="color: #638547;"
            @click="next()"
          >
            {{ $t("Pests_Next") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { DxForm, DxSimpleItem } from 'devextreme-vue/form';
import navbar from '../../components/NavBar.vue';
import EventBus from '../../js/event-bus';
import Api from '../../services/Api';

export default {
  name: 'FormAction',
  components: {
    navbar,
    DxForm,
    DxSimpleItem,
  },
  props: {
  },
  data() {
    return {
      loaded: false,
      stepper: 0,
      selectedPhenologicalState: '',
      selectedSpecie: '',
      pathBack: '/fixedMonitoringPoint/',
      plaguesStates: [],
      selectedPlagueState: '',
      weatherConditions: [],
      selectedWeatherCondition: '',
      species: [],
      comments: '',
    };
  },
  computed: {
    ...mapGetters('Scouting', ['phenologicalStates', 'plaguesAndDiseases', 'actualSelection', 'incidentName', 'formMonitoringPoint', 'selectedScoutingPoint', 'locationSelected', 'pictures', 'latitude', 'longitude']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      EventBus.$on('go_back_event', this.goBack);
      if (this.incidentName !== '') {
        this.pathBack = '/casualRegister/';
      }
      const keyvalues = await Api.getAllRecords('sync/keyvalues');
      if (this.actualSelection === 'pests') {
        const plagues = keyvalues.filter((element) => element.family === 'PLAGUES_STATES');
        if (plagues) {
          this.plaguesStates = plagues[0].values;
        }
      }
      if (this.actualSelection === 'diseases') {
        const diseases = keyvalues.filter((element) => element.family === 'DISEASES_STATES');
        if (diseases) {
          this.plaguesStates = diseases[0].values;
        }
      }
      const plaguesDiseases = await Api.getAllRecords('plagues_and_disease');
      const conditions = plaguesDiseases.filter((element) => element.type === 'EXTREME_WEATHER_CONDITIONS');
      if (conditions) {
        this.weatherConditions = conditions;
      }
      this.species = this.plaguesAndDiseases.filter(
        (element) => element.type === this.actualSelection.toUpperCase(),
      );
    } catch (error) {
      console.warn(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  beforeDestroy() {
    EventBus.$off('go_back_event');
    this.setPictures([]);
  },
  methods: {
    setTitle() {
      this.formTitle = '';
      switch (this.actualSelection) {
        case 'pests':
          this.formTitle = this.$t('formAction_Title_Pests');
          break;
        case 'diseases':
          this.formTitle = this.$t('formAction_Title_Diseases');
          break;
        case 'phenological':
          this.formTitle = this.$t('formAction_Title_Phenological');
          break;
        case 'extreme_weather_conditions':
          this.formTitle = this.$t('formAction_Title_WeatherConditions');
          break;
        case 'other':
          this.formTitle = this.$t('formAction_Title_Other');
          break;
        default:
          this.formTitle = this.actualSelection;
          break;
      }
      return this.formTitle;
    },
    getActualDate() {
      return moment(new Date()).format('YYYY/MM/DD');
    },
    changeInputStepper(inputValue) {
      this.stepper = inputValue;
      return inputValue;
    },
    changePhenologicalState(e) {
      this.selectedPhenologicalState = e.value;
      this.setSelectedPhenologicalState(e.value);
    },
    changeSpecie(e) {
      this.selectedSpecie = e.value;
      this.setSelectedPlague(e.value);
    },
    changePlagueState(e) {
      this.selectedPlagueState = e.value;
      this.setSelectedPlagueState(e.value);
    },
    changeWeatherCondition(e) {
      this.selectedWeatherCondition = e.value;
      this.setSelectedWeatherCondition(e.value);
    },
    changeComments(e) {
      this.comments = e.value;
    },
    next() {
      let goNext = true;
      switch (this.actualSelection) {
        case 'diseases':
          if (this.selectedPhenologicalState === '' || this.selectedSpecie === '' || this.selectedPlagueState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.saveDisease();
          break;
        case 'pests':
          if (this.selectedPhenologicalState === '' || this.selectedSpecie === '' || this.selectedPlagueState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.savePest();
          break;
        case 'extreme_weather_conditions':
          if (this.selectedPhenologicalState === '' || this.selectedWeatherCondition === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.saveWeatherCondition();
          break;
        case 'phenological':
          if (this.selectedPhenologicalState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.savePhenological();
          break;
        case 'other':
          if (this.selectedPhenologicalState === '') {
            this.$f7.dialog.alert(this.$t('formAction_Alert_MissingData'));
            goNext = false;
          }
          this.saveOther();
          break;
        default:
          break;
      }
      if (goNext) {
        this.setPictures([]);
        this.$f7.views.main.router.navigate('/formAction/', { reloadCurrent: true });
      }
    },
    saveDisease() {
      const newForm = this.formMonitoringPoint;
      const disease = {
        plagues_and_diseases: this.selectedSpecie,
        phenological_phase: this.selectedPhenologicalState,
        presence: true,
        state: this.selectedPlagueState,
        comments: this.comments,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.disease.push(disease);
      this.setFormMonitoringPoint(newForm);
    },
    savePest() {
      const newForm = this.formMonitoringPoint;
      const plague = {
        plagues_and_diseases: this.selectedSpecie,
        phenological_phase: this.selectedPhenologicalState,
        state: this.selectedPlagueState,
        number: this.stepper,
        comments: this.comments,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.plagues.push(plague);
      this.setFormMonitoringPoint(newForm);
    },
    saveWeatherCondition() {
      const newForm = this.formMonitoringPoint;
      const wheaterCondition = {
        plagues_and_diseases: this.selectedWeatherCondition,
        phenological_phase: this.selectedPhenologicalState,
        comments: this.comments,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.wheater_conditions.push(wheaterCondition);
      this.setFormMonitoringPoint(newForm);
    },
    savePhenological() {
      const newForm = this.formMonitoringPoint;
      const phenology = {
        phenological_phase: this.selectedPhenologicalState,
        comments: this.comments,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.phenology.push(phenology);
      this.setFormMonitoringPoint(newForm);
    },
    saveOther() {
      const newForm = this.formMonitoringPoint;
      const other = {
        phenological_phase: this.selectedPhenologicalState,
        comments: this.comments,
        photos: this.picturesToBase64(this.pictures),
      };
      newForm.others.push(other);
      this.setFormMonitoringPoint(newForm);
    },
    async finish() {
      this.$f7.preloader.show();
      try {
        switch (this.actualSelection) {
          case 'diseases':
            if (this.selectedPhenologicalState !== '' && this.selectedSpecie !== '' && this.selectedPlagueState !== '') {
              this.saveDisease();
            }
            break;
          case 'pests':
            if (this.selectedPhenologicalState !== '' && this.selectedSpecie !== '' && this.selectedPlagueState !== '') {
              this.savePest();
            }
            break;
          case 'extreme_weather_conditions':
            if (this.selectedPhenologicalState !== '' && this.selectedWeatherCondition !== '') {
              this.saveWeatherCondition();
            }
            break;
          case 'phenological':
            if (this.selectedPhenologicalState !== '') {
              this.savePhenological();
            }
            break;
          case 'other':
            if (this.selectedPhenologicalState !== '') {
              this.saveOther();
            }
            break;
          default:
            break;
        }
        // Sleep necesario para guardar la foto del ultimo formulario por retardo de vuex
        await this.sleep(1000);
        this.confirmFinish();
      } catch (error) {
        console.warn(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async confirmFinish() {
      this.$f7.preloader.show();
      try {
        const newForm = this.formMonitoringPoint;
        newForm.date = moment(new Date()).format('YYYY-MM-DD HH:mm');
        if (this.selectedScoutingPoint !== '') {
          newForm.scouting_point_id = this.selectedScoutingPoint;
        } else {
          const scoutingPoint = {
            type: this.actualSelection.toUpperCase(),
            name: this.incidentName,
            location: this.locationSelected,
            coordinates: {
              latitude: parseFloat(this.latitude).toFixed(4),
              longitude: parseFloat(this.longitude).toFixed(4),
            },
          };
          newForm.scouting_point = scoutingPoint;
        }
        await Api.sendChangesRecords('sync/scouting', { data: [newForm] });
        // if (newForm.disease.length > 0
        //     || newForm.others.length > 0
        //     || newForm.phenology.length > 0
        //     || newForm.plagues.length > 0
        //     || newForm.wheater_conditions.length > 0
        // ) {
        //   await ScoutingPoint.insertScoutingForm(newForm);
        // }
        this.$f7.views.main.router.navigate(this.pathBack, { reloadCurrent: true });
      } catch (error) {
        console.warn(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    // Convertir imagen a base64
    convertImgToBase64(url, callback, outputFormat) {
      let canvas = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL(outputFormat || 'image/jpeg');
        callback.call(this, dataURL);
        // Clean up
        canvas = null;
      };
      img.src = url;
    },
    picturesToBase64(arrayPictures) {
      const picturesArrayToSend = [];
      arrayPictures.forEach((element) => {
        // Se añade al array de fotos a mostrar el base64 de la foto
        this.convertImgToBase64(element, (base64Img) => {
          picturesArrayToSend.push(base64Img);
        });
      });
      return picturesArrayToSend;
    },
    goBack() {
      const self = this;
      this.$f7.dialog.confirm(
        `${this.$t('formAction_Dialog_Confirm')}`,
        () => {
          self.$f7.views.main.router.navigate(this.pathBack, { reloadCurrent: true });
        },
      );
    },
    ...mapActions('Scouting', ['setSelectedPhenologicalState', 'setSelectedPlague', 'setSelectedPlagueState', 'setSelectedWeatherCondition', 'setFormMonitoringPoint', 'setPictures']),
  },
};

</script>

<style lang="scss">
.main-formAction{
  .content-list{
    &__title{
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 3px;
      padding-left: 15px;
      color: var(--fr-nutella);
    }
    ul{
      &::before, &::after{
        height: 0!important;
      }
    }
    .smart-border {
      padding-bottom: 0px;
      .item-link {
        .item-content {
          padding: 0;
          .item-inner {
            background-color: #ffffff;
            min-height: 56px;
            border-radius: 8px;
            border: 1px solid #d8d8d8 !important;
            color: var(--f7-badge-bg-color);
            font-weight: 500;
            line-height: 21px;
            padding: 5px 15px;
            width: 100%;
            .item-after {
              margin-left: 0;
              padding: 0;
              font-weight: 500;
              font-size: 16px;
              line-height: 1.2;
              max-width: 90%;
              color: var(--ha-darkgray);
            }
          }
          .item-title {
            display: none;
          }
        }
      }
    }
  }
  .stepper{
    display: flex;
    justify-content: center;
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}
.ic-piccam{
  content: url("../../static/img/ic_piccam.svg");
  margin-right: 5px;
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.main-formAction{
 .item-input-wrap textarea {
    background-color: #FFFFFF;
    min-height: 60px;
    border-radius: 8px;
    border: 1px solid #D8D8D8 !important;
    color: var(--fr-kinder);
    font-weight: 500;
    line-height: 21px;
    padding: 6px 12px;
    width: 100%;
    margin-bottom: 15px;
  }
  .item-content {
    padding: 0;
  }
  .item-inner {
    padding: 0;
  }
  .item-input:not(.item-input-outline) .item-input-wrap:after,
  .input:not(.input-outline):after {
    opacity: 0;
  }
}
</style>
