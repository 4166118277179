var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{staticClass:"content-list-order main-formAction  bg-cream"},[_c('navbar',{attrs:{"new-icon-enable":true,"text":_vm.setTitle(),"path-back":_vm.pathBack,"back-with-event":true}}),_vm._v(" "),_c('div',{staticClass:"bg-color-kinder padding-half text-align-center"},[_c('span',{staticClass:"text-color-white"},[_vm._v(_vm._s(_vm.getActualDate()))])]),_vm._v(" "),_c('div',{staticClass:"content-devx"},[_c('DxForm',{attrs:{"id":"form"}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Pests_Phenological_State_of_the_Crop'),"caption":_vm.$t('Pests_Phenological_State_of_the_Crop'),"editor-type":"dxSelectBox","editor-options":{
          dataSource: _vm.phenologicalStates,
          searchEnabled: false,
          onValueChanged: _vm.changePhenologicalState,
          value: _vm.selectedPhenologicalState,
          placeholder: 'Selecciona',
          valueExpr: 'id',
          displayExpr: function(item) {
            return item && ((item.code) + " - " + (item.phase));
          },
        }}}),_vm._v(" "),(_vm.actualSelection === 'diseases' || _vm.actualSelection === 'pests')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Pests_Species'),"caption":_vm.$t('Pests_Species'),"editor-type":"dxSelectBox","editor-options":{
          dataSource: _vm.species,
          searchEnabled: false,
          onValueChanged: _vm.changeSpecie,
          value: _vm.selectedSpecie,
          placeholder: 'Selecciona',
          valueExpr: 'id',
          displayExpr: function(item) {
            return item && ((item.scientific_name) + " " + (item.name));
          },
        }}}):_vm._e(),_vm._v(" "),(_vm.actualSelection === 'diseases' || _vm.actualSelection === 'pests')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Pests_State_of_Development'),"caption":_vm.$t('Pests_State_of_Development'),"editor-type":"dxSelectBox","editor-options":{
          dataSource: _vm.plaguesStates,
          searchEnabled: false,
          onValueChanged: _vm.changePlagueState,
          value: _vm.selectedPlagueState,
          placeholder: 'Selecciona',
        }}}):_vm._e(),_vm._v(" "),(_vm.actualSelection === 'extreme_weather_conditions')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('ExtremeWeatherConditions_Type'),"caption":_vm.$t('ExtremeWeatherConditions_Type'),"editor-type":"dxSelectBox","editor-options":{
          dataSource: _vm.weatherConditions,
          searchEnabled: false,
          onValueChanged: _vm.changeWeatherCondition,
          value: _vm.selectedWeatherCondition,
          placeholder: 'Selecciona',
          valueExpr: 'id',
          displayExpr: 'name',
        }}}):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Pests_Comments'),"editor-type":"dxTextArea","editor-options":{
          value: _vm.comments,
          onValueChanged: _vm.changeComments,
          showSpinButtons: false,
          placeholder: '',
          showClearButton: true,
          maxLength: 256,
        }}})],1),_vm._v(" "),_c('f7-block',[(_vm.actualSelection === 'pests' )?_c('f7-block',[_c('div',{staticClass:"content-list__title text-align-center"},[_vm._v("\n          "+_vm._s(_vm.$t("Pests_NDetected"))+"\n        ")]),_vm._v(" "),_c('f7-stepper',{attrs:{"min":0,"max":9999,"step":1,"large":"","fill":"","value":0},on:{"input":function($event){return _vm.changeInputStepper($event.target.value)}}})],1):_vm._e()],1),_vm._v(" "),_c('f7-row',{staticClass:"padding-left padding-right padding-bottom margin"},[_c('f7-col',[_c('f7-button',{staticClass:"dx-btn-success",attrs:{"name":"btnContinue","raised":"","type":"success","button":""},on:{"click":function($event){return _vm.finish()}}},[_vm._v("\n          "+_vm._s(_vm.$t("Pests_Finish"))+"\n        ")])],1),_vm._v(" "),_c('f7-col',[_c('f7-button',{staticClass:"dx-btn-cancel",staticStyle:{"color":"#638547"},attrs:{"name":"btnContinue","raised":"","type":"success","button":""},on:{"click":function($event){return _vm.next()}}},[_vm._v("\n          "+_vm._s(_vm.$t("Pests_Next"))+"\n        ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }