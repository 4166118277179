<template>
  <div style="margin: 50px 10%">
    <DxForm
      id="form"
    >
      <DxGroupItem
        :col-count="2"
        :caption="$t('Register_registerText')"
      >
        <!-- NAME / SURNAME -->
        <DxSimpleItem
          :data-field="$t('Register_inpName_name')"
          :editor-options="{
            onValueChanged: changeName,
            value: actualName,
          }"
          editor-type="dxTextBox"
          name="Name"
        >
          <DxRequiredRule :message="$t('Register_inpName_name')" />
        </DxSimpleItem>
        <DxSimpleItem
          :data-field="$t('Register_inpSurnames_name')"
          :editor-options="{
            onValueChanged: changeSurname,
            value: actualSurname,
          }"
          editor-type="dxTextBox"
          name="Surname"
        >
          <DxRequiredRule :message="$t('Register_inpSurnames_name')" />
        </DxSimpleItem>

        <!-- City / INSCRIPTION TYPE -->
        <DxSimpleItem
          :data-field="$t('Register_inpCity')"
          :editor-options="{
            items: citiesToShow,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            onValueChanged: changeCity,
            value: actualCity,
          }"
          editor-type="dxSelectBox"
          name="cityName"
        >
          <DxRequiredRule :message="$t('Register_inpCity')" />
        </DxSimpleItem>
        <DxSimpleItem
          :data-field="$t('Register_inpInscriptionType')"
          :editor-options="{
            items: inscriptionTypes,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            onValueChanged: changeInscriptionType,
            value: actualInscriptionType,
          }"
          editor-type="dxSelectBox"
          name="InscriptionType"
        >
          <DxRequiredRule :message="$t('Register_inpInscriptionType')" />
        </DxSimpleItem>

        <!-- EMAIL / PASSWORD -->
        <DxSimpleItem
          :data-field="$t('Register_inpEmail_name')"
          :editor-options="{
            onValueChanged: changeEmail,
            value: actualEmail,
            disabled: isEmailAndEmailConfirmEqual
          }"
          editor-type="dxTextBox"
          name="e-mail"
        >
          <DxRequiredRule :message="$t('Register_inpEmail_name')" />
        </DxSimpleItem>
        <DxSimpleItem
          :data-field="$t('Register_inpPassword')"
          :editor-options="{
            onValueChanged: changePassword,
            value: actualPassword,
            mode: 'password',
            disabled: isPswdAndPswdConfirmEqual
          }"
          editor-type="dxTextBox"
          name="pass"
        >
          <DxRequiredRule :message="$t('Register_inpPassword')" />
        </DxSimpleItem>

        <!-- Confirm Email / CONFIRMPASSWORD -->
        <DxSimpleItem
          :data-field="$t('Register_inpConfirmEmail_name')"
          :editor-options="{
            onValueChanged: changeEmailConfirm,
            value: actualEmailConfirm,
            disabled: isEmailAndEmailConfirmEqual
          }"
          editor-type="dxTextBox"
          name="Confirm e-mail"
        >
          <DxRequiredRule :message="$t('Register_inpConfirmEmail_name')" />
        </DxSimpleItem>
        <DxSimpleItem
          :data-field="$t('Register_inpConfirmPassword')"
          :editor-options="{
            onValueChanged: changePasswordConfirm,
            value: actualPasswordConfirm,
            mode: 'password',
            disabled: isPswdAndPswdConfirmEqual
          }"
          editor-type="dxTextBox"
          name="Confirm Password"
        >
          <DxRequiredRule :message="$t('Register_inpConfirmPassword')" />
        </DxSimpleItem>

        <!-- PHONE -->
        <DxSimpleItem
          :data-field="$t('Register_inpPhone')"
          :editor-options="{
            onValueChanged: changePhone,
            value: actualPhone,
          }"
          editor-type="dxNumberBox"
          name="Phone"
        >
          <DxRequiredRule :message="$t('Register_inpPhone')" />
        </DxSimpleItem>
        <!-- SMS Check -->
        <DxSimpleItem
          :data-field="$t('Register_sms')"
          :editor-options="{
            onValueChanged: changeSMS,
            value: actualSMS,
          }"
          editor-type="dxCheckBox"
          name="sms"
        />
        <DxEmptyItem :visible="actualSMS" />
        <DxSimpleItem
          :data-field="$t('Register_cultive')"
          :editor-options="{
            dataSource: ['Patata', 'Maiz', 'Cacao', 'Café'],
            onValueChanged: changeCultive,
            value: actualCultive,
          }"
          editor-type="dxRadioGroup"
          name="cultive"
          :visible="actualSMS"
        >
          <DxRequiredRule :message="$t('Register_cultive')" />
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
  DxEmptyItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions } from 'vuex';
import 'devextreme-vue/radio-group';

export default {
  name: 'RegisterForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxEmptyItem,
  },
  data() {
    return {
      actualName: '',
      actualSurname: '',
      actualCountry: '',
      actualComunity: '',
      actualProvince: '',
      actualCity: '',
      actualEmail: '',
      actualEmailConfirm: '',
      actualPassword: '',
      actualPasswordConfirm: '',
      actualPhone: '',
      actualInscriptionType: '',
      inscriptionTypes: ['Agricultor', 'Técnico Agrícola'],
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      cities: [],
      citiesToShow: [],
      actualSMS: false,
      actualCultive: null,
    };
  },
  computed: {
    isEmailAndEmailConfirmEqual() {
      if (this.actualEmail !== '' && this.actualEmailConfirm !== '' && this.actualEmail !== this.actualEmailConfirm) {
        this.$f7.dialog.alert(this.$t('UserRegister_Alert_Email'));
      }
      return false;
    },
    isPswdAndPswdConfirmEqual() {
      if (this.actualPassword !== '' && this.actualPasswordConfirm !== '' && this.actualPassword !== this.actualPasswordConfirm) {
        this.$f7.dialog.alert(this.$t('UserRegister_Alert_Password'));
      }
      return false;
    },
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    try {
      this.setNewUserToRegister();
      const xhr = await this.fetchGetCities();
      this.cities = JSON.parse(xhr.response);
      this.citiesToShow = JSON.parse(xhr.response).map(
        (city) => city.name,
      );
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    }
  },
  methods: {
    changeInscriptionType(e) {
      this.actualInscriptionType = e.value;
      this.setUserInscriptionType(e.value);
    },
    changePhone(e) {
      this.actualPhone = e.value;
      this.setUserPhone(e.value);
    },
    changeSMS(e) {
      this.actualSMS = e.value;
      if (!e.value) {
        this.actualCultive = null;
        this.setCultive(null);
      }
      this.setUserSMS(e.value);
    },
    changePasswordConfirm(e) {
      this.actualPasswordConfirm = e.value;
      this.setUserPasswordConfirm(e.value);
    },
    changePassword(e) {
      this.actualPassword = e.value;
      this.setUserPassword(e.value);
    },
    changeEmailConfirm(e) {
      if (this.validateEmail(e.value)) {
        this.actualEmailConfirm = e.value;
        this.setUserEmailConfirm(e.value);
      } else {
        // no es un email valido TODO.
      }
    },
    changeEmail(e) {
      if (this.validateEmail(e.value)) {
        this.actualEmail = e.value;
        this.setUserEmail(e.value);
      } else {
        // no es un email valido TODO.
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    changeCity(e) {
      this.actualCity = e.value;
      const codeCity = this.cities.filter((city) => city.name === e.value);
      this.setUserCity(codeCity[0].code);
    },
    changeName(e) {
      this.actualName = e.value;
      this.setUserName(e.value);
    },
    changeSurname(e) {
      this.actualSurname = e.value;
      this.setUserSurname(e.value);
    },
    changeCultive(e) {
      this.actualCultive = e.value;
      this.setCultive(e.value);
    },
    ...mapActions('authentication', ['setNewUserToRegister', 'setUserName', 'setUserSurname',
      'setUserCity', 'setUserEmail', 'setUserEmailConfirm', 'setUserPassword', 'setUserPasswordConfirm',
      'setUserPhone', 'setUserInscriptionType', 'fetchGetCities', 'setUserSMS', 'setCultive']),
  },
};
</script>

<style>

</style>
