<template>
  <f7-page>
    <navbar :text="$t('Reclassification_Title')" />
    <DxBinsTable :is-reclassifying="true" />
  </f7-page>
</template>

<script>
import DxBinsTable from '../../components/greenhouse/DxBinsTable.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'BinsReclassification',
  components: {
    navbar,
    DxBinsTable,
  },
  computed: {
  },
  methods: {
  },
};
</script>
