<template>
  <f7-page>
    <navbar :text="$t('EditBatch_Title')" />
    <DxBatchesInfoForm :editing="true" />
    <DxBatchesBinsTable />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel"
        style="margin-right:50px;"
        raised
        type="cancel"
        styling-mode="contained"
        name="ButtonCancel_caption"
        @click="navigateTo"
      >
        {{ $t('ButtonCancel_caption') }}
      </f7-button>

      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        :disabled="enableSaveButton"
        @click="addBatch"
      >
        {{ $t('EditBatch_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DxBatchesInfoForm from '../../components/greenhouse/DxBatchesInfoForm.vue';
import DxBatchesBinsTable from '../../components/greenhouse/DxBatchesBinsTable.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'EditBatch',
  components: {
    navbar,
    DxBatchesInfoForm,
    DxBatchesBinsTable,
  },
  computed: {
    ...mapGetters('greenhouse', ['selectedBins', 'nameBatch']),
    enableSaveButton() {
      if (this.selectedBins.length === 0 || this.nameBatch === '') {
        return true;
      }
      return false;
    },
  },
  beforeDestroy() {
    this.resetStates();
  },
  methods: {
    async addBatch() {
      try {
        await this.createBatch();
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.setActualMenuSelected('/batches/');
        this.$f7.views.main.router.navigate('/batches/', { reloadCurrent: true });
      }
    },
    navigateTo() {
      this.setActualMenuSelected('/batches/');
      this.$f7.views.main.router.navigate('/batches/', { reloadCurrent: true });
    },
    ...mapActions('greenhouse',
      [
        'createBatch',
        'resetStates',
      ]),
    ...mapActions('authentication', ['setActualMenuSelected']),
  },
};
</script>
