<template>
  <f7-page>
    <navbar :text="titleNavbar" />
    <DxFormProduct />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToProductDataBase"
      >
        {{ $t("Button_cancel_text") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="Button_save_text"
        @click="storeProduct"
      >
        {{ $t('Button_save_text') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DxFormProduct from '../../components/product/DxFormProduct.vue';

import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'StoreProduct',
  components: {
    DxFormProduct,
    navbar,
  },
  computed: {
    titleNavbar() {
      return (this.product.id !== '') ? this.$t('EditProduct_navbar_create') : this.$t('EditProduct_navbar_edit');
    },
    ...mapState('Product', ['product']),
  },
  methods: {
    goToProductDataBase() {
      this.$f7.views.main.router.navigate('/productsDatabase/', { reloadCurrent: true });
    },
    async storeProduct(e) {
      EventBus.$emit('updateProduct');
      e.preventDefault();
      if (this.product.code === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationCode'));
      } else if (this.product.family === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationFamily'));
      } else if (this.product.subfamily === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationSubfamily'));
      } else if (this.product.component === '' && this.product.family === 'PHITOSANITARY') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationComponent'));
      } else if ((typeof (this.product.properties.find((x) => x.key === 'SAPunit')) !== 'undefined')
      && this.product.properties.find((x) => x.key === 'SAPunit').value === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationSapunit'));
      } else if (this.product.description === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationDescription'));
      } else {
        this.$f7.preloader.show();
        try {
          const respuesta = await Api.updateProduct(this.product);
          if (respuesta.status === 409) {
            this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationNameDuplicated'));
          } else {
            this.$f7.views.main.router.navigate('/productsDatabase/', { reloadCurrent: true });
          }
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    ...mapActions('Product', ['setProduct']),
  },
};
</script>
