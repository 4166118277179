<template>
  <f7-page>
    <navbar :text="$t('LeftPanel_subtitle_wholesale_markets')" />
    <DxMarketTab />
  </f7-page>
</template>

<script>

import DxMarketTab from '../../components/workOrder/DxMarketTab.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'WholesaleMarkets',
  components: {
    DxMarketTab,
    navbar,
  },
};
</script>

<style>

</style>
