<template>
  <f7-page>
    <div v-if="loaded">
      <navbar :text="$t('ViewPostWork_Navbar_Title')" />
      <DxViewPlanningWorkOrder
        class="content-planning"
        :actual-work-order="actualWorkOrder"
        :show-tables="true"
        :calendar-multiple="false"
        :max-date="actualDate"
        :distributed-visible="false"
        :working-area-disabled="false"
      />
      <div class="content-devx main-view-post-work">
        <f7-row>
          <!-- <f7-col width="100">
            <f7-block-title
              class="no-padding-top"
              name="select_supervisor"
            >
              {{ $t("DetailTabs_supervisors_title") }}:
            </f7-block-title>
            <DxViewWorkOrderSelectSupervisor
              :supervisors-availables="actualWorkOrder.Supervisor"
              :additional-info-work-order="actualWorkOrder.additionalInfoWorkOrder"
            />
          </f7-col> -->
          <f7-col
            v-if="showIrrigation"
            width="100"
          >
            <f7-block-title>
              {{ $t('DxPostWorkIrrigation_Title') }}:
            </f7-block-title>
            <DxPostWorkIrrigation :read-only="true" />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxEmployeeTable_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderEmployeeTable
              :employees-array="actualWorkOrder.WorkersDetailTab"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxProducts_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderProducts
              :products="actualWorkOrder.products"
              :product-popup="actualWorkOrder.productPopup"
            />
            <f7-block-title>
              {{ $t('DxMachinery_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderMachinaryDriverTable
              :items-availables="actualWorkOrder.items"
              :drivers-availables="actualWorkOrder.itemsDriversWorkOrder"
              :machinery="actualWorkOrder.machineryPopup"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxAttachment_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderAttachmentMachinaryTable
              :types="actualWorkOrder.Attachments"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxTools_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderToolsTable
              :select-tool="actualWorkOrder.Tools"
              :is-post-work="true"
            />
          </f7-col>
          <f7-col
            v-if="showBinsPlanningForm"
            width="100"
          >
            <f7-block-title>
              {{ $t('DxNursery_Title') }}
            </f7-block-title>
            <DxBinsContentGridPlanning
              :allow-editting="false"
            />
          </f7-col>
        </f7-row>
      </div>
      <!-- <f7-block-footer ¿Se quiere algún boton?
        class="content-action-botton"
      >
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goToPostWork"
        >
          {{ $t("CreatePlanning_ButtonCancel_caption") }}
        </f7-button>
      </f7-block-footer> -->
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DxViewPlanningWorkOrder from '../components/ViewPostWorkOrder/DxViewPlanningWorkOrder.vue';
import DxViewWorkOrderAttachmentMachinaryTable from '../components/ViewPostWorkOrder/DxViewWorkOrderAttachmentMachinaryTable.vue';
import DxViewWorkOrderMachinaryDriverTable from '../components/ViewPostWorkOrder/DxViewWorkOrderMachinaryDriverTable.vue';
import DxViewWorkOrderToolsTable from '../components/ViewPostWorkOrder/DxViewWorkOrderToolsTable.vue';
// import DxViewWorkOrderSelectSupervisor from '../components/ViewPostWorkOrder/DxViewWorkOrderSelectSupervisor.vue';
import DxViewWorkOrderProducts from '../components/ViewPostWorkOrder/DxViewWorkOrderProducts.vue';
import DxViewWorkOrderEmployeeTable from '../components/ViewPostWorkOrder/DxViewWorkOrderEmployeeTable.vue';
import DxBinsContentGridPlanning from '../components/greenhouse/DxBinsContentGridPlanning.vue';
import DxPostWorkIrrigation from '../components/postWork/DxPostWorkIrrigation.vue';

import navbar from '../components/NavBar.vue';
import Api from '../services/Api';

export default {
  name: 'ViewPostWork',
  components: {
    DxViewPlanningWorkOrder,
    DxViewWorkOrderAttachmentMachinaryTable,
    DxViewWorkOrderMachinaryDriverTable,
    // DxViewWorkOrderSelectSupervisor,
    DxViewWorkOrderToolsTable,
    DxViewWorkOrderProducts,
    DxViewWorkOrderEmployeeTable,
    DxBinsContentGridPlanning,
    navbar,
    DxPostWorkIrrigation,
  },
  props: {
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
  },
  data() {
    return {
      loaded: false,
      itemsAvailables: [],
      driversAvailables: [],
      attachmentList: [],
      toolList: [],
      typesAttachmentList: [],
      typesMachinary: [],
      supervisorsAvailables: [],
      employeesArray: [],
      actualDate: moment().format('MM/DD/YYYY'),
    };
  },
  computed: {
    ...mapState('PlanningManager', ['activitySelected', 'taskSelected', 'dateSelected', 'workingArea',
      'actualLocationsSelected', 'performanceUnit', 'performanceCount', 'actualWorkOrder', 'employees',
      'items', 'attachments', 'tools', 'groupedSelected', 'shiftValue', 'groupName', 'showProducts', 'showIrrigation']),
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('Prescription', ['prescription']),
    ...mapGetters('greenhouse', ['showBinsPlanningForm']),
  },
  beforeDestroy() {
    this.setTotalAreaSelected(0);
    this.setNewPrescription();
    this.setShowIrrigation(false);
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      this.setNewPrescription();
      // this.initStore();
      // Cargamos los supervisores y el arbol de localizaciones que necesitan los supervisores
      if (this.actualWorkOrder.Supervisor !== '') {
        this.supervisorsAvailables.push({
          Supervisor: this.actualWorkOrder.Supervisor,
        });
      }
      // Cargamos los conductores
      this.driversAvailables = await this.getEmployeesDriver();
      // Cargamos los empleados
      this.employeesArray = await this.getEmployees();
      // Cargamos attachment
      let xhr = await Api.getDataSubfamilyByType('machinery');
      const machineryAttachment = JSON.parse(xhr.response);
      this.setDataAttachment(machineryAttachment);
      // Cargamos tools
      xhr = await Api.getDataSubfamilyByType('tool');
      const dataTools = JSON.parse(xhr.response);
      this.setDataTool(dataTools);
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async getEmployees() {
      const xhr = await Api.getEmployeesAllFullName(
        this.limitFilter,
        this.pageFilter,
        this.fullNameFilter,
      );
      return JSON.parse(xhr.response);
    },
    async getEmployeesDriver() {
      const xhr = await Api.getEmployees();
      return JSON.parse(xhr.response).data.filter((employee) => employee.driver === true);
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    isDriver(empleado) {
      for (let i = 0; i < empleado.properties.length; i += 1) {
        if (empleado.properties[i].key === 'drive') {
          return empleado.properties[i].value;
        }
      }
      return 'False';
    },
    setDataAttachment(dataMachinaryAttachments) {
      this.itemsAvailables.length = 0;
      this.attachmentList.length = 0;
      this.typesAttachmentList.length = 0;
      this.typesMachinary.length = 0;
      for (let i = 0; i < dataMachinaryAttachments.length; i += 1) {
        if (dataMachinaryAttachments[i].id === 'VEHICLE') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.itemsAvailables.push(dataMachinaryAttachments[i].items[j]);
            this.typesMachinary.push({ id: dataMachinaryAttachments[i].items[j].id, name: dataMachinaryAttachments[i].items[j].id });
          }
        } else if (dataMachinaryAttachments[i].id === 'ATTACHMENT') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.attachmentList.push(dataMachinaryAttachments[i].items[j]);
            this.typesAttachmentList.push({ id: dataMachinaryAttachments[i].items[j].id, name: dataMachinaryAttachments[i].items[j].id });
          }
        }
      }
    },
    setDataTool(tools) {
      this.toolList.length = 0;
      for (let i = 0; i < tools.length; i += 1) {
        if (tools[i].id === 'TOOL') {
          for (let j = 0; j < tools[i].items.length; j += 1) {
            this.toolList.push({
              name: tools[i].items[j].id,
              id: tools[i].items[j].id,
            });
          }
          break;
        }
      }
    },
    goToPostWork() {
      this.$f7.views.main.router.navigate('/workOrders/', { reloadCurrent: true });
    },
    async createPlanning() {
      this.goToPostWork(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
    },
    calculateItems(items) {
      const arrayItems = [];
      const arrayNames = [];
      if (items.length > 0) {
        for (let i = 0; i < items.length; i += 1) {
          if (arrayNames.indexOf(items[i].type) >= 0) {
            arrayItems[arrayNames.indexOf(items[i].type)].count += 1;
          } else {
            arrayNames.push(items[i].type);
            arrayItems.push({ type: items[i].type, count: 1 });
          }
        }
      }
      return arrayItems;
    },
    calculateAttachments(attachments) {
      const arrayAttachments = [];
      const arrayNames = [];
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i += 1) {
          if (arrayNames.indexOf(attachments[i].type) >= 0) {
            arrayAttachments[arrayNames.indexOf(attachments[i].type)].count += 1;
          } else {
            arrayNames.push(attachments[i].type);
            arrayAttachments.push({ type: attachments[i].type, count: 1 });
          }
        }
      }
      return arrayAttachments;
    },
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('Prescription', ['setNewPrescription']),
    ...mapActions('PlanningManager', ['setShowIrrigation']),
  },
};
</script>
