<template>
  <f7-page>
    <navbar :text="$t('CreatePoint_Title')" />
    <DxPointForm
      class="content-planning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToMonitoringPoints"
      >
        {{ $t("Button_cancel_text") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="createPoint"
      >
        {{ $t('Button_save_text') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxPointForm from '../../components/scouting/DxPointForm.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  name: 'CreatePoint',
  components: {
    DxPointForm,
    navbar,
  },
  computed: {
    ...mapState('Scouting', ['point']),
    ...mapState('PlanningManager', ['actualLocationsSelectedNoRecursive']),
  },
  methods: {
    goToMonitoringPoints() {
      this.$f7.views.main.router.navigate('/monitoringPoints/', { reloadCurrent: true });
    },
    async createPoint() {
      if (this.point.name === '') {
        this.$f7.dialog.alert(this.$t('Point_name_validation'));
      } else if (this.point.type === '') {
        this.$f7.dialog.alert(this.$t('Point_type_validation'));
      } else if (this.actualLocationsSelectedNoRecursive.length > 1) {
        this.$f7.dialog.alert(this.$t('Point_location_validation'));
      } else if (this.actualLocationsSelectedNoRecursive.length === 0) {
        this.$f7.dialog.alert(this.$t('Point_no_selected_location_validation'));
      } else {
        this.$f7.preloader.show();
        try {
          let year = null;
          if (this.point.yearPlanting !== 'Invalid date') {
            year = parseInt(this.point.yearPlanting, 10);
          }
          const newPoint = {
            name: this.point.name,
            location: this.actualLocationsSelectedNoRecursive[0],
            coordinates: {
              latitude: this.point.latitude,
              longitude: this.point.longitude,
            },
            number_plants: this.point.numberPlants,
            variety: this.point.variety,
            type: this.point.type,
            planting_year: year,
            status: this.point.status,
            note: this.point.note,
          };
          await Api.createPoint(newPoint);
          this.goToMonitoringPoints(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
  },
};
</script>
