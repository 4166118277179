<template>
  <f7-page>
    <navbar :text="$t('MonitoringPoints_title')" />
    <DxMonitoringPoints />
  </f7-page>
</template>

<script>

import DxMonitoringPoints from '../../components/scouting/DxMonitoringPoints.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxMonitoringPoints,
    navbar,
  },
};
</script>

<style>

</style>
