var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-block',{staticClass:"no-padding"},[_c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxSimpleItem',{attrs:{"editor-options":{
        items: _vm.areaList,
        displayExpr:'name',
        valueExpr:'id',
        searchEnabled: true,
        onValueChanged: _vm.changeLevel,
        placeholder: 'Selecciona'
      },"editor-type":"dxSelectBox"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }