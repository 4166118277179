<template>
  <div>
    <f7-row>
      <f7-col>
        <DxForm :col-count="1">
          <DxGroupItem
            css-class="content-activity"
            :col-span="1"
          >
            <DxSimpleItem
              css-class="content-activity__area"
              :data-field="$t('totalArea_field')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: totalAreaSelected,
                mode: 'number',
                disabled: true,
              }"
            />
            <DxSimpleItem
              css-class="content-activity__area"
              :data-field="$t('DxWorkOrder_short_id')"
              :editor-options="{
                value: planning.shortId,
                disabled: true,
              }"
            />
          </DxGroupItem>
        </DxForm>
        <DxForm
          id="form"
          :col-count="1"
        >
          <DxGroupItem :col-span="1">
            <DxGroupItem
              :caption="$t('PlanningComponent_caption_text')"
            >
              <DxSimpleItem
                :data-field="$t('PlanningComponent_activityName_caption')"
                :editor-options="{
                  value: planning.Activity,
                  disabled: true,
                }"
                name="activityName"
              />

              <DxSimpleItem
                :data-field="$t('PlanningComponent_activitycode_caption')"
                name="activityCode"
                :editor-options="{ disabled: true, value: planning.activityCode }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_taskName_caption')"
                :editor-options="{
                  value: planning.Task,
                  disabled: true,
                }"
                name="taskName"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_taskCode_caption')"
                name="taskCode"
                :editor-options="{ disabled: true, value: planning.taskCode }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_variety_caption')"
                :editor-options="{
                  value: planning.variety,
                  disabled: true,
                }"
                name="variety"
                :visible="planning.activityCode === '08'"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_date_caption')"
                name="date"
                :editor-options="{
                  dateFormat: 'dd/mm/yyyy',
                  value: planning.Date,
                  disabled: true,
                }"
              />
              <DxSimpleItem
                :data-field="`${$t('PlanningComponent_workingarea_caption')} (HA)`"
                name="workingArea"
                :editor-options="{
                  disabled: true,
                  value: parseFloat(planning.area_effective, 10).toFixed(2),
                  mode: 'number',
                  placeholder: '0',
                }"
              />
              <DxSimpleItem
                v-if="planning.Planner && planning.Planner.goal_performance.unit !== ''"
                :data-field="`${$t('PlanningComponent_performanceCount_caption')} (${planning.Planner.goal_performance.unit})`"
                name="PerformanceCount"
                editor-type="dxNumberBox"
                :editor-options="{
                  disabled: true,
                  value: planning.Planner.goal_performance.count,
                  mode: 'number',
                  placeholder: '0',
                }"
              />
              <DxSimpleItem
                v-if="(typeof planning.manualPerformance) !== 'undefined'"
                :data-field="$t('PlanningComponent_manualPerformance_caption')"
                name="manualPerformance"
                editor-type="dxNumberBox"
                :editor-options="{
                  disabled: true,
                  value: planning.manualPerformance.total,
                  mode: 'number',
                }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_distributed_caption')"
                name="distributed"
                :visible="distributedVisible"
                editor-type="dxCheckBox"
                :editor-options="{
                  value: planning.Planner.distributed,
                  disabled: true,
                }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_cost_caption')"
                name="cost"
                editor-type="dxNumberBox"
                :editor-options="{
                  disabled: true,
                  value: planning.cost,
                  mode: 'number',
                }"
              />
            </DxGroupItem>
          </DxGroupItem>
          <DxGroupItem :col-span="1">
            <DxGroupItem
              :caption="$t('PlanningComponent_numberWorkers_caption')"
            >
              <DxSimpleItem
                :data-field="$t('PlanningComponent_seasional_caption')"
                :editor-options="{
                  value: planning.Workers,
                  disabled: true,
                }"
              />
            </DxGroupItem>
          </DxGroupItem>
        </DxForm>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxViewWorkOrderForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
  },
  props: {
    planning: { type: Object, default: null },
    showTables: { type: Boolean, default: true },
    distributedVisible: { type: Boolean, default: true },
  },
  computed: {
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('PlanningManager', ['workers']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.setShowProducts((this.planning.products.length > 0));
  },
  methods: {
    ...mapActions('PlanningManager', ['setShowProducts']),
  },
};
</script>

<style lang="scss">
#form-container {
  margin: 10px 10px 30px;
}

.long-title h3 {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.content-activity{
  .dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection{
    padding-top: 0;
    margin-top: -5px;
  }
  &__area{
    background-color: #EAE3D8;
    border-radius: 3px;
    padding: 8px 10px;
    margin: 15px 0;
    span, input{
      color: #6F4343;
      opacity: 1;
      text-transform: none;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }
    input{
      font-weight: normal;
    }
  }
}
.content-machinery{
  .content-devx{
    margin: 0 0 15px 0!important;
  }
  .dx-form-group-caption{
    padding: 0 0 10px 0!important;
    display: block;
  }
}
</style>
