<template>
  <f7-page>
    <navbar :text="$t('Monitoring_others_navbar_title')" />
    <DxMonitoringOthers :key-name="'monitoringOthers'" />
  </f7-page>
</template>

<script>
import DxMonitoringOthers from '../../components/scouting/DxMonitoringOthers.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'MonitoringOthers',
  components: {
    DxMonitoringOthers,
    navbar,
  },
};
</script>
