var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-devx content-dx-daily-planning no-shadow no-margin"},[_c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxPostWorkIrrigation_Dose'),"caption":_vm.$t('DxPostWorkIrrigation_Dose'),"editor-options":{
          readOnly: _vm.readOnly,
          value: _vm.irrigation.dose,
          showSpinButtons: false,
          mode: 'number',
          placeholder: '0',
          showClearButton: true,
          min: '0',
          step: '1',
          onValueChanged: _vm.doseChanged,
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxPostWorkIrrigation_Hour'),"caption":_vm.$t('DxPostWorkIrrigation_Hour'),"editor-options":{
          readOnly: _vm.readOnly,
          value: _vm.irrigation.time,
          showSpinButtons: false,
          mode: 'number',
          placeholder: '0',
          showClearButton: true,
          min: '0',
          step: '1',
          onValueChanged: _vm.timeChanged,
        }}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }