var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DxForm',{attrs:{"id":"form"}},[_c('DxGroupItem',{attrs:{"col-count":2,"caption":_vm.$t('DxTerritorialUnitContentForm_Title')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxTerritorialUnitContentForm_Name'),"editor-options":{
          onValueChanged: _vm.changeName,
          value: _vm.actualName,
        },"editor-type":"dxTextBox","name":"Name"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxTerritorialUnitContentForm_Required_Name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxTerritorialUnitContentForm_Area'),"editor-options":{
          onValueChanged: _vm.changeArea,
          value: _vm.actualArea,
          disabled: _vm.disableArea,
        },"editor-type":"dxNumberBox","name":"Area"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxTerritorialUnitContentForm_Required_Area')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxTerritorialUnitContentForm_Code'),"editor-options":{
          onValueChanged: _vm.changeCode,
          value: _vm.actualCode,
        },"editor-type":"dxTextBox","name":"Code"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxTerritorialUnitContentForm_Required_Code')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_inpCity'),"editor-options":{
          items: _vm.citiesToShow,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          onValueChanged: _vm.changeCity,
          value: _vm.actualCity,
        },"editor-type":"dxSelectBox","name":"cityName"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Register_inpCity')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxTerritorialUnitContentForm_Type'),"editor-options":{
          items: _vm.typesToDisplay,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          onValueChanged: _vm.changeType,
          value: _vm.actualType,
        },"editor-type":"dxSelectBox","name":"Type"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxTerritorialUnitContentForm_Required_Type')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }