<template>
  <div
    class="content-devx content-dx-worker main-dx-machinery"
  >
    <f7-block-title>
      {{ $t('QuillabambaAgroveterinarySuppliesPrices') }}
    </f7-block-title>
    <DxDataGrid
      id="worker-grid"
      :data-source="data"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxWeatherHumidityGrid"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="Página {0} de {1} ({2} elementos)"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="Nro"
        caption="Nro"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="PRODUCTO"
        caption="PRODUCTO"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="CANTIDAD"
        caption="CANTIDAD"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="UNIDAD"
        caption="UNIDAD"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="pUnitario"
        caption="P. UNITARIO"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'CuscoAgroveterinarySuppliesPrices',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      data: [
        {
          Nro: 1,
          PRODUCTO: 'NITRATO DE AMONIO',
          UNIDAD: 'BOLSA',
          CANTIDAD: 1,
          pUnitario: 140,
        },
        {
          Nro: 2,
          PRODUCTO: 'FOSFATO DI AMONICO',
          UNIDAD: 'BOLSA',
          CANTIDAD: 1,
          pUnitario: 185,
        },
        {
          Nro: 3,
          PRODUCTO: 'CLORURO DE POTASIO',
          UNIDAD: 'BOLSA',
          CANTIDAD: 1,
          pUnitario: 145,
        },
        {
          Nro: 4,
          PRODUCTO: 'NPK 20 20',
          UNIDAD: 'BOLSA',
          CANTIDAD: 1,
          pUnitario: 150,
        },
        {
          Nro: 5,
          PRODUCTO: 'SULFAMAR',
          UNIDAD: 'BOLSA',
          CANTIDAD: 1,
          pUnitario: 140,
        },
        {
          Nro: null,
          PRODUCTO: 'PARA CAFÉ',
          UNIDAD: null,
          CANTIDAD: null,
          pUnitario: null,
        },
        {
          Nro: 6,
          PRODUCTO: 'PACAR',
          UNIDAD: 'LITRO',
          CANTIDAD: 1,
          pUnitario: 50,
        },
        {
          Nro: 7,
          PRODUCTO: 'FERTILEC 11 - 8 - 6',
          UNIDAD: 'LITO',
          CANTIDAD: 1,
          pUnitario: 30,
        },
        {
          Nro: 8,
          PRODUCTO: 'NEMATODES',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 20,
        },
        {
          Nro: null,
          PRODUCTO: null,
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 6,
        },
        {
          Nro: null,
          PRODUCTO: 'PARA CACAO',
          UNIDAD: 'KILO',
          CANTIDAD: 1,
          pUnitario: 4.5,
        },
        {
          Nro: 9,
          PRODUCTO: 'ABAMET',
          UNIDAD: 'LITRO',
          CANTIDAD: 1,
          pUnitario: 125,
        },
        {
          Nro: 10,
          PRODUCTO: 'GLORGOS',
          UNIDAD: 'LITRO',
          CANTIDAD: 1,
          pUnitario: 55,
        },
        {
          Nro: 11,
          PRODUCTO: 'TRONCOL',
          UNIDAD: 'LITRO',
          CANTIDAD: '1 CUARTO',
          pUnitario: 90,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('QuillabambaAgroveterinarySuppliesPrices');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WeatherHumidityGrid.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
