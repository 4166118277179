<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-worker"
  >
    <DxDataGrid
      id="workers-tab"
      ref="workersTable"
      :data-source="workersFormated"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="false"
      @exporting="onExporting"
    >
      <DxExport
        :enabled="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
        :text="searchText"
      />
      <DxColumn
        data-field="workerName"
        name="workerName"
        :caption="$t('DxWorker_workerName_caption')"
      />
      <DxColumn
        data-field="initDate"
        name="initDate"
        :caption="$t('DxWorker_checkIn_caption')"
      />
      <DxColumn
        data-field="endDate"
        name="endDate"
        :caption="$t('DxWorker_checkOut_caption')"
      />
      <DxColumn
        data-field="totalHoursForMethod"
        name="totalHoursForMethod"
        :caption="$t('DxWorker_totalHours_caption')"
      />
      <DxColumn
        data-field="note"
        :caption="$t('DxWorker_note_caption')"
        :allow-header-filtering="false"
        alignment="left"
        :width="200"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxExport,
    DxSearchPanel,
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      workersFormated: [],
      loaded: false,
      text: '',
    };
  },
  computed: {
    ...mapState('worker', ['searchText']),
  },
  beforeMount() {
    this.$f7.preloader.show();
    for (let i = 0; i < this.masterDetailData.data.workerName.length; i += 1) {
      this.workersFormated.push({
        workerName: this.masterDetailData.data.workerName[i],
        initDate: this.masterDetailData.data.initDate[i],
        endDate: this.masterDetailData.data.endDate[i],
        totalHoursForMethod: this.masterDetailData.data.totalHoursForMethod[i],
        note: this.masterDetailData.data.note,
      });
    }
    this.$f7.preloader.hide();
    this.loaded = true;
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('DetailsWorkers');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DetailsWorkers.xlsx');
          });
      });
      e.cancel = true;
    },
  },

};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
