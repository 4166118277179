<template>
  <f7-page>
    <navbar :text="$t('LeftPanel_subtitle_wholesale_market_prices')" />
    <DxQuillabambaMarketPrices />
    <DxVinoMarketPrices />
    <DxHuancaroMarketPrices />
  </f7-page>
</template>

<script>
import DxQuillabambaMarketPrices from '../../components/markets/DxQuillabambaMarketPrices.vue';
import DxVinoMarketPrices from '../../components/markets/DxVinoMarketPrices.vue';
import DxHuancaroMarketPrices from '../../components/markets/DxHuancaroMarketPrices.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'WholesaleMarketsPrices',
  components: {
    DxQuillabambaMarketPrices,
    DxVinoMarketPrices,
    DxHuancaroMarketPrices,
    navbar,
  },
};
</script>

<style>

</style>
