var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('f7-row',[_c('f7-col',[_c('DxForm',{attrs:{"col-count":1}},[_c('DxGroupItem',{attrs:{"css-class":"content-activity","col-span":1}},[_c('DxSimpleItem',{attrs:{"css-class":"content-activity__area","data-field":_vm.$t('totalArea_field'),"editor-type":"dxNumberBox","editor-options":{
              value: _vm.totalAreaSelected,
              mode: 'number',
              disabled: true,
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"css-class":"content-activity__area","data-field":_vm.$t('DxWorkOrder_short_id'),"editor-options":{
              value: _vm.planning.shortId,
              disabled: true,
            }}})],1)],1),_vm._v(" "),_c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxGroupItem',{attrs:{"col-span":1}},[_c('DxGroupItem',{attrs:{"caption":_vm.$t('PlanningComponent_caption_text')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_activityName_caption'),"editor-options":{
                value: _vm.planning.Activity,
                disabled: true,
              },"name":"activityName"}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_activitycode_caption'),"name":"activityCode","editor-options":{ disabled: true, value: _vm.planning.activityCode }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_taskName_caption'),"editor-options":{
                value: _vm.planning.Task,
                disabled: true,
              },"name":"taskName"}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_taskCode_caption'),"name":"taskCode","editor-options":{ disabled: true, value: _vm.planning.taskCode }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_variety_caption'),"editor-options":{
                value: _vm.planning.variety,
                disabled: true,
              },"name":"variety","visible":_vm.planning.activityCode === '08'}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_date_caption'),"name":"date","editor-options":{
                dateFormat: 'dd/mm/yyyy',
                value: _vm.planning.Date,
                disabled: true,
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('PlanningComponent_workingarea_caption')) + " (HA)"),"name":"workingArea","editor-options":{
                disabled: true,
                value: parseFloat(_vm.planning.area_effective, 10).toFixed(2),
                mode: 'number',
                placeholder: '0',
              }}}),_vm._v(" "),(_vm.planning.Planner && _vm.planning.Planner.goal_performance.unit !== '')?_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('PlanningComponent_performanceCount_caption')) + " (" + (_vm.planning.Planner.goal_performance.unit) + ")"),"name":"PerformanceCount","editor-type":"dxNumberBox","editor-options":{
                disabled: true,
                value: _vm.planning.Planner.goal_performance.count,
                mode: 'number',
                placeholder: '0',
              }}}):_vm._e(),_vm._v(" "),((typeof _vm.planning.manualPerformance) !== 'undefined')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_manualPerformance_caption'),"name":"manualPerformance","editor-type":"dxNumberBox","editor-options":{
                disabled: true,
                value: _vm.planning.manualPerformance.total,
                mode: 'number',
              }}}):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_distributed_caption'),"name":"distributed","visible":_vm.distributedVisible,"editor-type":"dxCheckBox","editor-options":{
                value: _vm.planning.Planner.distributed,
                disabled: true,
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_cost_caption'),"name":"cost","editor-type":"dxNumberBox","editor-options":{
                disabled: true,
                value: _vm.planning.cost,
                mode: 'number',
              }}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":1}},[_c('DxGroupItem',{attrs:{"caption":_vm.$t('PlanningComponent_numberWorkers_caption')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_seasional_caption'),"editor-options":{
                value: _vm.planning.Workers,
                disabled: true,
              }}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }