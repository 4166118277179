<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-shadow no-margin"
    >
      <DxForm
        id="form"
        :col-count="1"
      >
        <DxSimpleItem
          :data-field="$t('DxPostWorkIrrigation_Dose')"
          :caption="$t('DxPostWorkIrrigation_Dose')"
          :editor-options="{
            readOnly: readOnly,
            value: irrigation.dose,
            showSpinButtons: false,
            mode: 'number',
            placeholder: '0',
            showClearButton: true,
            min: '0',
            step: '1',
            onValueChanged: doseChanged,
          }"
        />
        <DxSimpleItem
          :data-field="$t('DxPostWorkIrrigation_Hour')"
          :caption="$t('DxPostWorkIrrigation_Hour')"
          :editor-options="{
            readOnly: readOnly,
            value: irrigation.time,
            showSpinButtons: false,
            mode: 'number',
            placeholder: '0',
            showClearButton: true,
            min: '0',
            step: '1',
            onValueChanged: timeChanged,
          }"
        />
      </DxForm>
    </div>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DxPostWorkIrrigation',
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      time: 0,
      dose: 0,
    };
  },
  computed: {
    ...mapGetters('PlanningManager', ['irrigation']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
  },
  methods: {
    timeChanged(e) {
      this.time = e.value;
      this.setIrrigationTime(e.value);
    },
    doseChanged(e) {
      this.time = e.dose;
      this.setIrrigationDose(e.value);
    },
    ...mapActions('PlanningManager', ['setIrrigationTime', 'setIrrigationDose']),
  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
