<template>
  <div
    class="content-devx content-dx-worker main-dx-machinery"
  >
    <f7-block-title>
      {{ $t('DxSoldTable_Title') }}:
    </f7-block-title>
    <DxDataGrid
      id="worker-grid"
      :data-source="dataSource"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
    >
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxCostTable_date')"
        :allow-header-filtering="false"
        alignment="left"
        data-type="datetime"
        format="yyyy-MM-dd"
      />
      <DxColumn
        data-field="task"
        :caption="$t('DxCostTable_task')"
        :allow-header-filtering="false"
        alignment="left"
        data-type="datetime"
        format="HH:mm"
      />
      <DxColumn
        data-field="cost"
        :caption="$t('DxSoldTable_cost')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';

export default {
  name: 'DxSoldTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxPager,
    DxSelection,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
