import Api from '../../services/Api';

import {
  APPLICATION_NAME,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  APP_LOGO,
} from '../../js/constants';
import EventBus from '../../js/event-bus';

export default {
  namespaced: true,

  state: {
    appConfig: {
      name: APPLICATION_NAME,
      logoUrl: APP_LOGO,
      apiBaseUrl: null,
      primaryColor: DEFAULT_PRIMARY_COLOR,
      secondaryColor: DEFAULT_SECONDARY_COLOR,
    },
    loggedUser: null,
    wantToRemember: false,
    wantToRecoveryPass: false,
    changePassword: false,
    messageChangePassword: false,
    currentLocation: null,
    user: null,
    actualMenuSelected: undefined,
    userRegister: {
      name: '',
      surname: '',
      city: '',
      email: '',
      emailConfirm: '',
      password: '',
      passwordConfirm: '',
      phone: '',
      inscriptionType: '',
      sms: false,
      cultive: null,
    },
    tempSelectedUser: null,
  },

  getters: {
    getWantToRemember: (state) => state.wantToRemember,
    getPermissions: (state) => state.user.permissions,
    userFarmsIds: (state) => state.user.farms_id,
    getActualMenuSelected: (state) => state.actualMenuSelected,
    userRegister: (state) => state.userRegister,
    disableRegisterButton(state) {
      if (state.userRegister.name !== ''
          && state.userRegister.surname !== ''
          && state.userRegister.city !== ''
          && state.userRegister.email !== ''
          && state.userRegister.emailConfirm !== ''
          && state.userRegister.password !== ''
          && state.userRegister.passwordConfirm !== ''
          && state.userRegister.phone !== ''
          && state.userRegister.inscriptionType !== ''
          && state.userRegister.email === state.userRegister.emailConfirm
          && state.userRegister.password === state.userRegister.passwordConfirm
          && (!state.userRegister.sms || (state.userRegister.sms && state.userRegister.cultive !== null))) {
        return false;
      }
      return true;
    },
  },

  actions: {
    async fetchGetCities({ commit }) {
      let response = [];
      try {
        response = await Api.getCities();
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchRegisterUser({ commit, getters }) {
      try {
        const xhr = await Api.registerUser(getters.userRegister);
        if (xhr.status === 204) {
          return Promise.resolve();
        }
        return Promise.reject(xhr.response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    initialize(context) {
      if (context.state.loggedUser) {
        EventBus.$emit('newLoggedUser', context.state.loggedUser);
      }
      EventBus.$on('invalidTokenDetected', () => context.dispatch('logout'));
      EventBus.$on('newLoggedUser', (newLoggedUser) => context.commit('setLoggedUser', newLoggedUser));
      EventBus.$on('newLoggedUser', (newLoggedUser) => context.commit('setUser', JSON.parse(newLoggedUser)));
    },

    async logout(context) {
      context.commit('setLoggedUser', null);
      context.commit('setWantToRemember', false);
      context.commit('setActualMenuSelected', undefined);
      await context.dispatch('AccessControl/destroy', null, { root: true });
    },

    selectLocation(context, value) {
      context.commit('setCurrentLocation', value);
    },

    wantToRecoveryPass(context) {
      context.commit('wantToRecoveryPass');
    },

    changePassword(context) {
      context.commit('changePassword');
    },

    setMessageChangePassword(context) {
      context.commit('setMessageChangePassword');
    },
    setActualMenuSelected({ commit }, payload) {
      commit('setActualMenuSelected', payload);
    },

    setNewUserToRegister({ commit }) {
      commit('SET_NEW_USER_TO_REGISTER', '');
    },
    setUserName({ commit }, payload) {
      commit('SET_USER_NAME', payload);
    },
    setUserSurname({ commit }, payload) {
      commit('SET_USER_SURNAME', payload);
    },
    setUserCity({ commit }, payload) {
      commit('SET_USER_CITY', payload);
    },
    setUserEmail({ commit }, payload) {
      commit('SET_USER_EMAIL', payload);
    },
    setUserEmailConfirm({ commit }, payload) {
      commit('SET_USER_EMAIL_CONFIRM', payload);
    },
    setUserPassword({ commit }, payload) {
      commit('SET_USER_PASSWORD', payload);
    },
    setUserPasswordConfirm({ commit }, payload) {
      commit('SET_USER_PASSWORD_CONFIRM', payload);
    },
    setUserPhone({ commit }, payload) {
      commit('SET_USER_PHONE', payload);
    },
    setUserInscriptionType({ commit }, payload) {
      commit('SET_USER_INSCRIPTION_TYPE', payload);
    },
    setUserSMS({ commit }, payload) {
      commit('SET_USER_SMS', payload);
    },
    setCultive({ commit }, payload) {
      commit('setCultive', payload);
    },
    setTempSelectedUser({ commit }, payload) {
      commit('setTempSelectedUser', payload);
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setLoggedUser(state, user) {
      state.loggedUser = user;
    },

    setAppConfig(state, value) {
      state.appConfig = value;
    },

    setWantToRemember(state, value) {
      state.wantToRemember = value;
    },

    wantToRecoveryPass(state) {
      state.wantToRecoveryPass = !state.wantToRecoveryPass;
    },

    changePassword(state) {
      state.changePassword = !state.changePassword;
    },

    setMessageChangePassword(state) {
      state.messageChangePassword = !state.messageChangePassword;
    },
    setActualMenuSelected(state, payload) {
      state.actualMenuSelected = payload;
    },

    SET_NEW_USER_TO_REGISTER(state) {
      state.userRegister = {
        name: '',
        surname: '',
        city: '',
        email: '',
        emailConfirm: '',
        password: '',
        passwordConfirm: '',
        phone: '',
        inscriptionType: '',
        sms: false,
        cultive: null,
      };
    },
    SET_USER_NAME(state, payload) {
      state.userRegister.name = payload;
    },
    SET_USER_SURNAME(state, payload) {
      state.userRegister.surname = payload;
    },
    SET_USER_CITY(state, payload) {
      state.userRegister.city = payload;
    },
    SET_USER_EMAIL(state, payload) {
      state.userRegister.email = payload;
    },
    SET_USER_EMAIL_CONFIRM(state, payload) {
      state.userRegister.emailConfirm = payload;
    },
    SET_USER_PASSWORD(state, payload) {
      state.userRegister.password = payload;
    },
    SET_USER_PASSWORD_CONFIRM(state, payload) {
      state.userRegister.passwordConfirm = payload;
    },
    SET_USER_PHONE(state, payload) {
      state.userRegister.phone = payload;
    },
    SET_USER_INSCRIPTION_TYPE(state, payload) {
      state.userRegister.inscriptionType = payload;
    },
    SET_USER_SMS(state, payload) {
      state.userRegister.sms = payload;
    },
    setCultive(state, payload) {
      state.userRegister.cultive = payload;
    },
    setTempSelectedUser(state, payload) {
      state.tempSelectedUser = payload;
    },
  },
};
