var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{staticClass:"content-list-order main-casualregister  bg-cream"},[_c('navbar',{attrs:{"new-icon-enable":true,"text":("" + (_vm.$t('LeftPanel_Subtitle_CasualRegister'))),"show-button-sync":true}}),_vm._v(" "),_c('div',{staticClass:"bg-color-kinder padding-half text-align-center"},[_c('span',{staticClass:"text-color-white"},[_vm._v(_vm._s(_vm.getActualDate()))])]),_vm._v(" "),_c('div',{staticClass:"content-devx"},[_c('DxForm',{attrs:{"id":"form"}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('CasualRegister_Title_IncidentName'),"editor-type":"dxTextBox","editor-options":{
          value: _vm.incidentName,
          onValueChanged: _vm.changeIncidentName,
          showSpinButtons: false,
          placeholder: '',
          showClearButton: true,
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('CasualRegister_Title_Type'),"caption":_vm.$t('CasualRegister_Title_Type'),"editor-type":"dxSelectBox","editor-options":{
          dataSource: _vm.typePlagues,
          searchEnabled: false,
          onValueChanged: _vm.changeType,
          value: _vm.selectedType,
          placeholder: 'Selecciona',
          valueExpr: 'id',
          displayExpr: 'name'
        }}})],1),_vm._v(" "),_c('f7-block',{staticClass:"content-list"},[_c('div',{staticClass:"block-title margin-top margin-bottom-half"},[_c('span',{staticClass:"text-color-nutella"},[_vm._v(_vm._s(_vm.$t('CasualRegister_Title_Location')))])]),_vm._v(" "),_c('f7-block',{staticClass:"no-padding main-changelocation__treelist margin-bottom"},[_c('div',{staticClass:"card card-outline no-margin"},[_c('div',{staticClass:"card-content"},[_c('f7-treeview',[_c('RecursiveTreeView',{attrs:{"nodes":_vm.locationTreeTreated,"single-selection-enabled":true,"last-level-to-display":2}})],1)],1)])])],1),_vm._v(" "),_c('f7-button',{staticClass:"dx-btn-success",staticStyle:{"margin":"0 0.5rem"},attrs:{"name":"btnContinue","raised":"","type":"success","button":""},on:{"click":function($event){return _vm.addCasualRegister()}}},[_vm._v("\n      "+_vm._s(_vm.$t("FixedMonitoringPoint_Button_Continue"))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }