<template>
  <f7-page>
    <navbar :text="$t('Nursery_navbar_title')" />
    <DxNurseryReport />
  </f7-page>
</template>

<script>
import DxNurseryReport from '../../components/reporting/DxNurseryReport.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'NurseryReport',
  components: {
    DxNurseryReport,
    navbar,
  },
};
</script>
